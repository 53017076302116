<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpText
    :text="value.text"
    pre
  >
  </ItpText>
  <ItpButton
    :text="'Edit text templates...'|pgettext('MultilineNotes')"
    @click.prevent.stop="evh_5962862420439144_clickPreventStop($event, {})"
  >
  </ItpButton>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class MultilineNotes extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  value!: object;

  @Watch('value')
  onValue(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("value")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_showModal_2960_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_5962862420439144_showModal_2960, alias=undefined
    return {
      name: "TextTemplates",
    }
  }

  async evh_5962862420439144_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_2958: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_2957: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_5962862420439144_showModal_2960: actions.ShowModalAction;
    evh_5962862420439144_clickPreventStop_2959: actions.EventHandlerImpl;
    evh_5962862420439144_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_2958 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_2957 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_2958,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_2957],
      }
    );
    const act_5962862420439144_showModal_2960 = new actions.ShowModalAction(
      {
        actionArgs: this.act_5962862420439144_showModal_2960_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_clickPreventStop_2959 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_showModal_2960,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_5962862420439144_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_clickPreventStop_2959],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_2958,
      evh_7315092382398562_reload_2957,
      evh_7315092382398562_reload,
      act_5962862420439144_showModal_2960,
      evh_5962862420439144_clickPreventStop_2959,
      evh_5962862420439144_clickPreventStop,
    }
  }
}

Vue.component("MultilineNotes", MultilineNotes);

</script>