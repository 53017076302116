<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <DIV
    class="card"
    v-if="loaded"
  >
    <DIV
      class="card-header d-flex align-items-center"
    >
      <DIV>
        <SPAN>
          {{ 'Notifications'|gettext }}
        </SPAN>
        <SPAN
          class="badge badge-secondary ml-2"
          v-if="items.length"
        >
          {{ items.length }}
        </SPAN>
      </DIV>
      <DIV
        class="ml-auto"
      >
      </DIV>
      <DIV>
        <ItpButton
          icon="fa-sync"
          :tooltip="'Reload'|gettext"
          :spinning="actions.reload && actions.reload.isRunning"
          size="sm"
          variant="link"
          @click="evh_8697745105058001_click($event, {})"
        >
        </ItpButton>
      </DIV>
    </DIV>
    <UL
      class="list-group list-group-flush"
      v-if="items.length"
      :style="b_3343864469950044_style"
    >
      <LI
        :key="item.id"
        class="list-group-item"
        v-for="item in items"
      >
        <ItpBox>
          <UcExpiringContractNotificationCard
            :notification="item"
            v-if="item.type == 'expiring_contract'"
          >
          </UcExpiringContractNotificationCard>
          <UcInflationIndexedPriceChangeNotificationCard
            :notification="item"
            v-if="item.type == 'inflation_indexed_price_change'"
          >
          </UcInflationIndexedPriceChangeNotificationCard>
          <button
            data-dismiss="modal"
            :aria-label="'Remove'|gettext"
            :title="'Remove'|gettext"
            class="close ml-auto mt-2 mr-2 align-self-start"
            @click="evh_2406129194387753_click($event, {item})"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </ItpBox>
      </LI>
    </UL>
    <DIV
      class="card-body"
      v-if="!items.length"
    >
      <p
        class="card-text"
      >
        {{ 'No new notifications.'|gettext }}
      </p>
    </DIV>
  </DIV>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class Notifications extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  items!: any;

  async $$load_items() {
    return this.$fn.fetch('list_notification', { parameters: { state: 'new' } })
  }

  ux!: any;
  b_3343864469950044_style!: any;
  dataMembers = ['items', 'ux', 'b_3343864469950044_style'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        items: null,
        ux: null,
        b_3343864469950044_style: {
          overflow: "auto",
        }
        ,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async evh_8697745105058001_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8697745105058001_click.executeFromDOM(this, event, scope);
  }

  async act_2406129194387753_request_2226_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_2406129194387753_request_2226, alias=markRead
    return {
      operation: "update_notification",
      data: {
        id: $event.scope.item.id,
        etag: $event.scope.item.etag,
        state: 'handled',
        result_notes: this.notes,
      },
    }
  }

  async evh_2406129194387753_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2406129194387753_click.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_2222: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_2221: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_8697745105058001_reloadComponentData_2224: actions.ReloadComponentDataAction;
    evh_8697745105058001_click_2223: actions.EventHandlerImpl;
    evh_8697745105058001_click: actions.EventHandlerGroup;
    act_2406129194387753_reloadComponentData_2228: actions.ReloadComponentDataAction;
    evh_2406129194387753_success_2227: actions.EventHandlerImpl;
    act_2406129194387753_request_2226: actions.RequestAction;
    evh_2406129194387753_click_2225: actions.EventHandlerImpl;
    evh_2406129194387753_click: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_2222 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_2221 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_2222,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_2221],
      }
    );
    const act_8697745105058001_reloadComponentData_2224 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_8697745105058001_click_2223 = new actions.EventHandlerImpl(
      {
        action: act_8697745105058001_reloadComponentData_2224,
        event: "click",
        displayName: "reloadComponentData",
      }
    );
    const evh_8697745105058001_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_8697745105058001_click_2223],
      }
    );
    const act_2406129194387753_reloadComponentData_2228 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_2406129194387753_success_2227 = new actions.EventHandlerImpl(
      {
        action: act_2406129194387753_reloadComponentData_2228,
        event: "success",
        displayName: "reloadComponentData",
      }
    );
    const act_2406129194387753_request_2226 = new actions.RequestAction(
      {
        actionArgs: this.act_2406129194387753_request_2226_getActionArgs,
        displayName: "markRead",
        events: [evh_2406129194387753_success_2227],
      }
    );
    const evh_2406129194387753_click_2225 = new actions.EventHandlerImpl(
      {
        action: act_2406129194387753_request_2226,
        event: "click",
        displayName: "markRead",
      }
    );
    const evh_2406129194387753_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_2406129194387753_click_2225],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_2222,
      evh_7315092382398562_reload_2221,
      evh_7315092382398562_reload,
      act_8697745105058001_reloadComponentData_2224,
      evh_8697745105058001_click_2223,
      evh_8697745105058001_click,
      act_2406129194387753_reloadComponentData_2228,
      evh_2406129194387753_success_2227,
      act_2406129194387753_request_2226,
      evh_2406129194387753_click_2225,
      evh_2406129194387753_click,
      markRead: act_2406129194387753_request_2226,
    }
  }
}

Vue.component("Notifications", Notifications);

</script>