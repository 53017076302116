"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcClientStatement = class UcClientStatement extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['ux', 'b_7315092382398562_columnDefaults', 'b_7315092382398562_columns'];
    }
    beforeCreate() {
    }
    getQueryParameters(...args) {
        return { client_id: this.clientId };
    }
    onClient_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("clientId");
    }
    $$load_b_7315092382398562_columns() {
        return __awaiter(this, void 0, void 0, function* () {
            return [{
                    field: "transaction_date",
                    name: this.$fn.pgettext("ClientStatement", "Date"),
                    type: "date",
                },
                {
                    field: "transaction_type",
                    name: this.$fn.pgettext("ClientStatement", "Type"),
                    width: "100px",
                    formatter: {
                        name: "chain",
                        formatters: [{
                                name: "gettext",
                                conversion: "StartCase",
                                context: "ClientStatementType",
                                filterInput: true,
                            },
                            {
                                name: "cellclass",
                                value: (row) => ({
                                    'font-weight-bold': true,
                                    'bg-red-200': row.transaction_type === 'invoice',
                                    'bg-green-200': row.transaction_type === 'payment',
                                }),
                            },
                        ],
                    },
                },
                {
                    field: "transaction_number",
                    name: this.$fn.pgettext("ClientStatement", "Number"),
                    width: "120px",
                    type: "command",
                    formatter: {
                        name: "link",
                        href: "#",
                    },
                },
                {
                    field: "amount",
                    name: this.$fn.pgettext("ClientStatement", "Amount"),
                    type: "number",
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                },
                {
                    field: "currency",
                    name: this.$fn.pgettext("ClientStatement", "Currency"),
                },
                {
                    field: "balance",
                    name: this.$fn.pgettext("ClientStatement", "Balance"),
                    type: "number",
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                    noFooterTotals: true,
                },
            ];
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            ux: null,
            b_7315092382398562_columnDefaults: {
                sortable: false,
                filterable: true,
                resizable: true,
            },
            b_7315092382398562_columns: null,
        });
    }
    act_7315092382398562_showModal_4650_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Payment",
                props: {
                    modal: true,
                    oid: $event.data.data.id,
                },
            };
        });
    }
    evh_7315092382398562_command_4649_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.column.id === 'transaction_number' && $event.data.data.transaction_type === 'payment';
        });
    }
    act_7315092382398562_showModal_4652_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Invoice",
                props: {
                    modal: true,
                    oid: $event.data.data.id,
                },
            };
        });
    }
    evh_7315092382398562_command_4651_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.column.id === 'transaction_number' && $event.data.data.transaction_type === 'invoice';
        });
    }
    evh_7315092382398562_command(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_command.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_showModal_4650 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_7315092382398562_showModal_4650_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_command_4649 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_showModal_4650,
            event: "command",
            when: this.evh_7315092382398562_command_4649_getWhen,
            displayName: "showModal",
        });
        const act_7315092382398562_showModal_4652 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_7315092382398562_showModal_4652_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_command_4651 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_showModal_4652,
            event: "command",
            when: this.evh_7315092382398562_command_4651_getWhen,
            displayName: "showModal",
        });
        const evh_7315092382398562_command = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_command_4649, evh_7315092382398562_command_4651],
        });
        return {
            act_7315092382398562_showModal_4650,
            evh_7315092382398562_command_4649,
            act_7315092382398562_showModal_4652,
            evh_7315092382398562_command_4651,
            evh_7315092382398562_command,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: String,
    }),
    __metadata("design:type", String)
], UcClientStatement.prototype, "clientId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('clientId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcClientStatement.prototype, "onClient_id", null);
UcClientStatement = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcClientStatement);
exports.default = UcClientStatement;
vue_property_decorator_1.Vue.component("UcClientStatement", UcClientStatement);
