"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcContractStatusCard = class UcContractStatusCard extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['states', 'ux'];
    }
    beforeCreate() {
    }
    canTransitionTo(...args) {
        const nextStatus = args[0];
        const md = args[1];
        if (md.next_statuses.length == 0) {
            return false;
        }
        return md.next_statuses.indexOf(nextStatus) > -1;
    }
    onContract(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("contract");
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            states: [{
                    value: "approved",
                    text: this.$fn.pgettext("SetContractStatus", "Approve..."),
                    variant: "light",
                },
                {
                    value: "signed",
                    text: this.$fn.pgettext("SetContractStatus", "Sign..."),
                    variant: "light",
                },
                {
                    value: "rescinded",
                    text: this.$fn.pgettext("SetContractStatus", "Rescind..."),
                    variant: "light",
                },
                {
                    value: "active",
                    text: this.$fn.pgettext("SetContractStatus", "Activate..."),
                    variant: "light",
                },
                {
                    value: "terminated",
                    text: this.$fn.pgettext("SetContractStatus", "Terminate..."),
                    variant: "light",
                },
                {
                    value: "ended",
                    text: this.$fn.pgettext("SetContractStatus", "End..."),
                    variant: "light",
                },
                {
                    value: "archived",
                    text: this.$fn.pgettext("SetContractStatus", "Archive..."),
                    variant: "light",
                },
            ],
            ux: null,
        });
    }
    evh_7315092382398562_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
        });
    }
    act_3943151158818434_emit_6462_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_3943151158818434_close_6461_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.modalResult.ok;
        });
    }
    act_3943151158818434_showModal_6460_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "ContractStatusTransition",
                props: {
                    contract: this.contract,
                    status: $event.scope.state.value,
                },
            };
        });
    }
    evh_3943151158818434_clickStopPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_3943151158818434_clickStopPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_2857579216270778_emit_6466_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_2857579216270778_close_6465_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.modalResult.ok;
        });
    }
    act_2857579216270778_showModal_6464_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "ContractStatusTransition",
                props: {
                    contract: this.contract,
                    status: "unarchive",
                },
            };
        });
    }
    evh_2857579216270778_clickStopPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2857579216270778_clickStopPrevent.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_reloadComponentData_6458 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7315092382398562_reload_6457 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_6458,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_6457],
        });
        const act_3943151158818434_emit_6462 = new core_1.actions.EmitAction({
            actionArgs: this.act_3943151158818434_emit_6462_getActionArgs,
            events: [],
        });
        const evh_3943151158818434_close_6461 = new core_1.actions.EventHandlerImpl({
            action: act_3943151158818434_emit_6462,
            event: "close",
            when: this.evh_3943151158818434_close_6461_getWhen,
            displayName: "emit",
        });
        const act_3943151158818434_showModal_6460 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_3943151158818434_showModal_6460_getActionArgs,
            events: [evh_3943151158818434_close_6461],
        });
        const evh_3943151158818434_clickStopPrevent_6459 = new core_1.actions.EventHandlerImpl({
            action: act_3943151158818434_showModal_6460,
            event: "click.stop.prevent",
            displayName: "showModal",
        });
        const evh_3943151158818434_clickStopPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3943151158818434_clickStopPrevent_6459],
        });
        const act_2857579216270778_emit_6466 = new core_1.actions.EmitAction({
            actionArgs: this.act_2857579216270778_emit_6466_getActionArgs,
            events: [],
        });
        const evh_2857579216270778_close_6465 = new core_1.actions.EventHandlerImpl({
            action: act_2857579216270778_emit_6466,
            event: "close",
            when: this.evh_2857579216270778_close_6465_getWhen,
            displayName: "emit",
        });
        const act_2857579216270778_showModal_6464 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2857579216270778_showModal_6464_getActionArgs,
            events: [evh_2857579216270778_close_6465],
        });
        const evh_2857579216270778_clickStopPrevent_6463 = new core_1.actions.EventHandlerImpl({
            action: act_2857579216270778_showModal_6464,
            event: "click.stop.prevent",
            displayName: "showModal",
        });
        const evh_2857579216270778_clickStopPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2857579216270778_clickStopPrevent_6463],
        });
        return {
            act_7315092382398562_reloadComponentData_6458,
            evh_7315092382398562_reload_6457,
            evh_7315092382398562_reload,
            act_3943151158818434_emit_6462,
            evh_3943151158818434_close_6461,
            act_3943151158818434_showModal_6460,
            evh_3943151158818434_clickStopPrevent_6459,
            evh_3943151158818434_clickStopPrevent,
            act_2857579216270778_emit_6466,
            evh_2857579216270778_close_6465,
            act_2857579216270778_showModal_6464,
            evh_2857579216270778_clickStopPrevent_6463,
            evh_2857579216270778_clickStopPrevent,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], UcContractStatusCard.prototype, "contract", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('contract'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcContractStatusCard.prototype, "onContract", null);
UcContractStatusCard = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcContractStatusCard);
exports.default = UcContractStatusCard;
vue_property_decorator_1.Vue.component("UcContractStatusCard", UcContractStatusCard);
