"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let MiniCard = class MiniCard extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['ux'];
    }
    beforeCreate() {
    }
    onColor_code(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("colorCode");
    }
    onBadge(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("badge");
    }
    onNo_icon(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("noIcon");
    }
    onNo_link(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("noLink");
    }
    onTitle(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("title");
    }
    onIcon(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("icon");
    }
    onText(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("text");
    }
    onLink_to(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("linkTo");
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            ux: null,
        });
    }
    getActions() {
        return {};
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], MiniCard.prototype, "colorCode", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('colorCode'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], MiniCard.prototype, "onColor_code", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
    }),
    __metadata("design:type", Boolean)
], MiniCard.prototype, "badge", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('badge'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], MiniCard.prototype, "onBadge", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
    }),
    __metadata("design:type", Boolean)
], MiniCard.prototype, "noIcon", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('noIcon'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], MiniCard.prototype, "onNo_icon", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
    }),
    __metadata("design:type", Boolean)
], MiniCard.prototype, "noLink", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('noLink'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], MiniCard.prototype, "onNo_link", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], MiniCard.prototype, "title", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('title'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], MiniCard.prototype, "onTitle", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], MiniCard.prototype, "icon", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('icon'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], MiniCard.prototype, "onIcon", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], MiniCard.prototype, "text", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('text'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], MiniCard.prototype, "onText", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Object,
    }),
    __metadata("design:type", Object)
], MiniCard.prototype, "linkTo", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('linkTo'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], MiniCard.prototype, "onLink_to", null);
MiniCard = __decorate([
    (0, vue_property_decorator_1.Component)()
], MiniCard);
exports.default = MiniCard;
vue_property_decorator_1.Vue.component("MiniCard", MiniCard);
