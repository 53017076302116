"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcAttachmentsList = class UcAttachmentsList extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['container', 'ux'];
    }
    beforeCreate() {
    }
    onParent_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("parent_id");
    }
    onParent(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("parent");
    }
    onReadonly(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("readonly");
    }
    $$load_container() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_attachment', { parameters: { 'owner-id': this.parent_id || this.parent.id }, asResourceContainer: true });
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            container: null,
            ux: null,
        });
    }
    get items() {
        if (this.loaded) {
            return this.container.data;
        }
        return null;
    }
    get isReadOnly() {
        if (this.loaded) {
            return this.readonly || (this.parent && this.parent.isReadOnly);
        }
        return null;
    }
    evh_7315092382398562_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
        });
    }
    act_8320016629450276_showModal_1930_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "WAttachmentModal",
                props: {
                    object_id: this.parent_id || this.parent.id,
                },
            };
        });
    }
    evh_8320016629450276_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8320016629450276_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_8513850661379123_showModal_1934_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "WAttachmentModal",
                props: {
                    object_id: this.parent_id || this.parent.id,
                    attachment: _.cloneDeep($event.scope.item),
                },
            };
        });
    }
    evh_8513850661379123_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8513850661379123_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_3861238174616472_request_1938_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "delete_attachment",
                data: $event.scope.item,
            };
        });
    }
    act_3861238174616472_request_1938_getConfirm($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                message: this.$fn.gettext("Please confirm delete."),
                btnOk: this.$fn.gettext("Delete"),
            };
        });
    }
    evh_3861238174616472_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_3861238174616472_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_reloadComponentData_1928 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7315092382398562_reload_1927 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_1928,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_1927],
        });
        const act_8320016629450276_reloadComponentData_1932 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_8320016629450276_close_1931 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_reloadComponentData_1932,
            event: "close",
            displayName: "reloadComponentData",
        });
        const act_8320016629450276_showModal_1930 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_8320016629450276_showModal_1930_getActionArgs,
            events: [evh_8320016629450276_close_1931],
        });
        const evh_8320016629450276_clickPreventStop_1929 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_showModal_1930,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_8320016629450276_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8320016629450276_clickPreventStop_1929],
        });
        const act_8513850661379123_reloadComponentData_1936 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_8513850661379123_close_1935 = new core_1.actions.EventHandlerImpl({
            action: act_8513850661379123_reloadComponentData_1936,
            event: "close",
            displayName: "reloadComponentData",
        });
        const act_8513850661379123_showModal_1934 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_8513850661379123_showModal_1934_getActionArgs,
            events: [evh_8513850661379123_close_1935],
        });
        const evh_8513850661379123_clickPreventStop_1933 = new core_1.actions.EventHandlerImpl({
            action: act_8513850661379123_showModal_1934,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_8513850661379123_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8513850661379123_clickPreventStop_1933],
        });
        const act_3861238174616472_reloadComponentData_1940 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_3861238174616472_success_1939 = new core_1.actions.EventHandlerImpl({
            action: act_3861238174616472_reloadComponentData_1940,
            event: "success",
            displayName: "reloadComponentData",
        });
        const act_3861238174616472_request_1938 = new core_1.actions.RequestAction({
            actionArgs: this.act_3861238174616472_request_1938_getActionArgs,
            confirm: this.act_3861238174616472_request_1938_getConfirm,
            events: [evh_3861238174616472_success_1939],
        });
        const evh_3861238174616472_clickPreventStop_1937 = new core_1.actions.EventHandlerImpl({
            action: act_3861238174616472_request_1938,
            event: "click.prevent.stop",
            displayName: "request",
        });
        const evh_3861238174616472_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3861238174616472_clickPreventStop_1937],
        });
        return {
            act_7315092382398562_reloadComponentData_1928,
            evh_7315092382398562_reload_1927,
            evh_7315092382398562_reload,
            act_8320016629450276_reloadComponentData_1932,
            evh_8320016629450276_close_1931,
            act_8320016629450276_showModal_1930,
            evh_8320016629450276_clickPreventStop_1929,
            evh_8320016629450276_clickPreventStop,
            act_8513850661379123_reloadComponentData_1936,
            evh_8513850661379123_close_1935,
            act_8513850661379123_showModal_1934,
            evh_8513850661379123_clickPreventStop_1933,
            evh_8513850661379123_clickPreventStop,
            act_3861238174616472_reloadComponentData_1940,
            evh_3861238174616472_success_1939,
            act_3861238174616472_request_1938,
            evh_3861238174616472_clickPreventStop_1937,
            evh_3861238174616472_clickPreventStop,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], UcAttachmentsList.prototype, "parent_id", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('parent_id'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcAttachmentsList.prototype, "onParent_id", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Object,
    }),
    __metadata("design:type", Object)
], UcAttachmentsList.prototype, "parent", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('parent'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcAttachmentsList.prototype, "onParent", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
    }),
    __metadata("design:type", Boolean)
], UcAttachmentsList.prototype, "readonly", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('readonly'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcAttachmentsList.prototype, "onReadonly", null);
UcAttachmentsList = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcAttachmentsList);
exports.default = UcAttachmentsList;
vue_property_decorator_1.Vue.component("UcAttachmentsList", UcAttachmentsList);
