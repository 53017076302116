"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvoiceRegisterEntryHeader = exports.InvoiceRegisterEntry = exports.InvoiceRegister = exports.FieldsetInvoiceRegisterEntry = void 0;
const FieldsetInvoiceRegisterEntry_yaml_component_1 = require("./FieldsetInvoiceRegisterEntry.yaml?component");
exports.FieldsetInvoiceRegisterEntry = FieldsetInvoiceRegisterEntry_yaml_component_1.default;
const InvoiceRegister_yaml_component_1 = require("./InvoiceRegister.yaml?component");
exports.InvoiceRegister = InvoiceRegister_yaml_component_1.default;
const InvoiceRegisterEntry_yaml_component_1 = require("./InvoiceRegisterEntry.yaml?component");
exports.InvoiceRegisterEntry = InvoiceRegisterEntry_yaml_component_1.default;
const InvoiceRegisterEntryHeader_yaml_component_1 = require("./InvoiceRegisterEntryHeader.yaml?component");
exports.InvoiceRegisterEntryHeader = InvoiceRegisterEntryHeader_yaml_component_1.default;
__exportStar(require("./primitives"), exports);
