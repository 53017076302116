"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcFileIcon = class UcFileIcon extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['assignments', 'ux'];
    }
    beforeCreate() {
    }
    onFilename(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("filename");
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            assignments: {
                default: "vscode-icons:file-type-binary",
                pdf: "vscode-icons:file-type-pdf2",
                doc: "vscode-icons:file-type-word",
                docx: "vscode-icons:file-type-word",
                xls: "vscode-icons:file-type-excel",
                xlsx: "vscode-icons:file-type-excel",
                txt: "vscode-icons:file-type-text",
                zip: "vscode-icons:file-type-zip2",
                ps: "vscode-icons:file-type-photoshop2",
                md: "vscode-icons:file-type-markdown",
                bmp: "vscode-icons:file-type-image",
                jpg: "vscode-icons:file-type-image",
                jpeg: "vscode-icons:file-type-image",
                avi: "vscode-icons:file-type-video",
            },
            ux: null,
        });
    }
    get extension() {
        if (this.loaded) {
            return this.filename.indexOf('.')
                ? _.last(this.filename.split('.')).toLowerCase()
                : this.filename.slice(1, 3);
        }
        return null;
    }
    get icon() {
        if (this.loaded) {
            return _.get(this.assignments, this.extension, this.assignments.default);
        }
        return null;
    }
    getActions() {
        return {};
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: String,
    }),
    __metadata("design:type", String)
], UcFileIcon.prototype, "filename", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('filename'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcFileIcon.prototype, "onFilename", null);
UcFileIcon = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcFileIcon);
exports.default = UcFileIcon;
vue_property_decorator_1.Vue.component("UcFileIcon", UcFileIcon);
