"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let InvoiceReferenceSearch = class InvoiceReferenceSearch extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['formId', 'searchTerm', 'items', 'selectedItems', 'referenceTypes', 'selectedReferenceType', 'selectedReference', 'ux', 'b_7315092382398562_modalBindings', 'b_7789867021953041_clearable'];
    }
    beforeCreate() {
        this.$$cache_selectedReferenceType = new core_1.ComponentValueCache(this, "selectedReferenceType", "InvoiceReferenceSearchModal.selectedReferenceType");
    }
    onReferenceSelected(...args) {
        const { option, value } = args[0];
        if (option) {
            this.selectedItems.push(option);
            if (option.rental_id && option.rental_name) {
                this.selectedItems.push({
                    object: 'rental',
                    id: option.rental_id,
                    name: option.rental_name
                });
            }
            if (option.client_id && option.client_name) {
                this.selectedItems.push({
                    object: 'client',
                    id: option.client_id,
                    name: option.client_name
                });
            }
            if (option.properties) {
                _.each(option.properties, p => {
                    if (p.object == 'rental_property' && !p.inactive && p.property_unit) {
                        this.selectedItems.push({
                            object: 'property_unit',
                            id: p.property_unit.id,
                            name: p.property_unit.full_name || p.property_unit.full_code
                        });
                    }
                });
            }
        }
    }
    onInvoice_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("invoiceId");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_formId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    $$load_referenceTypes() {
        return __awaiter(this, void 0, void 0, function* () {
            return [
                {
                    type: 'contract',
                    text: this.$fn.gettext('Contracts'),
                    target: 'contracts',
                    dataSource: {
                        name: 'suggest_contract',
                        parameters: {}
                    },
                    template: '',
                },
                {
                    type: 'rental',
                    text: this.$fn.gettext('Rentals'),
                    target: 'rentals',
                    dataSource: {
                        name: 'suggest_rental',
                        parameters: {}
                    },
                },
                {
                    type: 'client',
                    text: this.$fn.gettext('Clients'),
                    target: 'tenants',
                    dataSource: {
                        name: 'suggest_client',
                        parameters: {}
                    },
                },
                {
                    type: 'property',
                    text: this.$fn.gettext('Properties'),
                    target: 'properties',
                    dataSource: {
                        name: 'suggest_property',
                        parameters: {
                            types: 'property,unit'
                        }
                    },
                },
            ];
        });
    }
    $$load_selectedReference() {
        return __awaiter(this, void 0, void 0, function* () {
            return _.find(this.referenceTypes, { type: this.selectedReferenceType }) || this.referenceTypes[0];
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            formId: null,
            searchTerm: null,
            items: [],
            selectedItems: [],
            referenceTypes: null,
            selectedReferenceType: null,
            selectedReference: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "md",
            },
            b_7789867021953041_clearable: false,
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.pgettext("Invoice", "Search Reference"),
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_6636_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_7789867021953041_setData_6640_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "searchTerm",
                value: null,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_7789867021953041_setData_6642_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "items",
                value: [],
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_7789867021953041_setData_6644_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "selectedReferenceType",
                value: $event.data.type,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_7789867021953041_selected(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7789867021953041_selected.executeFromDOM(this, event, scope);
        });
    }
    act_3468003412020058_script_6646_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.onReferenceSelected($event.data);
        });
    }
    act_3468003412020058_script_6646_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_3468003412020058_script_6646_getActionArgs_value($event),
            };
        });
    }
    evh_3468003412020058_optionSelected(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_3468003412020058_optionSelected.executeFromDOM(this, event, scope);
        });
    }
    act_6049458597007392_setData_6648_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "selectedItems",
                value: (_.remove(this.selectedItems, $event.scope.item), this.selectedItems.slice()),
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_6049458597007392_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6049458597007392_click.executeFromDOM(this, event, scope);
        });
    }
    act_1548630417156826_closeComponent_6652_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    ok: true,
                },
            };
        });
    }
    act_1548630417156826_request_6650_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "create_invoice_reference",
                data: _.map(this.selectedItems, p => ({ reference_id: p.id })),
                parameters: {
                    invoice_id: this.invoiceId,
                },
            };
        });
    }
    evh_1548630417156826_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1548630417156826_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    evh_1419464017721962_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_6636 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_6636_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_6635 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_6636,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_6635],
        });
        const act_2248226175642056_closeComponent_6638 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2248226175642056_close_6637 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_6638,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_6637],
        });
        const act_7789867021953041_setData_6640 = new core_1.actions.SetDataAction({
            actionArgs: this.act_7789867021953041_setData_6640_getActionArgs,
            events: [],
        });
        const evh_7789867021953041_selected_6639 = new core_1.actions.EventHandlerImpl({
            action: act_7789867021953041_setData_6640,
            event: "selected",
            displayName: "setData",
        });
        const act_7789867021953041_setData_6642 = new core_1.actions.SetDataAction({
            actionArgs: this.act_7789867021953041_setData_6642_getActionArgs,
            events: [],
        });
        const evh_7789867021953041_selected_6641 = new core_1.actions.EventHandlerImpl({
            action: act_7789867021953041_setData_6642,
            event: "selected",
            displayName: "setData",
        });
        const act_7789867021953041_setData_6644 = new core_1.actions.SetDataAction({
            actionArgs: this.act_7789867021953041_setData_6644_getActionArgs,
            events: [],
        });
        const evh_7789867021953041_selected_6643 = new core_1.actions.EventHandlerImpl({
            action: act_7789867021953041_setData_6644,
            event: "selected",
            displayName: "setData",
        });
        const evh_7789867021953041_selected = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7789867021953041_selected_6639, evh_7789867021953041_selected_6641, evh_7789867021953041_selected_6643],
        });
        const act_3468003412020058_script_6646 = new core_1.actions.ScriptAction({
            actionArgs: this.act_3468003412020058_script_6646_getActionArgs,
            events: [],
        });
        const evh_3468003412020058_optionSelected_6645 = new core_1.actions.EventHandlerImpl({
            action: act_3468003412020058_script_6646,
            event: "optionSelected",
            displayName: "script",
        });
        const evh_3468003412020058_optionSelected = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3468003412020058_optionSelected_6645],
        });
        const act_6049458597007392_setData_6648 = new core_1.actions.SetDataAction({
            actionArgs: this.act_6049458597007392_setData_6648_getActionArgs,
            events: [],
        });
        const evh_6049458597007392_click_6647 = new core_1.actions.EventHandlerImpl({
            action: act_6049458597007392_setData_6648,
            event: "click",
            displayName: "setData",
        });
        const evh_6049458597007392_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6049458597007392_click_6647],
        });
        const act_1548630417156826_closeComponent_6652 = new core_1.actions.CloseComponentAction({
            actionArgs: this.act_1548630417156826_closeComponent_6652_getActionArgs,
            events: [],
        });
        const evh_1548630417156826_success_6651 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_closeComponent_6652,
            event: "success",
            displayName: "closeComponent",
        });
        const act_1548630417156826_request_6650 = new core_1.actions.RequestAction({
            actionArgs: this.act_1548630417156826_request_6650_getActionArgs,
            displayName: "submitReferences",
            events: [evh_1548630417156826_success_6651],
        });
        const evh_1548630417156826_clickPreventStop_6649 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_request_6650,
            event: "click.prevent.stop",
            displayName: "submitReferences",
        });
        const evh_1548630417156826_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1548630417156826_clickPreventStop_6649],
        });
        const act_1419464017721962_closeComponent_6654 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_1419464017721962_clickPreventStop_6653 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_closeComponent_6654,
            event: "click.prevent.stop",
            displayName: "closeComponent",
        });
        const evh_1419464017721962_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1419464017721962_clickPreventStop_6653],
        });
        return {
            act_7315092382398562_closeModal_6636,
            evh_7315092382398562_close_6635,
            evh_7315092382398562_close,
            act_2248226175642056_closeComponent_6638,
            evh_2248226175642056_close_6637,
            evh_2248226175642056_close,
            act_7789867021953041_setData_6640,
            evh_7789867021953041_selected_6639,
            act_7789867021953041_setData_6642,
            evh_7789867021953041_selected_6641,
            act_7789867021953041_setData_6644,
            evh_7789867021953041_selected_6643,
            evh_7789867021953041_selected,
            act_3468003412020058_script_6646,
            evh_3468003412020058_optionSelected_6645,
            evh_3468003412020058_optionSelected,
            act_6049458597007392_setData_6648,
            evh_6049458597007392_click_6647,
            evh_6049458597007392_click,
            act_1548630417156826_closeComponent_6652,
            evh_1548630417156826_success_6651,
            act_1548630417156826_request_6650,
            evh_1548630417156826_clickPreventStop_6649,
            evh_1548630417156826_clickPreventStop,
            act_1419464017721962_closeComponent_6654,
            evh_1419464017721962_clickPreventStop_6653,
            evh_1419464017721962_clickPreventStop,
            submitReferences: act_1548630417156826_request_6650,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: String,
    }),
    __metadata("design:type", String)
], InvoiceReferenceSearch.prototype, "invoiceId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('invoiceId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], InvoiceReferenceSearch.prototype, "onInvoice_id", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], InvoiceReferenceSearch.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], InvoiceReferenceSearch.prototype, "onSize", null);
InvoiceReferenceSearch = __decorate([
    (0, vue_property_decorator_1.Component)()
], InvoiceReferenceSearch);
exports.default = InvoiceReferenceSearch;
vue_property_decorator_1.Vue.component("InvoiceReferenceSearch", InvoiceReferenceSearch);
