<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="SendMails"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      name="sendmail-options"
      id="zkhcfceubc"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <ItpFormGroup
        name="template"
        :label="'Template'|gettext"
        :description="'The template to use to generate the emails.'|gettext"
      >
        <ItpFormSelect
          name="name"
          size="md"
          v-model="selectedTemplate"
          required
          :options="templates"
          valueField="id"
          textField="name"
        >
        </ItpFormSelect>
      </ItpFormGroup>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      form="zkhcfceubc"
      :text="'Send'|gettext"
      type="submit"
      variant="primary"
      :spinning="actions.submit.isRunning"
    >
    </ItpButton>
    <ItpButton
      :text="'Cancel'|gettext"
      variant="default"
      :disabled="actions.submit.isRunning"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class SendMails extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    default: null,
  })
  items!: list;

  @Watch('items')
  onItems(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("items")
  }

  @Prop({
    type: String,
    default: "sm",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  selectedTemplate!: any;
  templates!: any;

  async $$load_templates() {
    return this.$fn.fetch('list_template')
      .then(data => _.filter(data, { template_type: 'mjml' }))
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['selectedTemplate', 'templates', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        selectedTemplate: null,
        templates: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.gettext("Send emails"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_2342_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_2342, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_register_2348_getActionArgs($event: actions.ActionEvent): Promise<actions.RegisterActionArgs> {
    // parameterResolver name=act_1040167445267876_register_2348, alias=undefined
    return {
      name: "resultLength",
      value: $event.data.response.data ? $event.data.response.data.length : 0,
    }
  }

  async act_1040167445267876_notification_2350_getActionArgs($event: actions.ActionEvent): Promise<actions.NotificationActionArgs> {
    // parameterResolver name=act_1040167445267876_notification_2350, alias=undefined
    return {
      message: this.$fn.ngettext("One email has been successfully enqueued.", "${n} emails have been successfully enqueued.", $event.data.resultLength),
    }
  }

  async act_1040167445267876_notification_2350_getWhen($event: actions.ActionEvent): Promise<actions.NotificationActionArgs> {
    // parameterResolver name=act_1040167445267876_notification_2350, alias=undefined
    return $event.data.resultLength > 0
  }

  async act_1040167445267876_reloadSlickgrid_2352_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_1040167445267876_reloadSlickgrid_2352, alias=undefined
    return {
      grid: "all",
    }
  }

  async act_1040167445267876_closeComponent_2354_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseComponentActionArgs> {
    // parameterResolver name=act_1040167445267876_closeComponent_2354, alias=undefined
    return {
      result: {
        close: true,
      }
      ,
    }
  }

  async act_1040167445267876_request_2346_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_1040167445267876_request_2346, alias=submit
    return {
      operation: "create_job",
      data: _.chain(this.items)
        .filter(item => item.customer_email)
        .map(item => ({
          job_type: 'sendmail:dunning_letter',
          job_data: {
            id: item.id,
            template: this.selectedTemplate,
            mail_to: item.customer_email
          }
        }))
        .value(),
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_2342: actions.CloseModalAction;
    evh_7315092382398562_close_2341: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_2344: actions.CloseComponentAction;
    evh_2248226175642056_close_2343: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1040167445267876_register_2348: actions.RegisterAction;
    evh_1040167445267876_success_2347: actions.EventHandlerImpl;
    act_1040167445267876_notification_2350: actions.NotificationAction;
    evh_1040167445267876_success_2349: actions.EventHandlerImpl;
    act_1040167445267876_reloadSlickgrid_2352: actions.ReloadSlickgridAction;
    evh_1040167445267876_success_2351: actions.EventHandlerImpl;
    act_1040167445267876_closeComponent_2354: actions.CloseComponentAction;
    evh_1040167445267876_success_2353: actions.EventHandlerImpl;
    act_1040167445267876_request_2346: actions.RequestAction;
    evh_1040167445267876_submit_2345: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_1419464017721962_closeComponent_2356: actions.CloseComponentAction;
    evh_1419464017721962_clickPreventStop_2355: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_2342 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_2342_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_2341 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_2342,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_2341],
      }
    );
    const act_2248226175642056_closeComponent_2344 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_2343 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_2344,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_2343],
      }
    );
    const act_1040167445267876_register_2348 = new actions.RegisterAction(
      {
        actionArgs: this.act_1040167445267876_register_2348_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_2347 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_register_2348,
        event: "success",
        displayName: "register",
      }
    );
    const act_1040167445267876_notification_2350 = new actions.NotificationAction(
      {
        actionArgs: this.act_1040167445267876_notification_2350_getActionArgs,
        when: this.act_1040167445267876_notification_2350_getWhen,
        events: [],
      }
    );
    const evh_1040167445267876_success_2349 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_notification_2350,
        event: "success",
        displayName: "notification",
      }
    );
    const act_1040167445267876_reloadSlickgrid_2352 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_1040167445267876_reloadSlickgrid_2352_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_2351 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_reloadSlickgrid_2352,
        event: "success",
        displayName: "reloadSlickgrid",
      }
    );
    const act_1040167445267876_closeComponent_2354 = new actions.CloseComponentAction(
      {
        actionArgs: this.act_1040167445267876_closeComponent_2354_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_2353 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeComponent_2354,
        event: "success",
        displayName: "closeComponent",
      }
    );
    const act_1040167445267876_request_2346 = new actions.RequestAction(
      {
        actionArgs: this.act_1040167445267876_request_2346_getActionArgs,
        displayName: "submit",
        events: [evh_1040167445267876_success_2347, evh_1040167445267876_success_2349, evh_1040167445267876_success_2351, evh_1040167445267876_success_2353],
      }
    );
    const evh_1040167445267876_submit_2345 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_request_2346,
        event: "submit",
        displayName: "submit",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_2345],
      }
    );
    const act_1419464017721962_closeComponent_2356 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_2355 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeComponent_2356,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_2355],
      }
    );
    return {
      act_7315092382398562_closeModal_2342,
      evh_7315092382398562_close_2341,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_2344,
      evh_2248226175642056_close_2343,
      evh_2248226175642056_close,
      act_1040167445267876_register_2348,
      evh_1040167445267876_success_2347,
      act_1040167445267876_notification_2350,
      evh_1040167445267876_success_2349,
      act_1040167445267876_reloadSlickgrid_2352,
      evh_1040167445267876_success_2351,
      act_1040167445267876_closeComponent_2354,
      evh_1040167445267876_success_2353,
      act_1040167445267876_request_2346,
      evh_1040167445267876_submit_2345,
      evh_1040167445267876_submit,
      act_1419464017721962_closeComponent_2356,
      evh_1419464017721962_clickPreventStop_2355,
      evh_1419464017721962_clickPreventStop,
      submit: act_1040167445267876_request_2346,
    }
  }
}

Vue.component("SendMails", SendMails);

</script>