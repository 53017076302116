<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <legend
    class="text-muted"
  >
    {{ 'General'|pgettext('Rental') }}
  </legend>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="4"
    name="name"
    :label="'Name'|pgettext('Rental')"
    :labelAlign="labelAlign"
    required
  >
    <ItpFormInput
      name="name"
      v-model="rental.name"
      required
      autofocus
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="description"
    :label="'Description'|pgettext('Rental')"
    :labelAlign="labelAlign"
  >
    <ItpFormTextarea
      name="description"
      v-model="rental.description"
    >
    </ItpFormTextarea>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="description"
    :label="'Company'|pgettext('Rental')"
    :labelAlign="labelAlign"
    required
  >
    <ItpFormSelect2
      name="company_id"
      v-model="rental.company_id"
      :dataSource="b_6917646200545441_dataSource"
      valueField="id"
      textField="name"
      required
    >
    </ItpFormSelect2>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="description"
    :label="'Site'|pgettext('Rental')"
    :labelAlign="labelAlign"
  >
    <ItpFormSelect2
      name="site_id"
      v-model="rental.site_id"
      :dataSource="b_6424600534600827_dataSource"
      :initialOptions="[rental.site]"
      valueField="id"
      :optionTemplate="b_6424600534600827_optionTemplate"
      use-option-template-for-selected-option
    >
    </ItpFormSelect2>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="location_code"
    :label="'Location'|pgettext('Rental')"
    :labelAlign="labelAlign"
  >
    <ItpFormInput
      name="location_code"
      v-model="rental.location_code"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    name="lot_number"
    :label="'Lot number'|pgettext('Rental')"
    :labelAlign="labelAlign"
  >
    <ItpFormInput
      name="lot_number"
      v-model="rental.lot_number"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    name="house_number"
    :label="'Building number'|pgettext('Rental')"
    :labelAlign="labelAlign"
  >
    <ItpFormInput
      name="house_number"
      v-model="rental.house_number"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    :label="'Area'|pgettext('Rental')"
    :labelAlign="labelAlign"
    contentClass="input-group"
  >
    <ItpFormInput
      type="number"
      name="area"
      v-model="rental.area"
      class="col-8"
    >
    </ItpFormInput>
    <ItpFormSelect
      name="unit_of_area"
      v-model="rental.unit_of_area"
      :options="units"
      class="col-4"
    >
    </ItpFormSelect>
  </ItpFormGroup>
  <hr>
  </hr>
  <FieldsetRentalValidityData
    :rental="rental"
    :labelAlign="labelAlign"
  >
  </FieldsetRentalValidityData>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class FieldsetRentalGeneralData extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  rental!: object;

  @Watch('rental')
  onRental(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("rental")
  }

  labelAlign!: any;
  units!: any;
  ux!: any;
  b_6917646200545441_dataSource!: any;
  b_6424600534600827_dataSource!: any;
  b_6424600534600827_optionTemplate!: any;
  dataMembers = ['labelAlign', 'units', 'ux', 'b_6917646200545441_dataSource', 'b_6424600534600827_dataSource', 'b_6424600534600827_optionTemplate'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        labelAlign: "right",
        units: ["ha"
          ,
          "m2"
          ,
        ]
        ,
        ux: null,
        b_6917646200545441_dataSource: {
          name: "suggest_company",
          parameters: {
            owned: true,
          }
          ,
        }
        ,
        b_6424600534600827_dataSource: {
          name: "suggest_property",
          parameters: {
            types: "property",
          }
          ,
        }
        ,
        b_6424600534600827_optionTemplate: "<div>\n\
  {{ option.name }}\n\
  <span :if=\"option.description\">- {{ option.description }}</span>\n\
</div>",
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_6846: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_6845: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_6846 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_6845 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_6846,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_6845],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_6846,
      evh_7315092382398562_reload_6845,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("FieldsetRentalGeneralData", FieldsetRentalGeneralData);

</script>