var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "3",
            contentColsMd: "3",
            name: "received_date",
            label: _vm._f("pgettext")("Entry date", "InvoiceRegisterEntry"),
            labelAlign: _vm.labelAlign,
            required: ""
          }
        },
        [
          _c("ItpFormDatetime", {
            attrs: { name: "received_date", required: "" },
            model: {
              value: _vm.item.received_date,
              callback: function($$v) {
                _vm.$set(_vm.item, "received_date", $$v)
              },
              expression: "item.received_date"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "3",
            contentColsMd: "8",
            name: "description",
            label: _vm._f("pgettext")("Subject", "InvoiceRegisterEntry"),
            labelAlign: _vm.labelAlign,
            required: ""
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "description", required: "", autofocus: "" },
            model: {
              value: _vm.item.description,
              callback: function($$v) {
                _vm.$set(_vm.item, "description", $$v)
              },
              expression: "item.description"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "ref_entry_id",
            label: _vm._f("pgettext")(
              "Reference entry",
              "InvoiceRegisterEntry"
            ),
            labelAlign: _vm.labelAlign,
            labelColsMd: "3",
            contentColsMd: "8"
          }
        },
        [
          _c("ItpFormSelect2", {
            attrs: {
              name: "ref_entry_id",
              dataSource: _vm.b_1548630417156826_dataSource,
              initialOptions: _vm.item.ref_entry ? [_vm.item.ref_entry] : [],
              valueField: "id",
              clearable: "",
              optionTemplate:
                "<UcSuggestInvoiceRegisterEntryOption :option=option></UcSuggestInvoiceRegisterEntryOption>",
              "use-option-template-for-selected-option": ""
            },
            on: {
              optionSelected: function($event) {
                return _vm.evh_1548630417156826_optionSelected($event, {})
              }
            },
            model: {
              value: _vm.item.ref_entry_id,
              callback: function($$v) {
                _vm.$set(_vm.item, "ref_entry_id", $$v)
              },
              expression: "item.ref_entry_id"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "3",
            contentColsMd: "8",
            name: "invoice_number",
            label: _vm._f("pgettext")("Invoice number", "InvoiceRegisterEntry"),
            labelAlign: _vm.labelAlign,
            required: ""
          }
        },
        [
          _c("ItpFormInput", {
            staticClass: "mb-2",
            attrs: { name: "invoice_number", required: "" },
            model: {
              value: _vm.item.invoice_number,
              callback: function($$v) {
                _vm.$set(_vm.item, "invoice_number", $$v)
              },
              expression: "item.invoice_number"
            }
          }),
          _vm._v(" "),
          _c(
            "ItpBox",
            { attrs: { direction: "column" } },
            [
              _vm.item.invoice_id
                ? _c(
                    "ItpBox",
                    [
                      _c(
                        "ItpLink",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            to: {
                              name: "app.invoice",
                              params: { id: _vm.item.invoice_id }
                            }
                          },
                          on: {
                            click: function($event) {
                              return _vm.evh_2524784394460522_click($event, {})
                            }
                          }
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.item.reference_invoice_number) +
                                "\n          "
                            )
                          ]),
                          _vm._v(" "),
                          _c("Icon", {
                            attrs: { icon: _vm.$config.externalLinkIcon }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.item.reference_invoice_number !=
                      _vm.item.invoice_number
                        ? _c(
                            "DIV",
                            {
                              staticClass: "ml-3",
                              attrs: {
                                title: _vm._f("pgettext")(
                                  "Invoice number mismatch",
                                  "InvoiceRegisterEntry"
                                )
                              }
                            },
                            [
                              _c("Icon", {
                                attrs: { icon: "emojione-v1:warning" }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "ItpBox",
                [
                  _c("ItpButton", {
                    staticClass: "mr-2",
                    attrs: {
                      text: _vm._f("pgettext")(
                        "Assign...",
                        "InvoiceRegisterEntry"
                      ),
                      size: "sm",
                      variant: "light",
                      tooltip: _vm._f("pgettext")(
                        "Assign invoice.",
                        "InvoiceRegisterEntry"
                      ),
                      icon: _vm.$config.assignIcon
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_7445225937215608_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.item.id
                    ? _c("ItpButton", {
                        attrs: {
                          text: _vm._f("pgettext")(
                            "Record...",
                            "InvoiceRegisterEntry"
                          ),
                          size: "sm",
                          variant: "light",
                          tooltip: _vm._f("pgettext")(
                            "Record a new invoice.",
                            "InvoiceRegisterEntry"
                          ),
                          icon: _vm.$config.addIcon
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.evh_3283186227685836_clickPreventStop(
                              $event,
                              {}
                            )
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "3",
            contentColsMd: "3",
            name: "invoice_type",
            label: _vm._f("pgettext")("Invoice type", "InvoiceRegisterEntry"),
            labelAlign: _vm.labelAlign,
            required: ""
          }
        },
        [
          _c("ItpFormSelect2", {
            attrs: {
              name: "invoice_type",
              options: _vm.invoiceTypes,
              valueField: "value",
              textField: "text",
              required: ""
            },
            model: {
              value: _vm.item.invoice_type,
              callback: function($$v) {
                _vm.$set(_vm.item, "invoice_type", $$v)
              },
              expression: "item.invoice_type"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "3",
            contentColsMd: "8",
            name: "status",
            label: _vm._f("pgettext")("Status", "InvoiceRegisterEntry"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormSelect2", {
            attrs: { name: "status", taggable: "", options: _vm.statuses },
            model: {
              value: _vm.item.status,
              callback: function($$v) {
                _vm.$set(_vm.item, "status", $$v)
              },
              expression: "item.status"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "3",
            contentColsMd: "8",
            name: "categories",
            label: _vm._f("pgettext")("Categories", "InvoiceRegisterEntry"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormSelect2", {
            attrs: {
              name: "categories",
              multiple: "",
              taggable: "",
              options: _vm.categories
            },
            model: {
              value: _vm.item.categories,
              callback: function($$v) {
                _vm.$set(_vm.item, "categories", $$v)
              },
              expression: "item.categories"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "3",
            contentColsMd: "8",
            name: "client",
            labelAlign: _vm.labelAlign,
            label: _vm._f("pgettext")("Client", "InvoiceRegisterEntry"),
            required: ""
          }
        },
        [
          _c("ItpFormSelect2", {
            attrs: {
              name: "contractor",
              dataSource: _vm.b_8680084583475136_dataSource,
              valueField: "id",
              required: "",
              optionTemplate:
                "<UcSuggestClientOption :option=option></UcSuggestClientOption>",
              "use-option-template-for-selected-option": ""
            },
            model: {
              value: _vm.item.client_id,
              callback: function($$v) {
                _vm.$set(_vm.item, "client_id", $$v)
              },
              expression: "item.client_id"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "3",
            contentColsMd: "3",
            name: "fulfillment_date",
            label: _vm._f("pgettext")(
              "Fulfillment date",
              "InvoiceRegisterEntry"
            ),
            labelAlign: _vm.labelAlign,
            required: ""
          }
        },
        [
          _c("ItpFormDatetime", {
            attrs: { name: "fulfillment_date", required: "" },
            model: {
              value: _vm.item.fulfillment_date,
              callback: function($$v) {
                _vm.$set(_vm.item, "fulfillment_date", $$v)
              },
              expression: "item.fulfillment_date"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "3",
            contentColsMd: "3",
            name: "due_date",
            label: _vm._f("pgettext")("Due date", "InvoiceRegisterEntry"),
            labelAlign: _vm.labelAlign,
            required: ""
          }
        },
        [
          _c("ItpFormDatetime", {
            attrs: { name: "due_date", required: "" },
            model: {
              value: _vm.item.due_date,
              callback: function($$v) {
                _vm.$set(_vm.item, "due_date", $$v)
              },
              expression: "item.due_date"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          staticClass: "col",
          attrs: {
            labelColsMd: "3",
            contentColsMd: "3",
            name: "payment_method",
            label: _vm._f("pgettext")("Payment method", "InvoiceRegisterEntry"),
            labelAlign: _vm.labelAlign,
            required: ""
          }
        },
        [
          _c("ItpFormSelect", {
            attrs: {
              name: "payment_method",
              options: _vm.paymentMethods,
              required: ""
            },
            model: {
              value: _vm.item.payment_method,
              callback: function($$v) {
                _vm.$set(_vm.item, "payment_method", $$v)
              },
              expression: "item.payment_method"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          staticClass: "col",
          attrs: {
            labelColsMd: "3",
            contentColsMd: "5",
            name: "amount",
            label: _vm._f("pgettext")("Amount", "InvoiceRegisterEntry"),
            labelAlign: _vm.labelAlign,
            required: "",
            contentClass: "input-group"
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { type: "number", name: "amount_amount", required: "" },
            model: {
              value: _vm.item.amount.amount,
              callback: function($$v) {
                _vm.$set(_vm.item.amount, "amount", $$v)
              },
              expression: "item.amount.amount"
            }
          }),
          _vm._v(" "),
          _c(
            "DIV",
            { staticClass: "input-group-append w-40" },
            [
              _c("ItpFormSelect", {
                attrs: {
                  name: "amount_currency",
                  options: _vm.currencies,
                  required: ""
                },
                model: {
                  value: _vm.item.amount.currency,
                  callback: function($$v) {
                    _vm.$set(_vm.item.amount, "currency", $$v)
                  },
                  expression: "item.amount.currency"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "3",
            contentColsMd: "8",
            name: "notes",
            label: _vm._f("pgettext")("Notes", "InvoiceRegisterEntry"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormTextarea", {
            attrs: { name: "notes" },
            model: {
              value: _vm.item.notes,
              callback: function($$v) {
                _vm.$set(_vm.item, "notes", $$v)
              },
              expression: "item.notes"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.item.isNew
        ? _c(
            "ItpFormGroup",
            { attrs: { labelColsMd: "3", contentColsMd: "8" } },
            [
              _c("ItpButton", {
                staticClass: "mr-2",
                attrs: {
                  form: _vm.form,
                  text: _vm._f("pgettext")(
                    "Create entry",
                    "InvoiceRegisterEntry/Button"
                  ),
                  type: "submit"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.item.isNew && !_vm.item.isReadOnly
        ? _c(
            "ItpFormGroup",
            { attrs: { labelColsMd: "3", contentColsMd: "8" } },
            [
              _c("ItpButton", {
                staticClass: "mr-2",
                attrs: {
                  form: _vm.form,
                  text: _vm._f("pgettext")("Save changes", "Button"),
                  type: "submit"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.item.id ? _c("hr") : _vm._e(),
      _vm._v(" "),
      _vm.item.id
        ? _c("UcAttachmentsList", { attrs: { parent: _vm.item } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }