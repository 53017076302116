"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcInvoiceLineGroup = class UcInvoiceLineGroup extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['editing', 'expenseTypes', 'ux'];
    }
    beforeCreate() {
    }
    onInvoice(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("invoice");
    }
    onGroup(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("group");
    }
    $$load_expenseTypes() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_object_by_type', { parameters: { object_type: 'expense-type' } });
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            editing: false,
            expenseTypes: null,
            ux: null,
        });
    }
    act_4280711203779102_emit_5240_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_4280711203779102_close_5239_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data.modalResult.value;
        });
    }
    act_4280711203779102_showModal_5238_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "InvoiceLineGroup",
                props: {
                    invoice: this.invoice,
                    groupId: this.group.data.id || null,
                    isDefaultGroup: !this.group.data.id,
                },
            };
        });
    }
    evh_4280711203779102_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_4280711203779102_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_4280711203779102_emit_5240 = new core_1.actions.EmitAction({
            actionArgs: this.act_4280711203779102_emit_5240_getActionArgs,
            events: [],
        });
        const evh_4280711203779102_close_5239 = new core_1.actions.EventHandlerImpl({
            action: act_4280711203779102_emit_5240,
            event: "close",
            when: this.evh_4280711203779102_close_5239_getWhen,
            displayName: "emit",
        });
        const act_4280711203779102_showModal_5238 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_4280711203779102_showModal_5238_getActionArgs,
            events: [evh_4280711203779102_close_5239],
        });
        const evh_4280711203779102_clickPreventStop_5237 = new core_1.actions.EventHandlerImpl({
            action: act_4280711203779102_showModal_5238,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_4280711203779102_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4280711203779102_clickPreventStop_5237],
        });
        return {
            act_4280711203779102_emit_5240,
            evh_4280711203779102_close_5239,
            act_4280711203779102_showModal_5238,
            evh_4280711203779102_clickPreventStop_5237,
            evh_4280711203779102_clickPreventStop,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], UcInvoiceLineGroup.prototype, "invoice", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('invoice'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcInvoiceLineGroup.prototype, "onInvoice", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], UcInvoiceLineGroup.prototype, "group", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('group'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcInvoiceLineGroup.prototype, "onGroup", null);
UcInvoiceLineGroup = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcInvoiceLineGroup);
exports.default = UcInvoiceLineGroup;
vue_property_decorator_1.Vue.component("UcInvoiceLineGroup", UcInvoiceLineGroup);
