var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c("VueSlickgrid", {
        ref: "grid",
        attrs: {
          name: "clientPayments",
          headerTitle: _vm._f("pgettext")("Payments", "ClientPayments"),
          items: _vm.$fn.slickDataSource("list_payment", {
            parameters: _vm.getQueryParameters
          }),
          "checkbox-row-selection": "",
          "footer-totals": "",
          "export-to-csv": "",
          exportToCsvFilename: _vm._f("pgettext")(
            "client-payments",
            "filename"
          ),
          columnDefaults: _vm.b_7315092382398562_columnDefaults,
          columns: _vm.b_7315092382398562_columns
        },
        on: {
          selectedRowsChanged: function($event) {
            return _vm.evh_7315092382398562_selectedRowsChanged($event, {})
          },
          command: function($event) {
            return _vm.evh_7315092382398562_command($event, {})
          }
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }