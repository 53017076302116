<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <div
    class="form-row"
    v-if="loaded"
  >
    <div
      class="form-group m-0 col"
    >
      <ItpFormSelect2
        name="mode"
        v-model="mode"
        :options="options"
        valueField="key"
        textField="name"
        required
        :size="size"
        class="w-auto"
        @optionSelected="evh_6466705138206980_optionSelected($event, {})"
      >
      </ItpFormSelect2>
    </div>
    <div
      class="form-group m-0 col"
    >
      <ItpFormDatetime
        name="start"
        type="date"
        v-model="item.start"
        required
        :size="size"
        @input="evh_3943151158818434_input($event, {})"
      >
      </ItpFormDatetime>
    </div>
    <div
      class="form-group m-0 col"
    >
      <ItpFormDatetime
        name="end"
        type="date"
        v-model="item.end"
        required
        :size="size"
        clearable
        @input="evh_4207795475682514_input($event, {})"
      >
      </ItpFormDatetime>
    </div>
  </div>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class DateRangeSelector extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
    this.$$cache_mode = new ComponentValueCache(this, "mode", "{{ name + '.mode' }}");
  }

  getCurrentWeek(...args: any[]) {
    const t0 = new Date();
    return [
      this.$fn.dateFns.startOfWeek(t0, { weekStartsOn: 1 }),
      null
    ];
  }

  getCurrentMonth(...args: any[]) {
    const t0 = new Date();
    return [
      this.$fn.dateFns.startOfMonth(t0),
      null
    ];
  }

  getCurrentYear(...args: any[]) {
    const t0 = new Date();
    return [
      this.$fn.dateFns.startOfYear(t0),
      null
    ];
  }

  getPreviousWeek(...args: any[]) {
    const prev_monday = this.$fn.dateFns.previousMonday(this.$fn.dateFns.startOfWeek(new Date(), { weekStartsOn: 1 }))
    return [
      prev_monday,
      this.$fn.dateFns.endOfWeek(prev_monday)
    ];
  }

  getPreviousMonth(...args: any[]) {
    const start = this.$fn.dateFns.sub(this.getCurrentMonth()[0], { months: 1 });
    return [
      start,
      this.$fn.dateFns.endOfMonth(start),
    ];
  }

  getPreviousYear(...args: any[]) {
    const start = this.$fn.dateFns.sub(this.getCurrentYear()[0], { years: 1 });
    return [
      start,
      this.$fn.dateFns.endOfYear(start),
    ];
  }

  getPreviousSevenDays(...args: any[]) {
    const t0 = new Date();
    return [
      this.$fn.dateFns.sub(t0, { days: 7 }),
      null,
    ];
  }

  getPreviousThirtyDays(...args: any[]) {
    const t0 = new Date();
    return [
      this.$fn.dateFns.sub(t0, { days: 30 }),
      null,
    ];
  }

  getPreviousSixtyDays(...args: any[]) {
    const t0 = new Date();
    return [
      this.$fn.dateFns.sub(t0, { days: 60 }),
      null,
    ];
  }

  @Prop({
    required: true,
    type: String,
  })
  name!: string;

  @Watch('name')
  onName(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("name")
  }

  @Prop({
    type: String,
  })
  from!: string;

  @Watch('from')
  onFrom(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("from")
  }

  @Prop({
    type: String,
  })
  to!: string;

  @Watch('to')
  onTo(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("to")
  }

  @Prop({
    type: String,
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  item!: any;

  async $$load_item() {
    return { start: this.from, end: this.to }
  }

  mode!: any;
  options!: any;

  async $$load_options() {
    return [{
      key: "custom",
      name: this.$fn.pgettext("DateRangeSelector", "Custom"),
    }
      ,
    {
      key: "current_week",
      name: this.$fn.pgettext("DateRangeSelector", "Current week"),
      value: this.getCurrentWeek(),
    }
      ,
    {
      key: "current_month",
      name: this.$fn.pgettext("DateRangeSelector", "Current month"),
      value: this.getCurrentMonth(),
    }
      ,
    {
      key: "current_year",
      name: this.$fn.pgettext("DateRangeSelector", "Current year"),
      value: this.getCurrentYear(),
    }
      ,
    {
      key: "previous_week",
      name: this.$fn.pgettext("DateRangeSelector", "Previous week"),
      value: this.getPreviousWeek(),
    }
      ,
    {
      key: "previous_month",
      name: this.$fn.pgettext("DateRangeSelector", "Previous month"),
      value: this.getPreviousMonth(),
    }
      ,
    {
      key: "previous_year",
      name: this.$fn.pgettext("DateRangeSelector", "Previous year"),
      value: this.getPreviousYear(),
    }
      ,
    {
      key: "previous_seven_days",
      name: this.$fn.pgettext("DateRangeSelector", "Previous seven days"),
      value: this.getPreviousSevenDays(),
    }
      ,
    {
      key: "previous_thirty_days",
      name: this.$fn.pgettext("DateRangeSelector", "Previous thirty days"),
      value: this.getPreviousThirtyDays(),
    }
      ,
    {
      key: "previous_60_days",
      name: this.$fn.pgettext("DateRangeSelector", "Previous sixty days"),
      value: this.getPreviousSixtyDays(),
    }
      ,
    ]
  }

  ux!: any;
  dataMembers = ['item', 'mode', 'options', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        item: null,
        mode: "custom",
        options: null,
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_6466705138206980_setData_1810_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_6466705138206980_setData_1810, alias=undefined
    return {
      path: "item.start",
      value: $event.data.option.value
        ? ($event.data.option.value[0] ? this.$fn.formatDate($event.data.option.value[0], 'yyyy-MM-dd') : null)
        : this.item.start,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_6466705138206980_setData_1812_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_6466705138206980_setData_1812, alias=undefined
    return {
      path: "item.end",
      value: $event.data.option.value
        ? ($event.data.option.value[1] ? this.$fn.formatDate($event.data.option.value[1], 'yyyy-MM-dd') : null)
        : this.item.end,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_6466705138206980_optionSelected(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6466705138206980_optionSelected.executeFromDOM(this, event, scope);
  }

  async act_3943151158818434_emit_1814_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_3943151158818434_emit_1814, alias=undefined
    return {
      event: "changed",
      value: [this.item.start, this.item.end],
    }
  }

  async evh_3943151158818434_input(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3943151158818434_input.executeFromDOM(this, event, scope);
  }

  async act_4207795475682514_emit_1816_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_4207795475682514_emit_1816, alias=undefined
    return {
      event: "changed",
      value: [this.item.start, this.item.end],
    }
  }

  async evh_4207795475682514_input(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4207795475682514_input.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_1808: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_1807: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_6466705138206980_setData_1810: actions.SetDataAction;
    evh_6466705138206980_optionSelected_1809: actions.EventHandlerImpl;
    act_6466705138206980_setData_1812: actions.SetDataAction;
    evh_6466705138206980_optionSelected_1811: actions.EventHandlerImpl;
    evh_6466705138206980_optionSelected: actions.EventHandlerGroup;
    act_3943151158818434_emit_1814: actions.EmitAction;
    evh_3943151158818434_input_1813: actions.EventHandlerImpl;
    evh_3943151158818434_input: actions.EventHandlerGroup;
    act_4207795475682514_emit_1816: actions.EmitAction;
    evh_4207795475682514_input_1815: actions.EventHandlerImpl;
    evh_4207795475682514_input: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_1808 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_1807 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_1808,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_1807],
      }
    );
    const act_6466705138206980_setData_1810 = new actions.SetDataAction(
      {
        actionArgs: this.act_6466705138206980_setData_1810_getActionArgs,
        events: [],
      }
    );
    const evh_6466705138206980_optionSelected_1809 = new actions.EventHandlerImpl(
      {
        action: act_6466705138206980_setData_1810,
        event: "optionSelected",
        displayName: "setData",
      }
    );
    const act_6466705138206980_setData_1812 = new actions.SetDataAction(
      {
        actionArgs: this.act_6466705138206980_setData_1812_getActionArgs,
        events: [],
      }
    );
    const evh_6466705138206980_optionSelected_1811 = new actions.EventHandlerImpl(
      {
        action: act_6466705138206980_setData_1812,
        event: "optionSelected",
        displayName: "setData",
      }
    );
    const evh_6466705138206980_optionSelected = new actions.EventHandlerGroup(
      {
        handlers: [evh_6466705138206980_optionSelected_1809, evh_6466705138206980_optionSelected_1811],
      }
    );
    const act_3943151158818434_emit_1814 = new actions.EmitAction(
      {
        actionArgs: this.act_3943151158818434_emit_1814_getActionArgs,
        events: [],
      }
    );
    const evh_3943151158818434_input_1813 = new actions.EventHandlerImpl(
      {
        action: act_3943151158818434_emit_1814,
        event: "input",
        displayName: "emit",
      }
    );
    const evh_3943151158818434_input = new actions.EventHandlerGroup(
      {
        handlers: [evh_3943151158818434_input_1813],
      }
    );
    const act_4207795475682514_emit_1816 = new actions.EmitAction(
      {
        actionArgs: this.act_4207795475682514_emit_1816_getActionArgs,
        events: [],
      }
    );
    const evh_4207795475682514_input_1815 = new actions.EventHandlerImpl(
      {
        action: act_4207795475682514_emit_1816,
        event: "input",
        displayName: "emit",
      }
    );
    const evh_4207795475682514_input = new actions.EventHandlerGroup(
      {
        handlers: [evh_4207795475682514_input_1815],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_1808,
      evh_7315092382398562_reload_1807,
      evh_7315092382398562_reload,
      act_6466705138206980_setData_1810,
      evh_6466705138206980_optionSelected_1809,
      act_6466705138206980_setData_1812,
      evh_6466705138206980_optionSelected_1811,
      evh_6466705138206980_optionSelected,
      act_3943151158818434_emit_1814,
      evh_3943151158818434_input_1813,
      evh_3943151158818434_input,
      act_4207795475682514_emit_1816,
      evh_4207795475682514_input_1815,
      evh_4207795475682514_input,
    }
  }
}

Vue.component("DateRangeSelector", DateRangeSelector);

</script>