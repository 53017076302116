"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
(0, core_1.addRoute)({
    name: "workSummary",
    path: "/worksheets-work-summary",
    component: "WorkSummaryView"
});
let WorkSummaryView = class WorkSummaryView extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['selectedItems', 'params', 'ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_headerShowReloadButton', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns', 'b_6466705138206980_style'];
    }
    beforeCreate() {
        this.$$cache_params = new core_1.ComponentValueCache(this, "params", "WorkSummaryViewFilter");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_params() {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                date_from: this.$fn.today(),
                date_to: null,
            };
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            selectedItems: [],
            params: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "sm",
            },
            b_2248226175642056_headerShowReloadButton: false,
            b_2248226175642056_columnDefaults: {
                sortable: true,
                filterable: true,
                resizable: true,
            },
            b_2248226175642056_columns: [{
                    field: "service_name",
                    name: this.$fn.pgettext("WorkSummaryView", "Service name"),
                    type: "command",
                    formatter: {
                        name: "routeLink",
                        to: "product",
                        navigateOnIconClick: true,
                        params: {
                            id: "service_id",
                        },
                    },
                },
                {
                    field: "unit",
                    name: this.$fn.pgettext("WorkSummaryView", "unit"),
                },
                {
                    field: "quantity",
                    name: this.$fn.pgettext("WorkSummaryView", "Quantity"),
                    type: "number",
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                },
                {
                    field: "net_amount",
                    name: this.$fn.pgettext("WorkSummaryView", "Net"),
                    type: "number",
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                },
                {
                    field: "vat_amount",
                    name: this.$fn.pgettext("WorkSummaryView", "VAT"),
                    type: "number",
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                },
                {
                    field: "amount",
                    name: this.$fn.pgettext("WorkSummaryView", "Amount"),
                    type: "number",
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                },
                {
                    field: "rental_name",
                    name: this.$fn.pgettext("WorkSummaryView", "Rental"),
                    type: "command",
                    formatter: {
                        name: "routeLink",
                        to: "rental",
                        navigateOnIconClick: true,
                        params: {
                            id: "rental_id",
                        },
                    },
                },
                {
                    field: "company_name",
                    name: this.$fn.pgettext("WorkSummaryView", "Company"),
                    formatter: {
                        name: "routeLink",
                        to: "company",
                        navigateOnIconClick: true,
                        params: {
                            id: "company_id",
                        },
                    },
                },
                {
                    field: "client_name",
                    width: "200px",
                    name: this.$fn.pgettext("WorkSummaryView", "Client"),
                    formatter: {
                        name: "routeLink",
                        to: "client",
                        navigateOnIconClick: true,
                        params: {
                            id: "client_id",
                        },
                    },
                },
                {
                    field: "contractor_name",
                    width: "200px",
                    name: this.$fn.pgettext("WorkSummaryView", "Contractor"),
                    formatter: {
                        name: "routeLink",
                        to: "client",
                        navigateOnIconClick: true,
                        params: {
                            id: "contractor_id",
                        },
                    },
                },
            ],
            b_6466705138206980_style: {
                width: "500px",
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.pgettext("WorkSummaryView", "Work summary"),
                icon: this.$config.worksheet.icon,
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_5890_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    act_2248226175642056_setData_5892_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "selectedItems",
                value: this.$refs.grid.getSelectedItems(),
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_2248226175642056_selectedRowsChanged(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope);
        });
    }
    act_2248226175642056_showModal_5894_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Payment",
                props: {
                    modal: true,
                    oid: $event.data.data.id,
                },
            };
        });
    }
    evh_2248226175642056_command_5893_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.column.id === 'payment_number';
        });
    }
    evh_2248226175642056_command(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_command.executeFromDOM(this, event, scope);
        });
    }
    act_6466705138206980_setData_5896_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "params.date_from",
                value: $event.data[0],
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_6466705138206980_setData_5898_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "params.date_to",
                value: $event.data[1],
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_6466705138206980_changed(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6466705138206980_changed.executeFromDOM(this, event, scope);
        });
    }
    act_4440454779651433_script_5900_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.$refs.grid.reload();
        });
    }
    act_4440454779651433_script_5900_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_4440454779651433_script_5900_getActionArgs_value($event),
            };
        });
    }
    evh_4440454779651433_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_4440454779651433_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_5890 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_5890_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_5889 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_5890,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_5889],
        });
        const act_2248226175642056_setData_5892 = new core_1.actions.SetDataAction({
            actionArgs: this.act_2248226175642056_setData_5892_getActionArgs,
            events: [],
        });
        const evh_2248226175642056_selectedRowsChanged_5891 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_setData_5892,
            event: "selectedRowsChanged",
            displayName: "setData",
        });
        const evh_2248226175642056_selectedRowsChanged = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_selectedRowsChanged_5891],
        });
        const act_2248226175642056_showModal_5894 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2248226175642056_showModal_5894_getActionArgs,
            events: [],
        });
        const evh_2248226175642056_command_5893 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_showModal_5894,
            event: "command",
            when: this.evh_2248226175642056_command_5893_getWhen,
            displayName: "showModal",
        });
        const evh_2248226175642056_command = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_command_5893],
        });
        const act_6466705138206980_setData_5896 = new core_1.actions.SetDataAction({
            actionArgs: this.act_6466705138206980_setData_5896_getActionArgs,
            events: [],
        });
        const evh_6466705138206980_changed_5895 = new core_1.actions.EventHandlerImpl({
            action: act_6466705138206980_setData_5896,
            event: "changed",
            displayName: "setData",
        });
        const act_6466705138206980_setData_5898 = new core_1.actions.SetDataAction({
            actionArgs: this.act_6466705138206980_setData_5898_getActionArgs,
            events: [],
        });
        const evh_6466705138206980_changed_5897 = new core_1.actions.EventHandlerImpl({
            action: act_6466705138206980_setData_5898,
            event: "changed",
            displayName: "setData",
        });
        const evh_6466705138206980_changed = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6466705138206980_changed_5895, evh_6466705138206980_changed_5897],
        });
        const act_4440454779651433_script_5900 = new core_1.actions.ScriptAction({
            actionArgs: this.act_4440454779651433_script_5900_getActionArgs,
            events: [],
        });
        const evh_4440454779651433_clickPreventStop_5899 = new core_1.actions.EventHandlerImpl({
            action: act_4440454779651433_script_5900,
            event: "click.prevent.stop",
            displayName: "script",
        });
        const evh_4440454779651433_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4440454779651433_clickPreventStop_5899],
        });
        return {
            act_7315092382398562_closeModal_5890,
            evh_7315092382398562_close_5889,
            evh_7315092382398562_close,
            act_2248226175642056_setData_5892,
            evh_2248226175642056_selectedRowsChanged_5891,
            evh_2248226175642056_selectedRowsChanged,
            act_2248226175642056_showModal_5894,
            evh_2248226175642056_command_5893,
            evh_2248226175642056_command,
            act_6466705138206980_setData_5896,
            evh_6466705138206980_changed_5895,
            act_6466705138206980_setData_5898,
            evh_6466705138206980_changed_5897,
            evh_6466705138206980_changed,
            act_4440454779651433_script_5900,
            evh_4440454779651433_clickPreventStop_5899,
            evh_4440454779651433_clickPreventStop,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], WorkSummaryView.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], WorkSummaryView.prototype, "onSize", null);
WorkSummaryView = __decorate([
    (0, vue_property_decorator_1.Component)()
], WorkSummaryView);
exports.default = WorkSummaryView;
vue_property_decorator_1.Vue.component("WorkSummaryView", WorkSummaryView);
