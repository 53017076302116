<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpRow>
    <ItpCol
      col="7"
    >
      <ItpFormGroup
        labelColsMd="4"
        contentColsMd="8"
        name="name"
        :label="'Name'|pgettext('Client')"
        :labelAlign="labelAlign"
        required
      >
        <ItpFormInput
          name="name"
          v-model="client.name"
          required
          autofocus
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="4"
        name="number"
        :label="'Code'|pgettext('Client')"
        :labelAlign="labelAlign"
        required
      >
        <ItpFormInput
          name="number"
          v-model="client.code"
          required
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="4"
        contentColsMd="8"
        name="client_relation"
        :label="'Type'|pgettext('Client')"
        :labelAlign="labelAlign"
        required
      >
        <ItpFormSelect
          name="client_relation"
          v-model="client.client_relation"
          required
          :options="clientRelations"
        >
        </ItpFormSelect>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="4"
        contentColsMd="8"
        name="groups"
        :label="'Groups'|pgettext('Client')"
        :labelAlign="labelAlign"
      >
        <ItpFormSelect2
          name="groups"
          v-model="client.groups"
          multiple
          :options="clientGroups"
        >
        </ItpFormSelect2>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="4"
        contentColsMd="8"
        name="emails"
        :label="'Email'|pgettext('Client')"
        :labelAlign="labelAlign"
        :description="'Multiple email addresses should be separated by semicolon.'|pgettext('Client')"
      >
        <ItpFormInput
          type="text"
          name="emails"
          v-model="client.emails"
        >
        </ItpFormInput>
      </ItpFormGroup>
      <hr>
      </hr>
      <ItpFormGroup
        labelColsMd="4"
        contentColsMd="8"
        name="notes"
        :label="'Notes'|pgettext('Client')"
        :labelAlign="labelAlign"
      >
        <ItpFormTextarea
          name="notes"
          v-model="client.notes"
        >
        </ItpFormTextarea>
      </ItpFormGroup>
    </ItpCol>
    <ItpCol
      col="5"
    >
      <ItpFormGroup
        labelColsMd="4"
        name="tax_number"
        :label="'Tax Number'|pgettext('Client')"
        :labelAlign="labelAlign"
      >
        <ItpFormInput
          name="tax_number"
          v-model="client.tax_number"
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="4"
        name="eu_tax_number"
        :label="'EU Tax Number'|pgettext('Client')"
        :labelAlign="labelAlign"
      >
        <ItpFormInput
          name="eu_tax_number"
          v-model="client.eu_tax_number"
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="4"
        name="registration_number"
        :label="'Registration Number'|pgettext('Client')"
        :labelAlign="labelAlign"
      >
        <ItpFormInput
          name="registration_number"
          v-model="client.registration_number"
        >
        </ItpFormInput>
      </ItpFormGroup>
      <hr>
      </hr>
      <ItpFormGroup
        name="payment_period_days"
        :label="'Payment period'|pgettext('Client')"
        labelColsMd="4"
        contentColsMd="5"
        :labelAlign="labelAlign"
        contentClass="input-group"
      >
        <ItpFormInput
          type="number"
          name="payment_period_days"
          v-model="client.payment_period_days"
        >
        </ItpFormInput>
        <DIV
          class="input-group-append"
        >
          <span
            class="input-group-text"
          >
            {{ 'days'|pgettext('InputGroupAddon') }}
          </span>
        </DIV>
      </ItpFormGroup>
      <ItpFormGroup
        name="discount"
        :label="'Discount'|pgettext('Client')"
        labelColsMd="4"
        contentColsMd="4"
        :labelAlign="labelAlign"
        contentClass="input-group"
      >
        <ItpFormInput
          type="number"
          name="discount"
          v-model="client.discount"
        >
        </ItpFormInput>
        <DIV
          class="input-group-append"
        >
          <span
            class="input-group-text"
          >
            %
          </span>
        </DIV>
      </ItpFormGroup>
      <hr>
      </hr>
      <ItpFormGroup
        labelColsMd="4"
        name="is_contractor"
        :labelAlign="labelAlign"
      >
        <ItpFormCheckbox
          name="is_clientor"
          :label="'Contractor'|pgettext('Client')"
          v-model="client.is_contractor"
        >
        </ItpFormCheckbox>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="4"
        name="is_natural_person"
        :labelAlign="labelAlign"
      >
        <ItpFormCheckbox
          name="is_natural_person"
          :label="'Natural Person'|pgettext('Client')"
          v-model="client.is_natural_person"
        >
        </ItpFormCheckbox>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="4"
        name="vat_exempt"
        :labelAlign="labelAlign"
      >
        <ItpFormCheckbox
          name="vat_exempt"
          :label="'VAT exempt'|pgettext('Client')"
          v-model="client.vat_exempt"
        >
        </ItpFormCheckbox>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="4"
        name="excluded_from_claims"
        :labelAlign="labelAlign"
      >
        <ItpFormCheckbox
          name="excluded_from_claims"
          :label="'Exclude from claims'|pgettext('Client')"
          v-model="client.excluded_from_claims"
        >
        </ItpFormCheckbox>
      </ItpFormGroup>
    </ItpCol>
  </ItpRow>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class FieldsetClientGeneral extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  client!: object;

  @Watch('client')
  onClient(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("client")
  }

  @Prop({
    type: String,
    default: "right",
  })
  labelAlign!: string;

  @Watch('labelAlign')
  onLabel_align(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("labelAlign")
  }

  clientRelations!: any;

  async $$load_clientRelations() {
    return this.$fn.getEnumValuesFromSchema('ClientRelations', { sort: false })
  }

  clientGroups!: any;

  async $$load_clientGroups() {
    return this.$fn.fetch('list_group', { parameters: { type: 'client' } })
      .then(data => data.data.map(p => p.name))
  }

  ux!: any;
  dataMembers = ['clientRelations', 'clientGroups', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        clientRelations: null,
        clientGroups: null,
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_4290: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_4289: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_4290 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_4289 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_4290,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_4289],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_4290,
      evh_7315092382398562_reload_4289,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("FieldsetClientGeneral", FieldsetClientGeneral);

</script>