"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UcInvoiceReferences = exports.InvoiceReferenceSearchOption = exports.InvoiceReferenceSearch = exports.InvoiceReferenceItem = void 0;
const InvoiceReferenceItem_yaml_component_1 = require("./InvoiceReferenceItem.yaml?component");
exports.InvoiceReferenceItem = InvoiceReferenceItem_yaml_component_1.default;
const InvoiceReferenceSearch_yaml_component_1 = require("./InvoiceReferenceSearch.yaml?component");
exports.InvoiceReferenceSearch = InvoiceReferenceSearch_yaml_component_1.default;
const InvoiceReferenceSearchOption_yaml_component_1 = require("./InvoiceReferenceSearchOption.yaml?component");
exports.InvoiceReferenceSearchOption = InvoiceReferenceSearchOption_yaml_component_1.default;
const UcInvoiceReferences_yaml_component_1 = require("./UcInvoiceReferences.yaml?component");
exports.UcInvoiceReferences = UcInvoiceReferences_yaml_component_1.default;
