<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpWidgetCard>
    <ItpBox
      alignItems="center"
      slot="header"
    >
      <strong>
        {{ 'General'|pgettext('Client') }}
      </strong>
      <ItpButton
        :text="'Edit...'|gettext"
        variant="light"
        size="sm"
        class="ml-auto"
        v-if="!readonly"
        @click.prevent.stop="evh_8320016629450276_clickPreventStop($event, {})"
      >
      </ItpButton>
    </ItpBox>
    <BListGroup>
      <ItpLabeledListGroupItem
        :label="'Name'|pgettext('Client')"
        :labelCols="labelCols"
        :content="client.name"
        v-if="client.name"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Number'|pgettext('Client')"
        :labelCols="labelCols"
        :content="client.code"
        v-if="client.code"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Client type'|pgettext('Client')"
        :labelCols="labelCols"
        v-if="client.client_relation"
      >
        <UcClientTypeBadge
          :value="client.client_relation"
        >
        </UcClientTypeBadge>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Email'|pgettext('Client')"
        :labelCols="labelCols"
        :content="client.emails"
        v-if="client.emails"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Tax number'|pgettext('Client')"
        :labelCols="labelCols"
        :content="client.tax_number"
        v-if="client.tax_number"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'EU tax number'|pgettext('Client')"
        :labelCols="labelCols"
        :content="client.eu_tax_number"
        v-if="client.eu_tax_number"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Registration number'|pgettext('Client')"
        :labelCols="labelCols"
        :content="client.registration_number"
        v-if="client.registration_number"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Notes'|pgettext('Client')"
        :labelCols="labelCols"
        :content="client.notes"
        v-if="client.notes"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Payment period'|pgettext('Client')"
        :labelCols="labelCols"
        v-if="client.payment_period_days"
      >
        {{ client.payment_period_days }} {{ 'days'|pgettext('InputGroupAddon') }}
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Discount'|pgettext('Client')"
        :labelCols="labelCols"
        v-if="client.discount"
      >
        {{ client.discount }} %
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Contractor'|pgettext('Client')"
        :labelCols="labelCols"
        :content="'Yes'|gettext"
        v-if="client.is_contractor"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Natural person'|pgettext('Client')"
        :labelCols="labelCols"
        :content="'Yes'|gettext"
        v-if="client.is_natural_person"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Exclude from claims'|pgettext('Client')"
        :labelCols="labelCols"
        :content="'Yes'|gettext"
        v-if="client.excluded_from_claims"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'VAT exempt'|pgettext('Client')"
        :labelCols="labelCols"
        :content="'Yes'|gettext"
        v-if="client.vat_exempt"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Groups'|pgettext('Client')"
        :labelCols="labelCols"
        :content="client.groups"
        v-if="client.groups"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'External key'|pgettext('Client')"
        :labelCols="labelCols"
        :content="client.external_key"
        v-if="client.external_key"
      >
      </ItpLabeledListGroupItem>
    </BListGroup>
  </ItpWidgetCard>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcClientGeneral extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  client!: object;

  @Watch('client')
  onClient(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("client")
  }

  @Prop({
    type: Boolean,
  })
  readonly!: boolean;

  @Watch('readonly')
  onReadonly(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("readonly")
  }

  @Prop({
    type: Number,
    default: 4,
  })
  labelCols!: number;

  @Watch('labelCols')
  onLabel_cols(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("labelCols")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_8320016629450276_emit_4310_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_8320016629450276_emit_4310, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_8320016629450276_close_4309_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_8320016629450276_close_4309, alias=undefined
    return !!$event.data.modalResult.value
  }

  async act_8320016629450276_showModal_4308_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8320016629450276_showModal_4308, alias=undefined
    return {
      name: "MClientEditor",
      props: {
        target: "general",
        client: _.cloneDeep(this.client),
      }
      ,
    }
  }

  async evh_8320016629450276_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_4306: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_4305: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_8320016629450276_emit_4310: actions.EmitAction;
    evh_8320016629450276_close_4309: actions.EventHandlerImpl;
    act_8320016629450276_showModal_4308: actions.ShowModalAction;
    evh_8320016629450276_clickPreventStop_4307: actions.EventHandlerImpl;
    evh_8320016629450276_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_4306 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_4305 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_4306,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_4305],
      }
    );
    const act_8320016629450276_emit_4310 = new actions.EmitAction(
      {
        actionArgs: this.act_8320016629450276_emit_4310_getActionArgs,
        events: [],
      }
    );
    const evh_8320016629450276_close_4309 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_emit_4310,
        event: "close",
        when: this.evh_8320016629450276_close_4309_getWhen,
        displayName: "emit",
      }
    );
    const act_8320016629450276_showModal_4308 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8320016629450276_showModal_4308_getActionArgs,
        events: [evh_8320016629450276_close_4309],
      }
    );
    const evh_8320016629450276_clickPreventStop_4307 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_showModal_4308,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_8320016629450276_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_clickPreventStop_4307],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_4306,
      evh_7315092382398562_reload_4305,
      evh_7315092382398562_reload,
      act_8320016629450276_emit_4310,
      evh_8320016629450276_close_4309,
      act_8320016629450276_showModal_4308,
      evh_8320016629450276_clickPreventStop_4307,
      evh_8320016629450276_clickPreventStop,
    }
  }
}

Vue.component("UcClientGeneral", UcClientGeneral);

</script>