<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpWidgetCard>
    <ItpBox
      alignItems="center"
      slot="header"
    >
      <strong>
        {{ 'Invoices'|pgettext('Contract') }}
      </strong>
      <strong
        class="mx-2"
        v-if="invoices && invoices.length"
      >
        ({{ invoices.length }})
      </strong>
      <ItpButton
        variant="light"
        icon="fa-sync-alt"
        :tooltip="'Reload'|gettext"
        :spinning="actions.reload.isRunning"
        size="sm"
        class="ml-2"
        @click.prevent.stop="evh_6705964765214521_clickPreventStop($event, {})"
      >
      </ItpButton>
      <ItpButton
        :text="'Generate invoice...'|pgettext('Contract')"
        variant="light"
        size="sm"
        class="ml-auto"
        @click.prevent.stop="evh_6806515264385235_clickPreventStop($event, {})"
      >
      </ItpButton>
    </ItpBox>
    <BListGroup
      v-if="loaded"
    >
      <BListGroupItem
        v-if="!invoices.length"
      >
        <span>
          {{ 'No related invoices.'|pgettext('Contract') }}
        </span>
      </BListGroupItem>
      <BListGroupItem
        :key="invoice.id"
        class="d-flex flex-column"
        v-for="invoice in filterItems(invoices, displayAll, displayCount)"
      >
        <table
          class="table table-borderless table-sm m-0"
        >
          <tbody>
            <tr>
              <td>
                <ItpLink
                  :text="invoice.invoice_number"
                  :to="{
    name: 'app.invoice',
    params: {id: invoice.id}
  }"
                >
                </ItpLink>
                <UcInvoiceStatusBadge
                  :value="invoice.invoice_status"
                  class="mt-2"
                  v-if="invoice.invoice_status === 'draft'"
                >
                </UcInvoiceStatusBadge>
              </td>
              <td>
                <ItpText
                  muted
                  spacing="mr-2"
                >
                  {{'Net'|pgettext('InvoiceContract')}}:

                </ItpText>
                <ItpText
                  strong
                  spacing="mr-3"
                >
                  {{invoice.net_total|number }} {{ invoice.currency}}

                </ItpText>
              </td>
              <td>
                <ItpText
                  muted
                  spacing="px-1"
                >
                  {{ 'Total'|pgettext('InvoiceContract') }}:

                </ItpText>
                <ItpText
                  strong
                  spacing="px-2"
                >
                  {{ invoice.total|number }} {{ invoice.currency }}

                </ItpText>
              </td>
              <td>
                <b-dropdown
                  size="sm"
                  toggle-class="btn-default-borderless"
                  no-caret
                  class="ml-auto mb-auto"
                >
                  <ItpIcon
                    fa="ellipsis-v"
                    class="mx-1"
                    slot="button-content"
                  >
                  </ItpIcon>
                  <b-dropdown-item-button
                    @click="evh_5137197219681649_click($event, {invoice})"
                  >
                    <ItpText
                      :text="'Log messages'|pgettext('Invoice')"
                      :tooltip="'View log messages'|pgettext('Invoice')"
                    >
                    </ItpText>
                  </b-dropdown-item-button>
                </b-dropdown>
              </td>
            </tr>
            <tr>
              <td>
                <ItpText
                  muted
                  strong
                  spacing="mr-4"
                >
                  {{ invoice.invoice_date|date }}
                </ItpText>
              </td>
              <td>
                <ItpText
                  :tooltip="'Billing Period'|pgettext('InvoiceContract')"
                  v-if="invoice.billing_period_start"
                >
                  {{ invoice.billing_period_start|date }} - {{ invoice.billing_period_end|date }}

                </ItpText>
              </td>
            </tr>
          </tbody>
        </table>
      </BListGroupItem>
      <BListGroupItem
        v-if="canHideItems || canDisplayMoreItems"
      >
        <ItpBox
          justifyContent="center"
        >
          <ItpLink
            :text="'Show less'|gettext"
            v-if="canHideItems"
            @click.prevent.stop="evh_7736732573189513_clickPreventStop($event, {})"
          >
          </ItpLink>
          <ItpLink
            :text="'Show more'|gettext"
            v-if="canDisplayMoreItems"
            @click.prevent.stop="evh_2912679705172878_clickPreventStop($event, {})"
          >
          </ItpLink>
        </ItpBox>
      </BListGroupItem>
    </BListGroup>
  </ItpWidgetCard>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcContractInvoicesPanel extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
    this.$$cache_displayAll = new ComponentValueCache(this, "displayAll", "Contract.Invoices.displayAll");
  }

  filterItems(...args: any[]) {
    const [invoices, displayAll, displayCount] = args;
    if (displayAll || displayCount == invoices.length) {
      return invoices;
    }
    return _.take(invoices, displayCount)
  }

  @Prop({
    required: true,
    type: String,
  })
  contractId!: string;

  @Watch('contractId')
  onContract_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("contractId")
  }

  invoices!: any;

  async $$load_invoices() {
    return this.$fn.fetch('list_invoice', { parameters: { contract_id: this.contractId } })
      .then(d => _.orderBy(d, ['invoice_date'], ['desc']))
  }

  displayAll!: any;
  displayCount!: any;
  ux!: any;
  dataMembers = ['invoices', 'displayAll', 'displayCount', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        invoices: null,
        displayAll: false,
        displayCount: 5,
        ux: null,
      },
    }
  }

  get canDisplayMoreItems() {
    if (this.loaded) {

      return !this.displayAll && this.invoices && this.displayCount < this.invoices.length
    }
    return null;
  }

  get canHideItems() {
    if (this.loaded) {

      return this.displayAll && this.invoices && this.displayCount < this.invoices.length
    }
    return null;
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async evh_6705964765214521_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6705964765214521_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_6806515264385235_showModal_6288_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_6806515264385235_showModal_6288, alias=undefined
    return {
      name: "InvoiceGeneration",
      props: {
        contract: this.contractId,
      }
      ,
    }
  }

  async evh_6806515264385235_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6806515264385235_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_5137197219681649_showModal_6290_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_5137197219681649_showModal_6290, alias=undefined
    return {
      name: "InvoiceLogMessageViewer",
      props: {
        invoiceId: $event.scope.invoice.id,
      }
      ,
    }
  }

  async evh_5137197219681649_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5137197219681649_click.executeFromDOM(this, event, scope);
  }

  async act_7736732573189513_setData_6292_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_7736732573189513_setData_6292, alias=undefined
    return {
      path: "displayAll",
      value: false,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_7736732573189513_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7736732573189513_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_2912679705172878_setData_6294_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2912679705172878_setData_6294, alias=undefined
    return {
      path: "displayAll",
      value: true,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_2912679705172878_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2912679705172878_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_6284: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_6283: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_6705964765214521_reloadComponentData_6286: actions.ReloadComponentDataAction;
    evh_6705964765214521_clickPreventStop_6285: actions.EventHandlerImpl;
    evh_6705964765214521_clickPreventStop: actions.EventHandlerGroup;
    act_6806515264385235_showModal_6288: actions.ShowModalAction;
    evh_6806515264385235_clickPreventStop_6287: actions.EventHandlerImpl;
    evh_6806515264385235_clickPreventStop: actions.EventHandlerGroup;
    act_5137197219681649_showModal_6290: actions.ShowModalAction;
    evh_5137197219681649_click_6289: actions.EventHandlerImpl;
    evh_5137197219681649_click: actions.EventHandlerGroup;
    act_7736732573189513_setData_6292: actions.SetDataAction;
    evh_7736732573189513_clickPreventStop_6291: actions.EventHandlerImpl;
    evh_7736732573189513_clickPreventStop: actions.EventHandlerGroup;
    act_2912679705172878_setData_6294: actions.SetDataAction;
    evh_2912679705172878_clickPreventStop_6293: actions.EventHandlerImpl;
    evh_2912679705172878_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_6284 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_6283 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_6284,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_6283],
      }
    );
    const act_6705964765214521_reloadComponentData_6286 = new actions.ReloadComponentDataAction(
      {
        displayName: "reload",
        events: [],
      }
    );
    const evh_6705964765214521_clickPreventStop_6285 = new actions.EventHandlerImpl(
      {
        action: act_6705964765214521_reloadComponentData_6286,
        event: "click.prevent.stop",
        displayName: "reload",
      }
    );
    const evh_6705964765214521_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_6705964765214521_clickPreventStop_6285],
      }
    );
    const act_6806515264385235_showModal_6288 = new actions.ShowModalAction(
      {
        actionArgs: this.act_6806515264385235_showModal_6288_getActionArgs,
        events: [],
      }
    );
    const evh_6806515264385235_clickPreventStop_6287 = new actions.EventHandlerImpl(
      {
        action: act_6806515264385235_showModal_6288,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_6806515264385235_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_6806515264385235_clickPreventStop_6287],
      }
    );
    const act_5137197219681649_showModal_6290 = new actions.ShowModalAction(
      {
        actionArgs: this.act_5137197219681649_showModal_6290_getActionArgs,
        events: [],
      }
    );
    const evh_5137197219681649_click_6289 = new actions.EventHandlerImpl(
      {
        action: act_5137197219681649_showModal_6290,
        event: "click",
        displayName: "showModal",
      }
    );
    const evh_5137197219681649_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_5137197219681649_click_6289],
      }
    );
    const act_7736732573189513_setData_6292 = new actions.SetDataAction(
      {
        actionArgs: this.act_7736732573189513_setData_6292_getActionArgs,
        events: [],
      }
    );
    const evh_7736732573189513_clickPreventStop_6291 = new actions.EventHandlerImpl(
      {
        action: act_7736732573189513_setData_6292,
        event: "click.prevent.stop",
        displayName: "setData",
      }
    );
    const evh_7736732573189513_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_7736732573189513_clickPreventStop_6291],
      }
    );
    const act_2912679705172878_setData_6294 = new actions.SetDataAction(
      {
        actionArgs: this.act_2912679705172878_setData_6294_getActionArgs,
        events: [],
      }
    );
    const evh_2912679705172878_clickPreventStop_6293 = new actions.EventHandlerImpl(
      {
        action: act_2912679705172878_setData_6294,
        event: "click.prevent.stop",
        displayName: "setData",
      }
    );
    const evh_2912679705172878_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_2912679705172878_clickPreventStop_6293],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_6284,
      evh_7315092382398562_reload_6283,
      evh_7315092382398562_reload,
      act_6705964765214521_reloadComponentData_6286,
      evh_6705964765214521_clickPreventStop_6285,
      evh_6705964765214521_clickPreventStop,
      act_6806515264385235_showModal_6288,
      evh_6806515264385235_clickPreventStop_6287,
      evh_6806515264385235_clickPreventStop,
      act_5137197219681649_showModal_6290,
      evh_5137197219681649_click_6289,
      evh_5137197219681649_click,
      act_7736732573189513_setData_6292,
      evh_7736732573189513_clickPreventStop_6291,
      evh_7736732573189513_clickPreventStop,
      act_2912679705172878_setData_6294,
      evh_2912679705172878_clickPreventStop_6293,
      evh_2912679705172878_clickPreventStop,
      reload: act_6705964765214521_reloadComponentData_6286,
    }
  }
}

Vue.component("UcContractInvoicesPanel", UcContractInvoicesPanel);

</script>