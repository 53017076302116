var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        card: "",
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "TextTemplates",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c("UcHeader", {
            attrs: {
              slot: "header",
              card: !_vm.modal,
              modal: _vm.modal,
              title: _vm._f("pgettext")(
                "Edit Text Templates",
                "TextTemplates|header"
              )
            },
            on: {
              close: function($event) {
                return _vm.evh_2248226175642056_close($event, {})
              }
            },
            slot: "header"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _c(
                "ItpSelect2",
                {
                  attrs: {
                    options: _vm.items,
                    valueField: "id",
                    textField: "name",
                    "bind-object": ""
                  },
                  model: {
                    value: _vm.selectedItem,
                    callback: function($$v) {
                      _vm.selectedItem = $$v
                    },
                    expression: "selectedItem"
                  }
                },
                [
                  _c(
                    "template",
                    { slot: "list-footer" },
                    [
                      _c("hr"),
                      _vm._v(" "),
                      _c("b-dropdown-item", [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                return _vm.evh_3054425420812800_click(
                                  $event,
                                  {}
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm._f("pgettext")(
                                    "Add new text template",
                                    "TextTemplates"
                                  )
                                ) +
                                "\n          "
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ],
                2
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _vm.selectedItem || _vm.createNewItem
                ? _c("TextTemplate", {
                    staticClass: "mt-3",
                    attrs: {
                      oid: _vm.createNewItem ? null : _vm.selectedItem.id
                    },
                    on: {
                      saved: function($event) {
                        return _vm.evh_3083451129821185_saved($event, {})
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
              slot: "footer"
            },
            [
              _c("ItpButton", {
                attrs: { text: _vm._f("gettext")("Close"), variant: "default" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_1548630417156826_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }