"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let EntityEvents = class EntityEvents extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['selectedEventId', 'eventData', 'ux', 'b_7315092382398562_noHeader', 'b_7315092382398562_modalBindings', 'b_7789867021953041_headerShowColumnSelectorButton', 'b_7789867021953041_headerShowReloadButton', 'b_7789867021953041_columns', 'b_2070266589801014_options'];
    }
    beforeCreate() {
    }
    onEntity_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("entityId");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            selectedEventId: null,
            eventData: null,
            ux: null,
            b_7315092382398562_noHeader: false,
            b_7315092382398562_modalBindings: {
                noHeader: false,
                noPrimaryButton: true,
                size: "full-screen",
            },
            b_7789867021953041_headerShowColumnSelectorButton: false,
            b_7789867021953041_headerShowReloadButton: false,
            b_7789867021953041_columns: [{
                    field: "event_name",
                    name: this.$fn.pgettext("EntityEvents", "Event"),
                    type: "command",
                },
                {
                    field: "timestamp",
                    name: this.$fn.pgettext("EntityEvents", "Timestamp"),
                    formatter: {
                        name: "datetime",
                    },
                },
                {
                    field: "identity",
                    name: this.$fn.pgettext("EntityEvents", "User"),
                    formatter: {
                        name: "user",
                    },
                },
            ],
            b_2070266589801014_options: {
                lineNumbers: true,
                matchBrackets: true,
                continueComments: "Enter",
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.pgettext("EntityEvents/header", "Entity events"),
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_1828_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_7789867021953041_setData_1832_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "selectedEventId",
                value: $event.data.data.id,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_7789867021953041_setData_1836_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "eventData",
                value: JSON.stringify($event.data.response.data, null, 2),
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_7789867021953041_request_1834_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "read_entity_event_data",
                data: { id: this.entityId, event_id: this.selectedEventId },
            };
        });
    }
    evh_7789867021953041_commandEventName(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7789867021953041_commandEventName.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_1828 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_1828_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_1827 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_1828,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_1827],
        });
        const act_2248226175642056_closeModal_1830 = new core_1.actions.CloseModalAction({
            events: [],
        });
        const evh_2248226175642056_close_1829 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeModal_1830,
            event: "close",
            displayName: "closeModal",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_1829],
        });
        const act_7789867021953041_setData_1832 = new core_1.actions.SetDataAction({
            actionArgs: this.act_7789867021953041_setData_1832_getActionArgs,
            events: [],
        });
        const evh_7789867021953041_commandEventName_1831 = new core_1.actions.EventHandlerImpl({
            action: act_7789867021953041_setData_1832,
            event: "command:event_name",
            displayName: "setData",
        });
        const act_7789867021953041_setData_1836 = new core_1.actions.SetDataAction({
            actionArgs: this.act_7789867021953041_setData_1836_getActionArgs,
            events: [],
        });
        const evh_7789867021953041_success_1835 = new core_1.actions.EventHandlerImpl({
            action: act_7789867021953041_setData_1836,
            event: "success",
            displayName: "setData",
        });
        const act_7789867021953041_request_1834 = new core_1.actions.RequestAction({
            actionArgs: this.act_7789867021953041_request_1834_getActionArgs,
            displayName: "loadEventData",
            events: [evh_7789867021953041_success_1835],
        });
        const evh_7789867021953041_commandEventName_1833 = new core_1.actions.EventHandlerImpl({
            action: act_7789867021953041_request_1834,
            event: "command:event_name",
            displayName: "loadEventData",
        });
        const evh_7789867021953041_commandEventName = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7789867021953041_commandEventName_1831, evh_7789867021953041_commandEventName_1833],
        });
        return {
            act_7315092382398562_closeModal_1828,
            evh_7315092382398562_close_1827,
            evh_7315092382398562_close,
            act_2248226175642056_closeModal_1830,
            evh_2248226175642056_close_1829,
            evh_2248226175642056_close,
            act_7789867021953041_setData_1832,
            evh_7789867021953041_commandEventName_1831,
            act_7789867021953041_setData_1836,
            evh_7789867021953041_success_1835,
            act_7789867021953041_request_1834,
            evh_7789867021953041_commandEventName_1833,
            evh_7789867021953041_commandEventName,
            loadEventData: act_7789867021953041_request_1834,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: String,
    }),
    __metadata("design:type", String)
], EntityEvents.prototype, "entityId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('entityId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], EntityEvents.prototype, "onEntity_id", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "full-screen",
    }),
    __metadata("design:type", String)
], EntityEvents.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], EntityEvents.prototype, "onSize", null);
EntityEvents = __decorate([
    (0, vue_property_decorator_1.Component)()
], EntityEvents);
exports.default = EntityEvents;
vue_property_decorator_1.Vue.component("EntityEvents", EntityEvents);
