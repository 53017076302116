<template>
<b-dropdown
  size="sm"
  toggle-class="btn-light border"
  v-if="loaded"
>
  <template
    slot="button-content"
  >
    <UcContractRevisionDropdownItem
      :revisionInfo="selectedRevision"
    >
    </UcContractRevisionDropdownItem>
  </template>
  <hr
    v-if="contract.revisions.length > 1"
  >
  </hr>
  <b-dropdown-item-button
    :key="revision.revision_number"
    v-for="revision in _.orderBy(contract.revisions, ['revision_number'])"
    @click.prevent.stop="evh_8358196963355853_clickPreventStop($event, {revision})"
  >
    <UcContractRevisionDropdownItem
      :revisionInfo="revision"
      :isSelected="contract.revision_number == revision.revision_number"
    >
    </UcContractRevisionDropdownItem>
  </b-dropdown-item-button>
  <hr>
  </hr>
  <b-dropdown-item-button
    @click="evh_8356612450665535_click($event, {})"
  >
    <ItpText
      :text="'Create revision...'|pgettext('Contract')"
    >
    </ItpText>
  </b-dropdown-item-button>
  <b-dropdown-item-button
    v-if="contract.metadata.can_delete_revision"
    @click="evh_1382594685840889_click($event, {})"
  >
    <ItpText
      :text="'Delete revision'|pgettext('Contract')"
    >
    </ItpText>
  </b-dropdown-item-button>
</b-dropdown>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcContractRevisionDropdown extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  otherRevisions(...args: any[]) {
    return _.filter(args[0], p => p.revision_number != args[1])
  }

  @Prop({
    required: true,
    type: Object,
  })
  contract!: object;

  @Watch('contract')
  onContract(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("contract")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  get selectedRevision() {
    if (this.loaded) {

      return _.find(this.contract.revisions, { revision_number: this.contract.revision_number })
    }
    return null;
  }

  async act_8358196963355853_navigate_4764_getActionArgs($event: actions.ActionEvent): Promise<actions.NavigateActionArgs> {
    // parameterResolver name=act_8358196963355853_navigate_4764, alias=undefined
    return {
      location: {
        name: "app.contract",
        query: {
          rev: $event.scope.revision.revision_number,
        }
        ,
        params: {
          id: this.contract.id,
        }
        ,
      }
      ,
    }
  }

  async evh_8358196963355853_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8358196963355853_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_8356612450665535_showModal_4766_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8356612450665535_showModal_4766, alias=undefined
    return {
      name: "CreateContractRevision",
      props: {
        contract: _.clone(this.contract),
      }
      ,
    }
  }

  async evh_8356612450665535_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8356612450665535_click.executeFromDOM(this, event, scope);
  }

  async act_1382594685840889_closeComponent_4772_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseComponentActionArgs> {
    // parameterResolver name=act_1382594685840889_closeComponent_4772, alias=undefined
    return {
      result: {
        close: true,
      }
      ,
    }
  }

  async act_1382594685840889_request_4770_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_1382594685840889_request_4770, alias=undefined
    return {
      operation: "delete_contract",
      data: this.contract,
      parameters: {
        rev: this.contract.revision_number,
      }
      ,
    }
  }

  async act_1382594685840889_request_4770_getConfirm($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_1382594685840889_request_4770, alias=undefined
    return {
      message: this.$fn.gettext("Please confirm delete."),
      btnOk: this.$fn.gettext("Delete"),
    }
  }

  async evh_1382594685840889_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1382594685840889_click.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_8358196963355853_navigate_4764: actions.NavigateAction;
    evh_8358196963355853_clickPreventStop_4763: actions.EventHandlerImpl;
    evh_8358196963355853_clickPreventStop: actions.EventHandlerGroup;
    act_8356612450665535_reloadComponentData_4768: actions.ReloadComponentDataAction;
    evh_8356612450665535_success_4767: actions.EventHandlerImpl;
    act_8356612450665535_showModal_4766: actions.ShowModalAction;
    evh_8356612450665535_click_4765: actions.EventHandlerImpl;
    evh_8356612450665535_click: actions.EventHandlerGroup;
    act_1382594685840889_closeComponent_4772: actions.CloseComponentAction;
    evh_1382594685840889_success_4771: actions.EventHandlerImpl;
    act_1382594685840889_request_4770: actions.RequestAction;
    evh_1382594685840889_click_4769: actions.EventHandlerImpl;
    evh_1382594685840889_click: actions.EventHandlerGroup;
  }

  getActions() {
    const act_8358196963355853_navigate_4764 = new actions.NavigateAction(
      {
        actionArgs: this.act_8358196963355853_navigate_4764_getActionArgs,
        events: [],
      }
    );
    const evh_8358196963355853_clickPreventStop_4763 = new actions.EventHandlerImpl(
      {
        action: act_8358196963355853_navigate_4764,
        event: "click.prevent.stop",
        displayName: "navigate",
      }
    );
    const evh_8358196963355853_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8358196963355853_clickPreventStop_4763],
      }
    );
    const act_8356612450665535_reloadComponentData_4768 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_8356612450665535_success_4767 = new actions.EventHandlerImpl(
      {
        action: act_8356612450665535_reloadComponentData_4768,
        event: "success",
        displayName: "reloadComponentData",
      }
    );
    const act_8356612450665535_showModal_4766 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8356612450665535_showModal_4766_getActionArgs,
        events: [evh_8356612450665535_success_4767],
      }
    );
    const evh_8356612450665535_click_4765 = new actions.EventHandlerImpl(
      {
        action: act_8356612450665535_showModal_4766,
        event: "click",
        displayName: "showModal",
      }
    );
    const evh_8356612450665535_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_8356612450665535_click_4765],
      }
    );
    const act_1382594685840889_closeComponent_4772 = new actions.CloseComponentAction(
      {
        actionArgs: this.act_1382594685840889_closeComponent_4772_getActionArgs,
        events: [],
      }
    );
    const evh_1382594685840889_success_4771 = new actions.EventHandlerImpl(
      {
        action: act_1382594685840889_closeComponent_4772,
        event: "success",
        displayName: "closeComponent",
      }
    );
    const act_1382594685840889_request_4770 = new actions.RequestAction(
      {
        actionArgs: this.act_1382594685840889_request_4770_getActionArgs,
        confirm: this.act_1382594685840889_request_4770_getConfirm,
        events: [evh_1382594685840889_success_4771],
      }
    );
    const evh_1382594685840889_click_4769 = new actions.EventHandlerImpl(
      {
        action: act_1382594685840889_request_4770,
        event: "click",
        displayName: "request",
      }
    );
    const evh_1382594685840889_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_1382594685840889_click_4769],
      }
    );
    return {
      act_8358196963355853_navigate_4764,
      evh_8358196963355853_clickPreventStop_4763,
      evh_8358196963355853_clickPreventStop,
      act_8356612450665535_reloadComponentData_4768,
      evh_8356612450665535_success_4767,
      act_8356612450665535_showModal_4766,
      evh_8356612450665535_click_4765,
      evh_8356612450665535_click,
      act_1382594685840889_closeComponent_4772,
      evh_1382594685840889_success_4771,
      act_1382594685840889_request_4770,
      evh_1382594685840889_click_4769,
      evh_1382594685840889_click,
    }
  }
}

Vue.component("UcContractRevisionDropdown", UcContractRevisionDropdown);

</script>