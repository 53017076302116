"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
(0, core_1.addRoute)({
    name: "templates.template",
    path: ":id",
    component: "Template",
    params: [{
            name: "id",
            prop: "resourceId",
        },
    ]
});
let Template = class Template extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['item', 'sampleDataSearchResult', 'sampleData', 'previewData', 'emailAddress', 'ux', 'b_7315092382398562_modalBindings', 'b_3418904655244121_options', 'b_8695782439888834_style', 'b_2407519033660513_dataSource', 'b_5419303415570863_options'];
    }
    beforeCreate() {
        this.$$cache_sampleData = new core_1.ComponentValueCache(this, "sampleData", "Template.sampleData");
        this.$$cache_emailAddress = new core_1.ComponentValueCache(this, "emailAddress", "Template.emailAddress");
    }
    onResource_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("resourceId");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('read_template', { asResource: true, parameters: { id: this.resourceId } });
        });
    }
    $$load_emailAddress() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$app.auth.user.email;
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            item: null,
            sampleDataSearchResult: null,
            sampleData: "{ }",
            previewData: null,
            emailAddress: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "full-screen",
            },
            b_3418904655244121_options: {
                lineNumbers: true,
                matchBrackets: true,
                continueComments: "Enter",
                extraKeys: {
                    "Ctrl-Q": "toggleComment",
                },
            },
            b_8695782439888834_style: {
                top: 0,
                right: 0,
            },
            b_2407519033660513_dataSource: {
                name: "get_suggestions",
                parameters: {
                    type: "dunning-letter",
                },
            },
            b_5419303415570863_options: {
                lineNumbers: true,
                matchBrackets: true,
                continueComments: "Enter",
                extraKeys: {
                    "Ctrl-Q": "toggleComment",
                },
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.item && this.item.id ?
                    this.$fn.gettext('Create template') :
                    `${this.$fn.gettext('Edit template')} / ${this.item.name}`,
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_4212_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    act_8320016629450276_setData_4216_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item",
                value: $event.data.response.data,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_8320016629450276_success_4215_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.response.data;
        });
    }
    act_8320016629450276_emit_4218_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "TemplateChanged",
                value: $event.data.response.data,
            };
        });
    }
    evh_8320016629450276_success_4217_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.response.data;
        });
    }
    act_8320016629450276_request_4214_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "update_template",
                data: this.item,
            };
        });
    }
    evh_8320016629450276_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8320016629450276_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_8399737542760576_notification_4222_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                message: this.$fn.pgettext("Templates", "Email has been sent."),
            };
        });
    }
    act_8399737542760576_request_4220_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "sendmail_template_test",
                data: {
                    template_type: this.item.template_type,
                    template: this.item.content,
                    data: JSON.parse(this.sampleData),
                    subject: this.item.subject,
                    email: this.emailAddress
                },
            };
        });
    }
    evh_8399737542760576_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8399737542760576_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_8695782439888834_setData_4226_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "previewData",
                value: $event.data.response.data,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_8695782439888834_request_4224_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "render_template_test_html",
                data: {
                    template_type: this.item.template_type,
                    template: this.item.content,
                    subject: this.item.subject,
                    data: JSON.parse(this.sampleData)
                },
            };
        });
    }
    evh_8695782439888834_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8695782439888834_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_4212 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_4212_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_4211 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_4212,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_4211],
        });
        const act_8320016629450276_setData_4216 = new core_1.actions.SetDataAction({
            actionArgs: this.act_8320016629450276_setData_4216_getActionArgs,
            events: [],
        });
        const evh_8320016629450276_success_4215 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_setData_4216,
            event: "success",
            when: this.evh_8320016629450276_success_4215_getWhen,
            displayName: "setData",
        });
        const act_8320016629450276_emit_4218 = new core_1.actions.EmitAction({
            actionArgs: this.act_8320016629450276_emit_4218_getActionArgs,
            events: [],
        });
        const evh_8320016629450276_success_4217 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_emit_4218,
            event: "success",
            when: this.evh_8320016629450276_success_4217_getWhen,
            displayName: "emit",
        });
        const act_8320016629450276_request_4214 = new core_1.actions.RequestAction({
            actionArgs: this.act_8320016629450276_request_4214_getActionArgs,
            displayName: "save",
            events: [evh_8320016629450276_success_4215, evh_8320016629450276_success_4217],
        });
        const evh_8320016629450276_clickPreventStop_4213 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_request_4214,
            event: "click.prevent.stop",
            displayName: "save",
        });
        const evh_8320016629450276_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8320016629450276_clickPreventStop_4213],
        });
        const act_8399737542760576_notification_4222 = new core_1.actions.NotificationAction({
            actionArgs: this.act_8399737542760576_notification_4222_getActionArgs,
            events: [],
        });
        const evh_8399737542760576_success_4221 = new core_1.actions.EventHandlerImpl({
            action: act_8399737542760576_notification_4222,
            event: "success",
            displayName: "notification",
        });
        const act_8399737542760576_request_4220 = new core_1.actions.RequestAction({
            actionArgs: this.act_8399737542760576_request_4220_getActionArgs,
            displayName: "sendmail",
            events: [evh_8399737542760576_success_4221],
        });
        const evh_8399737542760576_clickPreventStop_4219 = new core_1.actions.EventHandlerImpl({
            action: act_8399737542760576_request_4220,
            event: "click.prevent.stop",
            displayName: "sendmail",
        });
        const evh_8399737542760576_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8399737542760576_clickPreventStop_4219],
        });
        const act_8695782439888834_setData_4226 = new core_1.actions.SetDataAction({
            actionArgs: this.act_8695782439888834_setData_4226_getActionArgs,
            events: [],
        });
        const evh_8695782439888834_success_4225 = new core_1.actions.EventHandlerImpl({
            action: act_8695782439888834_setData_4226,
            event: "success",
            displayName: "setData",
        });
        const act_8695782439888834_request_4224 = new core_1.actions.RequestAction({
            actionArgs: this.act_8695782439888834_request_4224_getActionArgs,
            displayName: "renderPreview",
            events: [evh_8695782439888834_success_4225],
        });
        const evh_8695782439888834_clickPreventStop_4223 = new core_1.actions.EventHandlerImpl({
            action: act_8695782439888834_request_4224,
            event: "click.prevent.stop",
            displayName: "renderPreview",
        });
        const evh_8695782439888834_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8695782439888834_clickPreventStop_4223],
        });
        return {
            act_7315092382398562_closeModal_4212,
            evh_7315092382398562_close_4211,
            evh_7315092382398562_close,
            act_8320016629450276_setData_4216,
            evh_8320016629450276_success_4215,
            act_8320016629450276_emit_4218,
            evh_8320016629450276_success_4217,
            act_8320016629450276_request_4214,
            evh_8320016629450276_clickPreventStop_4213,
            evh_8320016629450276_clickPreventStop,
            act_8399737542760576_notification_4222,
            evh_8399737542760576_success_4221,
            act_8399737542760576_request_4220,
            evh_8399737542760576_clickPreventStop_4219,
            evh_8399737542760576_clickPreventStop,
            act_8695782439888834_setData_4226,
            evh_8695782439888834_success_4225,
            act_8695782439888834_request_4224,
            evh_8695782439888834_clickPreventStop_4223,
            evh_8695782439888834_clickPreventStop,
            save: act_8320016629450276_request_4214,
            sendmail: act_8399737542760576_request_4220,
            renderPreview: act_8695782439888834_request_4224,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: String,
    }),
    __metadata("design:type", String)
], Template.prototype, "resourceId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('resourceId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Template.prototype, "onResource_id", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "full-screen",
    }),
    __metadata("design:type", String)
], Template.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Template.prototype, "onSize", null);
Template = __decorate([
    (0, vue_property_decorator_1.Component)()
], Template);
exports.default = Template;
vue_property_decorator_1.Vue.component("Template", Template);
