"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcContractServices = class UcContractServices extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['today', 'displayHelper', 'ux'];
    }
    beforeCreate() {
    }
    filterItems(...args) {
        return _.sortBy(args[0], p => this.isServiceStale(p) ? "_" + p.name : p.name);
    }
    isServiceStale(...args) {
        const [service] = args;
        return service.valid_to && this.$fn.parseISODate(service.valid_to) < this.today;
    }
    onContract(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("contract");
    }
    onReadonly(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("readonly");
    }
    $$load_today() {
        return __awaiter(this, void 0, void 0, function* () {
            return new Date();
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            today: null,
            displayHelper: null,
            ux: null,
        });
    }
    evh_7315092382398562_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
        });
    }
    act_8320016629450276_emit_6404_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_8320016629450276_close_6403_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data.modalResult.ok;
        });
    }
    act_8320016629450276_showModal_6402_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "ContractServiceEditor",
                props: {
                    mode: "addService",
                    contract: this.contract,
                },
            };
        });
    }
    evh_8320016629450276_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8320016629450276_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_3577443900630089_emit_6408_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_3577443900630089_close_6407_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data.modalResult.ok;
        });
    }
    act_3577443900630089_showModal_6406_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "ContractServiceEditor",
                props: {
                    mode: "editService",
                    contract: this.contract,
                    service: _.cloneDeep($event.scope.service),
                },
            };
        });
    }
    evh_3577443900630089_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_3577443900630089_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_6027561141562583_emit_6410_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_6027561141562583_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6027561141562583_reload.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_reloadComponentData_6400 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7315092382398562_reload_6399 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_6400,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_6399],
        });
        const act_8320016629450276_emit_6404 = new core_1.actions.EmitAction({
            actionArgs: this.act_8320016629450276_emit_6404_getActionArgs,
            displayName: "emitReload",
            events: [],
        });
        const evh_8320016629450276_close_6403 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_emit_6404,
            event: "close",
            when: this.evh_8320016629450276_close_6403_getWhen,
            displayName: "emitReload",
        });
        const act_8320016629450276_showModal_6402 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_8320016629450276_showModal_6402_getActionArgs,
            events: [evh_8320016629450276_close_6403],
        });
        const evh_8320016629450276_clickPreventStop_6401 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_showModal_6402,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_8320016629450276_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8320016629450276_clickPreventStop_6401],
        });
        const act_3577443900630089_emit_6408 = new core_1.actions.EmitAction({
            actionArgs: this.act_3577443900630089_emit_6408_getActionArgs,
            displayName: "emitReload",
            events: [],
        });
        const evh_3577443900630089_close_6407 = new core_1.actions.EventHandlerImpl({
            action: act_3577443900630089_emit_6408,
            event: "close",
            when: this.evh_3577443900630089_close_6407_getWhen,
            displayName: "emitReload",
        });
        const act_3577443900630089_showModal_6406 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_3577443900630089_showModal_6406_getActionArgs,
            events: [evh_3577443900630089_close_6407],
        });
        const evh_3577443900630089_clickPreventStop_6405 = new core_1.actions.EventHandlerImpl({
            action: act_3577443900630089_showModal_6406,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_3577443900630089_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3577443900630089_clickPreventStop_6405],
        });
        const act_6027561141562583_emit_6410 = new core_1.actions.EmitAction({
            actionArgs: this.act_6027561141562583_emit_6410_getActionArgs,
            displayName: "emitReload",
            events: [],
        });
        const evh_6027561141562583_reload_6409 = new core_1.actions.EventHandlerImpl({
            action: act_6027561141562583_emit_6410,
            event: "reload",
            displayName: "emitReload",
        });
        const evh_6027561141562583_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6027561141562583_reload_6409],
        });
        return {
            act_7315092382398562_reloadComponentData_6400,
            evh_7315092382398562_reload_6399,
            evh_7315092382398562_reload,
            act_8320016629450276_emit_6404,
            evh_8320016629450276_close_6403,
            act_8320016629450276_showModal_6402,
            evh_8320016629450276_clickPreventStop_6401,
            evh_8320016629450276_clickPreventStop,
            act_3577443900630089_emit_6408,
            evh_3577443900630089_close_6407,
            act_3577443900630089_showModal_6406,
            evh_3577443900630089_clickPreventStop_6405,
            evh_3577443900630089_clickPreventStop,
            act_6027561141562583_emit_6410,
            evh_6027561141562583_reload_6409,
            evh_6027561141562583_reload,
            emitReload: act_6027561141562583_emit_6410,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], UcContractServices.prototype, "contract", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('contract'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcContractServices.prototype, "onContract", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
    }),
    __metadata("design:type", Boolean)
], UcContractServices.prototype, "readonly", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('readonly'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcContractServices.prototype, "onReadonly", null);
UcContractServices = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcContractServices);
exports.default = UcContractServices;
vue_property_decorator_1.Vue.component("UcContractServices", UcContractServices);
