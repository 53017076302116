var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "address",
    [
      _c(
        "ItpBox",
        [
          _c("UcAddressTypeBadge", { attrs: { value: _vm.item.address_type } }),
          _vm._v(" "),
          _vm.item.is_default && _vm.displayDefaultFlag
            ? _c("ItpBadge", {
                staticClass: "ml-3",
                attrs: {
                  text: _vm._f("gettext")("Default"),
                  variant: "info",
                  "no-box-shadow": ""
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.compactDisplay && _vm.item.address
        ? _c("DIV", [_vm._v("\n    " + _vm._s(_vm.item.address) + "\n  ")])
        : _vm._e(),
      _vm._v(" "),
      !_vm.compactDisplay || !_vm.item.address
        ? _c(
            "DIV",
            [
              _vm._l(_vm.item.lines, function(line, index) {
                return _vm.item.lines && _vm.item.lines.length
                  ? _c("DIV", { key: index }, [
                      _vm._v("\n      " + _vm._s(line) + "\n    ")
                    ])
                  : _vm._e()
              }),
              _vm._v(" "),
              _vm.item.line_1
                ? _c("DIV", [
                    _vm._v("\n      " + _vm._s(_vm.item.line_1)),
                    _c("br")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.item.line_2
                ? _c("DIV", [
                    _vm._v("\n      " + _vm._s(_vm.item.line_2)),
                    _c("br")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.item.line_3
                ? _c("DIV", [
                    _vm._v("\n      " + _vm._s(_vm.item.line_3)),
                    _c("br")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.item.street_address
                ? _c("DIV", [
                    _vm._v("\n      " + _vm._s(_vm.item.street_address)),
                    _c("br")
                  ])
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("DIV", [
        _vm._v(
          "\n    " +
            _vm._s(_vm.item.city) +
            " " +
            _vm._s(_vm.item.region) +
            " " +
            _vm._s(_vm.item.postal_code) +
            "\n\n  "
        )
      ]),
      _vm._v(" "),
      _vm.item.country
        ? _c("DIV", [_vm._v("\n    " + _vm._s(_vm.item.country) + "\n\n  ")])
        : _vm._e(),
      _vm._v(" "),
      _vm.item.other_details
        ? _c("ItpText", { attrs: { text: _vm.item.other_details, pre: "" } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }