<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <UcClientMiniCard
    :item="item"
    :noLink="noLink"
    v-if="item.object === 'client'"
  >
  </UcClientMiniCard>
  <UcContractMiniCard
    :item="item"
    :noLink="noLink"
    v-if="item.object === 'contract'"
  >
  </UcContractMiniCard>
  <UcPropertyMiniCard
    :item="item"
    :noLink="noLink"
    v-if="item.object === 'property' || item.object == 'property_unit'"
  >
  </UcPropertyMiniCard>
  <UcRentalMiniCard
    :item="item"
    :noLink="noLink"
    v-if="item.object === 'rental'"
  >
  </UcRentalMiniCard>
  <UcWorksheetMiniCard
    :item="item"
    :noLink="noLink"
    v-if="item.object === 'worksheet'"
  >
  </UcWorksheetMiniCard>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class InvoiceReferenceItem extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    type: Object,
  })
  item!: object;

  @Watch('item')
  onItem(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("item")
  }

  @Prop({
    type: Boolean,
  })
  noLink!: boolean;

  @Watch('noLink')
  onNo_link(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("noLink")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_6614: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_6613: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_6614 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_6613 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_6614,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_6613],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_6614,
      evh_7315092382398562_reload_6613,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("InvoiceReferenceItem", InvoiceReferenceItem);

</script>