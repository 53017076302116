<template>
<ItpBox
  direction="column"
>
  <DIV
    v-if="items && !items.length"
  >
    {{ 'No references added.'|pgettext('InvoiceReferences') }}
  </DIV>
  <ItpBox
    direction="column"
    v-if="items && items.length"
  >
    <ItpBox
      :key="item.id"
      class="border rounded rounded-lg p-2 mb-2 w-100"
      v-for="item in items"
      :style="b_1040167445267876_style"
    >
      <InvoiceReferenceItem
        :item="item"
      >
      </InvoiceReferenceItem>
      <ItpLink
        icon="fa-times"
        class="ml-2 ml-auto"
        v-if="!invoice.isReadOnly"
        @click="evh_4044930568678877_click($event, {item})"
      >
      </ItpLink>
    </ItpBox>
  </ItpBox>
  <ItpLink
    :icon="$config.addIcon"
    :text="'Add reference'|gettext"
    class="ml-auto"
    v-if="!invoice.isReadOnly"
    @click.prevent="evh_8358196963355853_clickPrevent($event, {})"
  >
  </ItpLink>
</ItpBox>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcInvoiceReferences extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    type: Object,
  })
  invoice!: object;

  @Watch('invoice')
  onInvoice(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoice")
  }

  ux!: any;
  b_1040167445267876_style!: any;
  dataMembers = ['ux', 'b_1040167445267876_style'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
        b_1040167445267876_style: {
          "min-width": "150px",
        }
        ,
      },
    }
  }

  get items() {
    if (this.loaded) {

      return this.invoice.references.data
    }
    return null;
  }

  async act_4044930568678877_emit_6670_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_4044930568678877_emit_6670, alias=undefined
    return {
      event: "reload",
    }
  }

  async act_4044930568678877_crud_6668_getActionArgs($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_4044930568678877_crud_6668, alias=undefined
    return {
      objectType: "invoice_reference",
      op: "delete",
      data: {
        invoice_id: this.invoice.id,
        etag: this.invoice.etag,
        reference_type: $event.scope.item.object,
        reference_id: $event.scope.item.id,
      }
      ,
    }
  }

  async act_4044930568678877_crud_6668_getConfirm($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_4044930568678877_crud_6668, alias=undefined
    return {
      message: this.$fn.gettext("Please confirm deleting the reference."),
      btnOk: this.$fn.gettext("Delete reference"),
      severity: "warning",
    }
  }

  async evh_4044930568678877_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4044930568678877_click.executeFromDOM(this, event, scope);
  }

  async act_8358196963355853_emit_6674_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_8358196963355853_emit_6674, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_8358196963355853_close_6673_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_8358196963355853_close_6673, alias=undefined
    return !!$event.data.modalResult && !!$event.data.modalResult.ok
  }

  async act_8358196963355853_showModal_6672_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8358196963355853_showModal_6672, alias=undefined
    return {
      name: "InvoiceReferenceSearch",
      props: {
        invoiceId: this.invoice.id,
      }
      ,
    }
  }

  async evh_8358196963355853_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8358196963355853_clickPrevent.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_4044930568678877_emit_6670: actions.EmitAction;
    evh_4044930568678877_success_6669: actions.EventHandlerImpl;
    act_4044930568678877_crud_6668: actions.CRUDAction;
    evh_4044930568678877_click_6667: actions.EventHandlerImpl;
    evh_4044930568678877_click: actions.EventHandlerGroup;
    act_8358196963355853_emit_6674: actions.EmitAction;
    evh_8358196963355853_close_6673: actions.EventHandlerImpl;
    act_8358196963355853_showModal_6672: actions.ShowModalAction;
    evh_8358196963355853_clickPrevent_6671: actions.EventHandlerImpl;
    evh_8358196963355853_clickPrevent: actions.EventHandlerGroup;
  }

  getActions() {
    const act_4044930568678877_emit_6670 = new actions.EmitAction(
      {
        actionArgs: this.act_4044930568678877_emit_6670_getActionArgs,
        events: [],
      }
    );
    const evh_4044930568678877_success_6669 = new actions.EventHandlerImpl(
      {
        action: act_4044930568678877_emit_6670,
        event: "success",
        displayName: "emit",
      }
    );
    const act_4044930568678877_crud_6668 = new actions.CRUDAction(
      {
        actionArgs: this.act_4044930568678877_crud_6668_getActionArgs,
        confirm: this.act_4044930568678877_crud_6668_getConfirm,
        events: [evh_4044930568678877_success_6669],
      }
    );
    const evh_4044930568678877_click_6667 = new actions.EventHandlerImpl(
      {
        action: act_4044930568678877_crud_6668,
        event: "click",
        displayName: "crud",
      }
    );
    const evh_4044930568678877_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_4044930568678877_click_6667],
      }
    );
    const act_8358196963355853_emit_6674 = new actions.EmitAction(
      {
        actionArgs: this.act_8358196963355853_emit_6674_getActionArgs,
        events: [],
      }
    );
    const evh_8358196963355853_close_6673 = new actions.EventHandlerImpl(
      {
        action: act_8358196963355853_emit_6674,
        event: "close",
        when: this.evh_8358196963355853_close_6673_getWhen,
        displayName: "emit",
      }
    );
    const act_8358196963355853_showModal_6672 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8358196963355853_showModal_6672_getActionArgs,
        events: [evh_8358196963355853_close_6673],
      }
    );
    const evh_8358196963355853_clickPrevent_6671 = new actions.EventHandlerImpl(
      {
        action: act_8358196963355853_showModal_6672,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_8358196963355853_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_8358196963355853_clickPrevent_6671],
      }
    );
    return {
      act_4044930568678877_emit_6670,
      evh_4044930568678877_success_6669,
      act_4044930568678877_crud_6668,
      evh_4044930568678877_click_6667,
      evh_4044930568678877_click,
      act_8358196963355853_emit_6674,
      evh_8358196963355853_close_6673,
      act_8358196963355853_showModal_6672,
      evh_8358196963355853_clickPrevent_6671,
      evh_8358196963355853_clickPrevent,
    }
  }
}

Vue.component("UcInvoiceReferences", UcInvoiceReferences);

</script>