"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
(0, core_1.addRoute)({
    name: "settings.numberings",
    path: "numberings",
    component: "Numberings"
});
let Numberings = class Numberings extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['items', 'ux', 'b_7315092382398562_modalBindings', 'b_8320016629450276_editable', 'b_8320016629450276_columns'];
    }
    beforeCreate() {
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_items() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_numbering');
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            items: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "sm",
            },
            b_8320016629450276_editable: false,
            b_8320016629450276_columns: [{
                    name: "key",
                    header: this.$fn.pgettext("Numbering", "Key"),
                    type: "link",
                    width: "120px",
                },
                {
                    name: "resource_type",
                    header: this.$fn.pgettext("Numbering", "Resource Type"),
                    width: "120px",
                },
                {
                    name: "description",
                    header: this.$fn.pgettext("Numbering", "Description"),
                    width: "160px",
                },
                {
                    name: "format",
                    header: this.$fn.pgettext("Numbering", "Format"),
                    width: "260px",
                },
                {
                    name: "increment",
                    type: "number",
                    header: this.$fn.pgettext("Numbering", "Increment"),
                    width: "110px",
                },
                {
                    name: "next_value",
                    type: "number",
                    header: this.$fn.pgettext("Numbering", "Next value"),
                    width: "110px",
                },
                {
                    name: "next_number",
                    type: "number",
                    header: this.$fn.pgettext("Numbering", "Next Number"),
                    width: "110px",
                },
            ],
        });
    }
    get document() {
        if (this.loaded) {
            return;
        }
        return null;
    }
    act_7315092382398562_closeModal_3006_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    act_4615685607825208_showModal_3008_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Numbering",
            };
        });
    }
    evh_4615685607825208_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_4615685607825208_click.executeFromDOM(this, event, scope);
        });
    }
    evh_2095089031469157_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2095089031469157_click.executeFromDOM(this, event, scope);
        });
    }
    act_8320016629450276_script_3012_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            $event.data.waitFor(this.$fn.crud('numbering').upsert(Object.assign(Object.assign({}, $event.data.row), { start: $event.data.row.lastvalue })));
        });
    }
    act_8320016629450276_script_3012_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_8320016629450276_script_3012_getActionArgs_value($event),
            };
        });
    }
    evh_8320016629450276_save(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8320016629450276_save.executeFromDOM(this, event, scope);
        });
    }
    act_8320016629450276_script_3014_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            $event.data.waitFor(this.$fn.crud('numbering').delete($event.data.row));
        });
    }
    act_8320016629450276_script_3014_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_8320016629450276_script_3014_getActionArgs_value($event),
            };
        });
    }
    evh_8320016629450276_delete(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8320016629450276_delete.executeFromDOM(this, event, scope);
        });
    }
    act_8320016629450276_showModal_3016_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Numbering",
                props: {
                    oid: $event.data.row.id,
                },
            };
        });
    }
    evh_8320016629450276_click_3015_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.col.name === 'key';
        });
    }
    evh_8320016629450276_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8320016629450276_click.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_3006 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_3006_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_3005 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_3006,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_3005],
        });
        const act_4615685607825208_showModal_3008 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_4615685607825208_showModal_3008_getActionArgs,
            events: [],
        });
        const evh_4615685607825208_click_3007 = new core_1.actions.EventHandlerImpl({
            action: act_4615685607825208_showModal_3008,
            event: "click",
            displayName: "showModal",
        });
        const evh_4615685607825208_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4615685607825208_click_3007],
        });
        const act_2095089031469157_reloadComponentData_3010 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_2095089031469157_click_3009 = new core_1.actions.EventHandlerImpl({
            action: act_2095089031469157_reloadComponentData_3010,
            event: "click",
            displayName: "reloadComponentData",
        });
        const evh_2095089031469157_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2095089031469157_click_3009],
        });
        const act_8320016629450276_script_3012 = new core_1.actions.ScriptAction({
            actionArgs: this.act_8320016629450276_script_3012_getActionArgs,
            events: [],
        });
        const evh_8320016629450276_save_3011 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_script_3012,
            event: "save",
            displayName: "script",
        });
        const evh_8320016629450276_save = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8320016629450276_save_3011],
        });
        const act_8320016629450276_script_3014 = new core_1.actions.ScriptAction({
            actionArgs: this.act_8320016629450276_script_3014_getActionArgs,
            events: [],
        });
        const evh_8320016629450276_delete_3013 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_script_3014,
            event: "delete",
            displayName: "script",
        });
        const evh_8320016629450276_delete = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8320016629450276_delete_3013],
        });
        const act_8320016629450276_showModal_3016 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_8320016629450276_showModal_3016_getActionArgs,
            events: [],
        });
        const evh_8320016629450276_click_3015 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_showModal_3016,
            event: "click",
            when: this.evh_8320016629450276_click_3015_getWhen,
            displayName: "showModal",
        });
        const evh_8320016629450276_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8320016629450276_click_3015],
        });
        return {
            act_7315092382398562_closeModal_3006,
            evh_7315092382398562_close_3005,
            evh_7315092382398562_close,
            act_4615685607825208_showModal_3008,
            evh_4615685607825208_click_3007,
            evh_4615685607825208_click,
            act_2095089031469157_reloadComponentData_3010,
            evh_2095089031469157_click_3009,
            evh_2095089031469157_click,
            act_8320016629450276_script_3012,
            evh_8320016629450276_save_3011,
            evh_8320016629450276_save,
            act_8320016629450276_script_3014,
            evh_8320016629450276_delete_3013,
            evh_8320016629450276_delete,
            act_8320016629450276_showModal_3016,
            evh_8320016629450276_click_3015,
            evh_8320016629450276_click,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], Numberings.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Numberings.prototype, "onSize", null);
Numberings = __decorate([
    (0, vue_property_decorator_1.Component)()
], Numberings);
exports.default = Numberings;
vue_property_decorator_1.Vue.component("Numberings", Numberings);
