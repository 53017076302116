"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UcRentalServices = exports.UcRentalServiceCostAllocators = exports.UcRentalFormServicesTable = exports.RentalServiceCostAllocator = exports.RentalService = void 0;
const RentalService_yaml_component_1 = require("./RentalService.yaml?component");
exports.RentalService = RentalService_yaml_component_1.default;
const RentalServiceCostAllocator_yaml_component_1 = require("./RentalServiceCostAllocator.yaml?component");
exports.RentalServiceCostAllocator = RentalServiceCostAllocator_yaml_component_1.default;
const UcRentalFormServicesTable_yaml_component_1 = require("./UcRentalFormServicesTable.yaml?component");
exports.UcRentalFormServicesTable = UcRentalFormServicesTable_yaml_component_1.default;
const UcRentalServiceCostAllocators_yaml_component_1 = require("./UcRentalServiceCostAllocators.yaml?component");
exports.UcRentalServiceCostAllocators = UcRentalServiceCostAllocators_yaml_component_1.default;
const UcRentalServices_yaml_component_1 = require("./UcRentalServices.yaml?component");
exports.UcRentalServices = UcRentalServices_yaml_component_1.default;
