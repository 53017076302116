<template>
<VueSlickgrid
  name="clientPayments"
  :headerTitle="'Payments'|pgettext('ClientPayments')"
  :items="$fn.slickDataSource('list_payment', {parameters: getQueryParameters})"
  checkbox-row-selection
  footer-totals
  export-to-csv
  :exportToCsvFilename="'client-payments'|pgettext('filename')"
  :columnDefaults="b_7315092382398562_columnDefaults"
  :columns="b_7315092382398562_columns"
  ref="grid"
  v-if="loaded"
  @selectedRowsChanged="evh_7315092382398562_selectedRowsChanged($event, {})"
  @command="evh_7315092382398562_command($event, {})"
>
</VueSlickgrid>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcClientPayments extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  getQueryParameters(...args: any[]) {
    return { client: this.clientId }
  }

  @Prop({
    required: true,
    type: String,
  })
  clientId!: string;

  @Watch('clientId')
  onClient_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("clientId")
  }

  paymentMethods!: any;

  async $$load_paymentMethods() {
    return this.$fn.fetch('list_payment_method')
      .then(data => _.reduce(data, (m, v, k) => _.set(m, v.id, this.$fn.pgettext('PaymentMethod', v.name))))
  }

  ux!: any;
  b_7315092382398562_columnDefaults!: any;
  b_7315092382398562_columns!: any;

  async $$load_b_7315092382398562_columns() {
    return [{
      field: "payment_date",
      name: this.$fn.pgettext("PaymentBrowserView", "Date"),
      type: "date",
    }
      ,
    {
      field: "payment_number",
      name: this.$fn.pgettext("PaymentBrowserView", "Number"),
      type: "command",
      formatter: [{
        name: "chain",
        formatters: [{
          name: "link",
          href: "#",
        }
          ,
        {
          name: "cellclass",
          value: (row) => ({
            'font-weight-bold': true,
            'bg-yellow-200': !row.locked
          }),
        }
          ,
        ]
        ,
      }
        ,
      ]
      ,
    }
      ,
    {
      field: "payment_method",
      name: this.$fn.pgettext("PaymentBrowserView", "Method"),
      formatter: {
        name: "mapvalue",
        mapping: this.paymentMethods,
      }
      ,
    }
      ,
    {
      field: "account_name",
      name: this.$fn.pgettext("PaymentBrowserView", "Account"),
    }
      ,
    {
      field: "payment_category",
      name: this.$fn.pgettext("PaymentBrowserView", "Title"),
    }
      ,
    {
      field: "description",
      name: this.$fn.pgettext("PaymentBrowserView", "Description"),
    }
      ,
    {
      field: "opening_balance",
      name: this.$fn.pgettext("PaymentBrowserView", "Opening balance"),
      type: "number",
      formatter: {
        name: "localestring",
        locale: "hu-HU",
      }
      ,
      noFooterTotals: true,
    }
      ,
    {
      field: "amount_in",
      name: this.$fn.pgettext("PaymentBrowserView", "In"),
      type: "number",
      formatter: {
        name: "localestring",
        locale: "hu-HU",
      }
      ,
    }
      ,
    {
      field: "amount_out",
      name: this.$fn.pgettext("PaymentBrowserView", "Out"),
      type: "number",
      formatter: {
        name: "localestring",
        locale: "hu-HU",
      }
      ,
    }
      ,
    {
      field: "closing_balance",
      name: this.$fn.pgettext("PaymentBrowserView", "Closing balance"),
      type: "number",
      formatter: {
        name: "localestring",
        locale: "hu-HU",
      }
      ,
      noFooterTotals: true,
    }
      ,
    {
      field: "currency",
      name: this.$fn.pgettext("PaymentBrowserView", "Currency"),
    }
      ,
    {
      field: "exchange_rate",
      name: this.$fn.pgettext("PaymentBrowserView", "Exchange Rate"),
      type: "number",
      formatter: {
        name: "localestring",
        locale: "hu-HU",
      }
      ,
      noFooterTotals: true,
    }
      ,
    {
      field: "reference_number",
      name: this.$fn.pgettext("PaymentBrowserView", "Ref Nr."),
    }
      ,
    {
      field: "file_number",
      name: this.$fn.pgettext("PaymentBrowserView", "File Nr."),
    }
      ,
    {
      field: "invoices",
      name: this.$fn.pgettext("PaymentBrowserView", "Invoices"),
      formatter: [{
        name: "chain",
        separator: ", ",
        formatterContext: "value",
        formatters: [{
          name: "getvalue",
          opts: {
            field: "invoice_number",
            source: "value",
          }
          ,
        }
          ,
        {
          name: "routeLink",
          to: "invoice",
          navigateOnIconClick: true,
          params: {
            id: "id",
          }
          ,
        }
          ,
        ]
        ,
      }
        ,
      ]
      ,
    }
      ,
    ]
  }

  dataMembers = ['paymentMethods', 'ux', 'b_7315092382398562_columnDefaults', 'b_7315092382398562_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        paymentMethods: null,
        ux: null,
        b_7315092382398562_columnDefaults: {
          sortable: true,
          filterable: true,
          resizable: true,
        }
        ,
        b_7315092382398562_columns: null,
      },
    }
  }

  async act_7315092382398562_setData_4628_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_7315092382398562_setData_4628, alias=undefined
    return {
      path: "selectedItems",
      value: this.$refs.grid.getSelectedItems(),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_7315092382398562_selectedRowsChanged(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_selectedRowsChanged.executeFromDOM(this, event, scope);
  }

  async act_7315092382398562_showModal_4630_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_7315092382398562_showModal_4630, alias=undefined
    return {
      name: "Payment",
      props: {
        modal: true,
        oid: $event.data.data.id,
      }
      ,
    }
  }

  async evh_7315092382398562_command_4629_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_7315092382398562_command_4629, alias=undefined
    return $event.data.column.id === 'payment_number'
  }

  async act_7315092382398562_showModal_4632_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_7315092382398562_showModal_4632, alias=undefined
    return {
      name: "Account",
      props: {
        oid: $event.data.data.account_id,
      }
      ,
    }
  }

  async evh_7315092382398562_command_4631_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_7315092382398562_command_4631, alias=undefined
    return $event.data.column.id === 'account_name'
  }

  async evh_7315092382398562_command(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_command.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_setData_4628: actions.SetDataAction;
    evh_7315092382398562_selectedRowsChanged_4627: actions.EventHandlerImpl;
    evh_7315092382398562_selectedRowsChanged: actions.EventHandlerGroup;
    act_7315092382398562_showModal_4630: actions.ShowModalAction;
    evh_7315092382398562_command_4629: actions.EventHandlerImpl;
    act_7315092382398562_showModal_4632: actions.ShowModalAction;
    evh_7315092382398562_command_4631: actions.EventHandlerImpl;
    evh_7315092382398562_command: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_setData_4628 = new actions.SetDataAction(
      {
        actionArgs: this.act_7315092382398562_setData_4628_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_selectedRowsChanged_4627 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_setData_4628,
        event: "selectedRowsChanged",
        displayName: "setData",
      }
    );
    const evh_7315092382398562_selectedRowsChanged = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_selectedRowsChanged_4627],
      }
    );
    const act_7315092382398562_showModal_4630 = new actions.ShowModalAction(
      {
        actionArgs: this.act_7315092382398562_showModal_4630_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_command_4629 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_showModal_4630,
        event: "command",
        when: this.evh_7315092382398562_command_4629_getWhen,
        displayName: "showModal",
      }
    );
    const act_7315092382398562_showModal_4632 = new actions.ShowModalAction(
      {
        actionArgs: this.act_7315092382398562_showModal_4632_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_command_4631 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_showModal_4632,
        event: "command",
        when: this.evh_7315092382398562_command_4631_getWhen,
        displayName: "showModal",
      }
    );
    const evh_7315092382398562_command = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_command_4629, evh_7315092382398562_command_4631],
      }
    );
    return {
      act_7315092382398562_setData_4628,
      evh_7315092382398562_selectedRowsChanged_4627,
      evh_7315092382398562_selectedRowsChanged,
      act_7315092382398562_showModal_4630,
      evh_7315092382398562_command_4629,
      act_7315092382398562_showModal_4632,
      evh_7315092382398562_command_4631,
      evh_7315092382398562_command,
    }
  }
}

Vue.component("UcClientPayments", UcClientPayments);

</script>