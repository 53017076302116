"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcRentalFormContracts = class UcRentalFormContracts extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['items', 'ux', 'b_1468474219929533_displayRental'];
    }
    beforeCreate() {
    }
    onRental(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("rental");
    }
    $$load_items() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_contract', { parameters: { filter: 'rental_id eq "' + this.rental.id + '"' } })
                .then(resp => _.orderBy(resp.data, ['valid_from', 'valid_to'], ['desc', 'desc']));
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            items: null,
            ux: null,
            b_1468474219929533_displayRental: false,
        });
    }
    evh_3343864469950044_close_6829_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data.modalResult.value;
        });
    }
    act_3343864469950044_showModal_6828_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "CreateContract",
                props: {
                    rentalId: this.rental.id,
                },
            };
        });
    }
    evh_3343864469950044_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_3343864469950044_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_3343864469950044_reloadComponentData_6830 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_3343864469950044_close_6829 = new core_1.actions.EventHandlerImpl({
            action: act_3343864469950044_reloadComponentData_6830,
            event: "close",
            when: this.evh_3343864469950044_close_6829_getWhen,
            displayName: "reloadComponentData",
        });
        const act_3343864469950044_showModal_6828 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_3343864469950044_showModal_6828_getActionArgs,
            events: [evh_3343864469950044_close_6829],
        });
        const evh_3343864469950044_clickPreventStop_6827 = new core_1.actions.EventHandlerImpl({
            action: act_3343864469950044_showModal_6828,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_3343864469950044_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3343864469950044_clickPreventStop_6827],
        });
        return {
            act_3343864469950044_reloadComponentData_6830,
            evh_3343864469950044_close_6829,
            act_3343864469950044_showModal_6828,
            evh_3343864469950044_clickPreventStop_6827,
            evh_3343864469950044_clickPreventStop,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], UcRentalFormContracts.prototype, "rental", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('rental'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcRentalFormContracts.prototype, "onRental", null);
UcRentalFormContracts = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcRentalFormContracts);
exports.default = UcRentalFormContracts;
vue_property_decorator_1.Vue.component("UcRentalFormContracts", UcRentalFormContracts);
