<template>
<div>
  <ItpWidgetCard
    class="mb-3"
  >
    <ItpBox
      alignItems="center"
      slot="header"
    >
      <strong>
        {{ 'Selling prices'|pgettext('ProductPrices') }}
      </strong>
      <ItpButton
        :text="'Add price...'|pgettext('ProductPrices')"
        variant="light"
        size="sm"
        class="ml-auto"
        @click.prevent.stop="evh_3343864469950044_clickPreventStop($event, {})"
      >
      </ItpButton>
    </ItpBox>
    <BListGroup
      v-if="loaded"
    >
      <BListGroupItem
        v-if="!sellingPrices.length"
      >
        <span>
          {{ 'No prices.'|pgettext('ProductPrices') }}
        </span>
      </BListGroupItem>
      <BListGroupItem
        :key="item.id"
        class="d-flex flex-column"
        v-for="item in sellingPrices"
      >
        <ItpBox
          alignItems="start"
        >
          <ItpIcon
            :icon="item.is_purchase_price ? 'icons8:buy' : 'material-symbols:sell-outline'"
          >
          </ItpIcon>
          <ItpBox
            direction="column"
            class="w-100"
          >
            <ItpBox
              class="W-100"
            >
              <ItpBox
                class="col-md-3"
              >
                <ItpText
                  :strikethrough="item.inactive"
                  class="font-weight-bold"
                >
                  {{ item.price }} {{ item.currency }}
                </ItpText>
                <ItpBadge
                  :title="'From quantity'|pgettext('ProductPrice')"
                  no-box-shadow
                  variant="light"
                  class="mx-3 badge-icon"
                  v-if="item.quantity_from"
                >
                  <ItpIcon
                    name="fa-solid:greater-than-equal"
                  >
                  </ItpIcon>
                  <ItpText
                    :text="item.quantity_from"
                  >
                  </ItpText>
                </ItpBadge>
                <ItpBadge
                  :title="'Discount price'|pgettext('ProductPrice')"
                  no-box-shadow
                  variant="warning"
                  class="mx-3 badge-icon"
                  v-if="item.is_discount_price"
                >
                  <ItpIcon
                    name="mdi:discount-outline"
                  >
                  </ItpIcon>
                </ItpBadge>
              </ItpBox>
              <ItpBox
                class="col-md-3"
              >
                <UcValidityPeriod
                  :from="item.valid_from"
                  :to="item.valid_to"
                  class="pl-3"
                >
                </UcValidityPeriod>
              </ItpBox>
              <ItpBox
                alignItems="start"
                wrap-items
                class="col-md-6"
              >
                <UcCompactClientDisplay
                  :payload="item.partner"
                  class="pl-3"
                  v-if="item.partner"
                >
                </UcCompactClientDisplay>
              </ItpBox>
            </ItpBox>
            <ItpBox
              class="W-100"
            >
              <div
                class="col-md-3"
              >
                <ItpText
                  :text="item.note"
                  pre
                  v-if="item.note"
                >
                </ItpText>
              </div>
            </ItpBox>
          </ItpBox>
          <ItpButton
            icon="fa-pen"
            :tooltip="'Edit'|gettext"
            variant="light"
            size="sm"
            class="ml-auto"
            @click.prevent.stop="evh_8855792642156263_clickPreventStop($event, {item})"
          >
          </ItpButton>
        </ItpBox>
      </BListGroupItem>
    </BListGroup>
  </ItpWidgetCard>
  <ItpWidgetCard>
    <ItpBox
      alignItems="center"
      slot="header"
    >
      <strong>
        {{ 'Purchase prices'|pgettext('ProductPrices') }}
      </strong>
      <ItpButton
        :text="'Add price...'|pgettext('ProductPrices')"
        variant="light"
        size="sm"
        class="ml-auto"
        @click.prevent.stop="evh_302930856768608_clickPreventStop($event, {})"
      >
      </ItpButton>
    </ItpBox>
    <BListGroup
      v-if="loaded"
    >
      <BListGroupItem
        v-if="!purchasePrices.length"
      >
        <span>
          {{ 'No prices.'|pgettext('ProductPrices') }}
        </span>
      </BListGroupItem>
      <BListGroupItem
        :key="item.id"
        class="d-flex flex-column"
        v-for="item in purchasePrices"
      >
        <ItpBox
          alignItems="start"
        >
          <ItpIcon
            :icon="item.is_purchase_price ? 'icons8:buy' : 'material-symbols:sell-outline'"
          >
          </ItpIcon>
          <ItpBox
            direction="column"
            class="w-100"
          >
            <ItpBox
              class="W-100"
            >
              <ItpBox
                class="col-md-3"
              >
                <ItpText
                  :strikethrough="item.inactive"
                  class="font-weight-bold"
                >
                  {{ item.price }} {{ item.currency }}
                </ItpText>
                <ItpBadge
                  :title="'From quantity'|pgettext('ProductPrice')"
                  no-box-shadow
                  variant="light"
                  class="mx-3 badge-icon"
                  v-if="item.quantity_from"
                >
                  <ItpIcon
                    name="fa-solid:greater-than-equal"
                  >
                  </ItpIcon>
                  <ItpText
                    :text="item.quantity_from"
                  >
                  </ItpText>
                </ItpBadge>
                <ItpBadge
                  :title="'Discount price'|pgettext('ProductPrice')"
                  no-box-shadow
                  variant="warning"
                  class="mx-3 badge-icon"
                  v-if="item.is_discount_price"
                >
                  <ItpIcon
                    name="mdi:discount-outline"
                  >
                  </ItpIcon>
                </ItpBadge>
              </ItpBox>
              <ItpBox
                class="col-md-3"
              >
                <UcValidityPeriod
                  :from="item.valid_from"
                  :to="item.valid_to"
                  class="pl-3"
                >
                </UcValidityPeriod>
              </ItpBox>
              <ItpBox
                alignItems="start"
                wrap-items
                class="col-md-6"
              >
                <UcCompactClientDisplay
                  :payload="item.partner"
                  class="pl-3"
                  v-if="item.partner"
                >
                </UcCompactClientDisplay>
              </ItpBox>
            </ItpBox>
            <ItpBox
              class="W-100"
            >
              <div
                class="col-md-3"
              >
                <ItpText
                  :text="item.note"
                  pre
                  v-if="item.note"
                >
                </ItpText>
              </div>
            </ItpBox>
          </ItpBox>
          <ItpButton
            icon="fa-pen"
            :tooltip="'Edit'|gettext"
            variant="light"
            size="sm"
            class="ml-auto"
            @click.prevent.stop="evh_7972369138378646_clickPreventStop($event, {item})"
          >
          </ItpButton>
        </ItpBox>
      </BListGroupItem>
    </BListGroup>
  </ItpWidgetCard>
</div>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcProductPrices extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  sortItems(...args: any[]) {
    return _.orderBy(args[0], ['valid_from', 'price'], ['desc', 'desc'])
  }

  @Prop({
    required: true,
    type: Object,
  })
  product!: object;

  @Watch('product')
  onProduct(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("product")
  }

  items!: any;

  async $$load_items() {
    return this.$fn.fetch('list_product_price', { parameters: { 'product_id': this.product.id } })
  }

  currencies!: any;
  ux!: any;
  dataMembers = ['items', 'currencies', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        items: null,
        currencies: ["HUF"
          ,
          "EUR"
          ,
          "USD"
          ,
        ]
        ,
        ux: {
          initialLoadSpinner: true,
        }
        ,
      },
    }
  }

  get purchasePrices() {
    if (this.loaded) {

      return this.sortItems(_.filter(this.items, { is_purchase_price: true }))
    }
    return null;
  }

  get sellingPrices() {
    if (this.loaded) {

      return this.sortItems(_.filter(this.items, { is_purchase_price: false }))
    }
    return null;
  }

  async evh_3343864469950044_close_5755_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_3343864469950044_close_5755, alias=undefined
    return !!$event.data.modalResult.value
  }

  async act_3343864469950044_showModal_5754_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_3343864469950044_showModal_5754, alias=undefined
    return {
      name: "ProductPrice",
      props: {
        product: this.product,
        is_purchase_price: false,
      }
      ,
    }
  }

  async evh_3343864469950044_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3343864469950044_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async evh_8855792642156263_close_5759_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_8855792642156263_close_5759, alias=undefined
    return !!$event.data.modalResult.ok
  }

  async act_8855792642156263_showModal_5758_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8855792642156263_showModal_5758, alias=undefined
    return {
      name: "ProductPrice",
      props: {
        oid: $event.scope.item.id,
        mode: "edit",
        product: this.product,
      }
      ,
    }
  }

  async evh_8855792642156263_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8855792642156263_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async evh_302930856768608_close_5763_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_302930856768608_close_5763, alias=undefined
    return !!$event.data.modalResult.value
  }

  async act_302930856768608_showModal_5762_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_302930856768608_showModal_5762, alias=undefined
    return {
      name: "ProductPrice",
      props: {
        product: this.product,
        is_purchase_price: true,
      }
      ,
    }
  }

  async evh_302930856768608_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_302930856768608_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async evh_7972369138378646_close_5767_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_7972369138378646_close_5767, alias=undefined
    return !!$event.data.modalResult.ok
  }

  async act_7972369138378646_showModal_5766_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_7972369138378646_showModal_5766, alias=undefined
    return {
      name: "ProductPrice",
      props: {
        oid: $event.scope.item.id,
        mode: "edit",
        product: this.product,
      }
      ,
    }
  }

  async evh_7972369138378646_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7972369138378646_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_3343864469950044_reloadComponentData_5756: actions.ReloadComponentDataAction;
    evh_3343864469950044_close_5755: actions.EventHandlerImpl;
    act_3343864469950044_showModal_5754: actions.ShowModalAction;
    evh_3343864469950044_clickPreventStop_5753: actions.EventHandlerImpl;
    evh_3343864469950044_clickPreventStop: actions.EventHandlerGroup;
    act_8855792642156263_reloadComponentData_5760: actions.ReloadComponentDataAction;
    evh_8855792642156263_close_5759: actions.EventHandlerImpl;
    act_8855792642156263_showModal_5758: actions.ShowModalAction;
    evh_8855792642156263_clickPreventStop_5757: actions.EventHandlerImpl;
    evh_8855792642156263_clickPreventStop: actions.EventHandlerGroup;
    act_302930856768608_reloadComponentData_5764: actions.ReloadComponentDataAction;
    evh_302930856768608_close_5763: actions.EventHandlerImpl;
    act_302930856768608_showModal_5762: actions.ShowModalAction;
    evh_302930856768608_clickPreventStop_5761: actions.EventHandlerImpl;
    evh_302930856768608_clickPreventStop: actions.EventHandlerGroup;
    act_7972369138378646_reloadComponentData_5768: actions.ReloadComponentDataAction;
    evh_7972369138378646_close_5767: actions.EventHandlerImpl;
    act_7972369138378646_showModal_5766: actions.ShowModalAction;
    evh_7972369138378646_clickPreventStop_5765: actions.EventHandlerImpl;
    evh_7972369138378646_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_3343864469950044_reloadComponentData_5756 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_3343864469950044_close_5755 = new actions.EventHandlerImpl(
      {
        action: act_3343864469950044_reloadComponentData_5756,
        event: "close",
        when: this.evh_3343864469950044_close_5755_getWhen,
        displayName: "reloadComponentData",
      }
    );
    const act_3343864469950044_showModal_5754 = new actions.ShowModalAction(
      {
        actionArgs: this.act_3343864469950044_showModal_5754_getActionArgs,
        events: [evh_3343864469950044_close_5755],
      }
    );
    const evh_3343864469950044_clickPreventStop_5753 = new actions.EventHandlerImpl(
      {
        action: act_3343864469950044_showModal_5754,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_3343864469950044_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_3343864469950044_clickPreventStop_5753],
      }
    );
    const act_8855792642156263_reloadComponentData_5760 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_8855792642156263_close_5759 = new actions.EventHandlerImpl(
      {
        action: act_8855792642156263_reloadComponentData_5760,
        event: "close",
        when: this.evh_8855792642156263_close_5759_getWhen,
        displayName: "reloadComponentData",
      }
    );
    const act_8855792642156263_showModal_5758 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8855792642156263_showModal_5758_getActionArgs,
        events: [evh_8855792642156263_close_5759],
      }
    );
    const evh_8855792642156263_clickPreventStop_5757 = new actions.EventHandlerImpl(
      {
        action: act_8855792642156263_showModal_5758,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_8855792642156263_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8855792642156263_clickPreventStop_5757],
      }
    );
    const act_302930856768608_reloadComponentData_5764 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_302930856768608_close_5763 = new actions.EventHandlerImpl(
      {
        action: act_302930856768608_reloadComponentData_5764,
        event: "close",
        when: this.evh_302930856768608_close_5763_getWhen,
        displayName: "reloadComponentData",
      }
    );
    const act_302930856768608_showModal_5762 = new actions.ShowModalAction(
      {
        actionArgs: this.act_302930856768608_showModal_5762_getActionArgs,
        events: [evh_302930856768608_close_5763],
      }
    );
    const evh_302930856768608_clickPreventStop_5761 = new actions.EventHandlerImpl(
      {
        action: act_302930856768608_showModal_5762,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_302930856768608_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_302930856768608_clickPreventStop_5761],
      }
    );
    const act_7972369138378646_reloadComponentData_5768 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7972369138378646_close_5767 = new actions.EventHandlerImpl(
      {
        action: act_7972369138378646_reloadComponentData_5768,
        event: "close",
        when: this.evh_7972369138378646_close_5767_getWhen,
        displayName: "reloadComponentData",
      }
    );
    const act_7972369138378646_showModal_5766 = new actions.ShowModalAction(
      {
        actionArgs: this.act_7972369138378646_showModal_5766_getActionArgs,
        events: [evh_7972369138378646_close_5767],
      }
    );
    const evh_7972369138378646_clickPreventStop_5765 = new actions.EventHandlerImpl(
      {
        action: act_7972369138378646_showModal_5766,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_7972369138378646_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_7972369138378646_clickPreventStop_5765],
      }
    );
    return {
      act_3343864469950044_reloadComponentData_5756,
      evh_3343864469950044_close_5755,
      act_3343864469950044_showModal_5754,
      evh_3343864469950044_clickPreventStop_5753,
      evh_3343864469950044_clickPreventStop,
      act_8855792642156263_reloadComponentData_5760,
      evh_8855792642156263_close_5759,
      act_8855792642156263_showModal_5758,
      evh_8855792642156263_clickPreventStop_5757,
      evh_8855792642156263_clickPreventStop,
      act_302930856768608_reloadComponentData_5764,
      evh_302930856768608_close_5763,
      act_302930856768608_showModal_5762,
      evh_302930856768608_clickPreventStop_5761,
      evh_302930856768608_clickPreventStop,
      act_7972369138378646_reloadComponentData_5768,
      evh_7972369138378646_close_5767,
      act_7972369138378646_showModal_5766,
      evh_7972369138378646_clickPreventStop_5765,
      evh_7972369138378646_clickPreventStop,
    }
  }
}

Vue.component("UcProductPrices", UcProductPrices);

</script>