"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UcSuggestClientOption = exports.UcCompactClientDisplay = exports.UcClientTypeBadge = exports.UcClientMiniCard = void 0;
const UcClientMiniCard_yaml_component_1 = require("./UcClientMiniCard.yaml?component");
exports.UcClientMiniCard = UcClientMiniCard_yaml_component_1.default;
const UcClientTypeBadge_yaml_component_1 = require("./UcClientTypeBadge.yaml?component");
exports.UcClientTypeBadge = UcClientTypeBadge_yaml_component_1.default;
const UcCompactClientDisplay_yaml_component_1 = require("./UcCompactClientDisplay.yaml?component");
exports.UcCompactClientDisplay = UcCompactClientDisplay_yaml_component_1.default;
const UcSuggestClientOption_yaml_component_1 = require("./UcSuggestClientOption.yaml?component");
exports.UcSuggestClientOption = UcSuggestClientOption_yaml_component_1.default;
