<template>
<ItpButton
  :dropdownItem="dropdownItem"
  :text="noText ? null : messages.text"
  :icon="noIcon ? null : 'fa-lock'"
  :variant="dropdownItem ? 'default' : 'light'"
  :spinning="actions.lock.isRunning"
  :disabled="disabled"
  :size="size"
  :tooltip="tooltip || messages.tooltip"
  @click.prevent.stop="evh_7315092382398562_clickPreventStop($event, {})"
>
</ItpButton>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class BtnLockResource extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  resource!: object;

  @Watch('resource')
  onResource(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("resource")
  }

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Watch('disabled')
  onDisabled(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("disabled")
  }

  @Prop({
    type: Boolean,
    default: false,
  })
  noIcon!: boolean;

  @Watch('noIcon')
  onNo_icon(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("noIcon")
  }

  @Prop({
    type: Boolean,
    default: false,
  })
  noText!: boolean;

  @Watch('noText')
  onNo_text(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("noText")
  }

  @Prop({
    type: String,
    default: "sm",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  @Prop({
    type: String,
  })
  tooltip!: string;

  @Watch('tooltip')
  onTooltip(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("tooltip")
  }

  @Prop({
    type: String,
  })
  dialogHeader!: string;

  @Watch('dialogHeader')
  onDialog_header(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("dialogHeader")
  }

  @Prop({
    type: Boolean,
    default: false,
  })
  dropdownItem!: boolean;

  @Watch('dropdownItem')
  onDropdown_item(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("dropdownItem")
  }

  messages!: any;
  container!: any;

  async $$load_container() {
    return {
      self: this.resource.links.parent,
      object: 'list',
      data: []
    }
  }

  ux!: any;
  dataMembers = ['messages', 'container', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        messages: {
          text: this.$fn.pgettext("BtnLockText", "Lock"),
          tooltip: this.$fn.pgettext("BtnLockTooltip", "Lock"),
        }
        ,
        container: null,
        ux: null,
      },
    }
  }

  async act_7315092382398562_emit_1784_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_7315092382398562_emit_1784, alias=undefined
    return {
      event: "locked",
    }
  }

  async act_7315092382398562_rest_1782_getActionArgs($event: actions.ActionEvent): Promise<actions.RestActionArgs> {
    // parameterResolver name=act_7315092382398562_rest_1782, alias=lock
    return {
      method: "save",
      container: this.container,
      resource: this.resource,
      data: {
        etag: this.resource.etag,
        locked: true,
      },
    }
  }

  async act_7315092382398562_rest_1782_getConfirm($event: actions.ActionEvent): Promise<actions.RestActionArgs> {
    // parameterResolver name=act_7315092382398562_rest_1782, alias=lock
    return {
      header: this.dialogHeader,
      message: this.$fn.gettext("Locking prevents further modifications. Please confirm this operation."),
      btnOk: this.$fn.gettext("Lock"),
      severity: "warning",
    }
  }

  async evh_7315092382398562_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_emit_1784: actions.EmitAction;
    evh_7315092382398562_success_1783: actions.EventHandlerImpl;
    act_7315092382398562_rest_1782: actions.RestAction;
    evh_7315092382398562_clickPreventStop_1781: actions.EventHandlerImpl;
    evh_7315092382398562_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_emit_1784 = new actions.EmitAction(
      {
        actionArgs: this.act_7315092382398562_emit_1784_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_success_1783 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_emit_1784,
        event: "success",
        displayName: "emit",
      }
    );
    const act_7315092382398562_rest_1782 = new actions.RestAction(
      {
        actionArgs: this.act_7315092382398562_rest_1782_getActionArgs,
        confirm: this.act_7315092382398562_rest_1782_getConfirm,
        displayName: "lock",
        events: [evh_7315092382398562_success_1783],
      }
    );
    const evh_7315092382398562_clickPreventStop_1781 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_rest_1782,
        event: "click.prevent.stop",
        displayName: "lock",
      }
    );
    const evh_7315092382398562_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_clickPreventStop_1781],
      }
    );
    return {
      act_7315092382398562_emit_1784,
      evh_7315092382398562_success_1783,
      act_7315092382398562_rest_1782,
      evh_7315092382398562_clickPreventStop_1781,
      evh_7315092382398562_clickPreventStop,
      lock: act_7315092382398562_rest_1782,
    }
  }
}

Vue.component("BtnLockResource", BtnLockResource);

</script>