"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Home = void 0;
require("./styles.scss");
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const core_1 = require("@integro/core");
const connections_1 = require("./connections");
const ui = require("@integro/ui");
const components = require("./components");
const app = require("./app.yaml");
const msg_hu = require("./messages/hu.po");
const config = require("./config.yaml?plugin");
const scripts = require("./scripts");
const utils = require("./utils");
const template = `
<div class="app d-flex flex-column flex-nowrap overflow-hidden h-100">
  <MainMenu class="app__navbar"></MainMenu>
  <div class="app__content flex-grow-1 flex-shrink-1 overflow-hidden bg-light pt-2">
    <router-tab :keep-last-tab="false" restore>
      <template #default="tab">
        <ItpIcon v-if="tab.icon" :name="tab.icon" class="router-tab__item-icon" />
        <span class="router-tab__item-title" :title="tab.tips">{{
          tab.title
        }}</span>
        <i
          v-if="tab.closable"
          class="router-tab__item-close"
          @click.prevent="tab.close"
        />
      </template>
    </router-tab>
  </div>
</div>`;
let Home = class Home extends vue_property_decorator_1.Vue {
    data() {
        return {
            mode: 'development'
        };
    }
};
exports.Home = Home;
exports.Home = Home = __decorate([
    (0, vue_property_decorator_1.Component)({ template, components })
], Home);
vue_property_decorator_1.Vue.component('Home', Home);
const application = new core_1.Application(Object.assign(Object.assign({}, app), { connections: connections_1.connections,
    config, translations: [msg_hu], home: 'Home' }));
ui.initialize(application);
scripts.initialize(application);
_.set(application, 'utils', utils);
application.mount();
