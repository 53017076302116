"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcContractInvoicesPanel = class UcContractInvoicesPanel extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['invoices', 'displayAll', 'displayCount', 'ux'];
    }
    beforeCreate() {
        this.$$cache_displayAll = new core_1.ComponentValueCache(this, "displayAll", "Contract.Invoices.displayAll");
    }
    filterItems(...args) {
        const [invoices, displayAll, displayCount] = args;
        if (displayAll || displayCount == invoices.length) {
            return invoices;
        }
        return _.take(invoices, displayCount);
    }
    onContract_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("contractId");
    }
    $$load_invoices() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_invoice', { parameters: { contract_id: this.contractId } })
                .then(d => _.orderBy(d, ['invoice_date'], ['desc']));
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            invoices: null,
            displayAll: false,
            displayCount: 5,
            ux: null,
        });
    }
    get canDisplayMoreItems() {
        if (this.loaded) {
            return !this.displayAll && this.invoices && this.displayCount < this.invoices.length;
        }
        return null;
    }
    get canHideItems() {
        if (this.loaded) {
            return this.displayAll && this.invoices && this.displayCount < this.invoices.length;
        }
        return null;
    }
    evh_7315092382398562_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
        });
    }
    evh_6705964765214521_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6705964765214521_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_6806515264385235_showModal_6288_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "InvoiceGeneration",
                props: {
                    contract: this.contractId,
                },
            };
        });
    }
    evh_6806515264385235_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6806515264385235_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_5137197219681649_showModal_6290_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "InvoiceLogMessageViewer",
                props: {
                    invoiceId: $event.scope.invoice.id,
                },
            };
        });
    }
    evh_5137197219681649_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5137197219681649_click.executeFromDOM(this, event, scope);
        });
    }
    act_7736732573189513_setData_6292_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "displayAll",
                value: false,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_7736732573189513_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7736732573189513_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_2912679705172878_setData_6294_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "displayAll",
                value: true,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_2912679705172878_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2912679705172878_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_reloadComponentData_6284 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7315092382398562_reload_6283 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_6284,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_6283],
        });
        const act_6705964765214521_reloadComponentData_6286 = new core_1.actions.ReloadComponentDataAction({
            displayName: "reload",
            events: [],
        });
        const evh_6705964765214521_clickPreventStop_6285 = new core_1.actions.EventHandlerImpl({
            action: act_6705964765214521_reloadComponentData_6286,
            event: "click.prevent.stop",
            displayName: "reload",
        });
        const evh_6705964765214521_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6705964765214521_clickPreventStop_6285],
        });
        const act_6806515264385235_showModal_6288 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_6806515264385235_showModal_6288_getActionArgs,
            events: [],
        });
        const evh_6806515264385235_clickPreventStop_6287 = new core_1.actions.EventHandlerImpl({
            action: act_6806515264385235_showModal_6288,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_6806515264385235_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6806515264385235_clickPreventStop_6287],
        });
        const act_5137197219681649_showModal_6290 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_5137197219681649_showModal_6290_getActionArgs,
            events: [],
        });
        const evh_5137197219681649_click_6289 = new core_1.actions.EventHandlerImpl({
            action: act_5137197219681649_showModal_6290,
            event: "click",
            displayName: "showModal",
        });
        const evh_5137197219681649_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5137197219681649_click_6289],
        });
        const act_7736732573189513_setData_6292 = new core_1.actions.SetDataAction({
            actionArgs: this.act_7736732573189513_setData_6292_getActionArgs,
            events: [],
        });
        const evh_7736732573189513_clickPreventStop_6291 = new core_1.actions.EventHandlerImpl({
            action: act_7736732573189513_setData_6292,
            event: "click.prevent.stop",
            displayName: "setData",
        });
        const evh_7736732573189513_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7736732573189513_clickPreventStop_6291],
        });
        const act_2912679705172878_setData_6294 = new core_1.actions.SetDataAction({
            actionArgs: this.act_2912679705172878_setData_6294_getActionArgs,
            events: [],
        });
        const evh_2912679705172878_clickPreventStop_6293 = new core_1.actions.EventHandlerImpl({
            action: act_2912679705172878_setData_6294,
            event: "click.prevent.stop",
            displayName: "setData",
        });
        const evh_2912679705172878_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2912679705172878_clickPreventStop_6293],
        });
        return {
            act_7315092382398562_reloadComponentData_6284,
            evh_7315092382398562_reload_6283,
            evh_7315092382398562_reload,
            act_6705964765214521_reloadComponentData_6286,
            evh_6705964765214521_clickPreventStop_6285,
            evh_6705964765214521_clickPreventStop,
            act_6806515264385235_showModal_6288,
            evh_6806515264385235_clickPreventStop_6287,
            evh_6806515264385235_clickPreventStop,
            act_5137197219681649_showModal_6290,
            evh_5137197219681649_click_6289,
            evh_5137197219681649_click,
            act_7736732573189513_setData_6292,
            evh_7736732573189513_clickPreventStop_6291,
            evh_7736732573189513_clickPreventStop,
            act_2912679705172878_setData_6294,
            evh_2912679705172878_clickPreventStop_6293,
            evh_2912679705172878_clickPreventStop,
            reload: act_6705964765214521_reloadComponentData_6286,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: String,
    }),
    __metadata("design:type", String)
], UcContractInvoicesPanel.prototype, "contractId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('contractId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcContractInvoicesPanel.prototype, "onContract_id", null);
UcContractInvoicesPanel = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcContractInvoicesPanel);
exports.default = UcContractInvoicesPanel;
vue_property_decorator_1.Vue.component("UcContractInvoicesPanel", UcContractInvoicesPanel);
