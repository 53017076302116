<template>
<ItpModal
  no-primary-button
  :closeButtonText="'Close'|gettext"
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="WorksheetEditor"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :color="$config.worksheet.color"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <DIV
    class="page d-flex flex-column"
  >
    <DIV
      class="page__content container-fluid pt-3 h-100"
    >
      <ItpForm
        :id="formId"
        name="worksheet"
        class="row"
        ref="mainForm"
        @submit="evh_5534025912102772_submit($event, {})"
      >
        <ItpCol>
          <UcWorksheetEditorFieldset
            :worksheet="worksheet"
            :formId="formId"
            :target="target"
          >
          </UcWorksheetEditorFieldset>
        </ItpCol>
      </ItpForm>
    </DIV>
  </DIV>
  <UcFooter
    :card="!modal"
    :modal="modal"
    :color="$config.worksheet.color"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :text="'Save changes'|pgettext('Button')"
      type="submit"
      :form="forms.worksheet"
      :spinning="actions.save.isRunning"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpModal>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class WorksheetEditor extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "modal",
  };

  beforeCreate() {
  }

  getPayload(...args: any[]) {
    const [data] = args
    const fields = this.target && this.targetConfig[this.target].fieldsToSubmit
    return fields ? _.pick(data, fields) : data;
  }

  @Prop({
    type: Object,
  })
  worksheet!: object;

  @Watch('worksheet')
  onWorksheet(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("worksheet")
  }

  @Prop({
    type: String,
  })
  target!: string;

  @Watch('target')
  onTarget(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("target")
  }

  @Prop({
    type: String,
    default: "lg",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  defaultTitle!: any;
  targetConfig!: any;
  ux!: any;
  dataMembers = ['formId', 'defaultTitle', 'targetConfig', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        formId: null,
        defaultTitle: this.$fn.pgettext("Worksheet", "Edit Worksheet"),
        targetConfig: {
          header: {
            title: this.$fn.pgettext("Worksheet", "Edit Worksheet Header"),
            fieldsToSubmit: ["id"
              ,
              "revision_id"
              ,
              "etag"
              ,
              "company"
              ,
            ]
            ,
          }
          ,
        }
        ,
        ux: null,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.target ? this.targetConfig[this.target].title : this.defaultTitle,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_4256_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_4256, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_closeComponent_4258_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseComponentActionArgs> {
    // parameterResolver name=act_2248226175642056_closeComponent_4258, alias=undefined
    return {
      result: {
        cancel: true,
      }
      ,
    }
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_5534025912102772_closeModal_4262_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_5534025912102772_closeModal_4262, alias=undefined
    return {
      result: {
        ok: true,
        value: $event.data,
      }
      ,
    }
  }

  async act_5534025912102772_crud_4260_getActionArgs($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_5534025912102772_crud_4260, alias=save
    return {
      objectType: "worksheet",
      op: "upsert",
      data: this.getPayload(this.worksheet),
    }
  }

  async evh_5534025912102772_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5534025912102772_submit.executeFromDOM(this, event, scope);
  }

  async act_1419464017721962_closeComponent_4264_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseComponentActionArgs> {
    // parameterResolver name=act_1419464017721962_closeComponent_4264, alias=undefined
    return {
      result: {
        cancel: true,
      }
      ,
    }
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_4256: actions.CloseModalAction;
    evh_7315092382398562_close_4255: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_4258: actions.CloseComponentAction;
    evh_2248226175642056_close_4257: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_5534025912102772_closeModal_4262: actions.CloseModalAction;
    evh_5534025912102772_success_4261: actions.EventHandlerImpl;
    act_5534025912102772_crud_4260: actions.CRUDAction;
    evh_5534025912102772_submit_4259: actions.EventHandlerImpl;
    evh_5534025912102772_submit: actions.EventHandlerGroup;
    act_1419464017721962_closeComponent_4264: actions.CloseComponentAction;
    evh_1419464017721962_clickPreventStop_4263: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_4256 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_4256_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_4255 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_4256,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_4255],
      }
    );
    const act_2248226175642056_closeComponent_4258 = new actions.CloseComponentAction(
      {
        actionArgs: this.act_2248226175642056_closeComponent_4258_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_close_4257 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_4258,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_4257],
      }
    );
    const act_5534025912102772_closeModal_4262 = new actions.CloseModalAction(
      {
        actionArgs: this.act_5534025912102772_closeModal_4262_getActionArgs,
        events: [],
      }
    );
    const evh_5534025912102772_success_4261 = new actions.EventHandlerImpl(
      {
        action: act_5534025912102772_closeModal_4262,
        event: "success",
        displayName: "closeModal",
      }
    );
    const act_5534025912102772_crud_4260 = new actions.CRUDAction(
      {
        actionArgs: this.act_5534025912102772_crud_4260_getActionArgs,
        displayName: "save",
        events: [evh_5534025912102772_success_4261],
      }
    );
    const evh_5534025912102772_submit_4259 = new actions.EventHandlerImpl(
      {
        action: act_5534025912102772_crud_4260,
        event: "submit",
        displayName: "save",
      }
    );
    const evh_5534025912102772_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_5534025912102772_submit_4259],
      }
    );
    const act_1419464017721962_closeComponent_4264 = new actions.CloseComponentAction(
      {
        actionArgs: this.act_1419464017721962_closeComponent_4264_getActionArgs,
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_4263 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeComponent_4264,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_4263],
      }
    );
    return {
      act_7315092382398562_closeModal_4256,
      evh_7315092382398562_close_4255,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_4258,
      evh_2248226175642056_close_4257,
      evh_2248226175642056_close,
      act_5534025912102772_closeModal_4262,
      evh_5534025912102772_success_4261,
      act_5534025912102772_crud_4260,
      evh_5534025912102772_submit_4259,
      evh_5534025912102772_submit,
      act_1419464017721962_closeComponent_4264,
      evh_1419464017721962_clickPreventStop_4263,
      evh_1419464017721962_clickPreventStop,
      save: act_5534025912102772_crud_4260,
    }
  }
}

Vue.component("WorksheetEditor", WorksheetEditor);

</script>