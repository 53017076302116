var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c(
            "ItpForm",
            {
              staticClass: "row",
              attrs: {
                id: _vm.formId,
                name: _vm.formName,
                readonly: _vm.item.isReadOnly
              },
              on: {
                submit: function($event) {
                  return _vm.evh_2248226175642056_submit($event, {})
                }
              }
            },
            [
              _c(
                "ItpCol",
                [
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        labelColsMd: "2",
                        contentColsMd: "10",
                        name: "delivery_modes",
                        label: _vm._f("pgettext")(
                          "Object type",
                          "TextTemplate"
                        ),
                        labelAlign: _vm.labelAlign,
                        required: ""
                      }
                    },
                    [
                      _c("ItpFormSelect2", {
                        attrs: {
                          name: "object_type",
                          options: _vm.objectTypes,
                          valueField: "value",
                          textField: "name",
                          required: ""
                        },
                        model: {
                          value: _vm.item.object_type,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "object_type", $$v)
                          },
                          expression: "item.object_type"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        labelColsMd: "2",
                        contentColsMd: "10",
                        name: "name",
                        label: _vm._f("pgettext")("Name", "TextTemplate"),
                        labelAlign: _vm.labelAlign,
                        required: ""
                      }
                    },
                    [
                      _c("ItpFormInput", {
                        attrs: { name: "name", required: "" },
                        model: {
                          value: _vm.item.name,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "name", $$v)
                          },
                          expression: "item.name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        labelColsMd: "2",
                        contentColsMd: "10",
                        name: "description",
                        label: _vm._f("pgettext")(
                          "Description",
                          "TextTemplate"
                        ),
                        labelAlign: _vm.labelAlign
                      }
                    },
                    [
                      _c("ItpFormInput", {
                        attrs: { name: "description" },
                        model: {
                          value: _vm.item.description,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "description", $$v)
                          },
                          expression: "item.description"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        labelColsMd: "2",
                        contentColsMd: "10",
                        name: "condition",
                        label: _vm._f("pgettext")("Condition", "TextTemplate"),
                        labelAlign: _vm.labelAlign
                      }
                    },
                    [
                      _c("ItpFormTextarea", {
                        attrs: { name: "condition" },
                        model: {
                          value: _vm.item.condition,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "condition", $$v)
                          },
                          expression: "item.condition"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        labelColsMd: "2",
                        contentColsMd: "10",
                        name: "condition",
                        label: _vm._f("pgettext")("Template", "TextTemplate"),
                        labelAlign: _vm.labelAlign,
                        required: ""
                      }
                    },
                    [
                      _c("ItpFormTextarea", {
                        attrs: {
                          name: "template",
                          monospace: "",
                          required: ""
                        },
                        model: {
                          value: _vm.item.template,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "template", $$v)
                          },
                          expression: "item.template"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "DIV",
                    [
                      _c("ItpButton", {
                        staticClass: "ml-auto",
                        attrs: {
                          text: _vm._f("pgettext")("Save", "Button"),
                          type: "submit",
                          form: _vm.formId,
                          variant: "primary",
                          icon: "fa-save",
                          spinning:
                            _vm.actions.save && _vm.actions.save.isRunning,
                          disabled:
                            !_vm.forms[_vm.formName] ||
                            !_vm.forms[_vm.formName].submittable
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }