"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcContractRevisionDropdown = class UcContractRevisionDropdown extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['ux'];
    }
    beforeCreate() {
    }
    otherRevisions(...args) {
        return _.filter(args[0], p => p.revision_number != args[1]);
    }
    onContract(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("contract");
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            ux: null,
        });
    }
    get selectedRevision() {
        if (this.loaded) {
            return _.find(this.contract.revisions, { revision_number: this.contract.revision_number });
        }
        return null;
    }
    act_8358196963355853_navigate_4764_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                location: {
                    name: "app.contract",
                    query: {
                        rev: $event.scope.revision.revision_number,
                    },
                    params: {
                        id: this.contract.id,
                    },
                },
            };
        });
    }
    evh_8358196963355853_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8358196963355853_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_8356612450665535_showModal_4766_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "CreateContractRevision",
                props: {
                    contract: _.clone(this.contract),
                },
            };
        });
    }
    evh_8356612450665535_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8356612450665535_click.executeFromDOM(this, event, scope);
        });
    }
    act_1382594685840889_closeComponent_4772_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    close: true,
                },
            };
        });
    }
    act_1382594685840889_request_4770_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "delete_contract",
                data: this.contract,
                parameters: {
                    rev: this.contract.revision_number,
                },
            };
        });
    }
    act_1382594685840889_request_4770_getConfirm($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                message: this.$fn.gettext("Please confirm delete."),
                btnOk: this.$fn.gettext("Delete"),
            };
        });
    }
    evh_1382594685840889_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1382594685840889_click.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_8358196963355853_navigate_4764 = new core_1.actions.NavigateAction({
            actionArgs: this.act_8358196963355853_navigate_4764_getActionArgs,
            events: [],
        });
        const evh_8358196963355853_clickPreventStop_4763 = new core_1.actions.EventHandlerImpl({
            action: act_8358196963355853_navigate_4764,
            event: "click.prevent.stop",
            displayName: "navigate",
        });
        const evh_8358196963355853_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8358196963355853_clickPreventStop_4763],
        });
        const act_8356612450665535_reloadComponentData_4768 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_8356612450665535_success_4767 = new core_1.actions.EventHandlerImpl({
            action: act_8356612450665535_reloadComponentData_4768,
            event: "success",
            displayName: "reloadComponentData",
        });
        const act_8356612450665535_showModal_4766 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_8356612450665535_showModal_4766_getActionArgs,
            events: [evh_8356612450665535_success_4767],
        });
        const evh_8356612450665535_click_4765 = new core_1.actions.EventHandlerImpl({
            action: act_8356612450665535_showModal_4766,
            event: "click",
            displayName: "showModal",
        });
        const evh_8356612450665535_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8356612450665535_click_4765],
        });
        const act_1382594685840889_closeComponent_4772 = new core_1.actions.CloseComponentAction({
            actionArgs: this.act_1382594685840889_closeComponent_4772_getActionArgs,
            events: [],
        });
        const evh_1382594685840889_success_4771 = new core_1.actions.EventHandlerImpl({
            action: act_1382594685840889_closeComponent_4772,
            event: "success",
            displayName: "closeComponent",
        });
        const act_1382594685840889_request_4770 = new core_1.actions.RequestAction({
            actionArgs: this.act_1382594685840889_request_4770_getActionArgs,
            confirm: this.act_1382594685840889_request_4770_getConfirm,
            events: [evh_1382594685840889_success_4771],
        });
        const evh_1382594685840889_click_4769 = new core_1.actions.EventHandlerImpl({
            action: act_1382594685840889_request_4770,
            event: "click",
            displayName: "request",
        });
        const evh_1382594685840889_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1382594685840889_click_4769],
        });
        return {
            act_8358196963355853_navigate_4764,
            evh_8358196963355853_clickPreventStop_4763,
            evh_8358196963355853_clickPreventStop,
            act_8356612450665535_reloadComponentData_4768,
            evh_8356612450665535_success_4767,
            act_8356612450665535_showModal_4766,
            evh_8356612450665535_click_4765,
            evh_8356612450665535_click,
            act_1382594685840889_closeComponent_4772,
            evh_1382594685840889_success_4771,
            act_1382594685840889_request_4770,
            evh_1382594685840889_click_4769,
            evh_1382594685840889_click,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], UcContractRevisionDropdown.prototype, "contract", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('contract'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcContractRevisionDropdown.prototype, "onContract", null);
UcContractRevisionDropdown = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcContractRevisionDropdown);
exports.default = UcContractRevisionDropdown;
vue_property_decorator_1.Vue.component("UcContractRevisionDropdown", UcContractRevisionDropdown);
