"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UcFixedAssetWorkItemsPanel = exports.UcFixedAssetWasteBin = exports.UcFixedAssetRentalCard = exports.UcFixedAssetGeneral = exports.FieldsetFixedAssetWasteBin = exports.FieldsetFixedAssetGeneral = void 0;
const FieldsetFixedAssetGeneral_yaml_component_1 = require("./FieldsetFixedAssetGeneral.yaml?component");
exports.FieldsetFixedAssetGeneral = FieldsetFixedAssetGeneral_yaml_component_1.default;
const FieldsetFixedAssetWasteBin_yaml_component_1 = require("./FieldsetFixedAssetWasteBin.yaml?component");
exports.FieldsetFixedAssetWasteBin = FieldsetFixedAssetWasteBin_yaml_component_1.default;
const UcFixedAssetGeneral_yaml_component_1 = require("./UcFixedAssetGeneral.yaml?component");
exports.UcFixedAssetGeneral = UcFixedAssetGeneral_yaml_component_1.default;
const UcFixedAssetRentalCard_yaml_component_1 = require("./UcFixedAssetRentalCard.yaml?component");
exports.UcFixedAssetRentalCard = UcFixedAssetRentalCard_yaml_component_1.default;
const UcFixedAssetWasteBin_yaml_component_1 = require("./UcFixedAssetWasteBin.yaml?component");
exports.UcFixedAssetWasteBin = UcFixedAssetWasteBin_yaml_component_1.default;
const UcFixedAssetWorkItemsPanel_yaml_component_1 = require("./UcFixedAssetWorkItemsPanel.yaml?component");
exports.UcFixedAssetWorkItemsPanel = UcFixedAssetWorkItemsPanel_yaml_component_1.default;
