var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DIV", [
    _vm._v(
      "\n  " +
        _vm._s(
          (_vm.displayCode && _vm.option.code) ||
            _vm.option.full_name ||
            _vm.option.name
        ) +
        "\n\n"
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }