<template>
<ItpBox
  direction="column"
  alignItems="stretch"
  v-if="loaded"
>
  <ItpBox
    alignItems="center"
    class="pb-2"
  >
    <strong>
      {{ 'Invoice Lines'|pgettext('Invoice') }}
    </strong>
    <strong
      class="ml-1"
      v-if="invoiceLines.length"
    >
      ({{ invoiceLines.length }})
    </strong>
    <ItpButton
      size="sm"
      :text="'Upload...'|pgettext('Invoice|InvoiceLines')"
      class="ml-2"
      @click.prevent.stop="evh_1889980785424776_clickPreventStop($event, {})"
    >
    </ItpButton>
  </ItpBox>
  <ItpDataTable
    rowKey="id"
    tableId="invoiceLines"
    rowDetailsMode="alwaysVisible"
    :rowHeader="b_5962862420439144_rowHeader"
    :paging="b_5962862420439144_paging"
    :sortable="b_5962862420439144_sortable"
    :editable="!invoice.isReadOnly"
    :value="invoiceLines"
    :groups="invoiceLineGroups"
    groupKeyField="line_group_id"
    variant="details"
    row-selection
    columnHeaderStyle="cellHeader"
    cellClass="font-weight-bold"
    footer-totals
    :rowTemplate="b_5962862420439144_rowTemplate"
    :messages="b_5962862420439144_messages"
    :globs="{searchDataSource, messages}"
    :columns="b_5962862420439144_columns"
    class="h-100"
    @save-completed="evh_5962862420439144_saveCompleted($event, {})"
    @save="evh_5962862420439144_save($event, {})"
    @createProduct="evh_5962862420439144_createProduct($event, {})"
    @delete="evh_5962862420439144_delete($event, {})"
    @product:selected="evh_5962862420439144_productSelected($event, {})"
    @product:change="evh_5962862420439144_productChange($event, {})"
    @quantity:change="evh_5962862420439144_quantityChange($event, {})"
    @price:change="evh_5962862420439144_priceChange($event, {})"
    @discount:change="evh_5962862420439144_discountChange($event, {})"
    @net_amount:change="evh_5962862420439144_netAmountChange($event, {})"
    @amount:change="evh_5962862420439144_amountChange($event, {})"
    @tax_code:change="evh_5962862420439144_taxCodeChange($event, {})"
    @rental_or_property:selected="evh_5962862420439144_rentalOrPropertySelected($event, {})"
    @selectedRowsChanged="evh_5962862420439144_selectedRowsChanged($event, {})"
  >
    <template
      v-slot:rowGroupHeader="{ group, rows }"
    >
      <ItpBox>
        <UcInvoiceLineGroup
          :invoice="invoice"
          :group="group"
          @reload="evh_7789867021953041_reload($event, {group, rows})"
        >
        </UcInvoiceLineGroup>
      </ItpBox>
    </template>
    <template
      v-slot:afterLastRow="{ table }"
    >
      <ItpBox>
        <ItpLink
          :icon="$config.addIcon"
          size="sm"
          :text="'Add group...'|pgettext('InvoiceLineGroup')"
          class="ml-3"
          @click.prevent.stop="evh_309515315410323_clickPreventStop($event, {table})"
        >
        </ItpLink>
      </ItpBox>
    </template>
    <template
      v-slot:rowDetails="{ row }"
    >
      <div
        class="container-fluid  px-lg-1"
      >
        <ItpRow
          class="no-gutters"
        >
          <ItpCol
            w="2"
          >
            <ItpText
              pre
              :text="row.notes"
              collapsible
              v-if="row.notes && !row.isEditing"
            >
            </ItpText>
            <ItpFormTextarea
              rows="3"
              name="notes"
              :placeholder="'Notes'|gettext"
              v-model="row.notes"
              :disabled="!row.isEditing"
              class="mb-2"
              v-if="row.isEditing"
            >
            </ItpFormTextarea>
            <ItpFormSelect2
              name="reference_invoice_id"
              :placeholder="'Reference invoice'|pgettext('InvoiceLine')"
              v-model="row.reference_invoice_id"
              :initialOptions="row.reference_invoice ? [row.reference_invoice] : []"
              :dataSource="b_1270943406604881_dataSource"
              valueField="id"
              clearable
              optionTemplate="<UcSuggestInvoiceOption :option=option></UcSuggestInvoiceOption>"
              use-option-template-for-selected-option
              v-if="row.isEditing"
            >
            </ItpFormSelect2>
          </ItpCol>
          <ItpCol
            w="10"
          >
            <ItpBox
              v-if="!row.isNew && (row.isEditing || row.meter_accounting) && invoiceRentalNumber"
            >
              <ItpLink
                :icon="$config.addIcon"
                size="sm"
                :text="'Select readings...'|pgettext('InvoiceLines')"
                @click.prevent.stop="evh_880971572885910_clickPreventStop($event, {row})"
              >
              </ItpLink>
            </ItpBox>
            <ItpDataTable
              tableId="invoiceLineMeters"
              variant="basic"
              compact
              :paging="b_1803307708030275_paging"
              :sortable="b_1803307708030275_sortable"
              :rowHeader="b_1803307708030275_rowHeader"
              use-index-as-row-key
              :fillEmptyRowSpace="b_1803307708030275_fillEmptyRowSpace"
              :value="row.meter_accounting ? [row.meter_accounting] : []"
              :editable="!invoice.isReadOnly && !row.locked"
              :noCreateRow="!!row.meter_accounting"
              :globs="{ units, searchDataSource }"
              :messages="b_1803307708030275_messages"
              :rowTemplate="{
    invoice_id: invoice.id,
    invoice_line_id: row.id,
    from_date: null,
    from_amount: null,
    from_unit: null,
    to_date: null,
    to_amount: null,
    to_unit: null,
    billing_multiplier: 1
  }"
              :columns="b_1803307708030275_columns"
              class="bg-transparent datatable-basic mb-1"
              v-if="!row.isNew && row.isEditing || row.meter_accounting"
              @save="evh_1803307708030275_save($event, {row})"
              @save-completed="evh_1803307708030275_saveCompleted($event, {row})"
              @delete="evh_1803307708030275_delete($event, {row})"
              @delete-completed="evh_1803307708030275_deleteCompleted($event, {row})"
            >
            </ItpDataTable>
            <ItpDataTable
              tableId="invoiceLineAccounting"
              variant="basic"
              compact
              :paging="b_7700024326226090_paging"
              :sortable="b_7700024326226090_sortable"
              :rowHeader="b_7700024326226090_rowHeader"
              :fillEmptyRowSpace="b_7700024326226090_fillEmptyRowSpace"
              :value="row.accountings.data"
              :editable="!invoice.isReadOnly && !row.locked"
              :rowTemplate="schemaDefaults('CreateInvoiceLineAccounting', {
    invoice_id: invoice.id,
    invoice_line_id: row.id,
  })"
              :globs="{ searchDataSource }"
              :messages="b_7700024326226090_messages"
              :columns="b_7700024326226090_columns"
              class="bg-transparent datatable-basic mb-1"
              v-if="row.isEditing && !row.isNew || (row.accountings && row.accountings.data && row.accountings.data.length)"
              @save="evh_7700024326226090_save($event, {row})"
              @delete="evh_7700024326226090_delete($event, {row})"
              @save-completed="evh_7700024326226090_saveCompleted($event, {row})"
              @delete-completed="evh_7700024326226090_deleteCompleted($event, {row})"
            >
            </ItpDataTable>
            <ItpBox
              class="mb-3"
            >
              <DIV
                class="ml-auto"
                v-if="hasQuantityMismatch(row)"
              >
                <ItpIcon
                  icon="material-symbols:warning-rounded"
                  class="text-warning"
                >
                </ItpIcon>
                <ItpText
                  strong
                  :text="'Quantity mismatch.'|pgettext('invoiceLineAccounting')"
                >
                </ItpText>
              </DIV>
            </ItpBox>
          </ItpCol>
        </ItpRow>
      </div>
    </template>
  </ItpDataTable>
</ItpBox>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcInvoiceLines extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  onProductSelected(...args: any[]) {
    let [row, product] = args;

    if (!product) {
      product = row.product;
    }

    if (_.isObject(product) && product.id == null) {
      product = product.name;
      row.product = product;
    }

    if (_.isString(product)) {
      row.description = product;
      row.tax_code = this.defaultTaxCode;
      const nearestRow = row.$$table.nearestRow(row);
      console.log('PR', nearestRow);
      if (nearestRow) {
        row.unit = nearestRow.unit;
        row.discount = nearestRow.discount;
      }
    }
    else {
      row.product = product;
      row.unit = product.unit;
      row.description = product.name;
      row.tax_code = this.defaultTaxCode || (
        this.invoice.invoice_kind === "outgoing"
          ? product.sales_vat_code
          : product.purchase_vat_code
      );
      row.intermediated_service = product.intermediated_service || false;
    }
    row.setFocus('quantity')
  }

  onProductChanged(...args: any[]) {
    console.log('onProductChanged', args)
  }

  calculateUnitPrice(...args: any[]) {
    const [row, tax, decimals] = args;

    row.price = Big(
      Big(row.net_amount) / Big(row.quantity) / (1 - Big(row.discount) / 100)
    ).round(decimals).toNumber()
  }

  calculateNetAmount(...args: any[]) {
    const [row, tax, decimals] = args;

    row.net_amount = Big(
      Big(row.quantity) * Big(row.price) * (1 - Big(row.discount) / 100)
    ).round(decimals).toNumber()
  }

  calculateNetAmountFromAmount(...args: any[]) {
    const [row, tax, decimals] = args;

    row.net_amount = Big(
      Big(row.amount) / (1 + Big(tax.rate) / 100)
    ).round(decimals).toNumber()
  }

  calculateAmount(...args: any[]) {
    const [row, tax, decimals] = args;

    row.amount = Big(
      Big(row.net_amount) * (1 + Big(tax.rate) / 100)
    ).round(decimals).toNumber()
  }

  recalculateLineItem(...args: any[]) {
    const data = args[0].data;
    const row = data.row;
    const decimals = this.currency.digits;
    const isIncomingInvoice = this.isIncomingInvoice
    const tx = _.find(this.taxCodes, { id: row.tax_code });

    if (row.quantity === 0) {
      return;
    }

    switch (data.field) {
      case 'quantity':
      case 'price':
      case 'discount': {
        this.calculateNetAmount(row, tx, decimals);
        this.calculateAmount(row, tx, decimals);
        break;
      }

      case 'net_amount': {
        this.calculateUnitPrice(row, tx, this.currency.price_digits);
        this.calculateAmount(row, tx, decimals);
        break;
      }

      case 'tax_code': {
        this.calculateAmount(row, tx, decimals);
        break;
      }

      case 'amount': {
        this.calculateNetAmountFromAmount(row, tx, decimals);
        this.calculateUnitPrice(row, tx, this.currency.price_digits);
        break;
      }
    }
  }

  hasQuantityMismatch(...args: any[]) {
    const row = args[0]
    return row.accountings
      && row.accountings.data
      && row.accountings.data.length
      && _.sumBy(row.accountings.data, p => p.quantity) != row.quantity
  }

  getInvoiceLineEditorIsDisabled(...args: any[]) {
    const [row] = args;
    return !row.tax_code
  }

  onRentalOrPropertySelected(...args: any[]) {
    let [row, selection] = args;

    console.log(row, selection);

    if (!selection) {
      row.rental = null;
      row.property = null
      return;
    }

    if (_.isObject(selection) && selection.object == 'rental') {
      row.rental = selection;
      row.property = null
      return;
    }

    if (_.isObject(selection) && ['property', 'property_unit'].indexOf(selection.object) > -1) {
      row.rental = null;
      row.property = selection;
      return;
    }
  }

  @Prop({
    required: true,
    type: Object,
  })
  invoice!: object;

  @Watch('invoice')
  onInvoice(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoice")
  }

  @Prop({
    required: true,
    type: Object,
  })
  currency!: object;

  @Watch('currency')
  onCurrency(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("currency")
  }

  @Prop({
    type: Number,
  })
  defaultDiscount!: number;

  @Watch('defaultDiscount')
  onDefault_discount(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("defaultDiscount")
  }

  @Prop({
    type: String,
  })
  defaultTaxCode!: string;

  @Watch('defaultTaxCode')
  onDefault_tax_code(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("defaultTaxCode")
  }

  @Prop({
    required: true,
    type: Object,
  })
  formContext!: object;

  @Watch('formContext')
  onForm_context(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("formContext")
  }

  expenseTypes!: any;

  async $$load_expenseTypes() {
    return this.$fn.fetch('list_object_by_type', { parameters: { object_type: 'expense-type' } })
  }

  taxCodes!: any;

  async $$load_taxCodes() {
    return this.$fn.fetch('list_tax_code')
  }

  units!: any;

  async $$load_units() {
    return this.$fn.fetch('list_unit')
  }

  displayAddInvoiceLine!: any;
  messages!: any;
  ux!: any;
  b_5962862420439144_rowHeader!: any;
  b_5962862420439144_paging!: any;
  b_5962862420439144_sortable!: any;
  b_5962862420439144_rowTemplate!: any;

  async $$load_b_5962862420439144_rowTemplate() {
    return {
      description: null,
      discount: this.defaultDiscount || 0,
    }
  }

  b_5962862420439144_messages!: any;
  b_5962862420439144_columns!: any;

  async $$load_b_5962862420439144_columns() {
    return [{
      name: "product",
      header: this.$fn.gettext("Product"),
      width: "250px",
      required: true,
      textAlign: "left",
      editor: {
        name: "template",
        value: "<ItpSelect2\n\
  class=\"w-100\"\n\
  v-model=\"currentValue\"\n\
  bindObject\n\
  valueField=\"id\"\n\
  textField=\"name\"\n\
  required\n\
  :placeholder=\"messages.msgSearchProduct\"\n\
  :options=\"globs.searchDataSource('suggest_product')\"\n\
  :autofocus=autofocus\n\
  :form=\"formId\"\n\
  :inputId=\"col.field\"\n\
  :size=\"col.size\"\n\
  :state=\"validationState\"\n\
  :disabled=\"isDisabled\"\n\
  taggable\n\
  @optionSelected=\"col.emit('product:selected', {col, row, ...$event})\"\n\
  @change=\"onChange($event)\"\n\
>\n\
  <template #search=\"{attributes, events}\">\n\
    <input\n\
      class=\"vs__search\"\n\
      v-bind=\"attributes\"\n\
      v-on=\"events\"\n\
    />\n\
  </template>\n\
\n\
  <template #option=\"option\">\n\
    <UcSuggestProductOption :option=\"option\" v-if=\"option.object=='product'\">\n\
    </UcSuggestProductOption>\n\
    <span v-else>{{ option.name }}</span>\n\
  </template>\n\
\n\
  <template #selected-option=\"option\">\n\
    <UcSuggestProductOption :option=\"option\" v-if=\"option.object=='product'\">\n\
    </UcSuggestProductOption>\n\
    <span v-else>{{ option.name }}</span>\n\
  </template>\n\
\n\
  <template #list-footer>\n\
    <hr />\n\
    <ItpButton\n\
      class=\"ml-2\"\n\
      size=sm\n\
      variant=light\n\
      @click=\"col.emitCellEvent('createProduct', row)\">\n\
      {{ messages.msgCreateProduct }}\n\
    </ItpButton>\n\
  </template>\n\
\n\
  <template #footer>\n\
    <b-form-input\n\
      class=\"mt-1\"\n\
      type=text\n\
      v-model=row.description\n\
      id=\"description\"\n\
      name=\"description\"\n\
      autocomplete=\"off\"\n\
      :form=\"formId\"\n\
      :size=col.size\n\
      :disabled=\"isDisabled\"\n\
      :placeholder=\"globs.messages.invoiceLineDescriptionPlaceholder\"\n\
      @change=\"onFieldChange('description', row.description)\"\n\
    ></b-form-input>\n\
  </template>\n\
</ItpSelect2>\n\
",
      }
      ,
      formatter: {
        name: "template",
        value: "<div>\n\
  <b-link v-if=\"row.product\" :to=\"{\n\
    name: 'app.product',\n\
    params: { id: row.product.id }\n\
  }\">{{ row.description }}</b-link>\n\
  <span v-if=\"!row.product\">{{ row.description }}</span>\n\
</div>\n\
",
      }
      ,
    }
      ,
    {
      name: "advance",
      header: this.$fn.pgettext("InvoiceLine|ColHeader", "Adv."),
      headerTooltip: this.$fn.pgettext("invoiceLine|ColHeader|Tooltip", "Advance"),
      headerTextAlign: "center",
      width: "30px",
      type: "checkbox",
      editorIsDisabled: this.getInvoiceLineEditorIsDisabled,
    }
      ,
    {
      name: "intermediated_service",
      header: this.$fn.pgettext("AbbrIntermediatedService", "IMS"),
      headerTooltip: this.$fn.gettext("Intermediated Service"),
      headerTextAlign: "center",
      width: "30px",
      type: "checkbox",
      editorIsDisabled: this.getInvoiceLineEditorIsDisabled,
    }
      ,
    {
      name: "quantity",
      type: "number",
      maxAllowedDecimals: this.isIncomingInvoice ? null : this.currency.quantity_digits,
      default: 0,
      header: this.$fn.pgettext("Quantity Abbr", "Qty"),
      headerTooltip: this.$fn.gettext("Quantity"),
      headerTextAlign: "center",
      width: "60px",
      textAlign: "right",
      required: true,
      editorIsDisabled: this.getInvoiceLineEditorIsDisabled,
    }
      ,
    {
      name: "unit",
      header: this.$fn.pgettext("Unit Abbr", "Unit"),
      headerTooltip: this.$fn.gettext("Unit"),
      width: "70px",
      textAlign: "center",
      required: true,
      editorIsDisabled: this.getInvoiceLineEditorIsDisabled,
    }
      ,
    {
      name: "price",
      type: "number",
      maxAllowedDecimals: this.isIncomingInvoice ? null : this.currency.price_digits,
      default: 0,
      header: this.$fn.gettext("Price"),
      width: "70px",
      textAlign: "right",
      required: true,
      editorIsDisabled: this.getInvoiceLineEditorIsDisabled,
      footerTotals: rows => _.meanBy(rows, p => p.quantity * p.price),
    }
      ,
    {
      name: "discount",
      type: "number",
      maxAllowedDecimals: this.isIncomingInvoice ? null : this.currency.digits,
      default: this.defaultDiscount || 0,
      header: this.$fn.pgettext("AbbrDiscount", "Disc"),
      headerTooltip: this.$fn.gettext("Discount"),
      noFooterTotals: true,
      width: "50px",
      textAlign: "right",
      required: true,
      editorIsDisabled: this.getInvoiceLineEditorIsDisabled,
    }
      ,
    {
      name: "net_amount",
      type: "number",
      maxAllowedDecimals: this.currency.digits,
      default: 0,
      header: this.$fn.gettext("Net"),
      width: "100px",
      textAlign: "right",
      required: true,
      editorIsDisabled: this.getInvoiceLineEditorIsDisabled,
    }
      ,
    {
      name: "amount",
      type: "number",
      maxAllowedDecimals: this.currency.digits,
      default: 0,
      header: this.$fn.gettext("Amount"),
      width: "100px",
      textAlign: "right",
      required: true,
      editorIsDisabled: this.getInvoiceLineEditorIsDisabled,
    }
      ,
    {
      name: "tax_code",
      header: this.$fn.gettext("VAT"),
      headerTooltip: this.$fn.gettext("VAT Code"),
      width: "100px",
      type: "select",
      options: this.taxCodes,
      valueField: "id",
      textField: "id",
      textAlign: "right",
      editorIsDisabled: this.getInvoiceLineEditorIsDisabled,
    }
      ,
    {
      name: "expense_type",
      type: "select",
      header: this.$fn.pgettext("InvoiceLine|ColHeader", "Expense Type"),
      width: "100px",
      options: this.expenseTypes,
      valueField: "name",
      textField: "name",
    }
      ,
    {
      name: "rental_or_property",
      header: this.$fn.gettext("Cost Center"),
      textAlign: "left",
      width: "100px",
      editor: {
        name: "template",
        value: "<ItpSelect2\n\
  class=\"w-100\"\n\
  v-model=\"currentValue\"\n\
  bindObject\n\
  valueField=\"id\"\n\
  textField=\"name\"\n\
  required\n\
  :placeholder=\"messages.msgSearchCostCenter\"\n\
  :options=\"globs.searchDataSource('suggest_cost_center')\"\n\
  :initialOptions=\"[row.rental || row.property]\"\n\
  :autofocus=autofocus\n\
  :form=\"formId\"\n\
  :inputId=\"col.field\"\n\
  :size=\"col.size\"\n\
  :state=\"validationState\"\n\
  :disabled=\"isDisabled\"\n\
  @optionSelected=\"col.emit('rental_or_property:selected', {col, row, ...$event})\"\n\
  @change=\"onChange($event)\"\n\
>\n\
  <template #search=\"{attributes, events}\">\n\
    <input\n\
      class=\"vs__search\"\n\
      v-bind=\"attributes\"\n\
      v-on=\"events\"\n\
    />\n\
  </template>\n\
\n\
  <template #option=\"option\">\n\
    <UcSuggestRentalOption :option=\"option\" v-if=\"option.object=='rental'\">\n\
    </UcSuggestRentalOption>\n\
    <UcSuggestPropertyOption :option=\"option\" v-if=\"option.object=='property' || option.object == 'property_unit'\">\n\
    </UcSuggestPropertyOption>\n\
  </template>\n\
\n\
  <template #selected-option=\"option\">\n\
    <UcSuggestRentalOption :option=\"option\" v-if=\"option.object=='rental'\">\n\
    </UcSuggestRentalOption>\n\
    <UcSuggestPropertyOption :option=\"option\" v-if=\"option.object=='property' || option.object == 'property_unit'\">\n\
    </UcSuggestPropertyOption>\n\
  </template>\n\
</ItpSelect2>\n\
",
      }
      ,
      formatter: {
        name: "template",
        value: "<div>\n\
  <UcSuggestRentalOption :option=\"row.rental\" v-if=\"row.rental\">\n\
  </UcSuggestRentalOption>\n\
  <UcSuggestPropertyOption :option=\"row.property\" v-if=\"row.property\" display-code>\n\
  </UcSuggestPropertyOption>\n\
</div>\n\
",
      }
      ,
    }
      ,
    ]
  }

  b_1270943406604881_dataSource!: any;

  async $$load_b_1270943406604881_dataSource() {
    return {
      name: "suggest_invoice",
      parameters: {
        invoice_kind: this.invoice.invoice_kind,
        client_id: this.invoice.client.id,
        company_id: this.invoice.company.id,
        exclude_invoice: this.invoice.id,
      }
      ,
    }
  }

  b_1803307708030275_paging!: any;
  b_1803307708030275_sortable!: any;
  b_1803307708030275_rowHeader!: any;
  b_1803307708030275_fillEmptyRowSpace!: any;
  b_1803307708030275_messages!: any;
  b_1803307708030275_columns!: any;

  async $$load_b_1803307708030275_columns() {
    return [{
      name: "meter",
      header: this.$fn.gettext("Meter"),
      width: "160px",
      required: true,
      type: "select2",
      options: this.$fn.searchDataSource('suggest_meter'),
      bindObject: true,
      valueField: "id",
      textField: "serial_number",
      formatter: {
        name: "template",
        value: "<UcMeterMiniCard :item=\"row.meter\" link-style-underline no-strong></UcMeterMiniCard>\n\
",
      }
      ,
    }
      ,
    {
      name: "from",
      header: this.$fn.gettext("Previous reading"),
      width: "160px",
      formatter: {
        name: "template",
        value: "<div>{{ row.from_date|date }} {{ row.from_amount|number }} {{ row.from_unit }}</div>\n\
",
      }
      ,
      editor: {
        name: "template",
        value: "<div class=\"input-group\">\n\
  <ItpFormDatetime\n\
    v-model=\"row.from_date\"\n\
    name=\"from_date\"\n\
    placeholder=\"date\"\n\
    size=\"sm\"\n\
    style=\"min-width:100px;\"\n\
    required\n\
  ></ItpFormDatetime>\n\
\n\
  <ItpFormInput\n\
    type=\"number\"\n\
    name=\"from_amount\"\n\
    v-model=\"row.from_amount\"\n\
    placeholder=\"reading\"\n\
    size=\"sm\"\n\
    style=\"min-width:60px;\"\n\
    required\n\
  ></ItpFormInput>\n\
\n\
  <ItpFormSelect\n\
    v-model=\"row.from_unit\"\n\
    name=\"from_unit\"\n\
    placeholder=\"unit\"\n\
    size=\"sm\"\n\
    :options=\"globs.units\"\n\
    style=\"min-width:60px;\"\n\
    valueField=\"symbol\"\n\
    textField=\"symbol\"\n\
    required\n\
  ></ItpFormSelect>\n\
</div>\n\
",
      }
      ,
    }
      ,
    {
      name: "to",
      header: this.$fn.gettext("Current reading"),
      width: "240px",
      formatter: {
        name: "template",
        value: "<div>{{ row.to_date|date }} {{ row.to_amount|number }} {{ row.to_unit }}</div>\n\
",
      }
      ,
      editor: {
        name: "template",
        value: "<div class=\"input-group\">\n\
  <ItpFormDatetime\n\
    v-model=\"row.to_date\"\n\
    name=\"to_date\"\n\
    placeholder=\"date\"\n\
    size=\"sm\"\n\
    style=\"min-width:100px;\"\n\
    required\n\
  ></ItpFormDatetime>\n\
\n\
  <ItpFormInput\n\
    type=\"number\"\n\
    name=\"to_amount\"\n\
    v-model=\"row.to_amount\"\n\
    placeholder=\"reading\"\n\
    size=\"sm\"\n\
    style=\"min-width:60px;\"\n\
    required\n\
  ></ItpFormInput>\n\
\n\
  <ItpFormSelect\n\
    v-model=\"row.to_unit\"\n\
    name=\"to_unit\"\n\
    placeholder=\"unit\"\n\
    size=\"sm\"\n\
    :options=\"globs.units\"\n\
    style=\"min-width:60px;\"\n\
    valueField=\"symbol\"\n\
    textField=\"symbol\"\n\
    required\n\
  ></ItpFormSelect>\n\
</div>\n\
",
      }
      ,
    }
      ,
    {
      name: "consumption",
      header: this.$fn.gettext("Consumption"),
      readonly: true,
      formatter: {
        name: "template",
        value: "<div>{{ row.consumption_amount|number }} {{ row.consumption_unit }}</div>\n\
",
      }
      ,
    }
      ,
    {
      name: "billing_multiplier",
      header: this.$fn.gettext("Multiplier"),
      default: 1,
      width: "70px",
      type: "number",
      required: true,
    }
      ,
    ]
  }

  b_7700024326226090_paging!: any;
  b_7700024326226090_sortable!: any;
  b_7700024326226090_rowHeader!: any;
  b_7700024326226090_fillEmptyRowSpace!: any;
  b_7700024326226090_messages!: any;
  b_7700024326226090_columns!: any;

  async $$load_b_7700024326226090_columns() {
    return [{
      name: "expense_type",
      type: "select",
      header: this.$fn.gettext("Expense Type"),
      width: "100px",
      options: this.expenseTypes,
      valueField: "name",
      textField: "name",
    }
      ,
    {
      name: "rental",
      type: "select2",
      header: this.$fn.gettext("Rental"),
      width: "100px",
      path: "name",
      bindObject: true,
      textField: "name",
      options: this.$fn.searchDataSource('suggest_rental'),
      useOptionTemplateForSelectedOption: true,
      optionTemplate: "<UcSuggestRentalOption :option=\"option\"></UcSuggestRentalOption>",
    }
      ,
    {
      name: "property",
      type: "select2",
      header: this.$fn.gettext("Property"),
      width: "100px",
      path: "name",
      bindObject: true,
      textField: "name",
      options: this.$fn.searchDataSource('suggest_property'),
      useOptionTemplateForSelectedOption: true,
      optionTemplate: "<UcSuggestPropertyOption :option=\"option\"></UcSuggestPropertyOption>",
    }
      ,
    {
      name: "contractor",
      type: "select2",
      header: this.$fn.gettext("Contractor"),
      width: "100px",
      path: "name",
      bindObject: true,
      textField: "name",
      options: this.$fn.searchDataSource('suggest_client', { 'is-contractor': true }),
    }
      ,
    {
      name: "quantity",
      type: "number",
      header: this.$fn.gettext("Quantity"),
      width: "100px",
    }
      ,
    ]
  }

  dataMembers = ['expenseTypes', 'taxCodes', 'units', 'displayAddInvoiceLine', 'messages', 'ux', 'b_5962862420439144_rowHeader', 'b_5962862420439144_paging', 'b_5962862420439144_sortable', 'b_5962862420439144_rowTemplate', 'b_5962862420439144_messages', 'b_5962862420439144_columns', 'b_1270943406604881_dataSource', 'b_1803307708030275_paging', 'b_1803307708030275_sortable', 'b_1803307708030275_rowHeader', 'b_1803307708030275_fillEmptyRowSpace', 'b_1803307708030275_messages', 'b_1803307708030275_columns', 'b_7700024326226090_paging', 'b_7700024326226090_sortable', 'b_7700024326226090_rowHeader', 'b_7700024326226090_fillEmptyRowSpace', 'b_7700024326226090_messages', 'b_7700024326226090_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        expenseTypes: null,
        taxCodes: null,
        units: null,
        displayAddInvoiceLine: null,
        messages: {
          invoiceLineProductSearchPlaceholder: this.$fn.pgettext("InvoiceLines", "Search product/service..."),
          invoiceLineCostCenterSearchPlaceholder: this.$fn.pgettext("invoiceLines", "search..."),
          invoiceLineDescriptionPlaceholder: this.$fn.pgettext("InvoiceLines", "Description"),
        }
        ,
        ux: null,
        b_5962862420439144_rowHeader: false,
        b_5962862420439144_paging: false,
        b_5962862420439144_sortable: false,
        b_5962862420439144_rowTemplate: null,
        b_5962862420439144_messages: {
          msgCreateRow: this.$fn.pgettext("InvoiceLines", "Add line"),
          msgCreateProduct: this.$fn.pgettext("InvoiceLines", "Add new product..."),
          msgSearchProduct: this.$fn.pgettext("InvoiceLines", "Search for product/service..."),
          msgSearchCostCenter: this.$fn.pgettext("InvoiceLines", "search..."),
        }
        ,
        b_5962862420439144_columns: null,
        b_1270943406604881_dataSource: null,
        b_1803307708030275_paging: false,
        b_1803307708030275_sortable: false,
        b_1803307708030275_rowHeader: false,
        b_1803307708030275_fillEmptyRowSpace: false,
        b_1803307708030275_messages: {
          msgCreateRow: this.$fn.pgettext("invoiceLines", "New meter row"),
        }
        ,
        b_1803307708030275_columns: null,
        b_7700024326226090_paging: false,
        b_7700024326226090_sortable: false,
        b_7700024326226090_rowHeader: false,
        b_7700024326226090_fillEmptyRowSpace: false,
        b_7700024326226090_messages: {
          msgCreateRow: this.$fn.pgettext("invoiceLines", "New accounting row"),
        }
        ,
        b_7700024326226090_columns: null,
      },
    }
  }

  get invoiceLines() {
    if (this.loaded) {

      return this.invoice.lines.data
    }
    return null;
  }

  get invoiceLineGroups() {
    if (this.loaded) {

      return this.invoice.line_groups.data
    }
    return null;
  }

  get isIncomingInvoice() {
    if (this.loaded) {

      return this.invoice.invoice_kind == 'incoming'
    }
    return null;
  }

  get invoiceRentalNumber() {
    if (this.loaded) {

      return _.chain(this.invoice.references.data).find({ object: 'rental' }).get('name').value()
    }
    return null;
  }

  async act_1889980785424776_showModal_6526_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_1889980785424776_showModal_6526, alias=undefined
    return {
      name: "UploadInvoiceLines",
      props: {
        invoice: this.invoice,
      }
      ,
    }
  }

  async evh_1889980785424776_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1889980785424776_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_emit_6528_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_5962862420439144_emit_6528, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_5962862420439144_saveCompleted(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_saveCompleted.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_script_6530_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_6530, alias=undefined
    this.invoice.id
      ? $event.data.waitFor(this.$fn.crud('invoice_line').upsert({ ...$event.data.row, invoice_id: this.invoice.id }))
      : this.invoiceLines.splice(this.invoiceLines.length - 1, 0, $event.data.row)
  }

  async act_5962862420439144_script_6530_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_6530, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_6530_getActionArgs_value($event),
    }
  }

  async evh_5962862420439144_save(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_save.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_script_6534_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_6534, alias=undefined
    this.onProductSelected($event.parent.data.row, $event.data.modalResult.value)
  }

  async act_5962862420439144_script_6534_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_6534, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_6534_getActionArgs_value($event),
    }
  }

  async act_5962862420439144_showModal_6532_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_5962862420439144_showModal_6532, alias=undefined
    return {
      name: "CreateProduct",
      props: {
        preventDefaultOnClose: true,
      }
      ,
    }
  }

  async evh_5962862420439144_createProduct(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_createProduct.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_script_6536_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_6536, alias=undefined
    $event.data.waitFor(this.$fn.crud('invoice_line').delete($event.data.row))
  }

  async act_5962862420439144_script_6536_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_6536, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_6536_getActionArgs_value($event),
    }
  }

  async evh_5962862420439144_delete(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_delete.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_script_6538_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_6538, alias=undefined
    this.onProductSelected($event.data.row, $event.data.option)
  }

  async act_5962862420439144_script_6538_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_6538, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_6538_getActionArgs_value($event),
    }
  }

  async evh_5962862420439144_productSelected(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_productSelected.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_script_6540_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_6540, alias=undefined
    this.onProductChanged($event)
  }

  async act_5962862420439144_script_6540_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_6540, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_6540_getActionArgs_value($event),
    }
  }

  async evh_5962862420439144_productChange(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_productChange.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_script_6542_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_6542, alias=undefined
    this.recalculateLineItem($event)
  }

  async act_5962862420439144_script_6542_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_6542, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_6542_getActionArgs_value($event),
    }
  }

  async evh_5962862420439144_quantityChange(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_quantityChange.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_script_6544_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_6544, alias=undefined
    this.recalculateLineItem($event)
  }

  async act_5962862420439144_script_6544_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_6544, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_6544_getActionArgs_value($event),
    }
  }

  async evh_5962862420439144_priceChange(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_priceChange.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_script_6546_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_6546, alias=undefined
    this.recalculateLineItem($event)
  }

  async act_5962862420439144_script_6546_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_6546, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_6546_getActionArgs_value($event),
    }
  }

  async evh_5962862420439144_discountChange(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_discountChange.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_script_6548_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_6548, alias=undefined
    this.recalculateLineItem($event)
  }

  async act_5962862420439144_script_6548_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_6548, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_6548_getActionArgs_value($event),
    }
  }

  async evh_5962862420439144_netAmountChange(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_netAmountChange.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_script_6550_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_6550, alias=undefined
    this.recalculateLineItem($event)
  }

  async act_5962862420439144_script_6550_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_6550, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_6550_getActionArgs_value($event),
    }
  }

  async evh_5962862420439144_amountChange(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_amountChange.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_script_6552_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_6552, alias=undefined
    this.recalculateLineItem($event)
  }

  async act_5962862420439144_script_6552_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_6552, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_6552_getActionArgs_value($event),
    }
  }

  async evh_5962862420439144_taxCodeChange(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_taxCodeChange.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_script_6554_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_6554, alias=undefined
    this.onRentalOrPropertySelected($event.data.row, $event.data.option)
  }

  async act_5962862420439144_script_6554_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_6554, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_6554_getActionArgs_value($event),
    }
  }

  async evh_5962862420439144_rentalOrPropertySelected(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_rentalOrPropertySelected.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_setData_6556_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_5962862420439144_setData_6556, alias=undefined
    return {
      path: "formContext.selectedInvoiceLines",
      value: $event.data.selection,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_5962862420439144_selectedRowsChanged(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_selectedRowsChanged.executeFromDOM(this, event, scope);
  }

  async act_7789867021953041_emit_6558_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_7789867021953041_emit_6558, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_7789867021953041_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7789867021953041_reload.executeFromDOM(this, event, scope);
  }

  async act_309515315410323_emit_6562_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_309515315410323_emit_6562, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_309515315410323_close_6561_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_309515315410323_close_6561, alias=undefined
    return !!$event.data.modalResult.value
  }

  async act_309515315410323_showModal_6560_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_309515315410323_showModal_6560, alias=undefined
    return {
      name: "InvoiceLineGroup",
      props: {
        invoice: this.invoice,
      }
      ,
    }
  }

  async evh_309515315410323_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_309515315410323_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_880971572885910_script_6566_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_880971572885910_script_6566, alias=undefined
    this.$fn.crud('invoice_line')
      .upsert({
        id: $event.scope.row.id,
        etag: $event.scope.row.etag,
        meter_accounting: {
          invoice_id: this.invoice.id,
          invoice_line_id: $event.scope.row.id,
          ...$event.data.modalResult.value,
        }
      }).then(() => this.$emit('reload'))
  }

  async act_880971572885910_script_6566_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_880971572885910_script_6566, alias=undefined
    return {
      value: () => this.act_880971572885910_script_6566_getActionArgs_value($event),
    }
  }

  async evh_880971572885910_close_6565_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_880971572885910_close_6565, alias=undefined
    return !!$event.data.modalResult.value
  }

  async act_880971572885910_showModal_6564_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_880971572885910_showModal_6564, alias=undefined
    return {
      name: "InvoiceLineMeterReadingSelector",
      props: {
        rental: this.invoiceRentalNumber,
        multiple: false,
        serviceId: $event.scope.row.product ? $event.scope.row.product.id : null,
      }
      ,
    }
  }

  async evh_880971572885910_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_880971572885910_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_1803307708030275_script_6568_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_1803307708030275_script_6568, alias=undefined
    $event.data.waitFor(
      this.$fn.crud('invoice_line')
        .upsert({
          id: $event.scope.row.id,
          etag: $event.scope.row.etag,
          meter_accounting: $event.data.row,
        })
    )
  }

  async act_1803307708030275_script_6568_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_1803307708030275_script_6568, alias=undefined
    return {
      value: () => this.act_1803307708030275_script_6568_getActionArgs_value($event),
    }
  }

  async evh_1803307708030275_save(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1803307708030275_save.executeFromDOM(this, event, scope);
  }

  async act_1803307708030275_emit_6570_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_1803307708030275_emit_6570, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_1803307708030275_saveCompleted(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1803307708030275_saveCompleted.executeFromDOM(this, event, scope);
  }

  async act_1803307708030275_script_6572_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_1803307708030275_script_6572, alias=undefined
    $event.data.waitFor(this.$fn.crud('invoice_line_meter_accounting').delete($event.data.row))
  }

  async act_1803307708030275_script_6572_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_1803307708030275_script_6572, alias=undefined
    return {
      value: () => this.act_1803307708030275_script_6572_getActionArgs_value($event),
    }
  }

  async evh_1803307708030275_delete(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1803307708030275_delete.executeFromDOM(this, event, scope);
  }

  async act_1803307708030275_emit_6574_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_1803307708030275_emit_6574, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_1803307708030275_deleteCompleted(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1803307708030275_deleteCompleted.executeFromDOM(this, event, scope);
  }

  async act_7700024326226090_script_6576_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_7700024326226090_script_6576, alias=undefined
    $event.data.waitFor(
      this.$fn.crud('invoice_line_accounting').upsert($event.data.row)
        .then(p => (this.$emit('reload'), p))
    )
  }

  async act_7700024326226090_script_6576_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_7700024326226090_script_6576, alias=undefined
    return {
      value: () => this.act_7700024326226090_script_6576_getActionArgs_value($event),
    }
  }

  async evh_7700024326226090_save(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7700024326226090_save.executeFromDOM(this, event, scope);
  }

  async act_7700024326226090_script_6578_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_7700024326226090_script_6578, alias=undefined
    $event.data.waitFor(this.$fn.crud('invoice_line_accounting').delete($event.data.row))
  }

  async act_7700024326226090_script_6578_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_7700024326226090_script_6578, alias=undefined
    return {
      value: () => this.act_7700024326226090_script_6578_getActionArgs_value($event),
    }
  }

  async evh_7700024326226090_delete(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7700024326226090_delete.executeFromDOM(this, event, scope);
  }

  async act_7700024326226090_emit_6580_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_7700024326226090_emit_6580, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_7700024326226090_saveCompleted(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7700024326226090_saveCompleted.executeFromDOM(this, event, scope);
  }

  async act_7700024326226090_emit_6582_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_7700024326226090_emit_6582, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_7700024326226090_deleteCompleted(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7700024326226090_deleteCompleted.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_1889980785424776_showModal_6526: actions.ShowModalAction;
    evh_1889980785424776_clickPreventStop_6525: actions.EventHandlerImpl;
    evh_1889980785424776_clickPreventStop: actions.EventHandlerGroup;
    act_5962862420439144_emit_6528: actions.EmitAction;
    evh_5962862420439144_saveCompleted_6527: actions.EventHandlerImpl;
    evh_5962862420439144_saveCompleted: actions.EventHandlerGroup;
    act_5962862420439144_script_6530: actions.ScriptAction;
    evh_5962862420439144_save_6529: actions.EventHandlerImpl;
    evh_5962862420439144_save: actions.EventHandlerGroup;
    act_5962862420439144_script_6534: actions.ScriptAction;
    evh_5962862420439144_close_6533: actions.EventHandlerImpl;
    act_5962862420439144_showModal_6532: actions.ShowModalAction;
    evh_5962862420439144_createProduct_6531: actions.EventHandlerImpl;
    evh_5962862420439144_createProduct: actions.EventHandlerGroup;
    act_5962862420439144_script_6536: actions.ScriptAction;
    evh_5962862420439144_delete_6535: actions.EventHandlerImpl;
    evh_5962862420439144_delete: actions.EventHandlerGroup;
    act_5962862420439144_script_6538: actions.ScriptAction;
    evh_5962862420439144_productSelected_6537: actions.EventHandlerImpl;
    evh_5962862420439144_productSelected: actions.EventHandlerGroup;
    act_5962862420439144_script_6540: actions.ScriptAction;
    evh_5962862420439144_productChange_6539: actions.EventHandlerImpl;
    evh_5962862420439144_productChange: actions.EventHandlerGroup;
    act_5962862420439144_script_6542: actions.ScriptAction;
    evh_5962862420439144_quantityChange_6541: actions.EventHandlerImpl;
    evh_5962862420439144_quantityChange: actions.EventHandlerGroup;
    act_5962862420439144_script_6544: actions.ScriptAction;
    evh_5962862420439144_priceChange_6543: actions.EventHandlerImpl;
    evh_5962862420439144_priceChange: actions.EventHandlerGroup;
    act_5962862420439144_script_6546: actions.ScriptAction;
    evh_5962862420439144_discountChange_6545: actions.EventHandlerImpl;
    evh_5962862420439144_discountChange: actions.EventHandlerGroup;
    act_5962862420439144_script_6548: actions.ScriptAction;
    evh_5962862420439144_netAmountChange_6547: actions.EventHandlerImpl;
    evh_5962862420439144_netAmountChange: actions.EventHandlerGroup;
    act_5962862420439144_script_6550: actions.ScriptAction;
    evh_5962862420439144_amountChange_6549: actions.EventHandlerImpl;
    evh_5962862420439144_amountChange: actions.EventHandlerGroup;
    act_5962862420439144_script_6552: actions.ScriptAction;
    evh_5962862420439144_taxCodeChange_6551: actions.EventHandlerImpl;
    evh_5962862420439144_taxCodeChange: actions.EventHandlerGroup;
    act_5962862420439144_script_6554: actions.ScriptAction;
    evh_5962862420439144_rentalOrPropertySelected_6553: actions.EventHandlerImpl;
    evh_5962862420439144_rentalOrPropertySelected: actions.EventHandlerGroup;
    act_5962862420439144_setData_6556: actions.SetDataAction;
    evh_5962862420439144_selectedRowsChanged_6555: actions.EventHandlerImpl;
    evh_5962862420439144_selectedRowsChanged: actions.EventHandlerGroup;
    act_7789867021953041_emit_6558: actions.EmitAction;
    evh_7789867021953041_reload_6557: actions.EventHandlerImpl;
    evh_7789867021953041_reload: actions.EventHandlerGroup;
    act_309515315410323_emit_6562: actions.EmitAction;
    evh_309515315410323_close_6561: actions.EventHandlerImpl;
    act_309515315410323_showModal_6560: actions.ShowModalAction;
    evh_309515315410323_clickPreventStop_6559: actions.EventHandlerImpl;
    evh_309515315410323_clickPreventStop: actions.EventHandlerGroup;
    act_880971572885910_script_6566: actions.ScriptAction;
    evh_880971572885910_close_6565: actions.EventHandlerImpl;
    act_880971572885910_showModal_6564: actions.ShowModalAction;
    evh_880971572885910_clickPreventStop_6563: actions.EventHandlerImpl;
    evh_880971572885910_clickPreventStop: actions.EventHandlerGroup;
    act_1803307708030275_script_6568: actions.ScriptAction;
    evh_1803307708030275_save_6567: actions.EventHandlerImpl;
    evh_1803307708030275_save: actions.EventHandlerGroup;
    act_1803307708030275_emit_6570: actions.EmitAction;
    evh_1803307708030275_saveCompleted_6569: actions.EventHandlerImpl;
    evh_1803307708030275_saveCompleted: actions.EventHandlerGroup;
    act_1803307708030275_script_6572: actions.ScriptAction;
    evh_1803307708030275_delete_6571: actions.EventHandlerImpl;
    evh_1803307708030275_delete: actions.EventHandlerGroup;
    act_1803307708030275_emit_6574: actions.EmitAction;
    evh_1803307708030275_deleteCompleted_6573: actions.EventHandlerImpl;
    evh_1803307708030275_deleteCompleted: actions.EventHandlerGroup;
    act_7700024326226090_script_6576: actions.ScriptAction;
    evh_7700024326226090_save_6575: actions.EventHandlerImpl;
    evh_7700024326226090_save: actions.EventHandlerGroup;
    act_7700024326226090_script_6578: actions.ScriptAction;
    evh_7700024326226090_delete_6577: actions.EventHandlerImpl;
    evh_7700024326226090_delete: actions.EventHandlerGroup;
    act_7700024326226090_emit_6580: actions.EmitAction;
    evh_7700024326226090_saveCompleted_6579: actions.EventHandlerImpl;
    evh_7700024326226090_saveCompleted: actions.EventHandlerGroup;
    act_7700024326226090_emit_6582: actions.EmitAction;
    evh_7700024326226090_deleteCompleted_6581: actions.EventHandlerImpl;
    evh_7700024326226090_deleteCompleted: actions.EventHandlerGroup;
  }

  getActions() {
    const act_1889980785424776_showModal_6526 = new actions.ShowModalAction(
      {
        actionArgs: this.act_1889980785424776_showModal_6526_getActionArgs,
        events: [],
      }
    );
    const evh_1889980785424776_clickPreventStop_6525 = new actions.EventHandlerImpl(
      {
        action: act_1889980785424776_showModal_6526,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_1889980785424776_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1889980785424776_clickPreventStop_6525],
      }
    );
    const act_5962862420439144_emit_6528 = new actions.EmitAction(
      {
        actionArgs: this.act_5962862420439144_emit_6528_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_saveCompleted_6527 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_emit_6528,
        event: "save-completed",
        displayName: "emit",
      }
    );
    const evh_5962862420439144_saveCompleted = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_saveCompleted_6527],
      }
    );
    const act_5962862420439144_script_6530 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_6530_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_save_6529 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_6530,
        event: "save",
        displayName: "script",
      }
    );
    const evh_5962862420439144_save = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_save_6529],
      }
    );
    const act_5962862420439144_script_6534 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_6534_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_close_6533 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_6534,
        event: "close",
        displayName: "script",
      }
    );
    const act_5962862420439144_showModal_6532 = new actions.ShowModalAction(
      {
        actionArgs: this.act_5962862420439144_showModal_6532_getActionArgs,
        events: [evh_5962862420439144_close_6533],
      }
    );
    const evh_5962862420439144_createProduct_6531 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_showModal_6532,
        event: "createProduct",
        displayName: "showModal",
      }
    );
    const evh_5962862420439144_createProduct = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_createProduct_6531],
      }
    );
    const act_5962862420439144_script_6536 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_6536_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_delete_6535 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_6536,
        event: "delete",
        displayName: "script",
      }
    );
    const evh_5962862420439144_delete = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_delete_6535],
      }
    );
    const act_5962862420439144_script_6538 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_6538_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_productSelected_6537 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_6538,
        event: "product:selected",
        displayName: "script",
      }
    );
    const evh_5962862420439144_productSelected = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_productSelected_6537],
      }
    );
    const act_5962862420439144_script_6540 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_6540_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_productChange_6539 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_6540,
        event: "product:change",
        displayName: "script",
      }
    );
    const evh_5962862420439144_productChange = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_productChange_6539],
      }
    );
    const act_5962862420439144_script_6542 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_6542_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_quantityChange_6541 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_6542,
        event: "quantity:change",
        displayName: "script",
      }
    );
    const evh_5962862420439144_quantityChange = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_quantityChange_6541],
      }
    );
    const act_5962862420439144_script_6544 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_6544_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_priceChange_6543 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_6544,
        event: "price:change",
        displayName: "script",
      }
    );
    const evh_5962862420439144_priceChange = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_priceChange_6543],
      }
    );
    const act_5962862420439144_script_6546 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_6546_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_discountChange_6545 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_6546,
        event: "discount:change",
        displayName: "script",
      }
    );
    const evh_5962862420439144_discountChange = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_discountChange_6545],
      }
    );
    const act_5962862420439144_script_6548 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_6548_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_netAmountChange_6547 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_6548,
        event: "net_amount:change",
        displayName: "script",
      }
    );
    const evh_5962862420439144_netAmountChange = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_netAmountChange_6547],
      }
    );
    const act_5962862420439144_script_6550 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_6550_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_amountChange_6549 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_6550,
        event: "amount:change",
        displayName: "script",
      }
    );
    const evh_5962862420439144_amountChange = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_amountChange_6549],
      }
    );
    const act_5962862420439144_script_6552 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_6552_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_taxCodeChange_6551 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_6552,
        event: "tax_code:change",
        displayName: "script",
      }
    );
    const evh_5962862420439144_taxCodeChange = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_taxCodeChange_6551],
      }
    );
    const act_5962862420439144_script_6554 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_6554_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_rentalOrPropertySelected_6553 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_6554,
        event: "rental_or_property:selected",
        displayName: "script",
      }
    );
    const evh_5962862420439144_rentalOrPropertySelected = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_rentalOrPropertySelected_6553],
      }
    );
    const act_5962862420439144_setData_6556 = new actions.SetDataAction(
      {
        actionArgs: this.act_5962862420439144_setData_6556_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_selectedRowsChanged_6555 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_setData_6556,
        event: "selectedRowsChanged",
        displayName: "setData",
      }
    );
    const evh_5962862420439144_selectedRowsChanged = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_selectedRowsChanged_6555],
      }
    );
    const act_7789867021953041_emit_6558 = new actions.EmitAction(
      {
        actionArgs: this.act_7789867021953041_emit_6558_getActionArgs,
        events: [],
      }
    );
    const evh_7789867021953041_reload_6557 = new actions.EventHandlerImpl(
      {
        action: act_7789867021953041_emit_6558,
        event: "reload",
        displayName: "emit",
      }
    );
    const evh_7789867021953041_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7789867021953041_reload_6557],
      }
    );
    const act_309515315410323_emit_6562 = new actions.EmitAction(
      {
        actionArgs: this.act_309515315410323_emit_6562_getActionArgs,
        events: [],
      }
    );
    const evh_309515315410323_close_6561 = new actions.EventHandlerImpl(
      {
        action: act_309515315410323_emit_6562,
        event: "close",
        when: this.evh_309515315410323_close_6561_getWhen,
        displayName: "emit",
      }
    );
    const act_309515315410323_showModal_6560 = new actions.ShowModalAction(
      {
        actionArgs: this.act_309515315410323_showModal_6560_getActionArgs,
        events: [evh_309515315410323_close_6561],
      }
    );
    const evh_309515315410323_clickPreventStop_6559 = new actions.EventHandlerImpl(
      {
        action: act_309515315410323_showModal_6560,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_309515315410323_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_309515315410323_clickPreventStop_6559],
      }
    );
    const act_880971572885910_script_6566 = new actions.ScriptAction(
      {
        actionArgs: this.act_880971572885910_script_6566_getActionArgs,
        events: [],
      }
    );
    const evh_880971572885910_close_6565 = new actions.EventHandlerImpl(
      {
        action: act_880971572885910_script_6566,
        event: "close",
        when: this.evh_880971572885910_close_6565_getWhen,
        displayName: "script",
      }
    );
    const act_880971572885910_showModal_6564 = new actions.ShowModalAction(
      {
        actionArgs: this.act_880971572885910_showModal_6564_getActionArgs,
        events: [evh_880971572885910_close_6565],
      }
    );
    const evh_880971572885910_clickPreventStop_6563 = new actions.EventHandlerImpl(
      {
        action: act_880971572885910_showModal_6564,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_880971572885910_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_880971572885910_clickPreventStop_6563],
      }
    );
    const act_1803307708030275_script_6568 = new actions.ScriptAction(
      {
        actionArgs: this.act_1803307708030275_script_6568_getActionArgs,
        events: [],
      }
    );
    const evh_1803307708030275_save_6567 = new actions.EventHandlerImpl(
      {
        action: act_1803307708030275_script_6568,
        event: "save",
        displayName: "script",
      }
    );
    const evh_1803307708030275_save = new actions.EventHandlerGroup(
      {
        handlers: [evh_1803307708030275_save_6567],
      }
    );
    const act_1803307708030275_emit_6570 = new actions.EmitAction(
      {
        actionArgs: this.act_1803307708030275_emit_6570_getActionArgs,
        events: [],
      }
    );
    const evh_1803307708030275_saveCompleted_6569 = new actions.EventHandlerImpl(
      {
        action: act_1803307708030275_emit_6570,
        event: "save-completed",
        displayName: "emit",
      }
    );
    const evh_1803307708030275_saveCompleted = new actions.EventHandlerGroup(
      {
        handlers: [evh_1803307708030275_saveCompleted_6569],
      }
    );
    const act_1803307708030275_script_6572 = new actions.ScriptAction(
      {
        actionArgs: this.act_1803307708030275_script_6572_getActionArgs,
        events: [],
      }
    );
    const evh_1803307708030275_delete_6571 = new actions.EventHandlerImpl(
      {
        action: act_1803307708030275_script_6572,
        event: "delete",
        displayName: "script",
      }
    );
    const evh_1803307708030275_delete = new actions.EventHandlerGroup(
      {
        handlers: [evh_1803307708030275_delete_6571],
      }
    );
    const act_1803307708030275_emit_6574 = new actions.EmitAction(
      {
        actionArgs: this.act_1803307708030275_emit_6574_getActionArgs,
        events: [],
      }
    );
    const evh_1803307708030275_deleteCompleted_6573 = new actions.EventHandlerImpl(
      {
        action: act_1803307708030275_emit_6574,
        event: "delete-completed",
        displayName: "emit",
      }
    );
    const evh_1803307708030275_deleteCompleted = new actions.EventHandlerGroup(
      {
        handlers: [evh_1803307708030275_deleteCompleted_6573],
      }
    );
    const act_7700024326226090_script_6576 = new actions.ScriptAction(
      {
        actionArgs: this.act_7700024326226090_script_6576_getActionArgs,
        events: [],
      }
    );
    const evh_7700024326226090_save_6575 = new actions.EventHandlerImpl(
      {
        action: act_7700024326226090_script_6576,
        event: "save",
        displayName: "script",
      }
    );
    const evh_7700024326226090_save = new actions.EventHandlerGroup(
      {
        handlers: [evh_7700024326226090_save_6575],
      }
    );
    const act_7700024326226090_script_6578 = new actions.ScriptAction(
      {
        actionArgs: this.act_7700024326226090_script_6578_getActionArgs,
        events: [],
      }
    );
    const evh_7700024326226090_delete_6577 = new actions.EventHandlerImpl(
      {
        action: act_7700024326226090_script_6578,
        event: "delete",
        displayName: "script",
      }
    );
    const evh_7700024326226090_delete = new actions.EventHandlerGroup(
      {
        handlers: [evh_7700024326226090_delete_6577],
      }
    );
    const act_7700024326226090_emit_6580 = new actions.EmitAction(
      {
        actionArgs: this.act_7700024326226090_emit_6580_getActionArgs,
        events: [],
      }
    );
    const evh_7700024326226090_saveCompleted_6579 = new actions.EventHandlerImpl(
      {
        action: act_7700024326226090_emit_6580,
        event: "save-completed",
        displayName: "emit",
      }
    );
    const evh_7700024326226090_saveCompleted = new actions.EventHandlerGroup(
      {
        handlers: [evh_7700024326226090_saveCompleted_6579],
      }
    );
    const act_7700024326226090_emit_6582 = new actions.EmitAction(
      {
        actionArgs: this.act_7700024326226090_emit_6582_getActionArgs,
        events: [],
      }
    );
    const evh_7700024326226090_deleteCompleted_6581 = new actions.EventHandlerImpl(
      {
        action: act_7700024326226090_emit_6582,
        event: "delete-completed",
        displayName: "emit",
      }
    );
    const evh_7700024326226090_deleteCompleted = new actions.EventHandlerGroup(
      {
        handlers: [evh_7700024326226090_deleteCompleted_6581],
      }
    );
    return {
      act_1889980785424776_showModal_6526,
      evh_1889980785424776_clickPreventStop_6525,
      evh_1889980785424776_clickPreventStop,
      act_5962862420439144_emit_6528,
      evh_5962862420439144_saveCompleted_6527,
      evh_5962862420439144_saveCompleted,
      act_5962862420439144_script_6530,
      evh_5962862420439144_save_6529,
      evh_5962862420439144_save,
      act_5962862420439144_script_6534,
      evh_5962862420439144_close_6533,
      act_5962862420439144_showModal_6532,
      evh_5962862420439144_createProduct_6531,
      evh_5962862420439144_createProduct,
      act_5962862420439144_script_6536,
      evh_5962862420439144_delete_6535,
      evh_5962862420439144_delete,
      act_5962862420439144_script_6538,
      evh_5962862420439144_productSelected_6537,
      evh_5962862420439144_productSelected,
      act_5962862420439144_script_6540,
      evh_5962862420439144_productChange_6539,
      evh_5962862420439144_productChange,
      act_5962862420439144_script_6542,
      evh_5962862420439144_quantityChange_6541,
      evh_5962862420439144_quantityChange,
      act_5962862420439144_script_6544,
      evh_5962862420439144_priceChange_6543,
      evh_5962862420439144_priceChange,
      act_5962862420439144_script_6546,
      evh_5962862420439144_discountChange_6545,
      evh_5962862420439144_discountChange,
      act_5962862420439144_script_6548,
      evh_5962862420439144_netAmountChange_6547,
      evh_5962862420439144_netAmountChange,
      act_5962862420439144_script_6550,
      evh_5962862420439144_amountChange_6549,
      evh_5962862420439144_amountChange,
      act_5962862420439144_script_6552,
      evh_5962862420439144_taxCodeChange_6551,
      evh_5962862420439144_taxCodeChange,
      act_5962862420439144_script_6554,
      evh_5962862420439144_rentalOrPropertySelected_6553,
      evh_5962862420439144_rentalOrPropertySelected,
      act_5962862420439144_setData_6556,
      evh_5962862420439144_selectedRowsChanged_6555,
      evh_5962862420439144_selectedRowsChanged,
      act_7789867021953041_emit_6558,
      evh_7789867021953041_reload_6557,
      evh_7789867021953041_reload,
      act_309515315410323_emit_6562,
      evh_309515315410323_close_6561,
      act_309515315410323_showModal_6560,
      evh_309515315410323_clickPreventStop_6559,
      evh_309515315410323_clickPreventStop,
      act_880971572885910_script_6566,
      evh_880971572885910_close_6565,
      act_880971572885910_showModal_6564,
      evh_880971572885910_clickPreventStop_6563,
      evh_880971572885910_clickPreventStop,
      act_1803307708030275_script_6568,
      evh_1803307708030275_save_6567,
      evh_1803307708030275_save,
      act_1803307708030275_emit_6570,
      evh_1803307708030275_saveCompleted_6569,
      evh_1803307708030275_saveCompleted,
      act_1803307708030275_script_6572,
      evh_1803307708030275_delete_6571,
      evh_1803307708030275_delete,
      act_1803307708030275_emit_6574,
      evh_1803307708030275_deleteCompleted_6573,
      evh_1803307708030275_deleteCompleted,
      act_7700024326226090_script_6576,
      evh_7700024326226090_save_6575,
      evh_7700024326226090_save,
      act_7700024326226090_script_6578,
      evh_7700024326226090_delete_6577,
      evh_7700024326226090_delete,
      act_7700024326226090_emit_6580,
      evh_7700024326226090_saveCompleted_6579,
      evh_7700024326226090_saveCompleted,
      act_7700024326226090_emit_6582,
      evh_7700024326226090_deleteCompleted_6581,
      evh_7700024326226090_deleteCompleted,
    }
  }
}

Vue.component("UcInvoiceLines", UcInvoiceLines);

</script>