<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpWidgetCard>
    <ItpBox
      alignItems="center"
      slot="header"
    >
      <strong>
        {{ 'Addresses'|gettext }}
      </strong>
      <ItpButton
        :text="'Add address...'|gettext"
        variant="light"
        size="sm"
        class="ml-auto"
        v-if="!parent.isReadOnly"
        @click.prevent.stop="evh_8320016629450276_clickPreventStop($event, {})"
      >
      </ItpButton>
    </ItpBox>
    <BListGroup>
      <b-list-group-item
        v-if="!items || !items.length"
      >
        <span>
          {{ 'No addresses have been added yet.'|gettext }}
        </span>
      </b-list-group-item>
      <b-list-group-item
        :key="item.id"
        class="d-flex flex-column"
        v-for="item in _.orderBy(items, ['is_default', 'created_at'], ['desc', 'desc'])"
      >
        <ItpBox
          spacing="pb-1"
        >
          <UcAddressCard
            :item="item"
            display-default-flag
          >
          </UcAddressCard>
          <b-dropdown
            size="sm"
            toggle-class="btn-default-borderless"
            no-caret
            class="ml-auto mb-auto"
            v-if="!item.locked"
          >
            <ItpIcon
              fa="ellipsis-v"
              class="mx-1"
              slot="button-content"
            >
            </ItpIcon>
            <b-dropdown-item-button
              @click.prevent.stop="evh_294572930540096_clickPreventStop($event, {item})"
            >
              <ItpText
                :text="'Edit'|pgettext('Command')"
              >
              </ItpText>
            </b-dropdown-item-button>
            <b-dropdown-item-button
              v-if="!item.locked"
              @click.prevent.stop="evh_3365375699802249_clickPreventStop($event, {item})"
            >
              <ItpText
                :text="'Delete'|pgettext('Command')"
              >
              </ItpText>
            </b-dropdown-item-button>
          </b-dropdown>
        </ItpBox>
        <DIV
          class="d-flex align-items-end"
        >
          <ItpText
            muted
            small
          >
            {{ (item.created_at || item.created_at)|datetime }}
          </ItpText>
          <small
            class="text-muted text-nowrap mx-2"
          >
            {{ (item.updated_by || item.created_by)|username }}
          </small>
          <DIV
            class="ml-2 d-flex align-self-end flex-grow-1 justify-content-end"
          >
            <ItpIcon
              :title="'Locked'|gettext"
              name="fa-lock"
              class="text-muted"
              v-if="item.locked"
            >
            </ItpIcon>
          </DIV>
        </DIV>
      </b-list-group-item>
    </BListGroup>
  </ItpWidgetCard>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcAddressList extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  parent!: object;

  @Watch('parent')
  onParent(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("parent")
  }

  container!: any;

  async $$load_container() {
    return this.parent.addresses
  }

  items!: any;

  async $$load_items() {
    return this.container.data
  }

  ux!: any;
  dataMembers = ['container', 'items', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        container: null,
        items: null,
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_8320016629450276_emit_1350_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_8320016629450276_emit_1350, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_8320016629450276_close_1349_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_8320016629450276_close_1349, alias=undefined
    return $event.data.modalResult && $event.data.modalResult.ok
  }

  async act_8320016629450276_showModal_1348_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8320016629450276_showModal_1348, alias=create
    return {
      name: "Address",
      props: {
        parent: this.parent,
      }
      ,
    }
  }

  async evh_8320016629450276_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_294572930540096_emit_1354_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_294572930540096_emit_1354, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_294572930540096_close_1353_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_294572930540096_close_1353, alias=undefined
    return $event.data.modalResult && $event.data.modalResult.ok
  }

  async act_294572930540096_showModal_1352_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_294572930540096_showModal_1352, alias=undefined
    return {
      name: "Address",
      props: {
        parent: this.parent,
        item: $event.scope.item,
      }
      ,
    }
  }

  async evh_294572930540096_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_294572930540096_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_3365375699802249_rest_1356_getActionArgs($event: actions.ActionEvent): Promise<actions.RestActionArgs> {
    // parameterResolver name=act_3365375699802249_rest_1356, alias=undefined
    return {
      method: "delete",
      container: this.container,
      resource: $event.scope.item,
    }
  }

  async act_3365375699802249_rest_1356_getConfirm($event: actions.ActionEvent): Promise<actions.RestActionArgs> {
    // parameterResolver name=act_3365375699802249_rest_1356, alias=undefined
    return {
      message: this.$fn.gettext("Please confirm delete."),
      btnOk: this.$fn.gettext("Delete"),
    }
  }

  async evh_3365375699802249_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3365375699802249_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_1346: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_1345: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_8320016629450276_emit_1350: actions.EmitAction;
    evh_8320016629450276_close_1349: actions.EventHandlerImpl;
    act_8320016629450276_showModal_1348: actions.ShowModalAction;
    evh_8320016629450276_clickPreventStop_1347: actions.EventHandlerImpl;
    evh_8320016629450276_clickPreventStop: actions.EventHandlerGroup;
    act_294572930540096_emit_1354: actions.EmitAction;
    evh_294572930540096_close_1353: actions.EventHandlerImpl;
    act_294572930540096_showModal_1352: actions.ShowModalAction;
    evh_294572930540096_clickPreventStop_1351: actions.EventHandlerImpl;
    evh_294572930540096_clickPreventStop: actions.EventHandlerGroup;
    act_3365375699802249_rest_1356: actions.RestAction;
    evh_3365375699802249_clickPreventStop_1355: actions.EventHandlerImpl;
    evh_3365375699802249_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_1346 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_1345 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_1346,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_1345],
      }
    );
    const act_8320016629450276_emit_1350 = new actions.EmitAction(
      {
        actionArgs: this.act_8320016629450276_emit_1350_getActionArgs,
        events: [],
      }
    );
    const evh_8320016629450276_close_1349 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_emit_1350,
        event: "close",
        when: this.evh_8320016629450276_close_1349_getWhen,
        displayName: "emit",
      }
    );
    const act_8320016629450276_showModal_1348 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8320016629450276_showModal_1348_getActionArgs,
        displayName: "create",
        events: [evh_8320016629450276_close_1349],
      }
    );
    const evh_8320016629450276_clickPreventStop_1347 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_showModal_1348,
        event: "click.prevent.stop",
        displayName: "create",
      }
    );
    const evh_8320016629450276_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_clickPreventStop_1347],
      }
    );
    const act_294572930540096_emit_1354 = new actions.EmitAction(
      {
        actionArgs: this.act_294572930540096_emit_1354_getActionArgs,
        events: [],
      }
    );
    const evh_294572930540096_close_1353 = new actions.EventHandlerImpl(
      {
        action: act_294572930540096_emit_1354,
        event: "close",
        when: this.evh_294572930540096_close_1353_getWhen,
        displayName: "emit",
      }
    );
    const act_294572930540096_showModal_1352 = new actions.ShowModalAction(
      {
        actionArgs: this.act_294572930540096_showModal_1352_getActionArgs,
        events: [evh_294572930540096_close_1353],
      }
    );
    const evh_294572930540096_clickPreventStop_1351 = new actions.EventHandlerImpl(
      {
        action: act_294572930540096_showModal_1352,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_294572930540096_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_294572930540096_clickPreventStop_1351],
      }
    );
    const act_3365375699802249_rest_1356 = new actions.RestAction(
      {
        actionArgs: this.act_3365375699802249_rest_1356_getActionArgs,
        confirm: this.act_3365375699802249_rest_1356_getConfirm,
        events: [],
      }
    );
    const evh_3365375699802249_clickPreventStop_1355 = new actions.EventHandlerImpl(
      {
        action: act_3365375699802249_rest_1356,
        event: "click.prevent.stop",
        displayName: "rest",
      }
    );
    const evh_3365375699802249_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_3365375699802249_clickPreventStop_1355],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_1346,
      evh_7315092382398562_reload_1345,
      evh_7315092382398562_reload,
      act_8320016629450276_emit_1350,
      evh_8320016629450276_close_1349,
      act_8320016629450276_showModal_1348,
      evh_8320016629450276_clickPreventStop_1347,
      evh_8320016629450276_clickPreventStop,
      act_294572930540096_emit_1354,
      evh_294572930540096_close_1353,
      act_294572930540096_showModal_1352,
      evh_294572930540096_clickPreventStop_1351,
      evh_294572930540096_clickPreventStop,
      act_3365375699802249_rest_1356,
      evh_3365375699802249_clickPreventStop_1355,
      evh_3365375699802249_clickPreventStop,
      create: act_8320016629450276_showModal_1348,
    }
  }
}

Vue.component("UcAddressList", UcAddressList);

</script>