"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
(0, core_1.addRoute)({
    name: "overlappingRevisions",
    path: "/overlapping-revisions",
    component: "ContractViewsOverlappingRevisions"
});
let ContractViewsOverlappingRevisions = class ContractViewsOverlappingRevisions extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns'];
    }
    beforeCreate() {
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "sm",
            },
            b_2248226175642056_columnDefaults: {
                sortable: true,
                filterable: true,
                resizable: true,
            },
            b_2248226175642056_columns: [{
                    field: "contract_a",
                    name: this.$fn.pgettext("ContractViewsOverlappingRevisions", "Contract 'A'"),
                    type: "command",
                    formatter: {
                        name: "routeLink",
                        to: "contract",
                        params: {
                            id: "contract_a_id",
                        },
                    },
                },
                {
                    field: "a_status",
                    name: this.$fn.pgettext("ContractViewsOverlappingRevisions", "'A' Status"),
                    formatter: {
                        name: "chain",
                        mode: "mapReduce",
                        formatters: [{
                                name: "gettext",
                                context: "ContractStatus",
                                conversion: "StartCase",
                                filterInput: true,
                            },
                            {
                                name: "badge",
                                variantMapSource: "fieldValue",
                                variantmap: {
                                    draft: "light",
                                    approved: "success",
                                    signed: "success",
                                    active: "primary",
                                    ended: "warning",
                                    terminated: "warning",
                                    rescinded: "danger",
                                    archived: "light",
                                },
                            },
                        ],
                    },
                },
                {
                    field: "client_a",
                    name: this.$fn.pgettext("ContractViewsOverlappingRevisions", "Client 'A'"),
                    type: "command",
                    formatter: {
                        name: "routeLink",
                        to: "client",
                        params: {
                            id: "client_a_id",
                        },
                    },
                },
                {
                    field: "a_valid_from",
                    name: this.$fn.pgettext("ContractViewsOverlappingRevisions", "'A' Valid From"),
                    formatter: {
                        name: "date",
                    },
                },
                {
                    field: "a_valid_to",
                    name: this.$fn.pgettext("ContractViewsOverlappingRevisions", "'A' Valid To"),
                    formatter: {
                        name: "date",
                    },
                },
                {
                    field: "contract_b",
                    name: this.$fn.pgettext("ContractViewsOverlappingRevisions", "Contract 'B'"),
                    type: "command",
                    formatter: {
                        name: "routeLink",
                        to: "contract",
                        params: {
                            id: "contract_b_id",
                        },
                    },
                },
                {
                    field: "b_status",
                    name: this.$fn.pgettext("ContractViewsOverlappingRevisions", "'B' Status"),
                    formatter: {
                        name: "chain",
                        mode: "mapReduce",
                        formatters: [{
                                name: "gettext",
                                context: "ContractStatus",
                                conversion: "StartCase",
                                filterInput: true,
                            },
                            {
                                name: "badge",
                                variantMapSource: "fieldValue",
                                variantmap: {
                                    draft: "light",
                                    approved: "success",
                                    signed: "success",
                                    active: "primary",
                                    ended: "warning",
                                    terminated: "warning",
                                    rescinded: "danger",
                                    archived: "light",
                                },
                            },
                        ],
                    },
                },
                {
                    field: "client_b",
                    name: this.$fn.pgettext("ContractViewsOverlappingRevisions", "Client 'B'"),
                    type: "command",
                    formatter: {
                        name: "routeLink",
                        to: "client",
                        params: {
                            id: "client_b_id",
                        },
                    },
                },
                {
                    field: "b_valid_from",
                    name: this.$fn.pgettext("ContractViewsOverlappingRevisions", "'B' Valid From"),
                    formatter: {
                        name: "date",
                    },
                },
                {
                    field: "b_valid_to",
                    name: this.$fn.pgettext("ContractViewsOverlappingRevisions", "'B' Valid To"),
                    formatter: {
                        name: "date",
                    },
                },
                {
                    field: "rental",
                    name: this.$fn.pgettext("ContractViewsOverlappingRevisions", "Rental"),
                    type: "command",
                    formatter: {
                        name: "routeLink",
                        to: "rental",
                        params: {
                            id: "rental_id",
                        },
                    },
                },
            ],
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.pgettext("Contracts", "Overlapping Revisions"),
                icon: this.$config.contract.views.overlappingRevisions.icon,
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_4996_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_4996 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_4996_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_4995 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_4996,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_4995],
        });
        return {
            act_7315092382398562_closeModal_4996,
            evh_7315092382398562_close_4995,
            evh_7315092382398562_close,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], ContractViewsOverlappingRevisions.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], ContractViewsOverlappingRevisions.prototype, "onSize", null);
ContractViewsOverlappingRevisions = __decorate([
    (0, vue_property_decorator_1.Component)()
], ContractViewsOverlappingRevisions);
exports.default = ContractViewsOverlappingRevisions;
vue_property_decorator_1.Vue.component("ContractViewsOverlappingRevisions", ContractViewsOverlappingRevisions);
