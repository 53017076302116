<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="WorksheetList"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <VueSlickgrid
    name="worksheets"
    :headerTitle="'Worksheets'|gettext"
    :items="$fn.slickDataSource('integro_api/list_worksheet')"
    checkbox-row-selection
    :columnDefaults="b_2248226175642056_columnDefaults"
    :columns="b_2248226175642056_columns"
    class="page--grid"
    ref="grid"
    @selectedRowsChanged="evh_2248226175642056_selectedRowsChanged($event, {})"
  >
    <DIV
      class="flex-grow-1 d-flex"
      slot="toolbar"
    >
      <ItpButton
        :icon="$config.worksheet.createWorksheetIcon"
        :text="'New worksheet...'|gettext"
        size="sm"
        variant="light"
        class="mx-2"
        @click="evh_6466705138206980_click($event, {})"
      >
      </ItpButton>
      <ItpButton
        icon="fa-trash"
        :text="'Delete'|gettext"
        size="sm"
        variant="light"
        :disabled="!selectedItems || !selectedItems.length"
        :spinning="actions.deleteItems.isRunning"
        class="mx-2"
        @click="evh_8320016629450276_click($event, {})"
      >
      </ItpButton>
      <PageHelp
        path="/worksheets/worksheets.html"
      >
      </PageHelp>
    </DIV>
  </VueSlickgrid>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "worksheets",
    path: "/worksheets",
    component: "WorksheetList"
  }
)

@Component()
export default class WorksheetList extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  selectedItems!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_2248226175642056_columnDefaults!: any;
  b_2248226175642056_columns!: any;
  dataMembers = ['selectedItems', 'ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        selectedItems: [
        ]
        ,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
        b_2248226175642056_columnDefaults: {
          sortable: true,
          filterable: true,
          resizable: true,
        }
        ,
        b_2248226175642056_columns: [{
          field: "number",
          name: this.$fn.pgettext("Worksheets", "Number"),
          type: "command",
          width: "200px",
          formatter: {
            name: "routeLink",
            to: "worksheet",
            params: {
              id: "id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "status",
          name: this.$fn.pgettext("Worksheets", "Status"),
          width: "150px",
          formatter: {
            name: "chain",
            mode: "mapReduce",
            formatters: [{
              name: "gettext",
              context: "WorksheetStatus",
              conversion: "StartCase",
              filterInput: true,
            }
              ,
            {
              name: "statusDot",
            }
              ,
            ]
            ,
          }
          ,
        }
          ,
        {
          field: "worksheet_date",
          name: this.$fn.pgettext("Worksheets", "Date"),
          type: "date",
        }
          ,
        {
          field: "description",
          width: "200px",
          name: this.$fn.pgettext("Worksheets", "Description"),
        }
          ,
        {
          field: "rental_name",
          name: this.$fn.pgettext("Worksheets", "Rental"),
          type: "command",
          formatter: {
            name: "routeLink",
            to: "rental",
            navigateOnIconClick: true,
            params: {
              id: "rental_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "company_name",
          name: this.$fn.pgettext("Worksheets", "Company"),
          formatter: {
            name: "routeLink",
            to: "company",
            navigateOnIconClick: true,
            params: {
              id: "company_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "client_name",
          width: "200px",
          name: this.$fn.pgettext("Worksheets", "Client"),
          formatter: {
            name: "routeLink",
            to: "client",
            navigateOnIconClick: true,
            params: {
              id: "client_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "contractor_name",
          width: "200px",
          name: this.$fn.pgettext("Worksheets", "Contractor"),
          formatter: {
            name: "routeLink",
            to: "client",
            navigateOnIconClick: true,
            params: {
              id: "contractor_id",
            }
            ,
          }
          ,
        }
          ,
        ]
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.gettext("Worksheets"),
        icon: this.$config.worksheet.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_4266_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_4266, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_setData_4268_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2248226175642056_setData_4268, alias=undefined
    return {
      path: "selectedItems",
      value: this.$refs.grid.getSelectedItems(),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_2248226175642056_selectedRowsChanged(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope);
  }

  async act_6466705138206980_showModal_4270_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_6466705138206980_showModal_4270, alias=undefined
    return {
      name: "Worksheet",
      props: {
        size: "lg",
      }
      ,
    }
  }

  async evh_6466705138206980_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6466705138206980_click.executeFromDOM(this, event, scope);
  }

  async act_8320016629450276_reloadSlickgrid_4274_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_8320016629450276_reloadSlickgrid_4274, alias=undefined
    return {
      grid: "worksheets",
    }
  }

  async act_8320016629450276_request_4272_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_8320016629450276_request_4272, alias=deleteItems
    return {
      operation: "delete_worksheets",
      data: { worksheets: this.selectedItems.map(p => p.id) },
    }
  }

  async evh_8320016629450276_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_click.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_4266: actions.CloseModalAction;
    evh_7315092382398562_close_4265: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_setData_4268: actions.SetDataAction;
    evh_2248226175642056_selectedRowsChanged_4267: actions.EventHandlerImpl;
    evh_2248226175642056_selectedRowsChanged: actions.EventHandlerGroup;
    act_6466705138206980_showModal_4270: actions.ShowModalAction;
    evh_6466705138206980_click_4269: actions.EventHandlerImpl;
    evh_6466705138206980_click: actions.EventHandlerGroup;
    act_8320016629450276_reloadSlickgrid_4274: actions.ReloadSlickgridAction;
    evh_8320016629450276_success_4273: actions.EventHandlerImpl;
    act_8320016629450276_request_4272: actions.RequestAction;
    evh_8320016629450276_click_4271: actions.EventHandlerImpl;
    evh_8320016629450276_click: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_4266 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_4266_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_4265 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_4266,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_4265],
      }
    );
    const act_2248226175642056_setData_4268 = new actions.SetDataAction(
      {
        actionArgs: this.act_2248226175642056_setData_4268_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_selectedRowsChanged_4267 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_setData_4268,
        event: "selectedRowsChanged",
        displayName: "setData",
      }
    );
    const evh_2248226175642056_selectedRowsChanged = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_selectedRowsChanged_4267],
      }
    );
    const act_6466705138206980_showModal_4270 = new actions.ShowModalAction(
      {
        actionArgs: this.act_6466705138206980_showModal_4270_getActionArgs,
        events: [],
      }
    );
    const evh_6466705138206980_click_4269 = new actions.EventHandlerImpl(
      {
        action: act_6466705138206980_showModal_4270,
        event: "click",
        displayName: "showModal",
      }
    );
    const evh_6466705138206980_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_6466705138206980_click_4269],
      }
    );
    const act_8320016629450276_reloadSlickgrid_4274 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_8320016629450276_reloadSlickgrid_4274_getActionArgs,
        events: [],
      }
    );
    const evh_8320016629450276_success_4273 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_reloadSlickgrid_4274,
        event: "success",
        displayName: "reloadSlickgrid",
      }
    );
    const act_8320016629450276_request_4272 = new actions.RequestAction(
      {
        actionArgs: this.act_8320016629450276_request_4272_getActionArgs,
        displayName: "deleteItems",
        events: [evh_8320016629450276_success_4273],
      }
    );
    const evh_8320016629450276_click_4271 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_request_4272,
        event: "click",
        displayName: "deleteItems",
      }
    );
    const evh_8320016629450276_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_click_4271],
      }
    );
    return {
      act_7315092382398562_closeModal_4266,
      evh_7315092382398562_close_4265,
      evh_7315092382398562_close,
      act_2248226175642056_setData_4268,
      evh_2248226175642056_selectedRowsChanged_4267,
      evh_2248226175642056_selectedRowsChanged,
      act_6466705138206980_showModal_4270,
      evh_6466705138206980_click_4269,
      evh_6466705138206980_click,
      act_8320016629450276_reloadSlickgrid_4274,
      evh_8320016629450276_success_4273,
      act_8320016629450276_request_4272,
      evh_8320016629450276_click_4271,
      evh_8320016629450276_click,
      deleteItems: act_8320016629450276_request_4272,
    }
  }
}

Vue.component("WorksheetList", WorksheetList);

</script>