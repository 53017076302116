"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let TextTemplates = class TextTemplates extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['items', 'selectedItem', 'createNewItem', 'ux', 'b_7315092382398562_modalBindings'];
    }
    beforeCreate() {
    }
    onItemSaved(...args) {
        const [data] = args;
        const index = _.indexOf(this.items, { id: data.id });
        if (index > -1) {
            this.items[index] = data;
        }
        else {
            this.items.push(data);
        }
        this.selectedItem = data;
        this.createNewItem = false;
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_items() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_object_by_type', { parameters: { object_type: 'text-templates' } });
        });
    }
    $$load_selectedItem() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.items.length ? this.items[0] : null;
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            items: null,
            selectedItem: null,
            createNewItem: false,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "lg",
            },
        });
    }
    get document() {
        if (this.loaded) {
            return;
        }
        return null;
    }
    act_7315092382398562_closeModal_2944_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_3054425420812800_setData_2948_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "createNewItem",
                value: true,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_3054425420812800_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_3054425420812800_click.executeFromDOM(this, event, scope);
        });
    }
    act_3083451129821185_script_2950_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.onItemSaved($event.data);
        });
    }
    act_3083451129821185_script_2950_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_3083451129821185_script_2950_getActionArgs_value($event),
            };
        });
    }
    evh_3083451129821185_saved(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_3083451129821185_saved.executeFromDOM(this, event, scope);
        });
    }
    evh_1548630417156826_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1548630417156826_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_2944 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_2944_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_2943 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_2944,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_2943],
        });
        const act_2248226175642056_closeComponent_2946 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2248226175642056_close_2945 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_2946,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_2945],
        });
        const act_3054425420812800_setData_2948 = new core_1.actions.SetDataAction({
            actionArgs: this.act_3054425420812800_setData_2948_getActionArgs,
            displayName: "addNewTextTemplate",
            events: [],
        });
        const evh_3054425420812800_click_2947 = new core_1.actions.EventHandlerImpl({
            action: act_3054425420812800_setData_2948,
            event: "click",
            displayName: "addNewTextTemplate",
        });
        const evh_3054425420812800_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3054425420812800_click_2947],
        });
        const act_3083451129821185_script_2950 = new core_1.actions.ScriptAction({
            actionArgs: this.act_3083451129821185_script_2950_getActionArgs,
            events: [],
        });
        const evh_3083451129821185_saved_2949 = new core_1.actions.EventHandlerImpl({
            action: act_3083451129821185_script_2950,
            event: "saved",
            displayName: "script",
        });
        const evh_3083451129821185_saved = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3083451129821185_saved_2949],
        });
        const act_1548630417156826_closeComponent_2952 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_1548630417156826_clickPreventStop_2951 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_closeComponent_2952,
            event: "click.prevent.stop",
            displayName: "closeComponent",
        });
        const evh_1548630417156826_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1548630417156826_clickPreventStop_2951],
        });
        return {
            act_7315092382398562_closeModal_2944,
            evh_7315092382398562_close_2943,
            evh_7315092382398562_close,
            act_2248226175642056_closeComponent_2946,
            evh_2248226175642056_close_2945,
            evh_2248226175642056_close,
            act_3054425420812800_setData_2948,
            evh_3054425420812800_click_2947,
            evh_3054425420812800_click,
            act_3083451129821185_script_2950,
            evh_3083451129821185_saved_2949,
            evh_3083451129821185_saved,
            act_1548630417156826_closeComponent_2952,
            evh_1548630417156826_clickPreventStop_2951,
            evh_1548630417156826_clickPreventStop,
            addNewTextTemplate: act_3054425420812800_setData_2948,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "lg",
    }),
    __metadata("design:type", String)
], TextTemplates.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], TextTemplates.prototype, "onSize", null);
TextTemplates = __decorate([
    (0, vue_property_decorator_1.Component)()
], TextTemplates);
exports.default = TextTemplates;
vue_property_decorator_1.Vue.component("TextTemplates", TextTemplates);
