<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpFormGroup
    name="name"
    :label="'Name'|pgettext('Contact')"
    labelColsMd="4"
    contentColsMd="8"
    :labelAlign="labelAlign"
    required
  >
    <ItpFormInput
      name="name"
      v-model="contact.name"
      required
      autofocus
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    name="job_title"
    :label="'Job Title'|pgettext('Contact')"
    labelColsMd="4"
    contentColsMd="8"
    :labelAlign="labelAlign"
  >
    <ItpFormInput
      name="job_title"
      v-model="contact.job_title"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    name="phone"
    :label="'Phone Number'|pgettext('Contact')"
    labelColsMd="4"
    contentColsMd="8"
    :labelAlign="labelAlign"
  >
    <ItpFormInput
      name="phone"
      v-model="contact.phone"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    name="mobile"
    :label="'Mobile Number'|pgettext('Contact')"
    labelColsMd="4"
    contentColsMd="8"
    :labelAlign="labelAlign"
  >
    <ItpFormInput
      name="mobile"
      v-model="contact.mobile"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    name="email"
    :label="'Email'|pgettext('Contact')"
    labelColsMd="4"
    contentColsMd="8"
    :labelAlign="labelAlign"
  >
    <ItpFormInput
      name="email"
      v-model="contact.email"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    name="fax"
    :label="'Fax'|pgettext('Contact')"
    labelColsMd="4"
    contentColsMd="8"
    :labelAlign="labelAlign"
  >
    <ItpFormInput
      name="fax"
      v-model="contact.fax"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    name="notes"
    :label="'Notes'|pgettext('Contact')"
    labelColsMd="4"
    contentColsMd="8"
    :labelAlign="labelAlign"
  >
    <ItpFormTextarea
      name="notes"
      v-model="contact.notes"
    >
    </ItpFormTextarea>
  </ItpFormGroup>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcContactFieldset extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  contact!: object;

  @Watch('contact')
  onContact(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("contact")
  }

  @Prop({
    type: String,
    default: "right",
  })
  labelAlign!: string;

  @Watch('labelAlign')
  onLabel_align(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("labelAlign")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_1988: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_1987: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_1988 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_1987 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_1988,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_1987],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_1988,
      evh_7315092382398562_reload_1987,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("UcContactFieldset", UcContactFieldset);

</script>