"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
(0, core_1.addRoute)({
    name: "settings.registers",
    path: "registers",
    component: "Registers"
});
let Registers = class Registers extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['items', 'ux', 'b_7315092382398562_modalBindings', 'b_8320016629450276_editable', 'b_8320016629450276_columns'];
    }
    beforeCreate() {
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_items() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_register');
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            items: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "sm",
            },
            b_8320016629450276_editable: false,
            b_8320016629450276_columns: [{
                    name: "name",
                    header: this.$fn.pgettext("Register", "Name"),
                    type: "link",
                    width: "120px",
                },
                {
                    name: "register_type",
                    header: this.$fn.pgettext("Register", "Type"),
                    width: "120px",
                },
            ],
        });
    }
    get document() {
        if (this.loaded) {
            return;
        }
        return null;
    }
    act_7315092382398562_closeModal_3798_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    act_4615685607825208_showModal_3800_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Register",
            };
        });
    }
    evh_4615685607825208_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_4615685607825208_click.executeFromDOM(this, event, scope);
        });
    }
    evh_2095089031469157_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2095089031469157_click.executeFromDOM(this, event, scope);
        });
    }
    act_8320016629450276_showModal_3804_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Register",
                props: {
                    oid: $event.data.row.id,
                },
            };
        });
    }
    evh_8320016629450276_click_3803_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.col.name === 'name';
        });
    }
    evh_8320016629450276_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8320016629450276_click.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_3798 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_3798_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_3797 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_3798,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_3797],
        });
        const act_4615685607825208_showModal_3800 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_4615685607825208_showModal_3800_getActionArgs,
            events: [],
        });
        const evh_4615685607825208_click_3799 = new core_1.actions.EventHandlerImpl({
            action: act_4615685607825208_showModal_3800,
            event: "click",
            displayName: "showModal",
        });
        const evh_4615685607825208_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4615685607825208_click_3799],
        });
        const act_2095089031469157_reloadComponentData_3802 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_2095089031469157_click_3801 = new core_1.actions.EventHandlerImpl({
            action: act_2095089031469157_reloadComponentData_3802,
            event: "click",
            displayName: "reloadComponentData",
        });
        const evh_2095089031469157_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2095089031469157_click_3801],
        });
        const act_8320016629450276_showModal_3804 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_8320016629450276_showModal_3804_getActionArgs,
            events: [],
        });
        const evh_8320016629450276_click_3803 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_showModal_3804,
            event: "click",
            when: this.evh_8320016629450276_click_3803_getWhen,
            displayName: "showModal",
        });
        const evh_8320016629450276_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8320016629450276_click_3803],
        });
        return {
            act_7315092382398562_closeModal_3798,
            evh_7315092382398562_close_3797,
            evh_7315092382398562_close,
            act_4615685607825208_showModal_3800,
            evh_4615685607825208_click_3799,
            evh_4615685607825208_click,
            act_2095089031469157_reloadComponentData_3802,
            evh_2095089031469157_click_3801,
            evh_2095089031469157_click,
            act_8320016629450276_showModal_3804,
            evh_8320016629450276_click_3803,
            evh_8320016629450276_click,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], Registers.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Registers.prototype, "onSize", null);
Registers = __decorate([
    (0, vue_property_decorator_1.Component)()
], Registers);
exports.default = Registers;
vue_property_decorator_1.Vue.component("Registers", Registers);
