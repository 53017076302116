"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let FieldsetInvoiceGeneration = class FieldsetInvoiceGeneration extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['currentGeneratorTask', 'labelAlign', 'item', 'formId', 'productInvoiceGroups', 'includeWorksheets', 'invoiceGroupFilters', 'operations', 'taskStatusDismissed', 'ux'];
    }
    beforeCreate() {
        this.$$cache_item = new core_1.ComponentValueCache(this, "item", "FieldsetInvoiceGeneration.formData");
        this.$$cache_includeWorksheets = new core_1.ComponentValueCache(this, "includeWorksheets", "FieldsetInvoiceGeneration.includeWorksheets");
        this.$$cache_invoiceGroupFilters = new core_1.ComponentValueCache(this, "invoiceGroupFilters", "FieldsetInvoiceGeneration.invoiceGroupFilters");
        this.$$cache_taskStatusDismissed = new core_1.ComponentValueCache(this, "taskStatusDismissed", "FieldsetInvoiceGeneration.taskStatusDismissed");
    }
    getPayload(...args) {
        {
            {
                return {
                    contracts: this.contract ? [this.contract] : null,
                    product_invoice_groups: _.reduce(this.invoiceGroupFilters, (arr, v, k) => {
                        if (!!v) {
                            arr.push(k);
                        }
                        return arr;
                    }, []),
                    exclude_product_invoice_groups: _.reduce(this.invoiceGroupFilters, (arr, v, k) => {
                        if (!v) {
                            arr.push(k);
                        }
                        return arr;
                    }, []),
                    skip_worksheets: !this.includeWorksheets
                };
            }
        }
    }
    updateStatus(...args) {
        _.extend(this.currentGeneratorTask, args[0]);
    }
    onDescription_required(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("descriptionRequired");
    }
    onContract(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("contract");
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                description: 'test',
                fulfillment_date: this.$fn.today(),
                due_date: null,
                billing_period_start: this.$fn.today(),
                billing_period_end: null,
                exchange_rates: [],
                service_type_flat_rate: true,
                service_type_measured: true,
                ignore_meter_billing_thresholds: false,
                meter_readings_start_date: null,
                invoice_tags: []
            };
        });
    }
    $$load_formId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    $$load_productInvoiceGroups() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_product_invoice_group');
        });
    }
    $$load_invoiceGroupFilters() {
        return __awaiter(this, void 0, void 0, function* () {
            return _.chain(this.productInvoiceGroups)
                .reduce((m, p) => (m[p.id] = true, m), {})
                .value();
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            currentGeneratorTask: null,
            labelAlign: null,
            item: null,
            formId: null,
            productInvoiceGroups: null,
            includeWorksheets: true,
            invoiceGroupFilters: null,
            operations: [{
                    value: "include",
                    text: this.$fn.pgettext("InvoiceGenerator", "Include"),
                },
                {
                    value: "exclude",
                    text: this.$fn.pgettext("InvoiceGenerator", "Exclude"),
                },
            ],
            taskStatusDismissed: false,
            ux: null,
        });
    }
    get canSubmit() {
        if (this.loaded) {
            return this.forms.InvoiceGeneration &&
                this.forms.InvoiceGeneration.submittable &&
                (!this.currentGeneratorTask ||
                    ['PENDING', 'STARTED'].indexOf(this.currentGeneratorTask.status) == -1);
        }
        return null;
    }
    evh_7315092382398562_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
        });
    }
    act_2248226175642056_setData_5058_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "taskStatusDismissed",
                value: false,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_2248226175642056_setData_5060_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "currentGeneratorTask",
                value: $event.data,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_2248226175642056_setData_5060_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data;
        });
    }
    act_2248226175642056_crud_5056_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                objectType: "a_job",
                op: "upsert",
                data: Object.assign(Object.assign(Object.assign({}, this.item), { job_type: 'invoice_generation' }), this.getPayload()),
            };
        });
    }
    evh_2248226175642056_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_submit.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_reloadComponentData_5054 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7315092382398562_reload_5053 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_5054,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_5053],
        });
        const act_2248226175642056_setData_5058 = new core_1.actions.SetDataAction({
            actionArgs: this.act_2248226175642056_setData_5058_getActionArgs,
            events: [],
        });
        const evh_2248226175642056_before_5057 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_setData_5058,
            event: "before",
            displayName: "setData",
        });
        const act_2248226175642056_setData_5060 = new core_1.actions.SetDataAction({
            actionArgs: this.act_2248226175642056_setData_5060_getActionArgs,
            when: this.act_2248226175642056_setData_5060_getWhen,
            events: [],
        });
        const evh_2248226175642056_success_5059 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_setData_5060,
            event: "success",
            displayName: "setData",
        });
        const act_2248226175642056_crud_5056 = new core_1.actions.CRUDAction({
            actionArgs: this.act_2248226175642056_crud_5056_getActionArgs,
            displayName: "submit",
            events: [evh_2248226175642056_before_5057, evh_2248226175642056_success_5059],
        });
        const evh_2248226175642056_submit_5055 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_crud_5056,
            event: "submit",
            displayName: "submit",
        });
        const evh_2248226175642056_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_submit_5055],
        });
        return {
            act_7315092382398562_reloadComponentData_5054,
            evh_7315092382398562_reload_5053,
            evh_7315092382398562_reload,
            act_2248226175642056_setData_5058,
            evh_2248226175642056_before_5057,
            act_2248226175642056_setData_5060,
            evh_2248226175642056_success_5059,
            act_2248226175642056_crud_5056,
            evh_2248226175642056_submit_5055,
            evh_2248226175642056_submit,
            submit: act_2248226175642056_crud_5056,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
    }),
    __metadata("design:type", Boolean)
], FieldsetInvoiceGeneration.prototype, "descriptionRequired", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('descriptionRequired'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], FieldsetInvoiceGeneration.prototype, "onDescription_required", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], FieldsetInvoiceGeneration.prototype, "contract", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('contract'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], FieldsetInvoiceGeneration.prototype, "onContract", null);
FieldsetInvoiceGeneration = __decorate([
    (0, vue_property_decorator_1.Component)()
], FieldsetInvoiceGeneration);
exports.default = FieldsetInvoiceGeneration;
vue_property_decorator_1.Vue.component("FieldsetInvoiceGeneration", FieldsetInvoiceGeneration);
