"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
(0, core_1.addRoute)({
    name: "autoReadings",
    path: "/auto-readings",
    component: "SensorDataBrowser"
});
let SensorDataBrowser = class SensorDataBrowser extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['selectedItems', 'timeTo', 'ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns'];
    }
    beforeCreate() {
        this.$$cache_timeTo = new core_1.ComponentValueCache(this, "timeTo", "SensorDataBrowser.timeTo");
    }
    getQueryParameters(...args) {
        return { time: this.timeTo };
    }
    onCreateMeterReadingsPayload(...args) {
        return this.$imodal
            .show({ name: 'CreateMeterReadingsParameters' })
            .then(result => {
            if (!result || result.cancel || !result.value) {
                return;
            }
            return Object.assign(Object.assign({}, result.value), { readings: this.selectedItems
                    .filter(p => !!p.meter_id)
                    .map(p => ({
                    meter_id: p.meter_id,
                    serial: p.meter_serial,
                    value: p.value,
                    unit: p.unit,
                    reading_type: 'automated',
                    reading_date: this.$fn.formatDate(this.$fn.parseISODate(p.timestamp), 'yyyy-MM-dd'),
                    reading_time: this.$fn.formatDate(this.$fn.parseISODate(p.timestamp), 'hh:mm'),
                })) });
        });
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_timeTo() {
        return __awaiter(this, void 0, void 0, function* () {
            return new Date();
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            selectedItems: [],
            timeTo: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "sm",
            },
            b_2248226175642056_columnDefaults: {
                sortable: true,
                filterable: true,
                resizable: true,
            },
            b_2248226175642056_columns: [{
                    field: "meter_type",
                    name: this.$fn.pgettext("MeterSensorDataBrowser", "Meter Type"),
                    formatter: {
                        name: "chain",
                        mode: "mapReduce",
                        formatters: [{
                                name: "gettext",
                                context: "MeterTypes",
                                conversion: "StartCase",
                                filterInput: true,
                            },
                        ],
                    },
                },
                {
                    field: "meter_serial",
                    name: this.$fn.pgettext("MeterSensorDataBrowser", "Meter"),
                    type: "command",
                    formatter: {
                        name: "routeLink",
                        to: "meter",
                        params: {
                            id: "meter_id",
                        },
                    },
                },
                {
                    field: "tag_id",
                    name: this.$fn.pgettext("MeterSensorDataBrowser", "Meter Tag"),
                    type: "command",
                    formatter: {
                        name: "routeLink",
                        to: "meter",
                        params: {
                            id: "meter_id",
                        },
                    },
                },
                {
                    field: "sensor",
                    name: this.$fn.pgettext("MeterSensorDataBrowser", "Sensor Key"),
                },
                {
                    field: "raw",
                    name: this.$fn.pgettext("MeterSensorDataBrowser", "Raw"),
                    textAlign: "right",
                    formatter: {
                        name: "number",
                    },
                    cssClass: "text-muted",
                },
                {
                    field: "offset",
                    name: this.$fn.pgettext("MeterSensorDataBrowser", "Offset"),
                    textAlign: "right",
                    formatter: {
                        name: "number",
                    },
                    cssClass: "text-muted",
                },
                {
                    field: "value",
                    name: this.$fn.pgettext("MeterSensorDataBrowser", "Value"),
                    textAlign: "right",
                    cssClass: "font-weight-bold",
                    formatter: {
                        name: "number",
                    },
                },
                {
                    field: "unit",
                    name: this.$fn.pgettext("MeterSensorDataBrowser", "Unit"),
                    cssClass: "font-weight-bold",
                },
                {
                    field: "timestamp",
                    name: this.$fn.pgettext("MeterSensorDataBrowser", "Timestamp"),
                    formatter: {
                        name: "datetime",
                    },
                },
                {
                    field: "rentals",
                    name: this.$fn.pgettext("MeterSensorDataBrowser", "Rentals"),
                    formatter: {
                        name: "chain",
                        mode: "mapReduce",
                        separator: ", ",
                        formatterContext: "item",
                        formatters: [{
                                name: "getvalue",
                                opts: {
                                    field: "name",
                                    source: "value",
                                },
                            },
                            {
                                name: "routeLink",
                                to: "rental",
                                navigateOnIconClick: true,
                                params: {
                                    id: "id",
                                },
                            },
                        ],
                    },
                },
                {
                    field: "tenants",
                    name: this.$fn.pgettext("MeterSensorDataBrowser", "Tenants"),
                    formatter: {
                        name: "chain",
                        mode: "mapReduce",
                        separator: ", ",
                        formatterContext: "item",
                        formatters: [{
                                name: "getvalue",
                                opts: {
                                    field: "name",
                                    source: "value",
                                },
                            },
                            {
                                name: "routeLink",
                                to: "client",
                                navigateOnIconClick: true,
                                params: {
                                    id: "id",
                                },
                            },
                        ],
                    },
                },
            ],
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.pgettext("MeterSensorDataBrowser", "Auto Readings"),
                icon: "fa-ethernet",
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_5552_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    act_2248226175642056_setData_5554_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "selectedItems",
                value: this.$refs.grid.getSelectedItems(),
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_2248226175642056_selectedRowsChanged(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope);
        });
    }
    act_8320016629450276_script_5556_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.$refs.grid.setSelectedItems([]);
        });
    }
    act_8320016629450276_script_5556_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_8320016629450276_script_5556_getActionArgs_value($event),
            };
        });
    }
    evh_8320016629450276_completed(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8320016629450276_completed.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_5552 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_5552_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_5551 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_5552,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_5551],
        });
        const act_2248226175642056_setData_5554 = new core_1.actions.SetDataAction({
            actionArgs: this.act_2248226175642056_setData_5554_getActionArgs,
            events: [],
        });
        const evh_2248226175642056_selectedRowsChanged_5553 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_setData_5554,
            event: "selectedRowsChanged",
            displayName: "setData",
        });
        const evh_2248226175642056_selectedRowsChanged = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_selectedRowsChanged_5553],
        });
        const act_8320016629450276_script_5556 = new core_1.actions.ScriptAction({
            actionArgs: this.act_8320016629450276_script_5556_getActionArgs,
            events: [],
        });
        const evh_8320016629450276_completed_5555 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_script_5556,
            event: "completed",
            displayName: "script",
        });
        const evh_8320016629450276_completed = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8320016629450276_completed_5555],
        });
        return {
            act_7315092382398562_closeModal_5552,
            evh_7315092382398562_close_5551,
            evh_7315092382398562_close,
            act_2248226175642056_setData_5554,
            evh_2248226175642056_selectedRowsChanged_5553,
            evh_2248226175642056_selectedRowsChanged,
            act_8320016629450276_script_5556,
            evh_8320016629450276_completed_5555,
            evh_8320016629450276_completed,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], SensorDataBrowser.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], SensorDataBrowser.prototype, "onSize", null);
SensorDataBrowser = __decorate([
    (0, vue_property_decorator_1.Component)()
], SensorDataBrowser);
exports.default = SensorDataBrowser;
vue_property_decorator_1.Vue.component("SensorDataBrowser", SensorDataBrowser);
