<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="RentalServiceCostAllocator"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :dialogName="resourceTitle"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      :name="formName"
      :id="formId"
      ref="mainForm"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <ItpFormGroup
        name="meter_id"
        :label="'Meter'|pgettext('RentalServiceCostAllocator')"
        labelColsMd="4"
        contentColsMd="8"
        :labelAlign="labelAlign"
        required
      >
        <ItpFormSelect2
          name="meter_id"
          v-model="editor.meter_id"
          :dataSource="b_7789867021953041_dataSource"
          :initialOptions="editor.meter ? [editor.meter] : []"
          valueField="id"
          textField="serial_number"
          use-option-template-for-selected-option
          optionTemplate="<UcSuggestMeterOption :option=&quot;option&quot;></UcSuggestMeterOption>"
          required
          :readonly="item.isReadOnly"
        >
        </ItpFormSelect2>
      </ItpFormGroup>
      <ItpFormGroup
        name="factor"
        :label="'Factor'|pgettext('RentalServiceCostAllocator')"
        labelColsMd="4"
        contentColsMd="4"
        :labelAlign="labelAlign"
        required
      >
        <ItpFormInput
          type="number"
          name="factor"
          v-model="editor.factor"
          :readonly="item.isReadOnly"
          required
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        name="notes"
        :label="'Notes'|pgettext('RentalServiceCostAllocator')"
        labelColsMd="4"
        contentColsMd="8"
        :labelAlign="labelAlign"
      >
        <ItpFormTextarea
          name="note"
          v-model="item.notes"
          :readonly="item.isReadOnly"
        >
        </ItpFormTextarea>
      </ItpFormGroup>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :text="'Save'|pgettext('Button')"
      type="submit"
      :form="forms[formName]"
      :spinning="actions.create.isRunning"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      :disabled="actions.create.isRunning"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class RentalServiceCostAllocator extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
  })
  oid!: string;

  @Watch('oid')
  onOid(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("oid")
  }

  @Prop({
    required: true,
    type: Object,
  })
  service!: object;

  @Watch('service')
  onService(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("service")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  item!: any;

  async $$load_item() {
    return this.oid ?
      this.$fn.fetch('read_rental_service_cost_allocator', { parameters: { id: this.oid }, asResource: true }) :
      this.$fn.schemaDefaults('CreateRentalServiceCostAllocator', {
        rental_id: this.service.rental.id,
        rental_service_id: this.service.id,
      })
  }

  labelAlign!: any;
  editor!: any;

  async $$load_editor() {
    return this.item.editor()
  }

  formName!: any;
  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  createTitle!: any;
  resourceTitle!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_7789867021953041_dataSource!: any;
  dataMembers = ['item', 'labelAlign', 'editor', 'formName', 'formId', 'createTitle', 'resourceTitle', 'ux', 'b_7315092382398562_modalBindings', 'b_7789867021953041_dataSource'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        item: null,
        labelAlign: "right",
        editor: null,
        formName: "rentalServiceCostAllocator",
        formId: null,
        createTitle: this.$fn.pgettext("RentalServiceCostAllocator", "New cost allocator"),
        resourceTitle: this.$fn.pgettext("RentalServiceCostAllocator/header", "Cost allocator"),
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "md",
        }
        ,
        b_7789867021953041_dataSource: {
          name: "suggest_meter",
          parameters: {
            types_only: "cost_allocator",
          }
          ,
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: [!this.oid && this.createTitle
          ,
        this.item && this.item.meter && this.item.meter.serial_number
          ,
        ]
        ,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_6994_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_6994, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_closeComponent_7000_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseComponentActionArgs> {
    // parameterResolver name=act_1040167445267876_closeComponent_7000, alias=undefined
    return {
      result: {
        ok: true,
      }
      ,
    }
  }

  async act_1040167445267876_rest_6998_getActionArgs($event: actions.ActionEvent): Promise<actions.RestActionArgs> {
    // parameterResolver name=act_1040167445267876_rest_6998, alias=create
    return {
      method: "save",
      container: this.editor.cost_allocators,
      resource: this.editor,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_6994: actions.CloseModalAction;
    evh_7315092382398562_close_6993: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_6996: actions.CloseComponentAction;
    evh_2248226175642056_close_6995: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1040167445267876_closeComponent_7000: actions.CloseComponentAction;
    evh_1040167445267876_saved_6999: actions.EventHandlerImpl;
    act_1040167445267876_rest_6998: actions.RestAction;
    evh_1040167445267876_submit_6997: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_1419464017721962_closeComponent_7002: actions.CloseComponentAction;
    evh_1419464017721962_clickPreventStop_7001: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_6994 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_6994_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_6993 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_6994,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_6993],
      }
    );
    const act_2248226175642056_closeComponent_6996 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_6995 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_6996,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_6995],
      }
    );
    const act_1040167445267876_closeComponent_7000 = new actions.CloseComponentAction(
      {
        actionArgs: this.act_1040167445267876_closeComponent_7000_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_saved_6999 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeComponent_7000,
        event: "saved",
        displayName: "closeComponent",
      }
    );
    const act_1040167445267876_rest_6998 = new actions.RestAction(
      {
        actionArgs: this.act_1040167445267876_rest_6998_getActionArgs,
        displayName: "create",
        events: [evh_1040167445267876_saved_6999],
      }
    );
    const evh_1040167445267876_submit_6997 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_rest_6998,
        event: "submit",
        displayName: "create",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_6997],
      }
    );
    const act_1419464017721962_closeComponent_7002 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_7001 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeComponent_7002,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_7001],
      }
    );
    return {
      act_7315092382398562_closeModal_6994,
      evh_7315092382398562_close_6993,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_6996,
      evh_2248226175642056_close_6995,
      evh_2248226175642056_close,
      act_1040167445267876_closeComponent_7000,
      evh_1040167445267876_saved_6999,
      act_1040167445267876_rest_6998,
      evh_1040167445267876_submit_6997,
      evh_1040167445267876_submit,
      act_1419464017721962_closeComponent_7002,
      evh_1419464017721962_clickPreventStop_7001,
      evh_1419464017721962_clickPreventStop,
      create: act_1040167445267876_rest_6998,
    }
  }
}

Vue.component("RentalServiceCostAllocator", RentalServiceCostAllocator);

</script>