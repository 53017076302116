"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
(0, core_1.addRoute)({
    name: "jobs",
    path: "/jobs",
    component: "Jobs"
});
let Jobs = class Jobs extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['selectedItems', 'ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns'];
    }
    beforeCreate() {
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            selectedItems: [],
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "sm",
            },
            b_2248226175642056_columnDefaults: {
                sortable: true,
                filterable: true,
                resizable: true,
            },
            b_2248226175642056_columns: [{
                    field: "number",
                    name: this.$fn.pgettext("Jobs", "Number"),
                    type: "command",
                    formatter: [{
                            name: "template",
                            format: "#{{ number }}",
                        },
                        {
                            name: "link",
                            href: "#",
                        },
                    ],
                },
                {
                    field: "status",
                    name: this.$fn.pgettext("Jobs", "Status"),
                    formatter: {
                        name: "chain",
                        formatters: [{
                                name: "gettext",
                                context: "JobStatus",
                            },
                            {
                                name: "cellclass",
                                options: {
                                    Running: "bg-warning-light",
                                    "Errored/Rerunning": "bg-warning-light",
                                    Errored: "bg-danger-light",
                                    Failed: "bg-danger-light",
                                    Completed: "bg-success-light",
                                },
                                optionsSource: "fieldValue",
                            },
                        ],
                    },
                },
                {
                    field: "job_type",
                    name: this.$fn.pgettext("Jobs", "Job type"),
                },
                {
                    field: "created_by",
                    name: this.$fn.pgettext("Jobs", "User"),
                    formatter: {
                        name: "user",
                    },
                },
                {
                    field: "created_at",
                    name: this.$fn.pgettext("Jobs", "Submit time"),
                    formatter: {
                        name: "datetime",
                    },
                },
            ],
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.gettext("Jobs"),
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_2650_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    act_2248226175642056_setData_2652_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "selectedItems",
                value: this.$refs.grid.getSelectedItems(),
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_2248226175642056_selectedRowsChanged(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope);
        });
    }
    act_2248226175642056_showModal_2654_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "DunningLetter",
                props: {
                    id: $event.data.data.job_data.id,
                },
            };
        });
    }
    evh_2248226175642056_command_2653_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.column.id === 'job_data';
        });
    }
    act_2248226175642056_showModal_2656_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "JobModal",
                props: {
                    id: $event.data.data.id,
                },
            };
        });
    }
    evh_2248226175642056_command_2655_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.column.id === 'number';
        });
    }
    evh_2248226175642056_command(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_command.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_2650 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_2650_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_2649 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_2650,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_2649],
        });
        const act_2248226175642056_setData_2652 = new core_1.actions.SetDataAction({
            actionArgs: this.act_2248226175642056_setData_2652_getActionArgs,
            events: [],
        });
        const evh_2248226175642056_selectedRowsChanged_2651 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_setData_2652,
            event: "selectedRowsChanged",
            displayName: "setData",
        });
        const evh_2248226175642056_selectedRowsChanged = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_selectedRowsChanged_2651],
        });
        const act_2248226175642056_showModal_2654 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2248226175642056_showModal_2654_getActionArgs,
            events: [],
        });
        const evh_2248226175642056_command_2653 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_showModal_2654,
            event: "command",
            when: this.evh_2248226175642056_command_2653_getWhen,
            displayName: "showModal",
        });
        const act_2248226175642056_showModal_2656 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2248226175642056_showModal_2656_getActionArgs,
            events: [],
        });
        const evh_2248226175642056_command_2655 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_showModal_2656,
            event: "command",
            when: this.evh_2248226175642056_command_2655_getWhen,
            displayName: "showModal",
        });
        const evh_2248226175642056_command = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_command_2653, evh_2248226175642056_command_2655],
        });
        return {
            act_7315092382398562_closeModal_2650,
            evh_7315092382398562_close_2649,
            evh_7315092382398562_close,
            act_2248226175642056_setData_2652,
            evh_2248226175642056_selectedRowsChanged_2651,
            evh_2248226175642056_selectedRowsChanged,
            act_2248226175642056_showModal_2654,
            evh_2248226175642056_command_2653,
            act_2248226175642056_showModal_2656,
            evh_2248226175642056_command_2655,
            evh_2248226175642056_command,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], Jobs.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Jobs.prototype, "onSize", null);
Jobs = __decorate([
    (0, vue_property_decorator_1.Component)()
], Jobs);
exports.default = Jobs;
vue_property_decorator_1.Vue.component("Jobs", Jobs);
