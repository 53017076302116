<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="MeterDataExchange"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpAlert
      variant="info"
      show
    >
      <p>
        <ItpIcon
          fa="info-circle"
        >
        </ItpIcon>
        <ItpText
          wrap
        >
          A szenzor-mérőóra beállítások adatcseréje Excel táblázat segítségével történik.
A mindenkor aktuális szenzor események innen tölthetőek le.

        </ItpText>
        <ItpLink
          :text="'Download events'|pgettext('MeterDataExchange')"
          :href="downloadMeterDataExchangeFileURL"
          button
          variant="light"
          use-auth
          force-download
        >
        </ItpLink>
        <br>
        </br>
        <ItpText>
          Új események az alábbi űrlap segítségével tölthetőek fel a rendszerbe.

        </ItpText>
      </p>
    </ItpAlert>
    <hr>
    </hr>
    <DIV
      class="container"
    >
      <ItpForm
        :name="formName"
        :id="formId"
        v-if="loaded"
        @submit="evh_648747096704084_submit($event, {})"
      >
        <ItpFormGroup
          name="file"
          :label="'Events file'|pgettext('MeterDataExchange')"
          labelFor="file"
          :description="'Only XLSX files are allowed. It must contain at least serial_number and meter_type columns.'|gettext"
          required
        >
          <ItpFormFile
            v-model="file"
            name="file"
            accept=".xlsx"
            required
            :validation="b_3086171180629160_validation"
          >
          </ItpFormFile>
        </ItpFormGroup>
      </ItpForm>
      <ItpAlert
        :show="resultText && !actions.submit.isRunning"
      >
        <p>
          {{ resultText }}
        </p>
      </ItpAlert>
    </DIV>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :form="formId"
      :text="'Upload events'|pgettext('MeterDataExchange')"
      type="submit"
      variant="primary"
      icon="fa-file-import"
      :spinning="actions.submit.isRunning"
      :disabled="!forms[formName] || !forms[formName].submittable"
    >
    </ItpButton>
    <ItpButton
      :text="'Cancel'|gettext"
      variant="default"
      :disabled="actions.submit.isRunning"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class MeterDataExchange extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  file!: any;
  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  formName!: any;
  resultText!: any;
  downloadMeterDataExchangeFileURL!: any;

  async $$load_downloadMeterDataExchangeFileURL() {
    return this.$fn.urlFor('meters/get_sensor_data_exchange', { format: 'xlsx' })
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_3086171180629160_validation!: any;
  dataMembers = ['file', 'formId', 'formName', 'resultText', 'downloadMeterDataExchangeFileURL', 'ux', 'b_7315092382398562_modalBindings', 'b_3086171180629160_validation'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        file: null,
        formId: null,
        formName: "meterDataExhange",
        resultText: null,
        downloadMeterDataExchangeFileURL: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "md",
        }
        ,
        b_3086171180629160_validation: ["ext:xlsx"
          ,
          "required"
          ,
        ]
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.gettext("Meter Data Exchange"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_2860_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_2860, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_648747096704084_register_2866_getActionArgs($event: actions.ActionEvent): Promise<actions.RegisterActionArgs> {
    // parameterResolver name=act_648747096704084_register_2866, alias=undefined
    return {
      name: "resultLength",
      value: $event.data.response.data ? $event.data.response.data.length : 0,
    }
  }

  async act_648747096704084_setData_2868_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_648747096704084_setData_2868, alias=undefined
    return {
      path: "resultText",
      value: this.$fn.ngettext("One event has been imported.", "${n} events have been imported.", this.resultLength),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_648747096704084_success_2867_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_648747096704084_success_2867, alias=undefined
    return $event.data.resultLength > 0
  }

  async act_648747096704084_setData_2870_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_648747096704084_setData_2870, alias=undefined
    return {
      path: "resultText",
      value: this.$fn.gettext("No events have been imported."),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_648747096704084_success_2869_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_648747096704084_success_2869, alias=undefined
    return !$event.data.resultLength
  }

  async act_648747096704084_request_2864_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_648747096704084_request_2864, alias=submit
    return {
      operation: "meters/upload_sensor_data_exchange",
      data: { file: this.file },
    }
  }

  async evh_648747096704084_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_648747096704084_submit.executeFromDOM(this, event, scope);
  }

  async act_1419464017721962_closeModal_2872_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1419464017721962_closeModal_2872, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
      }
      ,
    }
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_2860: actions.CloseModalAction;
    evh_7315092382398562_close_2859: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_2862: actions.CloseComponentAction;
    evh_2248226175642056_close_2861: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_648747096704084_register_2866: actions.RegisterAction;
    evh_648747096704084_success_2865: actions.EventHandlerImpl;
    act_648747096704084_setData_2868: actions.SetDataAction;
    evh_648747096704084_success_2867: actions.EventHandlerImpl;
    act_648747096704084_setData_2870: actions.SetDataAction;
    evh_648747096704084_success_2869: actions.EventHandlerImpl;
    act_648747096704084_request_2864: actions.RequestAction;
    evh_648747096704084_submit_2863: actions.EventHandlerImpl;
    evh_648747096704084_submit: actions.EventHandlerGroup;
    act_1419464017721962_closeModal_2872: actions.CloseModalAction;
    evh_1419464017721962_clickPreventStop_2871: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_2860 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_2860_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_2859 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_2860,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_2859],
      }
    );
    const act_2248226175642056_closeComponent_2862 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_2861 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_2862,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_2861],
      }
    );
    const act_648747096704084_register_2866 = new actions.RegisterAction(
      {
        actionArgs: this.act_648747096704084_register_2866_getActionArgs,
        events: [],
      }
    );
    const evh_648747096704084_success_2865 = new actions.EventHandlerImpl(
      {
        action: act_648747096704084_register_2866,
        event: "success",
        displayName: "register",
      }
    );
    const act_648747096704084_setData_2868 = new actions.SetDataAction(
      {
        actionArgs: this.act_648747096704084_setData_2868_getActionArgs,
        events: [],
      }
    );
    const evh_648747096704084_success_2867 = new actions.EventHandlerImpl(
      {
        action: act_648747096704084_setData_2868,
        event: "success",
        when: this.evh_648747096704084_success_2867_getWhen,
        displayName: "setData",
      }
    );
    const act_648747096704084_setData_2870 = new actions.SetDataAction(
      {
        actionArgs: this.act_648747096704084_setData_2870_getActionArgs,
        events: [],
      }
    );
    const evh_648747096704084_success_2869 = new actions.EventHandlerImpl(
      {
        action: act_648747096704084_setData_2870,
        event: "success",
        when: this.evh_648747096704084_success_2869_getWhen,
        displayName: "setData",
      }
    );
    const act_648747096704084_request_2864 = new actions.RequestAction(
      {
        actionArgs: this.act_648747096704084_request_2864_getActionArgs,
        displayName: "submit",
        events: [evh_648747096704084_success_2865, evh_648747096704084_success_2867, evh_648747096704084_success_2869],
      }
    );
    const evh_648747096704084_submit_2863 = new actions.EventHandlerImpl(
      {
        action: act_648747096704084_request_2864,
        event: "submit",
        displayName: "submit",
      }
    );
    const evh_648747096704084_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_648747096704084_submit_2863],
      }
    );
    const act_1419464017721962_closeModal_2872 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1419464017721962_closeModal_2872_getActionArgs,
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_2871 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeModal_2872,
        event: "click.prevent.stop",
        displayName: "closeModal",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_2871],
      }
    );
    return {
      act_7315092382398562_closeModal_2860,
      evh_7315092382398562_close_2859,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_2862,
      evh_2248226175642056_close_2861,
      evh_2248226175642056_close,
      act_648747096704084_register_2866,
      evh_648747096704084_success_2865,
      act_648747096704084_setData_2868,
      evh_648747096704084_success_2867,
      act_648747096704084_setData_2870,
      evh_648747096704084_success_2869,
      act_648747096704084_request_2864,
      evh_648747096704084_submit_2863,
      evh_648747096704084_submit,
      act_1419464017721962_closeModal_2872,
      evh_1419464017721962_clickPreventStop_2871,
      evh_1419464017721962_clickPreventStop,
      submit: act_648747096704084_request_2864,
    }
  }
}

Vue.component("MeterDataExchange", MeterDataExchange);

</script>