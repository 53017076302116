"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MainMenu = void 0;
__exportStar(require("./accounts"), exports);
__exportStar(require("./addresses"), exports);
__exportStar(require("./assets"), exports);
__exportStar(require("./client"), exports);
__exportStar(require("./common"), exports);
__exportStar(require("./contacts"), exports);
__exportStar(require("./contracts"), exports);
__exportStar(require("./dashboard"), exports);
__exportStar(require("./employees"), exports);
__exportStar(require("./finance"), exports);
__exportStar(require("./invoice"), exports);
__exportStar(require("./invoiceRegister"), exports);
__exportStar(require("./jobs"), exports);
__exportStar(require("./letterRegister"), exports);
__exportStar(require("./meters"), exports);
__exportStar(require("./multilineNotes"), exports);
__exportStar(require("./numberings"), exports);
__exportStar(require("./payments"), exports);
__exportStar(require("./product"), exports);
__exportStar(require("./property"), exports);
__exportStar(require("./registers"), exports);
__exportStar(require("./rental"), exports);
__exportStar(require("./settings"), exports);
__exportStar(require("./tasks"), exports);
__exportStar(require("./templates"), exports);
__exportStar(require("./worksheets"), exports);
const MainMenu_yaml_component_1 = require("./MainMenu.yaml?component");
exports.MainMenu = MainMenu_yaml_component_1.default;
