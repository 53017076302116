"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let MFixedAssetEditor = class MFixedAssetEditor extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['formId', 'defaultTitle', 'targetConfig', 'ux', 'b_7315092382398562_modalBindings'];
    }
    beforeCreate() {
    }
    getPayload(...args) {
        const [data] = args;
        if (!this.target) {
            return data;
        }
        const fieldsToSubmit = this.targetConfig[this.target].fieldsToSubmit;
        if (fieldsToSubmit.indexOf('*') > -1) {
            return data;
        }
        return _.pick(data, fieldsToSubmit);
    }
    onFixed_asset(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("fixedAsset");
    }
    onTarget(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("target");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_formId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            formId: null,
            defaultTitle: this.$fn.pgettext("FixedAsset", "Edit General Information"),
            targetConfig: {
                general: {
                    title: this.$fn.pgettext("FixedAsset", "Edit general data"),
                    fieldsToSubmit: ["*",
                    ],
                },
                wasteBin: {
                    title: this.$fn.pgettext("FixedAsset", "Edit waste bin data"),
                    fieldsToSubmit: ["id",
                        "etag",
                        "waste_bin",
                    ],
                },
            },
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "md",
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.target ? this.targetConfig[this.target].title : this.defaultTitle,
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_1544_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_1040167445267876_closeComponent_1550_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    ok: true,
                    value: $event.data,
                },
            };
        });
    }
    act_1040167445267876_crud_1548_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                objectType: "fixed_asset",
                op: "upsert",
                data: this.getPayload(this.fixedAsset),
            };
        });
    }
    evh_1040167445267876_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
        });
    }
    evh_1419464017721962_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_1544 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_1544_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_1543 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_1544,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_1543],
        });
        const act_2248226175642056_closeComponent_1546 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2248226175642056_close_1545 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_1546,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_1545],
        });
        const act_1040167445267876_closeComponent_1550 = new core_1.actions.CloseComponentAction({
            actionArgs: this.act_1040167445267876_closeComponent_1550_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_1549 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_closeComponent_1550,
            event: "success",
            displayName: "closeComponent",
        });
        const act_1040167445267876_crud_1548 = new core_1.actions.CRUDAction({
            actionArgs: this.act_1040167445267876_crud_1548_getActionArgs,
            displayName: "save",
            events: [evh_1040167445267876_success_1549],
        });
        const evh_1040167445267876_submit_1547 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_crud_1548,
            event: "submit",
            displayName: "save",
        });
        const evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_1547],
        });
        const act_1419464017721962_closeComponent_1552 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_1419464017721962_clickPreventStop_1551 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_closeComponent_1552,
            event: "click.prevent.stop",
            displayName: "closeComponent",
        });
        const evh_1419464017721962_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1419464017721962_clickPreventStop_1551],
        });
        return {
            act_7315092382398562_closeModal_1544,
            evh_7315092382398562_close_1543,
            evh_7315092382398562_close,
            act_2248226175642056_closeComponent_1546,
            evh_2248226175642056_close_1545,
            evh_2248226175642056_close,
            act_1040167445267876_closeComponent_1550,
            evh_1040167445267876_success_1549,
            act_1040167445267876_crud_1548,
            evh_1040167445267876_submit_1547,
            evh_1040167445267876_submit,
            act_1419464017721962_closeComponent_1552,
            evh_1419464017721962_clickPreventStop_1551,
            evh_1419464017721962_clickPreventStop,
            save: act_1040167445267876_crud_1548,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Object,
    }),
    __metadata("design:type", Object)
], MFixedAssetEditor.prototype, "fixedAsset", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('fixedAsset'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], MFixedAssetEditor.prototype, "onFixed_asset", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], MFixedAssetEditor.prototype, "target", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('target'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], MFixedAssetEditor.prototype, "onTarget", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], MFixedAssetEditor.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], MFixedAssetEditor.prototype, "onSize", null);
MFixedAssetEditor = __decorate([
    (0, vue_property_decorator_1.Component)()
], MFixedAssetEditor);
exports.default = MFixedAssetEditor;
vue_property_decorator_1.Vue.component("MFixedAssetEditor", MFixedAssetEditor);
