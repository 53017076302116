"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcRentalFormServicesTable = class UcRentalFormServicesTable extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['items', 'units', 'ux', 'b_7315092382398562_columns'];
    }
    beforeCreate() {
    }
    onMeterSelected(...args) {
        const { col, option, row, value } = args[0].data;
        if (value && value.unit) {
            row.billing_unit = value.unit;
        }
    }
    onEditable(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("editable");
    }
    onRental_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("rentalId");
    }
    $$load_items() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_rental_service', { parameters: { rental_id: this.rentalId } })
                .then(d => _.orderBy(d, ['id']));
        });
    }
    $$load_units() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_unit').then(d => _.orderBy(d, [p => p.symbol.toLowerCase()]));
        });
    }
    $$load_b_7315092382398562_columns() {
        return __awaiter(this, void 0, void 0, function* () {
            return [{
                    name: "locked",
                    type: "lock",
                },
                {
                    name: "inactive",
                    header: this.$fn.gettext("Inactive"),
                    width: "30px",
                    textAlign: "center",
                    formatter: {
                        name: "checkbox",
                    },
                    editor: {
                        name: "checkbox",
                    },
                },
                {
                    name: "valid_from",
                    type: "date",
                    header: this.$fn.gettext("Valid From"),
                    width: "120px",
                },
                {
                    name: "valid_to",
                    type: "date",
                    header: this.$fn.gettext("Valid To"),
                    width: "120px",
                },
                {
                    name: "service",
                    type: "select2",
                    header: this.$fn.gettext("Service"),
                    width: "250px",
                    required: true,
                    path: "name",
                    bindObject: true,
                    textField: "name",
                    options: this.$fn.searchDataSource('suggest_product', { product_types: ['service'] }),
                },
                {
                    name: "meter",
                    type: "select2",
                    header: this.$fn.gettext("Meter"),
                    width: "250px",
                    path: "serial_number",
                    bindObject: true,
                    textField: "serial_number",
                    options: this.$fn.searchDataSource('suggest_meter', { t: 'rentalService' }),
                    formatter: {
                        name: "template",
                        value: "<b-link :to=\"{\n\
  name: 'app.meter',\n\
  params: { id: row.meter.id }\n\
}\">{{ row.meter.serial_number }}</b-link>\n\
",
                    },
                },
                {
                    name: "billing_unit",
                    type: "select2",
                    header: this.$fn.gettext("Billing Unit"),
                    width: "120px",
                    path: "billing_unit",
                    valueField: "symbol",
                    textField: "symbol",
                    required: true,
                    options: this.units,
                },
                {
                    name: "meter_factor",
                    type: "number",
                    header: this.$fn.gettext("Factor"),
                    width: "100px",
                },
                {
                    name: "notes",
                    header: this.$fn.gettext("Notes"),
                    width: "320px",
                },
            ];
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            items: null,
            units: null,
            ux: {
                initialLoadSpinner: true,
            },
            b_7315092382398562_columns: null,
        });
    }
    act_7315092382398562_script_7010_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            $event.data.waitFor(this.$fn.crud('rental_service').upsert(Object.assign(Object.assign({}, $event.data.row), { asset_id: $event.data.row.asset_id, service_id: $event.data.row.service.id, meter_id: _.get($event.data.row, 'meter.id') })));
        });
    }
    act_7315092382398562_script_7010_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_7315092382398562_script_7010_getActionArgs_value($event),
            };
        });
    }
    evh_7315092382398562_save(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_save.executeFromDOM(this, event, scope);
        });
    }
    act_7315092382398562_script_7012_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            $event.data.waitFor(this.$fn.crud('rental_service').delete($event.data.row));
        });
    }
    act_7315092382398562_script_7012_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_7315092382398562_script_7012_getActionArgs_value($event),
            };
        });
    }
    evh_7315092382398562_delete(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_delete.executeFromDOM(this, event, scope);
        });
    }
    act_7315092382398562_script_7014_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.onMeterSelected($event);
        });
    }
    act_7315092382398562_script_7014_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_7315092382398562_script_7014_getActionArgs_value($event),
            };
        });
    }
    evh_7315092382398562_meterSelected(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_meterSelected.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_script_7010 = new core_1.actions.ScriptAction({
            actionArgs: this.act_7315092382398562_script_7010_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_save_7009 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_script_7010,
            event: "save",
            displayName: "script",
        });
        const evh_7315092382398562_save = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_save_7009],
        });
        const act_7315092382398562_script_7012 = new core_1.actions.ScriptAction({
            actionArgs: this.act_7315092382398562_script_7012_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_delete_7011 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_script_7012,
            event: "delete",
            displayName: "script",
        });
        const evh_7315092382398562_delete = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_delete_7011],
        });
        const act_7315092382398562_script_7014 = new core_1.actions.ScriptAction({
            actionArgs: this.act_7315092382398562_script_7014_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_meterSelected_7013 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_script_7014,
            event: "meter:selected",
            displayName: "script",
        });
        const evh_7315092382398562_meterSelected = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_meterSelected_7013],
        });
        return {
            act_7315092382398562_script_7010,
            evh_7315092382398562_save_7009,
            evh_7315092382398562_save,
            act_7315092382398562_script_7012,
            evh_7315092382398562_delete_7011,
            evh_7315092382398562_delete,
            act_7315092382398562_script_7014,
            evh_7315092382398562_meterSelected_7013,
            evh_7315092382398562_meterSelected,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
        default: true,
    }),
    __metadata("design:type", Boolean)
], UcRentalFormServicesTable.prototype, "editable", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('editable'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcRentalFormServicesTable.prototype, "onEditable", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: String,
    }),
    __metadata("design:type", String)
], UcRentalFormServicesTable.prototype, "rentalId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('rentalId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcRentalFormServicesTable.prototype, "onRental_id", null);
UcRentalFormServicesTable = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcRentalFormServicesTable);
exports.default = UcRentalFormServicesTable;
vue_property_decorator_1.Vue.component("UcRentalFormServicesTable", UcRentalFormServicesTable);
