"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let InvoiceRegisterEntryHeader = class InvoiceRegisterEntryHeader extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['messages', 'ux'];
    }
    beforeCreate() {
    }
    onEntry(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("entry");
    }
    onRegister(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("register");
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            messages: {
                newTransaction: this.$fn.pgettext("InvoiceRegister", "New entry"),
            },
            ux: null,
        });
    }
    get resourceTitle() {
        if (this.loaded) {
            return this.register.name;
        }
        return null;
    }
    get resourceKey() {
        if (this.loaded) {
            return this.entry.isNew ? this.messages.newTransaction : this.entry.entry_number;
        }
        return null;
    }
    act_1548630417156826_emit_2556_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_1548630417156826_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1548630417156826_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_6793520326132864_emit_2558_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_6793520326132864_success(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6793520326132864_success.executeFromDOM(this, event, scope);
        });
    }
    act_1269078620197197_emit_2560_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "deleted",
            };
        });
    }
    evh_1269078620197197_success(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1269078620197197_success.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_1548630417156826_emit_2556 = new core_1.actions.EmitAction({
            actionArgs: this.act_1548630417156826_emit_2556_getActionArgs,
            displayName: "reload",
            events: [],
        });
        const evh_1548630417156826_clickPreventStop_2555 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_emit_2556,
            event: "click.prevent.stop",
            displayName: "reload",
        });
        const evh_1548630417156826_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1548630417156826_clickPreventStop_2555],
        });
        const act_6793520326132864_emit_2558 = new core_1.actions.EmitAction({
            actionArgs: this.act_6793520326132864_emit_2558_getActionArgs,
            events: [],
        });
        const evh_6793520326132864_success_2557 = new core_1.actions.EventHandlerImpl({
            action: act_6793520326132864_emit_2558,
            event: "success",
            displayName: "emit",
        });
        const evh_6793520326132864_success = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6793520326132864_success_2557],
        });
        const act_1269078620197197_emit_2560 = new core_1.actions.EmitAction({
            actionArgs: this.act_1269078620197197_emit_2560_getActionArgs,
            events: [],
        });
        const evh_1269078620197197_success_2559 = new core_1.actions.EventHandlerImpl({
            action: act_1269078620197197_emit_2560,
            event: "success",
            displayName: "emit",
        });
        const evh_1269078620197197_success = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1269078620197197_success_2559],
        });
        return {
            act_1548630417156826_emit_2556,
            evh_1548630417156826_clickPreventStop_2555,
            evh_1548630417156826_clickPreventStop,
            act_6793520326132864_emit_2558,
            evh_6793520326132864_success_2557,
            evh_6793520326132864_success,
            act_1269078620197197_emit_2560,
            evh_1269078620197197_success_2559,
            evh_1269078620197197_success,
            reload: act_1548630417156826_emit_2556,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], InvoiceRegisterEntryHeader.prototype, "entry", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('entry'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], InvoiceRegisterEntryHeader.prototype, "onEntry", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], InvoiceRegisterEntryHeader.prototype, "register", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('register'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], InvoiceRegisterEntryHeader.prototype, "onRegister", null);
InvoiceRegisterEntryHeader = __decorate([
    (0, vue_property_decorator_1.Component)()
], InvoiceRegisterEntryHeader);
exports.default = InvoiceRegisterEntryHeader;
vue_property_decorator_1.Vue.component("InvoiceRegisterEntryHeader", InvoiceRegisterEntryHeader);
