"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcInvoiceGeneratorControls = class UcInvoiceGeneratorControls extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['labelAlign', 'currentGeneratorTask', 'poller', 'item', 'formId', 'groups', 'productInvoiceGroups', 'filterOptionTemplates', 'filterTypes', 'selectedFilterType', 'selectedFilterValue', 'selectedOperation', 'filters', 'includeWorksheets', 'invoiceGroupFilters', 'operations', 'taskStatusDismissed', 'ux', 'b_1045524669200057_clearable'];
    }
    beforeCreate() {
        this.$$cache_item = new core_1.ComponentValueCache(this, "item", "InvoiceGeneratorPanel.formData");
        this.$$cache_filters = new core_1.ComponentValueCache(this, "filters", "InvoiceGeneratorPanel.filters");
        this.$$cache_includeWorksheets = new core_1.ComponentValueCache(this, "includeWorksheets", "InvoiceGeneratorPanel.includeWorksheets");
        this.$$cache_invoiceGroupFilters = new core_1.ComponentValueCache(this, "invoiceGroupFilters", "InvoiceGeneratorPanel.invoiceGroupFilters");
        this.$$cache_taskStatusDismissed = new core_1.ComponentValueCache(this, "taskStatusDismissed", "InvoiceGeneratorPanel.taskStatusDismissed");
    }
    beforeDestroy(...args) {
        this.poller.stop();
    }
    onPoll(...args) {
        console.log("Poll completed!");
    }
    getPayload(...args) {
        {
            {
                return Object.assign(Object.assign(Object.assign({}, this.item), _.reduce(this.filters, (m, filter) => {
                    const target = (filter.op === 'include' ? '' : 'exclude_') + filter.type;
                    const arr = _.get(m, target, []);
                    arr.push(filter.id);
                    m[target] = arr;
                    return m;
                }, {})), { product_invoice_groups: _.reduce(this.invoiceGroupFilters, (arr, v, k) => {
                        if (!!v) {
                            arr.push(k);
                        }
                        return arr;
                    }, []), exclude_product_invoice_groups: _.reduce(this.invoiceGroupFilters, (arr, v, k) => {
                        if (!v) {
                            arr.push(k);
                        }
                        return arr;
                    }, []), skip_worksheets: !this.includeWorksheets });
            }
        }
    }
    updateStatus(...args) {
        _.extend(this.currentGeneratorTask, args[0]);
    }
    $$load_currentGeneratorTask() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('read_invoice_generation_task', { parameters: { id: 'current' } });
        });
    }
    $$load_poller() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.poller({ callback: () => this.onPoll(), id: "InvoiceGenerator" });
        });
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                description: 'Invoice generation job',
                fulfillment_date: this.$fn.today(),
                due_date: null,
                billing_period_start: this.$fn.today(),
                billing_period_end: null,
                exchange_rates: [],
                service_type_flat_rate: true,
                service_type_measured: true,
                ignore_meter_billing_thresholds: false,
                meter_readings_start_date: null,
                invoice_tags: [],
                include_zero_consumptions: true
            };
        });
    }
    $$load_formId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    $$load_groups() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_group').then(p => p.data);
        });
    }
    $$load_productInvoiceGroups() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_product_invoice_group');
        });
    }
    $$load_filterTypes() {
        return __awaiter(this, void 0, void 0, function* () {
            return [
                {
                    text: this.$fn.gettext('Clients'),
                    target: 'tenants',
                    dataSource: {
                        name: 'suggest_client',
                        parameters: {
                            relation: ['customer'],
                            with_contracts: true
                        }
                    },
                    useOptionTemplateForSelectedOption: true,
                    optionTemplate: '<UcSuggestClientOption :option="option"></UcSuggestClientOption>',
                },
                {
                    text: this.$fn.gettext('Client Groups'),
                    target: 'client_groups',
                    options: _.filter(this.groups, { group_type: 'company' })
                },
                {
                    text: this.$fn.gettext('Contracts'),
                    target: 'contracts',
                    dataSource: {
                        name: 'suggest_contract',
                        parameters: {}
                    },
                    useOptionTemplateForSelectedOption: true,
                    optionTemplate: '<UcSuggestContractOption :option="option"></UcSuggestContractOption>',
                },
                {
                    text: this.$fn.gettext('Rentals'),
                    target: 'rentals',
                    dataSource: {
                        name: 'suggest_rental',
                        parameters: {}
                    },
                    useOptionTemplateForSelectedOption: true,
                    optionTemplate: '<UcSuggestRentalOption :option="option"></UcSuggestRentalOption>',
                },
                {
                    text: this.$fn.gettext('Meter Groups'),
                    target: 'meter_groups',
                    options: _.filter(this.groups, { group_type: 'meter' })
                },
            ];
        });
    }
    $$load_filters() {
        return __awaiter(this, void 0, void 0, function* () {
            return [];
        });
    }
    $$load_invoiceGroupFilters() {
        return __awaiter(this, void 0, void 0, function* () {
            return _.chain(this.productInvoiceGroups)
                .reduce((m, p) => (m[p.id] = true, m), {})
                .value();
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            labelAlign: null,
            currentGeneratorTask: null,
            poller: null,
            item: null,
            formId: null,
            groups: null,
            productInvoiceGroups: null,
            filterOptionTemplates: {
                client: "UcSuggestClientOption",
                contract: "UcSuggestContractOption",
                rental: "UcSuggestRentalOption",
            },
            filterTypes: null,
            selectedFilterType: null,
            selectedFilterValue: null,
            selectedOperation: "include",
            filters: null,
            includeWorksheets: true,
            invoiceGroupFilters: null,
            operations: [{
                    value: "include",
                    text: this.$fn.pgettext("InvoiceGenerator", "Include"),
                },
                {
                    value: "exclude",
                    text: this.$fn.pgettext("InvoiceGenerator", "Exclude"),
                },
            ],
            taskStatusDismissed: false,
            ux: null,
            b_1045524669200057_clearable: false,
        });
    }
    get canAddFilter() {
        if (this.loaded) {
            return this.selectedFilterType && this.selectedFilterValue && this.selectedOperation;
        }
        return null;
    }
    get canSubmit() {
        if (this.loaded) {
            return this.forms.InvoiceGeneration &&
                this.forms.InvoiceGeneration.submittable &&
                (!this.currentGeneratorTask ||
                    ['PENDING', 'STARTED'].indexOf(this.currentGeneratorTask.status) == -1);
        }
        return null;
    }
    evh_7315092382398562_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
        });
    }
    act_2248226175642056_setData_5118_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "taskStatusDismissed",
                value: false,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_2248226175642056_setData_5120_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "currentGeneratorTask",
                value: $event.data,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_2248226175642056_setData_5120_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data;
        });
    }
    act_2248226175642056_crud_5116_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                objectType: "a_job",
                op: "upsert",
                data: Object.assign(Object.assign(Object.assign({}, this.item), { job_type: 'invoice_generation' }), this.getPayload()),
            };
        });
    }
    evh_2248226175642056_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_submit.executeFromDOM(this, event, scope);
        });
    }
    act_5891271350223090_script_5122_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.filters.push({
                id: this.selectedFilterValue.id,
                type: this.selectedFilterType.target,
                op: this.selectedOperation,
                value: this.selectedFilterValue
            });
            this.filters = this.filters.slice();
            this.selectedFilterValue = null;
        });
    }
    act_5891271350223090_script_5122_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_5891271350223090_script_5122_getActionArgs_value($event),
            };
        });
    }
    evh_5891271350223090_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5891271350223090_click.executeFromDOM(this, event, scope);
        });
    }
    act_1641744087697442_script_5124_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            (_.remove(this.filters, p => p.id == $event.scope.filter.id && p.op === $event.scope.filter.op),
                this.filters = this.filters.slice());
        });
    }
    act_1641744087697442_script_5124_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_1641744087697442_script_5124_getActionArgs_value($event),
            };
        });
    }
    evh_1641744087697442_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1641744087697442_click.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_reloadComponentData_5114 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7315092382398562_reload_5113 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_5114,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_5113],
        });
        const act_2248226175642056_setData_5118 = new core_1.actions.SetDataAction({
            actionArgs: this.act_2248226175642056_setData_5118_getActionArgs,
            events: [],
        });
        const evh_2248226175642056_before_5117 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_setData_5118,
            event: "before",
            displayName: "setData",
        });
        const act_2248226175642056_setData_5120 = new core_1.actions.SetDataAction({
            actionArgs: this.act_2248226175642056_setData_5120_getActionArgs,
            when: this.act_2248226175642056_setData_5120_getWhen,
            events: [],
        });
        const evh_2248226175642056_success_5119 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_setData_5120,
            event: "success",
            displayName: "setData",
        });
        const act_2248226175642056_crud_5116 = new core_1.actions.CRUDAction({
            actionArgs: this.act_2248226175642056_crud_5116_getActionArgs,
            displayName: "submit",
            events: [evh_2248226175642056_before_5117, evh_2248226175642056_success_5119],
        });
        const evh_2248226175642056_submit_5115 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_crud_5116,
            event: "submit",
            displayName: "submit",
        });
        const evh_2248226175642056_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_submit_5115],
        });
        const act_5891271350223090_script_5122 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5891271350223090_script_5122_getActionArgs,
            events: [],
        });
        const evh_5891271350223090_click_5121 = new core_1.actions.EventHandlerImpl({
            action: act_5891271350223090_script_5122,
            event: "click",
            displayName: "script",
        });
        const evh_5891271350223090_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5891271350223090_click_5121],
        });
        const act_1641744087697442_script_5124 = new core_1.actions.ScriptAction({
            actionArgs: this.act_1641744087697442_script_5124_getActionArgs,
            events: [],
        });
        const evh_1641744087697442_click_5123 = new core_1.actions.EventHandlerImpl({
            action: act_1641744087697442_script_5124,
            event: "click",
            displayName: "script",
        });
        const evh_1641744087697442_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1641744087697442_click_5123],
        });
        return {
            act_7315092382398562_reloadComponentData_5114,
            evh_7315092382398562_reload_5113,
            evh_7315092382398562_reload,
            act_2248226175642056_setData_5118,
            evh_2248226175642056_before_5117,
            act_2248226175642056_setData_5120,
            evh_2248226175642056_success_5119,
            act_2248226175642056_crud_5116,
            evh_2248226175642056_submit_5115,
            evh_2248226175642056_submit,
            act_5891271350223090_script_5122,
            evh_5891271350223090_click_5121,
            evh_5891271350223090_click,
            act_1641744087697442_script_5124,
            evh_1641744087697442_click_5123,
            evh_1641744087697442_click,
            submit: act_2248226175642056_crud_5116,
        };
    }
};
UcInvoiceGeneratorControls = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcInvoiceGeneratorControls);
exports.default = UcInvoiceGeneratorControls;
vue_property_decorator_1.Vue.component("UcInvoiceGeneratorControls", UcInvoiceGeneratorControls);
