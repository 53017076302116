<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="InvoiceLineGroup"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      :id="formId"
      :name="formName"
      autofocus="first"
      v-if="loaded"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <ItpCol>
        <ItpFormGroup
          name="description"
          :label="'Description'|pgettext('InvoiceLineGroup')"
          labelColsMd="4"
          contentColsMd="8"
          :labelAlign="labelAlign"
        >
          <ItpFormInput
            name="description"
            v-model="item.description"
            :readonly="item.readonly"
            :placeholder="'Enter group description'|pgettext('InvoiceLineGroup/description/placeholder')"
          >
          </ItpFormInput>
        </ItpFormGroup>
        <ItpFormGroup
          name="expense_type"
          :label="'Expense Type'|pgettext('InvoiceLineGroup')"
          labelColsMd="4"
          contentColsMd="8"
          :labelAlign="labelAlign"
        >
          <ItpFormSelect2
            name="expense_type"
            v-model="item.expense_type"
            :readonly="item.readonly"
            clearable
            :options="expenseTypes"
            valueField="name"
            textField="name"
          >
          </ItpFormSelect2>
        </ItpFormGroup>
        <ItpFormGroup
          name="rental"
          :label="'Rental'|pgettext('InvoiceLineGroup')"
          labelColsMd="4"
          contentColsMd="8"
          :labelAlign="labelAlign"
        >
          <ItpFormSelect2
            name="rental"
            v-model="item.rental"
            bind-object
            :readonly="item.readonly"
            clearable
            :dataSource="b_7307096920626688_dataSource"
            optionTemplate="<UcSuggestRentalOption :option=option>
</UcSuggestRentalOption>"
            use-option-template-for-selected-option
          >
          </ItpFormSelect2>
        </ItpFormGroup>
        <ItpFormGroup
          name="property"
          :label="'Property'|pgettext('InvoiceLineGroup')"
          labelColsMd="4"
          contentColsMd="8"
          :labelAlign="labelAlign"
        >
          <ItpFormSelect2
            name="property"
            v-model="item.property"
            bind-object
            :readonly="item.readonly"
            clearable
            :dataSource="b_3119842801924129_dataSource"
            optionTemplate="<UcSuggestPropertyOption :option=option>
</UcSuggestPropertyOption>"
            use-option-template-for-selected-option
          >
          </ItpFormSelect2>
        </ItpFormGroup>
        <ItpFormGroup
          name="worksheet"
          :label="'Worksheet'|pgettext('InvoiceLineGroup')"
          labelColsMd="4"
          contentColsMd="8"
          :labelAlign="labelAlign"
        >
          <ItpFormSelect2
            name="worksheet"
            v-model="item.worksheet"
            bind-object
            :readonly="item.readonly"
            clearable
            :dataSource="b_4994395583501828_dataSource"
            optionTemplate="<UcSuggestWorksheetOption :option=option>
</UcSuggestWorksheetOption>"
            use-option-template-for-selected-option
          >
          </ItpFormSelect2>
        </ItpFormGroup>
        <ItpFormGroup
          name="contractor"
          :label="'Contractor'|pgettext('InvoiceLineGroup')"
          labelColsMd="4"
          contentColsMd="8"
          :labelAlign="labelAlign"
        >
          <ItpFormSelect2
            name="contractor"
            v-model="item.contractor"
            bind-object
            :readonly="item.readonly"
            clearable
            :dataSource="b_2260153975480357_dataSource"
            optionTemplate="<UcSuggestClientOption :option=option></UcSuggestClientOption>"
            use-option-template-for-selected-option
          >
          </ItpFormSelect2>
        </ItpFormGroup>
      </ItpCol>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <BtnDeleteResource
      :resource="item"
      variant="danger"
      size="md"
      class="mr-auto"
      v-if="!item.isNew && !invoice.readonly && !item.readonly"
      @success="evh_1548630417156826_success($event, {})"
    >
    </BtnDeleteResource>
    <ItpButton
      :form="forms[formName] && forms[formName].id"
      icon="fa-save"
      :text="'Save'|gettext"
      type="submit"
      variant="primary"
      :spinning="actions.submit.isRunning"
      :disabled="!forms[formName] || !forms[formName].submittable"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      :disabled="actions.submit.isRunning"
      @click.prevent.stop="evh_2186679009640457_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class InvoiceLineGroup extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  invoice!: object;

  @Watch('invoice')
  onInvoice(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoice")
  }

  @Prop({
    type: String,
  })
  groupId!: string;

  @Watch('groupId')
  onGroup_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("groupId")
  }

  @Prop({
    type: Boolean,
  })
  isDefaultGroup!: boolean;

  @Watch('isDefaultGroup')
  onIs_default_group(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("isDefaultGroup")
  }

  @Prop({
    type: String,
    default: "right",
  })
  labelAlign!: string;

  @Watch('labelAlign')
  onLabel_align(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("labelAlign")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  item!: any;

  async $$load_item() {
    return this.groupId
      ? this.$fn.fetch('read_invoice_line_group', { asResource: true, parameters: { id: this.groupId } })
      : this.$fn.schemaDefaults('CreateInvoiceLineGroup', {
        invoice_id: this.invoice.id,
        is_default_group: this.isDefaultGroup
      })
  }

  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  formName!: any;
  expenseTypes!: any;

  async $$load_expenseTypes() {
    return this.$fn.fetch('list_object_by_type', { parameters: { object_type: 'expense-type' } }).then(d => _.orderBy(d, ['name']))
  }

  titles!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_7307096920626688_dataSource!: any;
  b_3119842801924129_dataSource!: any;
  b_4994395583501828_dataSource!: any;
  b_2260153975480357_dataSource!: any;
  dataMembers = ['item', 'formId', 'formName', 'expenseTypes', 'titles', 'ux', 'b_7315092382398562_modalBindings', 'b_7307096920626688_dataSource', 'b_3119842801924129_dataSource', 'b_4994395583501828_dataSource', 'b_2260153975480357_dataSource'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        item: null,
        formId: null,
        formName: "invoiceLineGroup",
        expenseTypes: null,
        titles: {
          incoming: this.$fn.pgettext("Invoice", "Create Incoming Invoice"),
          outgoing: this.$fn.pgettext("Invoice", "Create Outgoing Invoice"),
        }
        ,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "md",
        }
        ,
        b_7307096920626688_dataSource: {
          name: "suggest_rental",
        }
        ,
        b_3119842801924129_dataSource: {
          name: "suggest_property",
        }
        ,
        b_4994395583501828_dataSource: {
          name: "suggest_worksheet",
        }
        ,
        b_2260153975480357_dataSource: {
          name: "suggest_client",
          parameters: {
            contractors_only: true,
          }
          ,
        }
        ,
      },
    }
  }

  get incomingInvoice() {
    if (this.loaded) {

      return this.invoiceKind === 'incoming'
    }
    return null;
  }

  get document() {
    if (this.loaded) {

      return {
        title: [this.item.isNew && this.$fn.pgettext("InvoiceLineGroup", "New line group")
          ,
        !this.item.isNew && this.$fn.pgettext("InvoiceLineGroup", "Edit line group")
          ,
        ]
        ,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_5190_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_5190, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_closeModal_5196_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1040167445267876_closeModal_5196, alias=undefined
    return {
      result: {
        value: $event.data,
      }
      ,
    }
  }

  async act_1040167445267876_crud_5194_getActionArgs($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_1040167445267876_crud_5194, alias=submit
    return {
      objectType: "invoice_line_group",
      op: "upsert",
      data: this.item,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async act_1548630417156826_closeModal_5198_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1548630417156826_closeModal_5198, alias=undefined
    return {
      result: {
        value: {
          deleted: true,
        }
        ,
      }
      ,
    }
  }

  async evh_1548630417156826_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1548630417156826_success.executeFromDOM(this, event, scope);
  }

  async act_2186679009640457_closeModal_5200_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_2186679009640457_closeModal_5200, alias=undefined
    return {
      result: {
        close: true,
      }
      ,
    }
  }

  async evh_2186679009640457_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2186679009640457_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_5190: actions.CloseModalAction;
    evh_7315092382398562_close_5189: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeModal_5192: actions.CloseModalAction;
    evh_2248226175642056_close_5191: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1040167445267876_closeModal_5196: actions.CloseModalAction;
    evh_1040167445267876_success_5195: actions.EventHandlerImpl;
    act_1040167445267876_crud_5194: actions.CRUDAction;
    evh_1040167445267876_submit_5193: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_1548630417156826_closeModal_5198: actions.CloseModalAction;
    evh_1548630417156826_success_5197: actions.EventHandlerImpl;
    evh_1548630417156826_success: actions.EventHandlerGroup;
    act_2186679009640457_closeModal_5200: actions.CloseModalAction;
    evh_2186679009640457_clickPreventStop_5199: actions.EventHandlerImpl;
    evh_2186679009640457_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_5190 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_5190_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_5189 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_5190,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_5189],
      }
    );
    const act_2248226175642056_closeModal_5192 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_5191 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeModal_5192,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_5191],
      }
    );
    const act_1040167445267876_closeModal_5196 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1040167445267876_closeModal_5196_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_5195 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeModal_5196,
        event: "success",
        displayName: "closeModal",
      }
    );
    const act_1040167445267876_crud_5194 = new actions.CRUDAction(
      {
        actionArgs: this.act_1040167445267876_crud_5194_getActionArgs,
        displayName: "submit",
        events: [evh_1040167445267876_success_5195],
      }
    );
    const evh_1040167445267876_submit_5193 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_crud_5194,
        event: "submit",
        displayName: "submit",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_5193],
      }
    );
    const act_1548630417156826_closeModal_5198 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1548630417156826_closeModal_5198_getActionArgs,
        events: [],
      }
    );
    const evh_1548630417156826_success_5197 = new actions.EventHandlerImpl(
      {
        action: act_1548630417156826_closeModal_5198,
        event: "success",
        displayName: "closeModal",
      }
    );
    const evh_1548630417156826_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_1548630417156826_success_5197],
      }
    );
    const act_2186679009640457_closeModal_5200 = new actions.CloseModalAction(
      {
        actionArgs: this.act_2186679009640457_closeModal_5200_getActionArgs,
        events: [],
      }
    );
    const evh_2186679009640457_clickPreventStop_5199 = new actions.EventHandlerImpl(
      {
        action: act_2186679009640457_closeModal_5200,
        event: "click.prevent.stop",
        displayName: "closeModal",
      }
    );
    const evh_2186679009640457_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_2186679009640457_clickPreventStop_5199],
      }
    );
    return {
      act_7315092382398562_closeModal_5190,
      evh_7315092382398562_close_5189,
      evh_7315092382398562_close,
      act_2248226175642056_closeModal_5192,
      evh_2248226175642056_close_5191,
      evh_2248226175642056_close,
      act_1040167445267876_closeModal_5196,
      evh_1040167445267876_success_5195,
      act_1040167445267876_crud_5194,
      evh_1040167445267876_submit_5193,
      evh_1040167445267876_submit,
      act_1548630417156826_closeModal_5198,
      evh_1548630417156826_success_5197,
      evh_1548630417156826_success,
      act_2186679009640457_closeModal_5200,
      evh_2186679009640457_clickPreventStop_5199,
      evh_2186679009640457_clickPreventStop,
      submit: act_1040167445267876_crud_5194,
    }
  }
}

Vue.component("InvoiceLineGroup", InvoiceLineGroup);

</script>