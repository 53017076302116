<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <legend
    class="text-muted"
  >
    {{ 'General'|pgettext('Meter') }}
  </legend>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="meter_type"
    :label="'Meter type'|pgettext('Meter')"
    :labelAlign="labelAlign"
    required
  >
    <ItpFormSelect
      name="meter_type"
      v-model="meter.meter_type"
      :options="meterTypes"
    >
    </ItpFormSelect>
  </ItpFormGroup>
  <ItpFormGroup
    name="parent"
    :label="'Main meter'|pgettext('Meter')"
    labelColsMd="4"
    contentColsMd="8"
    :labelAlign="labelAlign"
  >
    <ItpFormSelect2
      name="parent"
      v-model="meter.parent"
      clearable
      :dataSource="b_1548630417156826_dataSource"
      :initialOptions="meter.main_meter ? [meter.main_meter] : []"
      valueField="id"
      textField="serial_number"
      use-option-template-for-selected-option
      optionTemplate="<UcSuggestMeterOption :option=&quot;option&quot;></UcSuggestMeterOption>"
    >
    </ItpFormSelect2>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="meter_unit"
    :label="'Unit and multiplier'|pgettext('Meter')"
    :labelAlign="labelAlign"
    contentClass="form-row"
    required
    :helpTooltip="'The multiplier converts the readings into the given units.'|pgettext('Meter')"
  >
    <DIV
      class="col-4"
    >
      <ItpFormSelect
        name="unit"
        v-model="meter.unit"
        :options="units"
        placeholder="P_(&quot;Meter&quot;, &quot;Meter&quot;, &quot;unit&quot;)"
      >
      </ItpFormSelect>
    </DIV>
    <DIV
      class="col-4"
    >
      <ItpFormInput
        type="number"
        name="multiplier"
        v-model="meter.multiplier"
        :placeholder="'Multiplier'|pgettext('Meter')"
        :title="'Multiplier'|pgettext('Meter')"
      >
      </ItpFormInput>
    </DIV>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="model_name"
    :label="'Model name'|pgettext('Meter')"
    :labelAlign="labelAlign"
  >
    <ItpFormInput
      name="model_name"
      v-model="meter.model_name"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="smart_device"
    :label="'Smart Device'|pgettext('Meter')"
    :labelAlign="labelAlign"
  >
    <ItpFormCheckbox
      name="smart_device"
      v-model="meter.smart_device"
    >
    </ItpFormCheckbox>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="groups"
    :label="'Groups'|pgettext('Meter')"
    :labelAlign="labelAlign"
  >
    <ItpFormSelect2
      name="groups"
      v-model="meter.groups"
      multiple
      :options="meterGroups"
    >
    </ItpFormSelect2>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="description"
    :label="'Site'|pgettext('Meter')"
    :labelAlign="labelAlign"
  >
    <ItpFormSelect2
      name="site_id"
      v-model="meter.site_id"
      clearable
      :dataSource="b_7072234632991584_dataSource"
      :initialOptions="meter.site ? [meter.site] : []"
      valueField="id"
      :optionTemplate="b_7072234632991584_optionTemplate"
      use-option-template-for-selected-option
    >
    </ItpFormSelect2>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="description"
    :label="'Description'|pgettext('Meter')"
    :labelAlign="labelAlign"
  >
    <ItpFormTextarea
      name="description"
      v-model="meter.description"
    >
    </ItpFormTextarea>
  </ItpFormGroup>
  <hr>
  </hr>
  <legend
    class="text-muted"
  >
    {{ 'Numbers'|pgettext('Meter') }}
  </legend>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="serial_number"
    :label="'Serial number'|pgettext('Meter')"
    :labelAlign="labelAlign"
    :helpTooltip="'The device\'s unique serial number.'|pgettext('Meter')"
    required
  >
    <ItpFormInput
      name="serial_number"
      v-model="meter.serial_number"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="deveui"
    :label="'DevEUI'|pgettext('Meter')"
    :labelAlign="labelAlign"
    :helpTooltip="'64-bit globally-unique Extended Unique Identifier (EUI-64) assigned by the manufacturer, or the owner, of the end-device.'|pgettext('Meter')"
  >
    <ItpFormInput
      name="deveui"
      v-model="meter.deveui"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="tag_id"
    :label="'Tag ID'|pgettext('Meter')"
    :labelAlign="labelAlign"
    :helpTooltip="'Locally-unique tag ID to be used within the company.'|pgettext('Meter')"
  >
    <ItpFormInput
      name="tag_id"
      v-model="meter.tag_id"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <hr>
  </hr>
  <FieldsetMeterValidityData
    :meter="meter"
    :labelAlign="labelAlign"
  >
  </FieldsetMeterValidityData>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class FieldsetMeterGeneralData extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  meter!: object;

  @Watch('meter')
  onMeter(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("meter")
  }

  labelAlign!: any;
  meterTypes!: any;

  async $$load_meterTypes() {
    return this.$fn.getEnumValuesFromSchema('MeterTypes')
  }

  meterGroups!: any;

  async $$load_meterGroups() {
    return this.$fn.fetch('list_group').then(data => data.data.map(p => p.name))
  }

  units!: any;
  ux!: any;
  b_1548630417156826_dataSource!: any;
  b_7072234632991584_dataSource!: any;
  b_7072234632991584_optionTemplate!: any;
  dataMembers = ['labelAlign', 'meterTypes', 'meterGroups', 'units', 'ux', 'b_1548630417156826_dataSource', 'b_7072234632991584_dataSource', 'b_7072234632991584_optionTemplate'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        labelAlign: "right",
        meterTypes: null,
        meterGroups: null,
        units: ["n/a"
          ,
          "kWh"
          ,
          "MWh"
          ,
          "m3"
          ,
          "GJ"
          ,
        ]
        ,
        ux: null,
        b_1548630417156826_dataSource: {
          name: "suggest_meter",
        }
        ,
        b_7072234632991584_dataSource: {
          name: "suggest_property",
          parameters: {
            types: "property",
          }
          ,
        }
        ,
        b_7072234632991584_optionTemplate: "<div>\n\
  {{ option.name }}\n\
  <span :if=\"option.description\">- {{ option.description }}</span>\n\
</div>",
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_6734: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_6733: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_6734 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_6733 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_6734,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_6733],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_6734,
      evh_7315092382398562_reload_6733,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("FieldsetMeterGeneralData", FieldsetMeterGeneralData);

</script>