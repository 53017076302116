"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UcPropertyValidityCard = exports.UcPropertyUnits = exports.UcPropertyGeneralData = exports.FieldsetPropertyGeneralData = void 0;
const FieldsetPropertyGeneralData_yaml_component_1 = require("./FieldsetPropertyGeneralData.yaml?component");
exports.FieldsetPropertyGeneralData = FieldsetPropertyGeneralData_yaml_component_1.default;
const UcPropertyGeneralData_yaml_component_1 = require("./UcPropertyGeneralData.yaml?component");
exports.UcPropertyGeneralData = UcPropertyGeneralData_yaml_component_1.default;
const UcPropertyUnits_yaml_component_1 = require("./UcPropertyUnits.yaml?component");
exports.UcPropertyUnits = UcPropertyUnits_yaml_component_1.default;
const UcPropertyValidityCard_yaml_component_1 = require("./UcPropertyValidityCard.yaml?component");
exports.UcPropertyValidityCard = UcPropertyValidityCard_yaml_component_1.default;
