"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UcEmployeeList = exports.WEmployeeModal = exports.UcEmployeeFieldset = exports.UcEmployeeCard = void 0;
const UcEmployeeCard_yaml_component_1 = require("./UcEmployeeCard.yaml?component");
exports.UcEmployeeCard = UcEmployeeCard_yaml_component_1.default;
const UcEmployeeFieldset_yaml_component_1 = require("./UcEmployeeFieldset.yaml?component");
exports.UcEmployeeFieldset = UcEmployeeFieldset_yaml_component_1.default;
const WEmployeeModal_yaml_component_1 = require("./WEmployeeModal.yaml?component");
exports.WEmployeeModal = WEmployeeModal_yaml_component_1.default;
const UcEmployeeList_yaml_component_1 = require("./UcEmployeeList.yaml?component");
exports.UcEmployeeList = UcEmployeeList_yaml_component_1.default;
