var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "InvoiceLineMeterReadingSelector",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c("UcHeader", {
            attrs: {
              slot: "header",
              card: !_vm.modal,
              modal: _vm.modal,
              title: _vm.documentTitle
            },
            on: {
              close: function($event) {
                return _vm.evh_2248226175642056_close($event, {})
              }
            },
            slot: "header"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded && _vm.services
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _vm.loaded
                ? _c(
                    "BListGroup",
                    [
                      !_vm.services.length
                        ? _c("BListGroupItem", [
                            _c("span", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm._f("pgettext")(
                                      "No rental services.",
                                      "MeterRentalServices"
                                    )
                                  ) +
                                  "\n        "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(_vm.services, function(item) {
                        return _vm.services && _vm.services.length
                          ? _c(
                              "BListGroupItem",
                              {
                                key: item.id,
                                staticClass: "d-flex flex-column"
                              },
                              [
                                _c(
                                  "ItpPanel",
                                  {
                                    attrs: {
                                      collapsible: "",
                                      initialState: "collapsed"
                                    }
                                  },
                                  [
                                    _c(
                                      "DIV",
                                      {
                                        staticClass: "w-100",
                                        attrs: { slot: "caption" },
                                        slot: "caption"
                                      },
                                      [
                                        _c(
                                          "ItpBox",
                                          {
                                            staticClass: "w-100",
                                            attrs: { alignItems: "start" }
                                          },
                                          [
                                            _c("UcInactive", {
                                              attrs: { value: item.inactive }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "ItpBox",
                                              {
                                                staticClass: "w-100",
                                                attrs: { direction: "column" }
                                              },
                                              [
                                                _c(
                                                  "ItpBox",
                                                  { staticClass: "W-100" },
                                                  [
                                                    _c(
                                                      "ItpBox",
                                                      {
                                                        staticClass: "col-md-5"
                                                      },
                                                      [
                                                        _c(
                                                          "ItpText",
                                                          {
                                                            staticClass:
                                                              "font-weight-bold mr-2",
                                                            attrs: {
                                                              strikethrough: _vm.isServiceStale(
                                                                item
                                                              )
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  item.service &&
                                                                    item.service
                                                                      .name
                                                                ) +
                                                                " (" +
                                                                _vm._s(
                                                                  item.billing_unit
                                                                ) +
                                                                ")\n                    "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "ItpBox",
                                                      {
                                                        staticClass: "col-md-4"
                                                      },
                                                      [
                                                        _c("UcValidityPeriod", {
                                                          staticClass: "pl-3",
                                                          attrs: {
                                                            from:
                                                              item.valid_from,
                                                            to: item.valid_to
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "ItpBox",
                                                      {
                                                        staticClass: "col-md-3",
                                                        attrs: {
                                                          alignItems: "center",
                                                          direction: "column"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ItpBadge",
                                                          {
                                                            staticClass: "mx-3",
                                                            attrs: {
                                                              title: _vm._f(
                                                                "gettext"
                                                              )(
                                                                "Billing multiplier"
                                                              ),
                                                              "no-box-shadow":
                                                                "",
                                                              variant: "light"
                                                            }
                                                          },
                                                          [
                                                            _c("ItpIcon", {
                                                              attrs: {
                                                                name: "fa-times"
                                                              }
                                                            }),
                                                            _vm._v(" "),
                                                            _c("ItpText", {
                                                              attrs: {
                                                                text:
                                                                  item.meter_factor
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        item.non_billable
                                                          ? _c("ItpBadge", {
                                                              staticClass:
                                                                "mx-3 mt-2",
                                                              attrs: {
                                                                variant:
                                                                  "warning",
                                                                text: _vm._f(
                                                                  "pgettext"
                                                                )(
                                                                  "Non billable",
                                                                  "RentalService"
                                                                ),
                                                                "no-box-shadow":
                                                                  ""
                                                              }
                                                            })
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "ItpBox",
                                                  { staticClass: "W-100" },
                                                  [
                                                    item.notes
                                                      ? _c("ItpText", {
                                                          attrs: {
                                                            text: item.notes,
                                                            pre: ""
                                                          }
                                                        })
                                                      : _vm._e()
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "ItpBox",
                                                  [
                                                    _c(
                                                      "UcCompactMeterDisplay",
                                                      {
                                                        attrs: {
                                                          payload: item.meter
                                                        }
                                                      }
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        item.consumptions &&
                                        item.consumptions.length
                                          ? _c(
                                              "ul",
                                              {
                                                staticClass: "list-group my-2"
                                              },
                                              _vm._l(
                                                item.consumptions,
                                                function(cons) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      key: cons.$key,
                                                      class:
                                                        "list-group-item list-group-item-" +
                                                        (item.inactive
                                                          ? "warning"
                                                          : "primary")
                                                    },
                                                    [
                                                      _c(
                                                        "ItpBox",
                                                        {
                                                          attrs: {
                                                            direction: "row"
                                                          }
                                                        },
                                                        [
                                                          _c("DIV", [
                                                            _c(
                                                              "strong",
                                                              {
                                                                staticClass:
                                                                  "mr-2"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    cons.from_amount
                                                                  ) +
                                                                    " " +
                                                                    _vm._s(
                                                                      cons.from_unit
                                                                    )
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "small",
                                                              {
                                                                staticClass:
                                                                  "mr-2"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "(" +
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "date"
                                                                      )(
                                                                        cons.from_date
                                                                      )
                                                                    ) +
                                                                    ")"
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v("\n-\n"),
                                                            _c(
                                                              "strong",
                                                              {
                                                                staticClass:
                                                                  "mr-2"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    cons.to_amount
                                                                  ) +
                                                                    " " +
                                                                    _vm._s(
                                                                      cons.to_unit
                                                                    )
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "small",
                                                              {
                                                                staticClass:
                                                                  "mr-2"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "(" +
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "date"
                                                                      )(
                                                                        cons.to_date
                                                                      )
                                                                    ) +
                                                                    ")"
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v("\n=\n"),
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  cons.diff
                                                                ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    cons.to_unit
                                                                  )
                                                              )
                                                            ])
                                                          ]),
                                                          _vm._v(" "),
                                                          _c(
                                                            "ItpButton",
                                                            {
                                                              staticClass:
                                                                "ml-auto",
                                                              attrs: {
                                                                size: "sm"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.preventDefault()
                                                                  $event.stopPropagation()
                                                                  return _vm.evh_1969262983629788_clickPreventStop(
                                                                    $event,
                                                                    {
                                                                      item: item,
                                                                      cons: cons
                                                                    }
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                    Select\n                  "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "table",
                                      {
                                        staticClass:
                                          "table table-condensed table-sm"
                                      },
                                      [
                                        _c("thead", [
                                          _c("tr", [
                                            _c("th"),
                                            _vm._v(" "),
                                            _c("th", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm._f("pgettext")(
                                                      "Value",
                                                      "InvoiceLineMeterReadingSelector|ColHead"
                                                    )
                                                  ) +
                                                  "\n                "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("th", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm._f("pgettext")(
                                                      "Diff",
                                                      "InvoiceLineMeterReadingSelector|ColHead"
                                                    )
                                                  ) +
                                                  "\n                "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("th", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm._f("pgettext")(
                                                      "Date",
                                                      "InvoiceLineMeterReadingSelector|ColHead"
                                                    )
                                                  ) +
                                                  "\n                "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("th", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm._f("pgettext")(
                                                      "Notes",
                                                      "InvoiceLineMeterReadingSelector|ColHead"
                                                    )
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "tbody",
                                          _vm._l(item.readings, function(
                                            reading
                                          ) {
                                            return _c(
                                              "tr",
                                              { key: reading.id },
                                              [
                                                _c(
                                                  "td",
                                                  [
                                                    _c("BFormCheckbox", {
                                                      attrs: {
                                                        name: reading.id,
                                                        id: reading.id
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.evh_7391797828980767_change(
                                                            $event,
                                                            {
                                                              item: item,
                                                              reading: reading
                                                            }
                                                          )
                                                        }
                                                      },
                                                      model: {
                                                        value: reading.selected,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            reading,
                                                            "selected",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "reading.selected"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(reading.value) +
                                                        " " +
                                                        _vm._s(reading.unit)
                                                    )
                                                  ])
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  { staticClass: "text-muted" },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(reading.diff) +
                                                        " " +
                                                        _vm._s(reading.unit) +
                                                        "\n\n                "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm._f("date")(
                                                          reading.reading_date
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("small", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("time")(
                                                          reading.reading_time
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(reading.notes) +
                                                      "\n\n                "
                                                  )
                                                ])
                                              ]
                                            )
                                          }),
                                          0
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      })
                    ],
                    2
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
              slot: "footer"
            },
            [
              _c("ItpButton", {
                attrs: { text: _vm._f("gettext")("Close"), variant: "default" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_1548630417156826_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }