<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <DIV
    v-if="item.revision_date"
  >
    <ItpText muted>{{ 'Contract date'|pgettext('Contract') }}:</ItpText> {{ item.revision_date }}

  </DIV>
  <DIV
    v-if="item.valid_from"
  >
    <ItpText muted>{{ 'From'|pgettext('Contract') }}:</ItpText> {{ item.valid_from }}

  </DIV>
  <DIV
    v-if="item.valid_to"
  >
    <ItpText muted>{{ 'To'|pgettext('Contract') }}:</ItpText> {{ item.valid_to }}

  </DIV>
  <DIV
    v-if="!item.valid_to"
  >
    <ItpText muted>{{ 'To'|pgettext('Contract') }}:</ItpText> {{ 'Indefinite'|pgettext('Contract') }}

  </DIV>
  <DIV
    v-if="item.notice_period"
  >
    <ItpText muted>{{ 'Notice Period'|pgettext('Contract') }}:</ItpText> {{ item.notice_period }}

  </DIV>
  <DIV>
    <ItpText muted>{{ 'Auto renewal'|pgettext('Contract') }}:</ItpText> {{ item.auto_renewal ? gettext('On') : gettext('Off') }}

  </DIV>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcContractValidityCard extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  item!: object;

  @Watch('item')
  onItem(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("item")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_6138: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_6137: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_6138 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_6137 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_6138,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_6137],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_6138,
      evh_7315092382398562_reload_6137,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("UcContractValidityCard", UcContractValidityCard);

</script>