"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcPaymentTransactionTypeSymbol = class UcPaymentTransactionTypeSymbol extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['transactionTypes', 'ux'];
    }
    beforeCreate() {
    }
    onPayment_type(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("paymentType");
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            transactionTypes: {
                credit: {
                    icon: "zondicons:arrow-outline-down",
                    tooltip: this.$fn.pgettext("PaymentType", "credit"),
                    color: "green",
                },
                debit: {
                    icon: "zondicons:arrow-outline-up",
                    tooltip: this.$fn.pgettext("PaymentType", "debit"),
                    color: "red",
                },
                deposit: {
                    icon: "zondicons:arrow-outline-down",
                    tooltip: this.$fn.pgettext("PaymentType", "deposit"),
                    color: "green",
                },
                payment: {
                    icon: "zondicons:arrow-outline-up",
                    tooltip: this.$fn.pgettext("PaymentType", "payment"),
                    color: "red",
                },
            },
            ux: null,
        });
    }
    get bindings() {
        if (this.loaded) {
            return this.transactionTypes[this.paymentType];
        }
        return null;
    }
    getActions() {
        return {};
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: String,
    }),
    __metadata("design:type", String)
], UcPaymentTransactionTypeSymbol.prototype, "paymentType", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('paymentType'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcPaymentTransactionTypeSymbol.prototype, "onPayment_type", null);
UcPaymentTransactionTypeSymbol = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcPaymentTransactionTypeSymbol);
exports.default = UcPaymentTransactionTypeSymbol;
vue_property_decorator_1.Vue.component("UcPaymentTransactionTypeSymbol", UcPaymentTransactionTypeSymbol);
