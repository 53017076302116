"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let InvoiceBrowser = class InvoiceBrowser extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['formId', 'selectedItems', 'invoices', 'selectedInvoices', 'filters', 'invoiceKinds', 'ux', 'b_7315092382398562_modalBindings', 'b_5731695935601903_dataSource', 'b_3054425420812800_dataSource', 'b_2070266589801014_editable', 'b_2070266589801014_columnDefaults', 'b_2070266589801014_columns'];
    }
    beforeCreate() {
    }
    invoiceTypeBadgeClass(...args) {
        const [row] = args;
        const cc = this.$config.invoice.types.colorCodes[row.invoice_type];
        return `bg-${cc.bg} text-${cc.text}`;
    }
    onMultiselect(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("multiselect");
    }
    onKind(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("kind");
    }
    onCompany_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("company_id");
    }
    onClient_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("client_id");
    }
    onInvoice_number(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("invoice_number");
    }
    onInvoice_date(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("invoice_date");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_formId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    $$load_filters() {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                date_from: this.invoice_date,
                date_to: null,
                kind: this.kind ? [this.kind] : [],
                company_id: this.company_id,
                client_id: this.client_id,
                unpaid: true
            };
        });
    }
    $$load_b_2070266589801014_columns() {
        return __awaiter(this, void 0, void 0, function* () {
            return [{
                    field: "invoice_kind",
                    name: this.$fn.pgettext("InvoicesView", "Kind"),
                    formatter: {
                        name: "mapvalue",
                        mapping: {
                            incoming: this.$fn.pgettext("InvoiceKind", "In"),
                            outgoing: this.$fn.pgettext("InvoiceKind", "Out"),
                        },
                    },
                },
                {
                    field: "invoice_number",
                    name: this.$fn.pgettext("InvoicesView", "Invoice nr"),
                    formatter: {
                        name: "cellclass",
                        value: (row) => ({
                            'font-weight-bold': true,
                            'bg-yellow-200': !row.locked,
                            'bg-yellow-500 text-yellow-800': row.is_corrected,
                            'bg-red-300 text-red-800': row.is_reversed,
                        }),
                    },
                },
                {
                    field: "invoice_type",
                    name: this.$fn.pgettext("InvoicesView", "Type"),
                    formatter: {
                        name: "chain",
                        formatters: [{
                                name: "gettext",
                                context: "InvoiceType",
                                conversion: "StartCase",
                                filterInput: true,
                            },
                            {
                                name: "badge",
                                dot: true,
                                pill: true,
                                badgeClass: this.invoiceTypeBadgeClass,
                            },
                        ],
                    },
                },
                {
                    field: "invoice_date",
                    name: this.$fn.pgettext("InvoicesView", "Date"),
                },
                {
                    field: "fulfillment_date",
                    name: this.$fn.pgettext("InvoicesView", "Fulfillment date"),
                    type: "date",
                },
                {
                    field: "due_date",
                    name: this.$fn.pgettext("InvoicesView", "Due date"),
                    type: "date",
                },
                {
                    field: "invoice_status",
                    name: this.$fn.pgettext("InvoicesView", "Status"),
                    formatter: {
                        name: "gettext",
                        context: "Status",
                        conversion: "StartCase",
                        filterInput: true,
                    },
                },
                {
                    field: "client_name",
                    name: this.$fn.pgettext("InvoicesView", "Client"),
                    formatter: {
                        name: "routeLink",
                        to: "client",
                        navigateOnIconClick: true,
                        params: {
                            id: "client_id",
                        },
                    },
                },
                {
                    field: "net_total",
                    name: this.$fn.pgettext("InvoicesView", "Net"),
                    type: "number",
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                },
                {
                    field: "total",
                    name: this.$fn.pgettext("InvoicesView", "Amount"),
                    type: "number",
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                },
                {
                    field: "paid",
                    name: this.$fn.pgettext("InvoicesView", "Paid"),
                    type: "number",
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                },
                {
                    field: "unpaid",
                    name: this.$fn.pgettext("InvoicesView", "Claim"),
                    type: "number",
                    formatter: {
                        name: "chain",
                        formatters: [{
                                name: "localestring",
                                locale: "hu-HU",
                            },
                            {
                                name: "cellclass",
                                value: (row) => ({
                                    'text-red-500': row.unpaid != null && row.unpaid > 0,
                                    'text-green-500': row.unpaid != null && row.unpaid <= 0,
                                    'font-weight-bold': row.unpaid != null
                                }),
                            },
                        ],
                    },
                },
                {
                    field: "currency",
                    name: this.$fn.pgettext("InvoicesView", "Currency"),
                },
                {
                    field: "tags",
                    name: this.$fn.pgettext("InvoicesView", "Tags"),
                },
                {
                    field: "rentals",
                    name: this.$fn.pgettext("InvoicesView", "Rentals"),
                },
                {
                    field: "contracts",
                    name: this.$fn.pgettext("InvoicesView", "Contracts"),
                },
            ];
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            formId: null,
            selectedItems: null,
            invoices: [],
            selectedInvoices: [],
            filters: null,
            invoiceKinds: [{
                    id: "incoming",
                    text: this.$fn.pgettext("InvoiceKind", "Incoming"),
                },
                {
                    id: "outgoing",
                    text: this.$fn.pgettext("InvoiceKind", "Outgoing"),
                },
            ],
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "full-screen",
            },
            b_5731695935601903_dataSource: {
                name: "suggest_company",
                parameters: {
                    owned: true,
                },
            },
            b_3054425420812800_dataSource: {
                name: "suggest_client",
            },
            b_2070266589801014_editable: false,
            b_2070266589801014_columnDefaults: {
                sortable: true,
                filterable: true,
                resizable: true,
            },
            b_2070266589801014_columns: null,
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.pgettext("InvoiceBrowser", "Browse Invoices"),
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_2312_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_5534025912102772_setData_2318_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "invoices",
                value: $event.data.response.data,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_5534025912102772_cache_2320_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "filters",
            };
        });
    }
    act_5534025912102772_request_2316_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "list_invoice",
                parameters: _.pickBy(this.filters, v => v || !_.isEmpty(v)),
            };
        });
    }
    evh_5534025912102772_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5534025912102772_submit.executeFromDOM(this, event, scope);
        });
    }
    act_2070266589801014_setData_2322_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "selectedItems",
                value: $event.data.sender.getSelectedItems(),
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_2070266589801014_selectedRowsChanged(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2070266589801014_selectedRowsChanged.executeFromDOM(this, event, scope);
        });
    }
    act_1548630417156826_closeComponent_2324_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    ok: true,
                    value: this.multiselect ? this.selectedItems : this.selectedItems[0],
                },
            };
        });
    }
    evh_1548630417156826_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1548630417156826_click.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_2312 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_2312_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_2311 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_2312,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_2311],
        });
        const act_2248226175642056_closeComponent_2314 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2248226175642056_close_2313 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_2314,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_2313],
        });
        const act_5534025912102772_setData_2318 = new core_1.actions.SetDataAction({
            actionArgs: this.act_5534025912102772_setData_2318_getActionArgs,
            events: [],
        });
        const evh_5534025912102772_success_2317 = new core_1.actions.EventHandlerImpl({
            action: act_5534025912102772_setData_2318,
            event: "success",
            displayName: "setData",
        });
        const act_5534025912102772_cache_2320 = new core_1.actions.CacheAction({
            actionArgs: this.act_5534025912102772_cache_2320_getActionArgs,
            events: [],
        });
        const evh_5534025912102772_success_2319 = new core_1.actions.EventHandlerImpl({
            action: act_5534025912102772_cache_2320,
            event: "success",
            displayName: "cache",
        });
        const act_5534025912102772_request_2316 = new core_1.actions.RequestAction({
            actionArgs: this.act_5534025912102772_request_2316_getActionArgs,
            displayName: "search",
            events: [evh_5534025912102772_success_2317, evh_5534025912102772_success_2319],
        });
        const evh_5534025912102772_submit_2315 = new core_1.actions.EventHandlerImpl({
            action: act_5534025912102772_request_2316,
            event: "submit",
            displayName: "search",
        });
        const evh_5534025912102772_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5534025912102772_submit_2315],
        });
        const act_2070266589801014_setData_2322 = new core_1.actions.SetDataAction({
            actionArgs: this.act_2070266589801014_setData_2322_getActionArgs,
            events: [],
        });
        const evh_2070266589801014_selectedRowsChanged_2321 = new core_1.actions.EventHandlerImpl({
            action: act_2070266589801014_setData_2322,
            event: "selectedRowsChanged",
            displayName: "setData",
        });
        const evh_2070266589801014_selectedRowsChanged = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2070266589801014_selectedRowsChanged_2321],
        });
        const act_1548630417156826_closeComponent_2324 = new core_1.actions.CloseComponentAction({
            actionArgs: this.act_1548630417156826_closeComponent_2324_getActionArgs,
            events: [],
        });
        const evh_1548630417156826_click_2323 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_closeComponent_2324,
            event: "click",
            displayName: "closeComponent",
        });
        const evh_1548630417156826_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1548630417156826_click_2323],
        });
        return {
            act_7315092382398562_closeModal_2312,
            evh_7315092382398562_close_2311,
            evh_7315092382398562_close,
            act_2248226175642056_closeComponent_2314,
            evh_2248226175642056_close_2313,
            evh_2248226175642056_close,
            act_5534025912102772_setData_2318,
            evh_5534025912102772_success_2317,
            act_5534025912102772_cache_2320,
            evh_5534025912102772_success_2319,
            act_5534025912102772_request_2316,
            evh_5534025912102772_submit_2315,
            evh_5534025912102772_submit,
            act_2070266589801014_setData_2322,
            evh_2070266589801014_selectedRowsChanged_2321,
            evh_2070266589801014_selectedRowsChanged,
            act_1548630417156826_closeComponent_2324,
            evh_1548630417156826_click_2323,
            evh_1548630417156826_click,
            search: act_5534025912102772_request_2316,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
    }),
    __metadata("design:type", Boolean)
], InvoiceBrowser.prototype, "multiselect", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('multiselect'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], InvoiceBrowser.prototype, "onMultiselect", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], InvoiceBrowser.prototype, "kind", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('kind'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], InvoiceBrowser.prototype, "onKind", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], InvoiceBrowser.prototype, "company_id", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('company_id'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], InvoiceBrowser.prototype, "onCompany_id", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], InvoiceBrowser.prototype, "client_id", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('client_id'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], InvoiceBrowser.prototype, "onClient_id", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], InvoiceBrowser.prototype, "invoice_number", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('invoice_number'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], InvoiceBrowser.prototype, "onInvoice_number", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({}),
    __metadata("design:type", typeof (_a = typeof date !== "undefined" && date) === "function" ? _a : Object)
], InvoiceBrowser.prototype, "invoice_date", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('invoice_date'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], InvoiceBrowser.prototype, "onInvoice_date", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "full-screen",
    }),
    __metadata("design:type", String)
], InvoiceBrowser.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], InvoiceBrowser.prototype, "onSize", null);
InvoiceBrowser = __decorate([
    (0, vue_property_decorator_1.Component)()
], InvoiceBrowser);
exports.default = InvoiceBrowser;
vue_property_decorator_1.Vue.component("InvoiceBrowser", InvoiceBrowser);
