<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpWidgetCard>
    <ItpBox
      alignItems="center"
      slot="header"
    >
      <strong>
        {{ 'Financial'|pgettext('Contract') }}
      </strong>
      <ItpButton
        :text="'Edit...'|gettext"
        variant="light"
        size="sm"
        class="ml-auto"
        v-if="!readonly"
        @click.prevent.stop="evh_8320016629450276_clickPreventStop($event, {})"
      >
      </ItpButton>
    </ItpBox>
    <BListGroup
      v-if="loaded"
    >
      <ItpLabeledListGroupItem
        :label="'Advances'|pgettext('Contract')"
        :labelCols="labelCols"
      >
        <ItpText>
          {{ item.rental_fee_advances_initial_months }} {{ 'months initial'|pgettext('ContractAdvances') }}
<br/>
{{ item.rental_fee_advances_reserve_months }} {{ 'months reserved'|pgettext('ContractAdvances') }}

        </ItpText>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Billing currency'|pgettext('Contract')"
        :labelCols="labelCols"
      >
        <ItpText>
          {{ item.billing_currency }}
        </ItpText>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Billing frequency'|pgettext('Contract')"
        :labelCols="labelCols"
      >
        <ItpText>
          {{ billingCycleValue }} {{ billingCycleName|gettext }}
        </ItpText>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Notes on invoice'|pgettext('Contract')"
        :labelCols="labelCols"
        :helpTooltip="'Text to append to the invoices generated from this contract.'|pgettext('Contract')"
        v-if="item.invoice_notes"
      >
        <ItpText>
          {{ item.invoice_notes }}
        </ItpText>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Payment terms'|pgettext('Contract')"
        :labelCols="labelCols"
        v-if="item.payment_terms"
      >
        <ItpText>
          {{ item.payment_terms.type|pgettext('ContractPaymentTerms') }}, {{ item.payment_terms.value }}

        </ItpText>
      </ItpLabeledListGroupItem>
    </BListGroup>
  </ItpWidgetCard>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcContractInvoicing extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  contract!: object;

  @Watch('contract')
  onContract(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("contract")
  }

  @Prop({
    type: Boolean,
  })
  readonly!: boolean;

  @Watch('readonly')
  onReadonly(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("readonly")
  }

  labelCols!: any;
  ux!: any;
  dataMembers = ['labelCols', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        labelCols: 6,
        ux: null,
      },
    }
  }

  get item() {
    if (this.loaded) {

      return this.contract.invoicing
    }
    return null;
  }

  get billingCycleName() {
    if (this.loaded) {

      return this.item.billing_cycle.split(' ')[1]
    }
    return null;
  }

  get billingCycleValue() {
    if (this.loaded) {

      return this.item.billing_cycle.split(' ')[0]
    }
    return null;
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_8320016629450276_emit_6270_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_8320016629450276_emit_6270, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_8320016629450276_close_6269_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_8320016629450276_close_6269, alias=undefined
    return !!$event.data.modalResult.value
  }

  async act_8320016629450276_showModal_6268_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8320016629450276_showModal_6268, alias=undefined
    return {
      name: "ContractEditor",
      props: {
        target: "invoicing",
        contract: _.cloneDeep(this.contract),
      }
      ,
    }
  }

  async evh_8320016629450276_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_6266: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_6265: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_8320016629450276_emit_6270: actions.EmitAction;
    evh_8320016629450276_close_6269: actions.EventHandlerImpl;
    act_8320016629450276_showModal_6268: actions.ShowModalAction;
    evh_8320016629450276_clickPreventStop_6267: actions.EventHandlerImpl;
    evh_8320016629450276_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_6266 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_6265 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_6266,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_6265],
      }
    );
    const act_8320016629450276_emit_6270 = new actions.EmitAction(
      {
        actionArgs: this.act_8320016629450276_emit_6270_getActionArgs,
        events: [],
      }
    );
    const evh_8320016629450276_close_6269 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_emit_6270,
        event: "close",
        when: this.evh_8320016629450276_close_6269_getWhen,
        displayName: "emit",
      }
    );
    const act_8320016629450276_showModal_6268 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8320016629450276_showModal_6268_getActionArgs,
        events: [evh_8320016629450276_close_6269],
      }
    );
    const evh_8320016629450276_clickPreventStop_6267 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_showModal_6268,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_8320016629450276_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_clickPreventStop_6267],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_6266,
      evh_7315092382398562_reload_6265,
      evh_7315092382398562_reload,
      act_8320016629450276_emit_6270,
      evh_8320016629450276_close_6269,
      act_8320016629450276_showModal_6268,
      evh_8320016629450276_clickPreventStop_6267,
      evh_8320016629450276_clickPreventStop,
    }
  }
}

Vue.component("UcContractInvoicing", UcContractInvoicing);

</script>