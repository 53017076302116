export * from './accounts';
export * from './addresses';
export * from './assets';
export * from './client';
export * from './common';
export * from './contacts';
export * from './contracts';
export * from './dashboard';
export * from './employees';
export * from './finance';
export * from './invoice';
export * from './invoiceRegister';
export * from './jobs';
export * from './letterRegister';
export * from './meters';
export * from './multilineNotes';
export * from './numberings';
export * from './payments';
export * from './product';
export * from './property';
export * from './registers';
export * from './rental';
export * from './settings';
export * from './tasks';
export * from './templates';
export * from './worksheets';

import MainMenu from './MainMenu.yaml?component';
export { MainMenu };
