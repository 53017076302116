"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let InvoicesViewFilters = class InvoicesViewFilters extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['item', 'ux', 'b_8564662037462133_options'];
    }
    beforeCreate() {
        this.$$cache_item = new core_1.ComponentValueCache(this, "item", "InvoicesViewFilters");
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                date_from: null,
                date_to: null,
                unpaid: false,
                kind: this.kind,
                date_type: "invoice_date",
            };
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            item: null,
            ux: null,
            b_8564662037462133_options: [{
                    value: "invoice_date",
                    text: this.$fn.pgettext("Invoice", "Invoice date"),
                },
                {
                    value: "fulfillment_date",
                    text: this.$fn.pgettext("Invoice", "Fulfillment date"),
                },
                {
                    value: "due_date",
                    text: this.$fn.pgettext("Invoice", "Due date"),
                },
            ],
        });
    }
    evh_7315092382398562_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
        });
    }
    act_3343864469950044_setData_5384_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item.date_from",
                value: $event.data[0],
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_3343864469950044_setData_5386_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item.date_to",
                value: $event.data[1],
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_3343864469950044_changed(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_3343864469950044_changed.executeFromDOM(this, event, scope);
        });
    }
    act_4892266742011185_emit_5388_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "submit",
                value: Object.assign(Object.assign({}, this.item), { kind: this.kind }),
            };
        });
    }
    evh_4892266742011185_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_4892266742011185_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_reloadComponentData_5382 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7315092382398562_reload_5381 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_5382,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_5381],
        });
        const act_3343864469950044_setData_5384 = new core_1.actions.SetDataAction({
            actionArgs: this.act_3343864469950044_setData_5384_getActionArgs,
            events: [],
        });
        const evh_3343864469950044_changed_5383 = new core_1.actions.EventHandlerImpl({
            action: act_3343864469950044_setData_5384,
            event: "changed",
            displayName: "setData",
        });
        const act_3343864469950044_setData_5386 = new core_1.actions.SetDataAction({
            actionArgs: this.act_3343864469950044_setData_5386_getActionArgs,
            events: [],
        });
        const evh_3343864469950044_changed_5385 = new core_1.actions.EventHandlerImpl({
            action: act_3343864469950044_setData_5386,
            event: "changed",
            displayName: "setData",
        });
        const evh_3343864469950044_changed = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3343864469950044_changed_5383, evh_3343864469950044_changed_5385],
        });
        const act_4892266742011185_emit_5388 = new core_1.actions.EmitAction({
            actionArgs: this.act_4892266742011185_emit_5388_getActionArgs,
            events: [],
        });
        const evh_4892266742011185_clickPreventStop_5387 = new core_1.actions.EventHandlerImpl({
            action: act_4892266742011185_emit_5388,
            event: "click.prevent.stop",
            displayName: "emit",
        });
        const evh_4892266742011185_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4892266742011185_clickPreventStop_5387],
        });
        return {
            act_7315092382398562_reloadComponentData_5382,
            evh_7315092382398562_reload_5381,
            evh_7315092382398562_reload,
            act_3343864469950044_setData_5384,
            evh_3343864469950044_changed_5383,
            act_3343864469950044_setData_5386,
            evh_3343864469950044_changed_5385,
            evh_3343864469950044_changed,
            act_4892266742011185_emit_5388,
            evh_4892266742011185_clickPreventStop_5387,
            evh_4892266742011185_clickPreventStop,
        };
    }
};
InvoicesViewFilters = __decorate([
    (0, vue_property_decorator_1.Component)()
], InvoicesViewFilters);
exports.default = InvoicesViewFilters;
vue_property_decorator_1.Vue.component("InvoicesViewFilters", InvoicesViewFilters);
