<template>
<ItpBox
  alignItems="center"
  class="items-spacing w-100"
  v-if="loaded"
>
  <ItpBox
    direction="column"
  >
    <ItpBox>
      <ItpText
        muted
        class="mb-0"
      >
        {{ resourceTitle }}
      </ItpText>
      <UcPaymentTransactionTypeSymbol
        :paymentType="payment.payment_type"
        class="mx-2"
        v-if="payment.payment_type"
      >
      </UcPaymentTransactionTypeSymbol>
    </ItpBox>
    <ItpText
      tag="h4"
      :text="resourceKey"
      class="mb-0"
    >
    </ItpText>
  </ItpBox>
  <DIV
    class="btn-toolbar ml-auto"
  >
    <ItpButton
      icon="fa-sync-alt"
      :tooltip="'Reload'|gettext"
      :spinning="actions.reload.isRunning"
      variant="light"
      class="ml-auto mr-2"
      v-if="!isNew"
      @click.prevent.stop="evh_1040167445267876_clickPreventStop($event, {})"
    >
    </ItpButton>
    <ItpButton
      variant="light"
      icon="icon-park-outline:preview-open"
      :href="payment.preview_url"
      :tooltip="'Open preview'|pgettext('Payment')"
      target="_blank"
      use-auth
      show-spinner
      class="ml-auto mr-2"
      v-if="payment.preview_url"
    >
    </ItpButton>
    <b-dropdown
      right
      variant="light"
      class="border"
      v-if="!isNew"
    >
      <ItpIcon
        fa="bars"
        class="mx-1"
        slot="button-content"
      >
      </ItpIcon>
      <BtnDeleteResource
        no-icon
        dropdown-item
        :resource="payment"
        :tooltip="'Delete payment'|pgettext('Payment')"
        :disabled="payment.isReadonly"
        v-if="!isNew"
        @success="evh_4942415580156499_success($event, {})"
      >
      </BtnDeleteResource>
    </b-dropdown>
  </DIV>
</ItpBox>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcPaymentHeader extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  payment!: object;

  @Watch('payment')
  onPayment(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("payment")
  }

  messages!: any;
  ux!: any;
  dataMembers = ['messages', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        messages: {
          cash: {
            in: this.$fn.pgettext("Payment", "Cash-In"),
            out: this.$fn.pgettext("Payment", "Cash-Out"),
          }
          ,
          bank_account: this.$fn.pgettext("Payment", "Bank transaction"),
          bad_debt_journal: this.$fn.pgettext("Payment", "Bad debt"),
          reconciliation_of_invoices: this.$fn.pgettext("Payment", "Invoice reconciliation"),
          newTransaction: this.$fn.pgettext("Payment", "New settlement"),
        }
        ,
        ux: null,
      },
    }
  }

  get isNew() {
    if (this.loaded) {

      return !this.payment.id
    }
    return null;
  }

  get isPettyCash() {
    if (this.loaded) {

      return this.payment.payment_method === 'cash'
    }
    return null;
  }

  get resourceTitle() {
    if (this.loaded) {

      return this.isPettyCash ? this.messages.cash[this.payment.direction] : this.messages[this.payment.account.account_type]
    }
    return null;
  }

  get resourceKey() {
    if (this.loaded) {

      return this.isNew && !this.isPettyCash ? this.messages.newTransaction : this.payment.snapshot('payment_number')
    }
    return null;
  }

  async act_1040167445267876_emit_5574_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_1040167445267876_emit_5574, alias=reload
    return {
      event: "reload",
    }
  }

  async evh_1040167445267876_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_4942415580156499_emit_5576_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_4942415580156499_emit_5576, alias=undefined
    return {
      event: "deleted",
    }
  }

  async evh_4942415580156499_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4942415580156499_success.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_1040167445267876_emit_5574: actions.EmitAction;
    evh_1040167445267876_clickPreventStop_5573: actions.EventHandlerImpl;
    evh_1040167445267876_clickPreventStop: actions.EventHandlerGroup;
    act_4942415580156499_emit_5576: actions.EmitAction;
    evh_4942415580156499_success_5575: actions.EventHandlerImpl;
    evh_4942415580156499_success: actions.EventHandlerGroup;
  }

  getActions() {
    const act_1040167445267876_emit_5574 = new actions.EmitAction(
      {
        actionArgs: this.act_1040167445267876_emit_5574_getActionArgs,
        displayName: "reload",
        events: [],
      }
    );
    const evh_1040167445267876_clickPreventStop_5573 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_emit_5574,
        event: "click.prevent.stop",
        displayName: "reload",
      }
    );
    const evh_1040167445267876_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_clickPreventStop_5573],
      }
    );
    const act_4942415580156499_emit_5576 = new actions.EmitAction(
      {
        actionArgs: this.act_4942415580156499_emit_5576_getActionArgs,
        events: [],
      }
    );
    const evh_4942415580156499_success_5575 = new actions.EventHandlerImpl(
      {
        action: act_4942415580156499_emit_5576,
        event: "success",
        displayName: "emit",
      }
    );
    const evh_4942415580156499_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_4942415580156499_success_5575],
      }
    );
    return {
      act_1040167445267876_emit_5574,
      evh_1040167445267876_clickPreventStop_5573,
      evh_1040167445267876_clickPreventStop,
      act_4942415580156499_emit_5576,
      evh_4942415580156499_success_5575,
      evh_4942415580156499_success,
      reload: act_1040167445267876_emit_5574,
    }
  }
}

Vue.component("UcPaymentHeader", UcPaymentHeader);

</script>