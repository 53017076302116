<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <legend>
    {{ 'Validity'|pgettext('Rental') }}
  </legend>
  <ItpFormGroup
    name="valid_from"
    :label="'Valid from'|pgettext('Rental')"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
  >
    <ItpFormDatetime
      name="valid_from"
      v-model="rental.valid_from"
    >
    </ItpFormDatetime>
  </ItpFormGroup>
  <ItpFormGroup
    name="valid_to"
    :label="'Valid to'|pgettext('Rental')"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
  >
    <ItpFormDatetime
      name="valid_to"
      v-model="rental.valid_to"
    >
    </ItpFormDatetime>
  </ItpFormGroup>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class FieldsetRentalValidityData extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  rental!: object;

  @Watch('rental')
  onRental(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("rental")
  }

  @Prop({
    type: String,
    default: "right",
  })
  labelAlign!: string;

  @Watch('labelAlign')
  onLabel_align(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("labelAlign")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_6850: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_6849: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_6850 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_6849 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_6850,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_6849],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_6850,
      evh_7315092382398562_reload_6849,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("FieldsetRentalValidityData", FieldsetRentalValidityData);

</script>