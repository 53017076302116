import FieldsetClientGeneral from './FieldsetClientGeneral.yaml?component';
import UcClientAddresses from './UcClientAddresses.yaml?component';
import UcClientGeneral from './UcClientGeneral.yaml?component';
import UcClientInvoices from './UcClientInvoices.yaml?component';
import UcClientInvoicesPanel from './UcClientInvoicesPanel.yaml?component';
import UcClientPayments from './UcClientPayments.yaml?component';
import UcClientStatement from './UcClientStatement.yaml?component';

export * from './bank-accounts';

export {
  FieldsetClientGeneral,
  UcClientAddresses,
  UcClientGeneral,
  UcClientInvoices,
  UcClientInvoicesPanel,
  UcClientPayments,
  UcClientStatement
};
