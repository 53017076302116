"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let WorkSummaryViewFilters = class WorkSummaryViewFilters extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['item', 'ux', 'b_8564662037462133_style'];
    }
    beforeCreate() {
        this.$$cache_item = new core_1.ComponentValueCache(this, "item", "WorkSummaryViewFilters");
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            item: {
                date_from: null,
                date_to: null,
            },
            ux: null,
            b_8564662037462133_style: {
                width: "500px",
            },
        });
    }
    evh_7315092382398562_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
        });
    }
    act_8564662037462133_setData_5912_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item.date_from",
                value: $event.data[0],
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_8564662037462133_setData_5914_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item.date_to",
                value: $event.data[1],
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_8564662037462133_changed(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8564662037462133_changed.executeFromDOM(this, event, scope);
        });
    }
    act_3943151158818434_emit_5916_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "submit",
                value: this.item,
            };
        });
    }
    evh_3943151158818434_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_3943151158818434_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_reloadComponentData_5910 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7315092382398562_reload_5909 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_5910,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_5909],
        });
        const act_8564662037462133_setData_5912 = new core_1.actions.SetDataAction({
            actionArgs: this.act_8564662037462133_setData_5912_getActionArgs,
            events: [],
        });
        const evh_8564662037462133_changed_5911 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_setData_5912,
            event: "changed",
            displayName: "setData",
        });
        const act_8564662037462133_setData_5914 = new core_1.actions.SetDataAction({
            actionArgs: this.act_8564662037462133_setData_5914_getActionArgs,
            events: [],
        });
        const evh_8564662037462133_changed_5913 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_setData_5914,
            event: "changed",
            displayName: "setData",
        });
        const evh_8564662037462133_changed = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8564662037462133_changed_5911, evh_8564662037462133_changed_5913],
        });
        const act_3943151158818434_emit_5916 = new core_1.actions.EmitAction({
            actionArgs: this.act_3943151158818434_emit_5916_getActionArgs,
            displayName: "load",
            events: [],
        });
        const evh_3943151158818434_clickPreventStop_5915 = new core_1.actions.EventHandlerImpl({
            action: act_3943151158818434_emit_5916,
            event: "click.prevent.stop",
            displayName: "load",
        });
        const evh_3943151158818434_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3943151158818434_clickPreventStop_5915],
        });
        return {
            act_7315092382398562_reloadComponentData_5910,
            evh_7315092382398562_reload_5909,
            evh_7315092382398562_reload,
            act_8564662037462133_setData_5912,
            evh_8564662037462133_changed_5911,
            act_8564662037462133_setData_5914,
            evh_8564662037462133_changed_5913,
            evh_8564662037462133_changed,
            act_3943151158818434_emit_5916,
            evh_3943151158818434_clickPreventStop_5915,
            evh_3943151158818434_clickPreventStop,
            load: act_3943151158818434_emit_5916,
        };
    }
};
WorkSummaryViewFilters = __decorate([
    (0, vue_property_decorator_1.Component)()
], WorkSummaryViewFilters);
exports.default = WorkSummaryViewFilters;
vue_property_decorator_1.Vue.component("WorkSummaryViewFilters", WorkSummaryViewFilters);
