"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let TextTemplate = class TextTemplate extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['objectTypes', 'formId', 'formName', 'labelAlign', 'container', 'item', 'ux'];
    }
    beforeCreate() {
    }
    onOid(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("oid");
    }
    $$load_formId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    $$load_container() {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                self: this.$fn.urlFor('create_object_by_type', { object_type: 'text-templates' }),
                object: 'list',
                data: []
            };
        });
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.oid ?
                this.$fn.fetch('get_object_by_type', { parameters: { object_type: 'text-templates', id: this.oid }, asResource: true }) :
                new this.$fn.Resource({
                    name: null,
                    object_type: null,
                    condition: null,
                    template: null,
                    description: null
                });
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            objectTypes: [{
                    value: "invoice",
                    name: this.$fn.pgettext("TextTemplate|Type", "Invoice"),
                },
                {
                    value: "invoice_line",
                    name: this.$fn.pgettext("TextTemplate|Type", "Invoice line"),
                },
            ],
            formId: null,
            formName: "textTemplate",
            labelAlign: "right",
            container: null,
            item: null,
            ux: null,
        });
    }
    evh_7315092382398562_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
        });
    }
    act_2248226175642056_emit_2992_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "saved",
                value: $event.data.response.data,
            };
        });
    }
    act_2248226175642056_rest_2990_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                method: "save",
                container: this.container,
                resource: this.item,
            };
        });
    }
    evh_2248226175642056_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_submit.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_reloadComponentData_2988 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7315092382398562_reload_2987 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_2988,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_2987],
        });
        const act_2248226175642056_emit_2992 = new core_1.actions.EmitAction({
            actionArgs: this.act_2248226175642056_emit_2992_getActionArgs,
            events: [],
        });
        const evh_2248226175642056_saved_2991 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_emit_2992,
            event: "saved",
            displayName: "emit",
        });
        const act_2248226175642056_rest_2990 = new core_1.actions.RestAction({
            actionArgs: this.act_2248226175642056_rest_2990_getActionArgs,
            displayName: "save",
            events: [evh_2248226175642056_saved_2991],
        });
        const evh_2248226175642056_submit_2989 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_rest_2990,
            event: "submit",
            displayName: "save",
        });
        const evh_2248226175642056_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_submit_2989],
        });
        return {
            act_7315092382398562_reloadComponentData_2988,
            evh_7315092382398562_reload_2987,
            evh_7315092382398562_reload,
            act_2248226175642056_emit_2992,
            evh_2248226175642056_saved_2991,
            act_2248226175642056_rest_2990,
            evh_2248226175642056_submit_2989,
            evh_2248226175642056_submit,
            save: act_2248226175642056_rest_2990,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], TextTemplate.prototype, "oid", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('oid'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], TextTemplate.prototype, "onOid", null);
TextTemplate = __decorate([
    (0, vue_property_decorator_1.Component)()
], TextTemplate);
exports.default = TextTemplate;
vue_property_decorator_1.Vue.component("TextTemplate", TextTemplate);
