var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpBox",
        { attrs: { alignItems: "center" } },
        [
          !_vm.noIcon
            ? _c("UcMeterTypeIcon", {
                staticClass: "mr-2",
                attrs: { type: _vm.item.meter_type }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.noLink || !_vm.linkStyleUnderline
            ? _c("ItpText", {
                staticClass: "mr-2",
                attrs: { strong: !_vm.noStrong, text: _vm.item.serial_number }
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.noLink && _vm.linkStyleUnderline
            ? _c(
                "ItpLink",
                {
                  attrs: {
                    to: {
                      name: "app.meter",
                      params: { id: _vm.item.id || _vm.item.meter_id }
                    }
                  }
                },
                [
                  _c("ItpText", {
                    staticClass: "mr-2",
                    attrs: {
                      strong: !_vm.noStrong,
                      text: _vm.item.serial_number
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.item.id &&
          !_vm.noLink &&
          _vm.linkStyleIcon &&
          !_vm.linkStyleUnderline
            ? _c("ItpLink", {
                attrs: {
                  icon: _vm.$config.externalLinkIcon,
                  to: {
                    name: "app.meter",
                    params: { id: _vm.item.id || _vm.item.meter_id }
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }