"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
(0, core_1.addRoute)({
    name: "product",
    path: "/products/:id",
    component: "Product",
    params: [{
            name: "id",
            prop: "oid",
        },
    ]
});
let Product = class Product extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['item', 'ux', 'b_7315092382398562_modalBindings', 'b_4615685607825208_to'];
    }
    beforeCreate() {
    }
    onOid(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("oid");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('read_product', { asResource: true, parameters: { id: this.oid } });
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            item: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "sm",
            },
            b_4615685607825208_to: {
                name: "app.products",
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: [this.item.name || this.item.number,
                    this.$fn.gettext("Product"),
                ],
                icon: this.$config.product.icon,
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_3190_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_8320016629450276_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8320016629450276_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    evh_6705964765214521_success(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6705964765214521_success.executeFromDOM(this, event, scope);
        });
    }
    evh_5477420070495986_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5477420070495986_reload.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_3190 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_3190_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_3189 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_3190,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_3189],
        });
        const act_8320016629450276_reloadComponentData_3192 = new core_1.actions.ReloadComponentDataAction({
            displayName: "reload",
            events: [],
        });
        const evh_8320016629450276_clickPreventStop_3191 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_reloadComponentData_3192,
            event: "click.prevent.stop",
            displayName: "reload",
        });
        const evh_8320016629450276_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8320016629450276_clickPreventStop_3191],
        });
        const act_6705964765214521_reloadComponentData_3194 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_6705964765214521_success_3193 = new core_1.actions.EventHandlerImpl({
            action: act_6705964765214521_reloadComponentData_3194,
            event: "success",
            displayName: "reloadComponentData",
        });
        const evh_6705964765214521_success = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6705964765214521_success_3193],
        });
        const act_5477420070495986_reloadComponentData_3196 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_5477420070495986_reload_3195 = new core_1.actions.EventHandlerImpl({
            action: act_5477420070495986_reloadComponentData_3196,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_5477420070495986_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5477420070495986_reload_3195],
        });
        return {
            act_7315092382398562_closeModal_3190,
            evh_7315092382398562_close_3189,
            evh_7315092382398562_close,
            act_8320016629450276_reloadComponentData_3192,
            evh_8320016629450276_clickPreventStop_3191,
            evh_8320016629450276_clickPreventStop,
            act_6705964765214521_reloadComponentData_3194,
            evh_6705964765214521_success_3193,
            evh_6705964765214521_success,
            act_5477420070495986_reloadComponentData_3196,
            evh_5477420070495986_reload_3195,
            evh_5477420070495986_reload,
            reload: act_8320016629450276_reloadComponentData_3192,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], Product.prototype, "oid", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('oid'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Product.prototype, "onOid", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], Product.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Product.prototype, "onSize", null);
Product = __decorate([
    (0, vue_property_decorator_1.Component)()
], Product);
exports.default = Product;
vue_property_decorator_1.Vue.component("Product", Product);
