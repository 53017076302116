<template>
<ItpPage
  card
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="Worksheet"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :color="$config.worksheet.color"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
    <UcWorksheetHeader
      :worksheet="item"
      @reload="evh_8564662037462133_reload($event, {})"
      @deleted="evh_8564662037462133_deleted($event, {})"
    >
    </UcWorksheetHeader>
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      :id="formId"
      name="worksheet"
      autofocus="first"
      v-if="isNew"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <ItpCol>
        <UcWorksheetEditorFieldset
          :worksheet="item"
        >
        </UcWorksheetEditorFieldset>
      </ItpCol>
    </ItpForm>
    <ItpPanel
      collapsible
      :caption="'Worksheet data'|pgettext('Worksheet')"
      carousel
      v-if="!isNew"
    >
      <UcWorksheetData
        :worksheet="item"
        :formId="formId"
        :reload="lastLoaded"
        class="pt-3"
        @reload="evh_1468474219929533_reload($event, {})"
      >
      </UcWorksheetData>
    </ItpPanel>
    <hr
      v-if="!isNew"
    >
    </hr>
    <DIV
      class="overflow-hidden w-100"
      v-if="!isNew"
    >
      <UcWorksheetWorkItems
        :worksheet="item"
        :isReadonly="isReadonly"
        :reload="lastLoaded"
        class="h-100 w-100"
        @reload="evh_1306227577716690_reload($event, {})"
      >
      </UcWorksheetWorkItems>
    </DIV>
    <hr
      v-if="!isNew"
    >
    </hr>
    <DIV
      class="overflow-hidden w-100"
      v-if="!isNew"
    >
      <UcWorksheetInvoiceLineAccountings
        :worksheet="item"
        :isReadonly="isReadonly"
        :reload="lastLoaded"
        class="h-100 w-100"
        @reload="evh_8723442106880637_reload($event, {})"
      >
      </UcWorksheetInvoiceLineAccountings>
    </DIV>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    :color="$config.payment.color"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :text="'Create worksheet'|pgettext('Button')"
      type="submit"
      :form="forms.worksheet"
      variant="primary"
      :spinning="actions.save.isRunning"
      class="mr-2"
      v-if="isNew"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "worksheet",
    path: "/worksheets/:id",
    component: "Worksheet",
    params: [{
      name: "id",
      prop: "oid",
    }
      ,
    ]

  }
)

@Component()
export default class Worksheet extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
  })
  oid!: string;

  @Watch('oid')
  onOid(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("oid")
  }

  @Prop({
    type: String,
    default: function() {
      return this.$fn.randomElementId()
    },
  })
  formId!: string;

  @Watch('formId')
  onForm_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("formId")
  }

  @Prop({
    type: String,
    default: "full-screen",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  item!: any;

  async $$load_item() {
    return (this.oid === 'create' || this.oid == null)
      ? this.$fn.fetch('get_worksheet_template', { asResource: true })
      : this.$fn.fetch('read_worksheet', { parameters: { id: this.oid }, asResource: true })
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['item', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        item: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "full-screen",
        }
        ,
      },
    }
  }

  get isNew() {
    if (this.loaded) {

      return this.item.isNew
    }
    return null;
  }

  get isReadonly() {
    if (this.loaded) {

      return false
    }
    return null;
  }

  get document() {
    if (this.loaded) {

      return {
        title: [this.item.snapshot('number')
          ,
        this.$fn.gettext("Worksheet")
          ,
        ]
        ,
        icon: this.$config.worksheet.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_4228_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_4228, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async evh_8564662037462133_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8564662037462133_reload.executeFromDOM(this, event, scope);
  }

  async act_8564662037462133_reloadSlickgrid_4236_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_8564662037462133_reloadSlickgrid_4236, alias=undefined
    return {
      grid: "worksheets",
    }
  }

  async evh_8564662037462133_deleted(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8564662037462133_deleted.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_reloadSlickgrid_4240_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_1040167445267876_reloadSlickgrid_4240, alias=undefined
    return {
      grid: "worksheets",
    }
  }

  async evh_1040167445267876_success_4239_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_1040167445267876_success_4239, alias=undefined
    return this.item.id
  }

  async act_1040167445267876_closeComponent_4242_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseComponentActionArgs> {
    // parameterResolver name=act_1040167445267876_closeComponent_4242, alias=undefined
    return {
      result: {
        close: true,
      }
      ,
    }
  }

  async evh_1040167445267876_success_4241_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_1040167445267876_success_4241, alias=undefined
    return !this.item.id
  }

  async act_1040167445267876_navigate_4244_getActionArgs($event: actions.ActionEvent): Promise<actions.NavigateActionArgs> {
    // parameterResolver name=act_1040167445267876_navigate_4244, alias=undefined
    return {
      location: {
        name: "app.worksheet",
        params: {
          id: $event.data.id,
        }
        ,
      }
      ,
    }
  }

  async evh_1040167445267876_success_4243_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_1040167445267876_success_4243, alias=undefined
    return !this.item.id
  }

  async act_1040167445267876_setData_4246_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_1040167445267876_setData_4246, alias=undefined
    return {
      path: "item",
      value: $event.data,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_1040167445267876_success_4245_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_1040167445267876_success_4245, alias=undefined
    return this.item.id && !!$event.data && !!$event.data.id
  }

  async act_1040167445267876_form_4248_getActionArgs($event: actions.ActionEvent): Promise<actions.FormActionArgs> {
    // parameterResolver name=act_1040167445267876_form_4248, alias=undefined
    return {
      name: this.formId,
      reset: true,
    }
  }

  async evh_1040167445267876_success_4247_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_1040167445267876_success_4247, alias=undefined
    return this.item.id && !!$event.data && !!$event.data.id
  }

  async act_1040167445267876_crud_4238_getActionArgs($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_1040167445267876_crud_4238, alias=save
    return {
      objectType: "worksheet",
      op: "upsert",
      data: this.item,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async evh_1468474219929533_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1468474219929533_reload.executeFromDOM(this, event, scope);
  }

  async evh_1306227577716690_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1306227577716690_reload.executeFromDOM(this, event, scope);
  }

  async evh_8723442106880637_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8723442106880637_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_4228: actions.CloseModalAction;
    evh_7315092382398562_close_4227: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeModal_4230: actions.CloseModalAction;
    evh_2248226175642056_close_4229: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_8564662037462133_reloadComponentData_4232: actions.ReloadComponentDataAction;
    evh_8564662037462133_reload_4231: actions.EventHandlerImpl;
    evh_8564662037462133_reload: actions.EventHandlerGroup;
    act_8564662037462133_closeModal_4234: actions.CloseModalAction;
    evh_8564662037462133_deleted_4233: actions.EventHandlerImpl;
    act_8564662037462133_reloadSlickgrid_4236: actions.ReloadSlickgridAction;
    evh_8564662037462133_deleted_4235: actions.EventHandlerImpl;
    evh_8564662037462133_deleted: actions.EventHandlerGroup;
    act_1040167445267876_reloadSlickgrid_4240: actions.ReloadSlickgridAction;
    evh_1040167445267876_success_4239: actions.EventHandlerImpl;
    act_1040167445267876_closeComponent_4242: actions.CloseComponentAction;
    evh_1040167445267876_success_4241: actions.EventHandlerImpl;
    act_1040167445267876_navigate_4244: actions.NavigateAction;
    evh_1040167445267876_success_4243: actions.EventHandlerImpl;
    act_1040167445267876_setData_4246: actions.SetDataAction;
    evh_1040167445267876_success_4245: actions.EventHandlerImpl;
    act_1040167445267876_form_4248: actions.FormAction;
    evh_1040167445267876_success_4247: actions.EventHandlerImpl;
    act_1040167445267876_crud_4238: actions.CRUDAction;
    evh_1040167445267876_submit_4237: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_1468474219929533_reloadComponentData_4250: actions.ReloadComponentDataAction;
    evh_1468474219929533_reload_4249: actions.EventHandlerImpl;
    evh_1468474219929533_reload: actions.EventHandlerGroup;
    act_1306227577716690_reloadComponentData_4252: actions.ReloadComponentDataAction;
    evh_1306227577716690_reload_4251: actions.EventHandlerImpl;
    evh_1306227577716690_reload: actions.EventHandlerGroup;
    act_8723442106880637_reloadComponentData_4254: actions.ReloadComponentDataAction;
    evh_8723442106880637_reload_4253: actions.EventHandlerImpl;
    evh_8723442106880637_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_4228 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_4228_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_4227 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_4228,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_4227],
      }
    );
    const act_2248226175642056_closeModal_4230 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_4229 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeModal_4230,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_4229],
      }
    );
    const act_8564662037462133_reloadComponentData_4232 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_8564662037462133_reload_4231 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_reloadComponentData_4232,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_8564662037462133_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_8564662037462133_reload_4231],
      }
    );
    const act_8564662037462133_closeModal_4234 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_8564662037462133_deleted_4233 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_closeModal_4234,
        event: "deleted",
        displayName: "closeModal",
      }
    );
    const act_8564662037462133_reloadSlickgrid_4236 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_8564662037462133_reloadSlickgrid_4236_getActionArgs,
        events: [],
      }
    );
    const evh_8564662037462133_deleted_4235 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_reloadSlickgrid_4236,
        event: "deleted",
        displayName: "reloadSlickgrid",
      }
    );
    const evh_8564662037462133_deleted = new actions.EventHandlerGroup(
      {
        handlers: [evh_8564662037462133_deleted_4233, evh_8564662037462133_deleted_4235],
      }
    );
    const act_1040167445267876_reloadSlickgrid_4240 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_1040167445267876_reloadSlickgrid_4240_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_4239 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_reloadSlickgrid_4240,
        event: "success",
        when: this.evh_1040167445267876_success_4239_getWhen,
        displayName: "reloadSlickgrid",
      }
    );
    const act_1040167445267876_closeComponent_4242 = new actions.CloseComponentAction(
      {
        actionArgs: this.act_1040167445267876_closeComponent_4242_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_4241 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeComponent_4242,
        event: "success",
        when: this.evh_1040167445267876_success_4241_getWhen,
        displayName: "closeComponent",
      }
    );
    const act_1040167445267876_navigate_4244 = new actions.NavigateAction(
      {
        actionArgs: this.act_1040167445267876_navigate_4244_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_4243 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_navigate_4244,
        event: "success",
        when: this.evh_1040167445267876_success_4243_getWhen,
        displayName: "navigate",
      }
    );
    const act_1040167445267876_setData_4246 = new actions.SetDataAction(
      {
        actionArgs: this.act_1040167445267876_setData_4246_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_4245 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_setData_4246,
        event: "success",
        when: this.evh_1040167445267876_success_4245_getWhen,
        displayName: "setData",
      }
    );
    const act_1040167445267876_form_4248 = new actions.FormAction(
      {
        actionArgs: this.act_1040167445267876_form_4248_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_4247 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_form_4248,
        event: "success",
        when: this.evh_1040167445267876_success_4247_getWhen,
        displayName: "form",
      }
    );
    const act_1040167445267876_crud_4238 = new actions.CRUDAction(
      {
        actionArgs: this.act_1040167445267876_crud_4238_getActionArgs,
        displayName: "save",
        events: [evh_1040167445267876_success_4239, evh_1040167445267876_success_4241, evh_1040167445267876_success_4243, evh_1040167445267876_success_4245, evh_1040167445267876_success_4247],
      }
    );
    const evh_1040167445267876_submit_4237 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_crud_4238,
        event: "submit",
        displayName: "save",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_4237],
      }
    );
    const act_1468474219929533_reloadComponentData_4250 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_1468474219929533_reload_4249 = new actions.EventHandlerImpl(
      {
        action: act_1468474219929533_reloadComponentData_4250,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_1468474219929533_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_1468474219929533_reload_4249],
      }
    );
    const act_1306227577716690_reloadComponentData_4252 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_1306227577716690_reload_4251 = new actions.EventHandlerImpl(
      {
        action: act_1306227577716690_reloadComponentData_4252,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_1306227577716690_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_1306227577716690_reload_4251],
      }
    );
    const act_8723442106880637_reloadComponentData_4254 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_8723442106880637_reload_4253 = new actions.EventHandlerImpl(
      {
        action: act_8723442106880637_reloadComponentData_4254,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_8723442106880637_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_8723442106880637_reload_4253],
      }
    );
    return {
      act_7315092382398562_closeModal_4228,
      evh_7315092382398562_close_4227,
      evh_7315092382398562_close,
      act_2248226175642056_closeModal_4230,
      evh_2248226175642056_close_4229,
      evh_2248226175642056_close,
      act_8564662037462133_reloadComponentData_4232,
      evh_8564662037462133_reload_4231,
      evh_8564662037462133_reload,
      act_8564662037462133_closeModal_4234,
      evh_8564662037462133_deleted_4233,
      act_8564662037462133_reloadSlickgrid_4236,
      evh_8564662037462133_deleted_4235,
      evh_8564662037462133_deleted,
      act_1040167445267876_reloadSlickgrid_4240,
      evh_1040167445267876_success_4239,
      act_1040167445267876_closeComponent_4242,
      evh_1040167445267876_success_4241,
      act_1040167445267876_navigate_4244,
      evh_1040167445267876_success_4243,
      act_1040167445267876_setData_4246,
      evh_1040167445267876_success_4245,
      act_1040167445267876_form_4248,
      evh_1040167445267876_success_4247,
      act_1040167445267876_crud_4238,
      evh_1040167445267876_submit_4237,
      evh_1040167445267876_submit,
      act_1468474219929533_reloadComponentData_4250,
      evh_1468474219929533_reload_4249,
      evh_1468474219929533_reload,
      act_1306227577716690_reloadComponentData_4252,
      evh_1306227577716690_reload_4251,
      evh_1306227577716690_reload,
      act_8723442106880637_reloadComponentData_4254,
      evh_8723442106880637_reload_4253,
      evh_8723442106880637_reload,
      save: act_1040167445267876_crud_4238,
    }
  }
}

Vue.component("Worksheet", Worksheet);

</script>