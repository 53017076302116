var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("legend", { staticClass: "text-muted" }, [
        _vm._v(
          "\n    " + _vm._s(_vm._f("pgettext")("Advances", "Contract")) + "\n  "
        )
      ]),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "rental_fee_advances_initial_months",
            label: _vm._f("pgettext")("Initial", "Contract"),
            appendText: _vm._f("pgettext")("months", "InputAddon"),
            labelColsMd: "4",
            contentColsMd: "4",
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: {
              type: "number",
              name: "rental_fee_advances_initial_months"
            },
            model: {
              value: _vm.contract.invoicing.rental_fee_advances_initial_months,
              callback: function($$v) {
                _vm.$set(
                  _vm.contract.invoicing,
                  "rental_fee_advances_initial_months",
                  $$v
                )
              },
              expression:
                "contract.invoicing.rental_fee_advances_initial_months"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "rental_fee_advances_reserve_months",
            label: _vm._f("pgettext")("Reserve", "Contract"),
            appendText: _vm._f("pgettext")("months", "InputAddon"),
            labelColsMd: "4",
            contentColsMd: "4",
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: {
              type: "number",
              name: "rental_fee_advances_reserve_months"
            },
            model: {
              value: _vm.contract.invoicing.rental_fee_advances_reserve_months,
              callback: function($$v) {
                _vm.$set(
                  _vm.contract.invoicing,
                  "rental_fee_advances_reserve_months",
                  $$v
                )
              },
              expression:
                "contract.invoicing.rental_fee_advances_reserve_months"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("legend", [
        _vm._v(
          "\n    " + _vm._s(_vm._f("pgettext")("Billing", "Contract")) + "\n  "
        )
      ]),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "billing_currency",
            label: _vm._f("pgettext")("Billing currency", "Contract"),
            labelColsMd: "4",
            contentColsMd: "3",
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormSelect2", {
            attrs: {
              name: "billing_currency",
              valueField: "code",
              textField: "code",
              required: "",
              options: _vm.currencies
            },
            model: {
              value: _vm.contract.invoicing.billing_currency,
              callback: function($$v) {
                _vm.$set(_vm.contract.invoicing, "billing_currency", $$v)
              },
              expression: "contract.invoicing.billing_currency"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "ItpFormGroup",
            {
              attrs: {
                name: "billing_frequency",
                label: _vm._f("pgettext")("Billing frequency", "Contract"),
                labelColsMd: "4",
                contentColsMd: "5",
                labelAlign: _vm.labelAlign,
                contentClass: "input-group"
              }
            },
            [
              _c("ItpFormInput", {
                attrs: { type: "number", name: "billing_frequency_value" },
                on: {
                  input: function($event) {
                    return _vm.evh_8120114320850587_input($event, {})
                  }
                },
                model: {
                  value: _vm.billing_frequency.value,
                  callback: function($$v) {
                    _vm.$set(_vm.billing_frequency, "value", $$v)
                  },
                  expression: "billing_frequency.value"
                }
              }),
              _vm._v(" "),
              _c(
                "DIV",
                { staticClass: "input-group-append w-50" },
                [
                  _c("ItpFormSelect", {
                    attrs: {
                      name: "billing_frequency_unit",
                      options: _vm.billingFrequencyOptions
                    },
                    on: {
                      input: function($event) {
                        return _vm.evh_7882194489015218_input($event, {})
                      }
                    },
                    model: {
                      value: _vm.billing_frequency.unit,
                      callback: function($$v) {
                        _vm.$set(_vm.billing_frequency, "unit", $$v)
                      },
                      expression: "billing_frequency.unit"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "invoice_notes",
            label: _vm._f("pgettext")("Notes on invoice", "Contract"),
            labelColsMd: "4",
            contentColsMd: "8",
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormTextarea", {
            attrs: { name: "invoice_notes_value" },
            model: {
              value: _vm.contract.invoicing.invoice_notes,
              callback: function($$v) {
                _vm.$set(_vm.contract.invoicing, "invoice_notes", $$v)
              },
              expression: "contract.invoicing.invoice_notes"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.loaded && _vm.contract
        ? _c(
            "ItpFormGroup",
            {
              attrs: {
                name: "payment_terms",
                label: _vm._f("pgettext")("Payment period", "Contract"),
                labelColsMd: "4",
                contentColsMd: "4",
                labelAlign: _vm.labelAlign,
                contentClass: "input-group"
              }
            },
            [
              _c("ItpFormInput", {
                attrs: { type: "number", name: "payment_terms_value" },
                model: {
                  value: _vm.contract.invoicing.payment_terms.value,
                  callback: function($$v) {
                    _vm.$set(_vm.contract.invoicing.payment_terms, "value", $$v)
                  },
                  expression: "contract.invoicing.payment_terms.value"
                }
              }),
              _vm._v(" "),
              _c(
                "DIV",
                { staticClass: "input-group-append w-50" },
                [
                  _c("ItpFormSelect", {
                    attrs: {
                      name: "payment_terms_type",
                      options: _vm.contractPaymentTermTypes
                    },
                    model: {
                      value: _vm.contract.invoicing.payment_terms.type,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.contract.invoicing.payment_terms,
                          "type",
                          $$v
                        )
                      },
                      expression: "contract.invoicing.payment_terms.type"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }