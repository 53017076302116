<template>
<ItpModal
  :noHeader="b_7315092382398562_noHeader"
  no-primary-button
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="DataViewerModal"
  @close="evh_7315092382398562_close($event, {})"
>
  <DIV
    class="container-fluid"
  >
    <CodeMirror
      :mode="mode"
      :options="b_8564662037462133_options"
      v-model="content"
    >
    </CodeMirror>
  </DIV>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpModal>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class DataViewerModal extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "modal",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
  })
  headerText!: string;

  @Watch('headerText')
  onHeader_text(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("headerText")
  }

  @Prop({
    required: true,
    type: String,
  })
  content!: string;

  @Watch('content')
  onContent(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("content")
  }

  @Prop({
    type: String,
  })
  mode!: string;

  @Watch('mode')
  onMode(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("mode")
  }

  @Prop({
    type: String,
    default: "lg",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  ux!: any;
  b_7315092382398562_noHeader!: any;
  b_8564662037462133_options!: any;
  dataMembers = ['ux', 'b_7315092382398562_noHeader', 'b_8564662037462133_options'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
        b_7315092382398562_noHeader: false,
        b_8564662037462133_options: {
          lineNumbers: true,
          matchBrackets: true,
          continueComments: "Enter",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.headerText || this.$fn.gettext("View Data"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_1796_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_1796, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_1796: actions.CloseModalAction;
    evh_7315092382398562_close_1795: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_1796 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_1796_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_1795 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_1796,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_1795],
      }
    );
    return {
      act_7315092382398562_closeModal_1796,
      evh_7315092382398562_close_1795,
      evh_7315092382398562_close,
    }
  }
}

Vue.component("DataViewerModal", DataViewerModal);

</script>