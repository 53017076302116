<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpPanel
    :collapsible="b_2248226175642056_collapsible"
  >
    <template
      slot="caption"
    >
      <h5
        class="ml-3 mb-3"
      >
        <span>
          Child jobs
        </span>
        <ItpBadge
          :text="'(' + (parent.jobs && parent.jobs.length || 0) + ')'"
          variant="light"
        >
        </ItpBadge>
      </h5>
    </template>
    <ul
      class="list-group"
    >
      <ItpPanel
        :key="item.id"
        collapsible
        initialState="collapsed"
        class="list-group-item"
        v-for="item in parent.jobs"
      >
        <template
          slot="caption"
        >
          <JobHeader
            :item="item"
          >
          </JobHeader>
        </template>
        <JobView
          no-display-header
          :item="item"
          class="mt-3"
        >
        </JobView>
      </ItpPanel>
    </ul>
  </ItpPanel>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class ChildJobs extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  parent!: object;

  @Watch('parent')
  onParent(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("parent")
  }

  ux!: any;
  b_2248226175642056_collapsible!: any;
  dataMembers = ['ux', 'b_2248226175642056_collapsible'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
        b_2248226175642056_collapsible: false,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_5396: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_5395: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_5396 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_5395 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_5396,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_5395],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_5396,
      evh_7315092382398562_reload_5395,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("ChildJobs", ChildJobs);

</script>