<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="LetterRegister"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <VueSlickgrid
    name="letterRegister"
    :headerTitle="'Letter register'|pgettext('Letter register')"
    :items="$fn.slickDataSource('list_letter_register_entries', {parameters: getQueryParameters})"
    export-to-csv
    :exportToCsvFilename="'letter-register'|pgettext('filename')"
    checkbox-row-selection
    :headerShowColumnSelectorButton="b_2248226175642056_headerShowColumnSelectorButton"
    :columnDefaults="b_2248226175642056_columnDefaults"
    :columns="b_2248226175642056_columns"
    class="page--grid"
    ref="grid"
    v-if="loaded"
    @command="evh_2248226175642056_command($event, {})"
    @selectedRowsChanged="evh_2248226175642056_selectedRowsChanged($event, {})"
  >
    <DIV
      class="flex-grow-1 d-flex"
      slot="toolbar"
    >
      <ItpFormSelect2
        name="register_name"
        v-model="selectedRegister"
        bind-object
        valueField="id"
        textField="name"
        :options="registers"
        class="w-25 mx-3"
        @input="evh_6466705138206980_input($event, {})"
      >
      </ItpFormSelect2>
      <ItpButton
        :icon="$config.letterRegister.createEntry"
        :text="'Add entry...'|pgettext('LetterRegister')"
        variant="light"
        class="mx-2"
        @click="evh_8320016629450276_click($event, {})"
      >
      </ItpButton>
    </DIV>
  </VueSlickgrid>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "letterRegister",
    path: "/letter-register",
    component: "LetterRegister"
  }
)

@Component()
export default class LetterRegister extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
    this.$$cache_selectedRegisterId = new ComponentValueCache(this, "selectedRegisterId", "LetterRegister.selectedRegisterId");
  }

  getQueryParameters(...args: any[]) {
    return {
      sort_mode: 'desc',
      register_name: this.selectedRegister ? this.selectedRegister.id : this.registers[0].id
    }
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  registers!: any;

  async $$load_registers() {
    return this.$fn.fetch('list_register')
      .then(data => _.filter(data, d => _.includes(d.register_type, 'letters')))
  }

  selectedRegisterId!: any;
  selectedRegister!: any;

  async $$load_selectedRegister() {
    return _.find(this.registers, { id: this.selectedRegisterId }) || this.registers[0]
  }

  selectedItems!: any;
  companies!: any;

  async $$load_companies() {
    return this.$fn.fetch('list_company').then(resp => resp.data)
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_2248226175642056_headerShowColumnSelectorButton!: any;
  b_2248226175642056_columnDefaults!: any;
  b_2248226175642056_columns!: any;
  dataMembers = ['registers', 'selectedRegisterId', 'selectedRegister', 'selectedItems', 'companies', 'ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_headerShowColumnSelectorButton', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        registers: null,
        selectedRegisterId: null,
        selectedRegister: null,
        selectedItems: [
        ]
        ,
        companies: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
        b_2248226175642056_headerShowColumnSelectorButton: false,
        b_2248226175642056_columnDefaults: {
          filterable: true,
          resizable: true,
        }
        ,
        b_2248226175642056_columns: [{
          field: "entry_number",
          name: this.$fn.pgettext("Letter register", "No."),
          type: "command",
          formatter: {
            name: "link",
            href: "#",
          }
          ,
        }
          ,
        {
          field: "entry_date",
          name: this.$fn.pgettext("Letter register", "Date"),
          type: "date",
        }
          ,
        {
          field: "delivery_modes",
          name: this.$fn.pgettext("Letter register", "Delivery mode"),
          formatter: {
            name: "join",
            separator: ", ",
          }
          ,
        }
          ,
        {
          field: "categories",
          name: this.$fn.pgettext("Letter register", "Category"),
        }
          ,
        {
          field: "client_name",
          name: this.$fn.pgettext("Letter register", "Client"),
          formatter: {
            name: "routeLink",
            to: "client",
            navigateOnIconClick: true,
            params: {
              id: "client_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "subject",
          name: this.$fn.pgettext("Letter register", "Subject"),
        }
          ,
        {
          field: "notes",
          name: this.$fn.pgettext("Letter register", "Notes"),
        }
          ,
        {
          field: "delivery_date",
          name: this.$fn.pgettext("Letter register", "Delivery date"),
        }
          ,
        {
          field: "undelivered",
          name: this.$fn.pgettext("Letter register", "Undelivered"),
          type: "boolean",
          formatter: {
            name: "checkmark",
          }
          ,
        }
          ,
        ]
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("Letter register", "Letter register"),
        icon: this.$config.letterRegister.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_2690_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_2690, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_reloadSlickgrid_2694_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_2248226175642056_reloadSlickgrid_2694, alias=undefined
    return {
      grid: "letterRegister",
    }
  }

  async act_2248226175642056_reloadSlickgrid_2696_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_2248226175642056_reloadSlickgrid_2696, alias=undefined
    return {
      grid: "letterRegister",
    }
  }

  async evh_2248226175642056_close_2695_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2248226175642056_close_2695, alias=undefined
    return !!$event.data.modalResult.ok
  }

  async act_2248226175642056_showModal_2692_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2248226175642056_showModal_2692, alias=undefined
    return {
      name: "LetterRegisterEntry",
      props: {
        register: this.selectedRegister,
        oid: $event.data.data.id,
      }
      ,
    }
  }

  async evh_2248226175642056_command_2691_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2248226175642056_command_2691, alias=undefined
    return $event.data.column.id === 'entry_number'
  }

  async evh_2248226175642056_command(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_command.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_setData_2698_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2248226175642056_setData_2698, alias=undefined
    return {
      path: "selectedItems",
      value: this.$refs.grid.getSelectedItems(),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_2248226175642056_selectedRowsChanged(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope);
  }

  async act_6466705138206980_reloadSlickgrid_2700_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_6466705138206980_reloadSlickgrid_2700, alias=undefined
    return {
      grid: "letterRegister",
    }
  }

  async act_6466705138206980_setData_2702_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_6466705138206980_setData_2702, alias=updateSelectedRegisterId
    return {
      path: "selectedRegisterId",
      value: $event.data.id,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_6466705138206980_input(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6466705138206980_input.executeFromDOM(this, event, scope);
  }

  async act_8320016629450276_reloadSlickgrid_2706_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_8320016629450276_reloadSlickgrid_2706, alias=undefined
    return {
      grid: "letterRegister",
    }
  }

  async act_8320016629450276_reloadSlickgrid_2708_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_8320016629450276_reloadSlickgrid_2708, alias=undefined
    return {
      grid: "letterRegister",
    }
  }

  async evh_8320016629450276_close_2707_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_8320016629450276_close_2707, alias=undefined
    return !!$event.data.modalResult.ok
  }

  async act_8320016629450276_showModal_2704_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8320016629450276_showModal_2704, alias=undefined
    return {
      name: "LetterRegisterEntry",
      props: {
        register: this.selectedRegister,
      }
      ,
    }
  }

  async evh_8320016629450276_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_click.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_2690: actions.CloseModalAction;
    evh_7315092382398562_close_2689: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_reloadSlickgrid_2694: actions.ReloadSlickgridAction;
    evh_2248226175642056_saved_2693: actions.EventHandlerImpl;
    act_2248226175642056_reloadSlickgrid_2696: actions.ReloadSlickgridAction;
    evh_2248226175642056_close_2695: actions.EventHandlerImpl;
    act_2248226175642056_showModal_2692: actions.ShowModalAction;
    evh_2248226175642056_command_2691: actions.EventHandlerImpl;
    evh_2248226175642056_command: actions.EventHandlerGroup;
    act_2248226175642056_setData_2698: actions.SetDataAction;
    evh_2248226175642056_selectedRowsChanged_2697: actions.EventHandlerImpl;
    evh_2248226175642056_selectedRowsChanged: actions.EventHandlerGroup;
    act_6466705138206980_reloadSlickgrid_2700: actions.ReloadSlickgridAction;
    evh_6466705138206980_input_2699: actions.EventHandlerImpl;
    act_6466705138206980_setData_2702: actions.SetDataAction;
    evh_6466705138206980_input_2701: actions.EventHandlerImpl;
    evh_6466705138206980_input: actions.EventHandlerGroup;
    act_8320016629450276_reloadSlickgrid_2706: actions.ReloadSlickgridAction;
    evh_8320016629450276_saved_2705: actions.EventHandlerImpl;
    act_8320016629450276_reloadSlickgrid_2708: actions.ReloadSlickgridAction;
    evh_8320016629450276_close_2707: actions.EventHandlerImpl;
    act_8320016629450276_showModal_2704: actions.ShowModalAction;
    evh_8320016629450276_click_2703: actions.EventHandlerImpl;
    evh_8320016629450276_click: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_2690 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_2690_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_2689 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_2690,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_2689],
      }
    );
    const act_2248226175642056_reloadSlickgrid_2694 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_2248226175642056_reloadSlickgrid_2694_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_saved_2693 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_reloadSlickgrid_2694,
        event: "saved",
        displayName: "reloadSlickgrid",
      }
    );
    const act_2248226175642056_reloadSlickgrid_2696 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_2248226175642056_reloadSlickgrid_2696_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_close_2695 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_reloadSlickgrid_2696,
        event: "close",
        when: this.evh_2248226175642056_close_2695_getWhen,
        displayName: "reloadSlickgrid",
      }
    );
    const act_2248226175642056_showModal_2692 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2248226175642056_showModal_2692_getActionArgs,
        events: [evh_2248226175642056_saved_2693, evh_2248226175642056_close_2695],
      }
    );
    const evh_2248226175642056_command_2691 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_showModal_2692,
        event: "command",
        when: this.evh_2248226175642056_command_2691_getWhen,
        displayName: "showModal",
      }
    );
    const evh_2248226175642056_command = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_command_2691],
      }
    );
    const act_2248226175642056_setData_2698 = new actions.SetDataAction(
      {
        actionArgs: this.act_2248226175642056_setData_2698_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_selectedRowsChanged_2697 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_setData_2698,
        event: "selectedRowsChanged",
        displayName: "setData",
      }
    );
    const evh_2248226175642056_selectedRowsChanged = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_selectedRowsChanged_2697],
      }
    );
    const act_6466705138206980_reloadSlickgrid_2700 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_6466705138206980_reloadSlickgrid_2700_getActionArgs,
        events: [],
      }
    );
    const evh_6466705138206980_input_2699 = new actions.EventHandlerImpl(
      {
        action: act_6466705138206980_reloadSlickgrid_2700,
        event: "input",
        displayName: "reloadSlickgrid",
      }
    );
    const act_6466705138206980_setData_2702 = new actions.SetDataAction(
      {
        actionArgs: this.act_6466705138206980_setData_2702_getActionArgs,
        displayName: "updateSelectedRegisterId",
        events: [],
      }
    );
    const evh_6466705138206980_input_2701 = new actions.EventHandlerImpl(
      {
        action: act_6466705138206980_setData_2702,
        event: "input",
        displayName: "updateSelectedRegisterId",
      }
    );
    const evh_6466705138206980_input = new actions.EventHandlerGroup(
      {
        handlers: [evh_6466705138206980_input_2699, evh_6466705138206980_input_2701],
      }
    );
    const act_8320016629450276_reloadSlickgrid_2706 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_8320016629450276_reloadSlickgrid_2706_getActionArgs,
        events: [],
      }
    );
    const evh_8320016629450276_saved_2705 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_reloadSlickgrid_2706,
        event: "saved",
        displayName: "reloadSlickgrid",
      }
    );
    const act_8320016629450276_reloadSlickgrid_2708 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_8320016629450276_reloadSlickgrid_2708_getActionArgs,
        events: [],
      }
    );
    const evh_8320016629450276_close_2707 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_reloadSlickgrid_2708,
        event: "close",
        when: this.evh_8320016629450276_close_2707_getWhen,
        displayName: "reloadSlickgrid",
      }
    );
    const act_8320016629450276_showModal_2704 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8320016629450276_showModal_2704_getActionArgs,
        events: [evh_8320016629450276_saved_2705, evh_8320016629450276_close_2707],
      }
    );
    const evh_8320016629450276_click_2703 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_showModal_2704,
        event: "click",
        displayName: "showModal",
      }
    );
    const evh_8320016629450276_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_click_2703],
      }
    );
    return {
      act_7315092382398562_closeModal_2690,
      evh_7315092382398562_close_2689,
      evh_7315092382398562_close,
      act_2248226175642056_reloadSlickgrid_2694,
      evh_2248226175642056_saved_2693,
      act_2248226175642056_reloadSlickgrid_2696,
      evh_2248226175642056_close_2695,
      act_2248226175642056_showModal_2692,
      evh_2248226175642056_command_2691,
      evh_2248226175642056_command,
      act_2248226175642056_setData_2698,
      evh_2248226175642056_selectedRowsChanged_2697,
      evh_2248226175642056_selectedRowsChanged,
      act_6466705138206980_reloadSlickgrid_2700,
      evh_6466705138206980_input_2699,
      act_6466705138206980_setData_2702,
      evh_6466705138206980_input_2701,
      evh_6466705138206980_input,
      act_8320016629450276_reloadSlickgrid_2706,
      evh_8320016629450276_saved_2705,
      act_8320016629450276_reloadSlickgrid_2708,
      evh_8320016629450276_close_2707,
      act_8320016629450276_showModal_2704,
      evh_8320016629450276_click_2703,
      evh_8320016629450276_click,
      updateSelectedRegisterId: act_6466705138206980_setData_2702,
    }
  }
}

Vue.component("LetterRegister", LetterRegister);

</script>