<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="Claims"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <VueSlickgrid
    name="claims"
    :headerTitle="documentTitle"
    :items="$fn.slickDataSource('list_claims')"
    checkbox-row-selection
    export-to-csv
    :exportToCsvFilename="'claims'|pgettext('ExportFilename')"
    footer-totals
    :columnDefaults="b_2248226175642056_columnDefaults"
    :columns="b_2248226175642056_columns"
    class="page--grid"
    ref="grid"
    v-if="loaded"
    @selectedRowsChanged="evh_2248226175642056_selectedRowsChanged($event, {})"
    @command="evh_2248226175642056_command($event, {})"
  >
    <DIV
      class="flex-grow-1 d-flex"
      slot="toolbar"
    >
      <ItpButton
        icon="fa-cog"
        :text="'Create dunning letters...'|gettext"
        size="sm"
        :disabled="!selectedItems || !selectedItems.length"
        variant="primary"
        class="mx-2"
        @click="evh_6466705138206980_click($event, {})"
      >
      </ItpButton>
      <PageHelp
        path="/claims/claims.html"
      >
      </PageHelp>
    </DIV>
  </VueSlickgrid>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "claims",
    path: "/claims",
    component: "Claims"
  }
)

@Component()
export default class Claims extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  invoiceTypeBadgeClass(...args: any[]) {
    const [row] = args;
    const cc = this.$config.invoice.types.colorCodes[row.invoice_type];
    return `bg-${cc.bg} text-${cc.text}`
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  selectedItems!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_2248226175642056_columnDefaults!: any;
  b_2248226175642056_columns!: any;

  async $$load_b_2248226175642056_columns() {
    return [{
      field: "invoice_number",
      name: this.$fn.pgettext("InvoicesView", "Invoice nr"),
      type: "command",
      formatter: [{
        name: "link",
        href: "#",
        className: "command-link",
      }
        ,
      {
        name: "cellclass",
        value: (row) => ({
          'font-weight-bold': true,
          'bg-yellow-200': !row.locked,
          'bg-yellow-500 text-yellow-800': row.is_corrected,
          'bg-red-300 text-red-800': row.is_reversed,
        }),
      }
        ,
      ]
      ,
    }
      ,
    {
      field: "invoice_type",
      name: this.$fn.pgettext("InvoicesView", "Type"),
      formatter: {
        name: "chain",
        formatters: [{
          name: "gettext",
          context: "InvoiceType",
          conversion: "StartCase",
          filterInput: true,
        }
          ,
        {
          name: "badge",
          dot: true,
          pill: true,
          badgeClass: this.invoiceTypeBadgeClass,
        }
          ,
        ]
        ,
      }
      ,
    }
      ,
    {
      field: "client_name",
      name: this.$fn.pgettext("Claims", "Client"),
      formatter: {
        name: "routeLink",
        to: "client",
        navigateOnIconClick: true,
        params: {
          id: "client_id",
        }
        ,
      }
      ,
    }
      ,
    {
      field: "dunning_letters",
      name: this.$fn.pgettext("Claims", "Dunning letters"),
      flags: {
        preventCellActivate: true,
      }
      ,
      type: "command",
      formatter: {
        name: "dunningLetterBadges",
      }
      ,
    }
      ,
    {
      field: "uncollectible",
      name: this.$fn.pgettext("Claims", "Uncollectible"),
      formatter: {
        name: "bool",
        trueValue: this.$fn.gettext("Yes"),
      }
      ,
    }
      ,
    {
      field: "days_past_due",
      name: this.$fn.pgettext("Claims", "Days past due"),
      formatter: {
        name: "overdueHighlight",
      }
      ,
    }
      ,
    {
      field: "amount",
      name: this.$fn.pgettext("Claims", "Amount"),
      type: "number",
      formatter: {
        name: "localestring",
        locale: "hu-HU",
      }
      ,
    }
      ,
    {
      field: "debt",
      name: this.$fn.pgettext("Claims", "Claim"),
      type: "number",
      formatter: {
        name: "localestring",
        locale: "hu-HU",
      }
      ,
    }
      ,
    {
      field: "paid",
      name: this.$fn.pgettext("Claims", "Paid"),
      type: "number",
      formatter: {
        name: "localestring",
        locale: "hu-HU",
      }
      ,
    }
      ,
    {
      field: "currency",
      name: this.$fn.pgettext("InvoicesView", "Currency"),
    }
      ,
    {
      field: "invoice_date",
      name: this.$fn.pgettext("InvoicesView", "Invoice date"),
    }
      ,
    {
      field: "fulfillment_date",
      name: this.$fn.pgettext("InvoicesView", "Fulfillment date"),
    }
      ,
    {
      field: "due_date",
      name: this.$fn.pgettext("InvoicesView", "Due date"),
    }
      ,
    {
      field: "payment_method",
      name: this.$fn.pgettext("InvoicesView", "Payment method"),
      formatter: {
        name: "gettext",
        context: "PaymentMethod",
        conversion: "Sentence",
        filterInput: true,
      }
      ,
    }
      ,
    ]
  }

  dataMembers = ['selectedItems', 'ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        selectedItems: [
        ]
        ,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
        b_2248226175642056_columnDefaults: {
          sortable: true,
          filterable: true,
          resizable: true,
        }
        ,
        b_2248226175642056_columns: null,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("Claims", "Claims"),
        icon: this.$config.claim.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_2284_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_2284, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_setData_2286_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2248226175642056_setData_2286, alias=setSelectedItems
    return {
      path: "selectedItems",
      value: this.$refs.grid.getSelectedItems(),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_2248226175642056_selectedRowsChanged(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_showModal_2288_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2248226175642056_showModal_2288, alias=showDunningLetter
    return {
      name: "DunningLetter",
      props: {
        id: $event.data.element.dataset.value,
      }
      ,
    }
  }

  async evh_2248226175642056_command_2287_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2248226175642056_command_2287, alias=undefined
    return $event.data.column.id === 'dunning_letters'
  }

  async act_2248226175642056_showModal_2290_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2248226175642056_showModal_2290, alias=showInvoiceModal
    return {
      name: "Invoice",
      props: {
        oid: $event.data.data.id,
      }
      ,
    }
  }

  async evh_2248226175642056_command_2289_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2248226175642056_command_2289, alias=undefined
    return $event.data.column.id === 'invoice_number'
  }

  async evh_2248226175642056_command(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_command.executeFromDOM(this, event, scope);
  }

  async act_6466705138206980_showModal_2292_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_6466705138206980_showModal_2292, alias=showCreateDunningLetter
    return {
      name: "CreateDunningLetter",
      props: {
        items: this.selectedItems,
      }
      ,
    }
  }

  async evh_6466705138206980_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6466705138206980_click.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_2284: actions.CloseModalAction;
    evh_7315092382398562_close_2283: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_setData_2286: actions.SetDataAction;
    evh_2248226175642056_selectedRowsChanged_2285: actions.EventHandlerImpl;
    evh_2248226175642056_selectedRowsChanged: actions.EventHandlerGroup;
    act_2248226175642056_showModal_2288: actions.ShowModalAction;
    evh_2248226175642056_command_2287: actions.EventHandlerImpl;
    act_2248226175642056_showModal_2290: actions.ShowModalAction;
    evh_2248226175642056_command_2289: actions.EventHandlerImpl;
    evh_2248226175642056_command: actions.EventHandlerGroup;
    act_6466705138206980_showModal_2292: actions.ShowModalAction;
    evh_6466705138206980_click_2291: actions.EventHandlerImpl;
    evh_6466705138206980_click: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_2284 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_2284_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_2283 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_2284,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_2283],
      }
    );
    const act_2248226175642056_setData_2286 = new actions.SetDataAction(
      {
        actionArgs: this.act_2248226175642056_setData_2286_getActionArgs,
        displayName: "setSelectedItems",
        events: [],
      }
    );
    const evh_2248226175642056_selectedRowsChanged_2285 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_setData_2286,
        event: "selectedRowsChanged",
        displayName: "setSelectedItems",
      }
    );
    const evh_2248226175642056_selectedRowsChanged = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_selectedRowsChanged_2285],
      }
    );
    const act_2248226175642056_showModal_2288 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2248226175642056_showModal_2288_getActionArgs,
        displayName: "showDunningLetter",
        events: [],
      }
    );
    const evh_2248226175642056_command_2287 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_showModal_2288,
        event: "command",
        when: this.evh_2248226175642056_command_2287_getWhen,
        displayName: "showDunningLetter",
      }
    );
    const act_2248226175642056_showModal_2290 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2248226175642056_showModal_2290_getActionArgs,
        displayName: "showInvoiceModal",
        events: [],
      }
    );
    const evh_2248226175642056_command_2289 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_showModal_2290,
        event: "command",
        when: this.evh_2248226175642056_command_2289_getWhen,
        displayName: "showInvoiceModal",
      }
    );
    const evh_2248226175642056_command = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_command_2287, evh_2248226175642056_command_2289],
      }
    );
    const act_6466705138206980_showModal_2292 = new actions.ShowModalAction(
      {
        actionArgs: this.act_6466705138206980_showModal_2292_getActionArgs,
        displayName: "showCreateDunningLetter",
        events: [],
      }
    );
    const evh_6466705138206980_click_2291 = new actions.EventHandlerImpl(
      {
        action: act_6466705138206980_showModal_2292,
        event: "click",
        displayName: "showCreateDunningLetter",
      }
    );
    const evh_6466705138206980_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_6466705138206980_click_2291],
      }
    );
    return {
      act_7315092382398562_closeModal_2284,
      evh_7315092382398562_close_2283,
      evh_7315092382398562_close,
      act_2248226175642056_setData_2286,
      evh_2248226175642056_selectedRowsChanged_2285,
      evh_2248226175642056_selectedRowsChanged,
      act_2248226175642056_showModal_2288,
      evh_2248226175642056_command_2287,
      act_2248226175642056_showModal_2290,
      evh_2248226175642056_command_2289,
      evh_2248226175642056_command,
      act_6466705138206980_showModal_2292,
      evh_6466705138206980_click_2291,
      evh_6466705138206980_click,
      setSelectedItems: act_2248226175642056_setData_2286,
      showDunningLetter: act_2248226175642056_showModal_2288,
      showInvoiceModal: act_2248226175642056_showModal_2290,
      showCreateDunningLetter: act_6466705138206980_showModal_2292,
    }
  }
}

Vue.component("Claims", Claims);

</script>