var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "InvoiceClaimsDashboard",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _c("SupersetDashboard", {
        staticClass: "w-100 h-100",
        attrs: {
          dashboardId: "77dd359d-75cd-484e-b877-af5aecd4dba5",
          "filters-expanded": "",
          accessData: function(dashboard_id) {
            return _vm.$fn.fetch("get_superset_guest_token", {
              parameters: { dashboard_id: dashboard_id }
            })
          }
        }
      }),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }