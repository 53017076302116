"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let JobHeader = class JobHeader extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['messages', 'ux', 'b_1382594685840889_style'];
    }
    beforeCreate() {
    }
    onItem(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("item");
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            messages: {
                title: this.$fn.pgettext("Job|ModalHeader", "Job"),
            },
            ux: null,
            b_1382594685840889_style: {
                maxWidth: "80px",
            },
        });
    }
    get resourceTitle() {
        if (this.loaded) {
            return this.$fn.pgettext("Job|ModalHeader", "Job");
        }
        return null;
    }
    get resourceKey() {
        if (this.loaded) {
            return '#' + this.item.number;
        }
        return null;
    }
    act_2754826507016131_emit_5468_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_2754826507016131_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2754826507016131_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_7463771596263925_emit_5472_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    act_7463771596263925_request_5470_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                url: this.item.links.retry,
                method: "PATCH",
                headers: {
                    "If-Match": this.item.etag,
                },
            };
        });
    }
    evh_7463771596263925_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7463771596263925_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_7763705103899431_emit_5476_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    act_7763705103899431_request_5474_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                url: this.item.links.cancel,
                method: "PATCH",
                headers: {
                    "If-Match": this.item.etag,
                },
            };
        });
    }
    evh_7763705103899431_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7763705103899431_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_2754826507016131_emit_5468 = new core_1.actions.EmitAction({
            actionArgs: this.act_2754826507016131_emit_5468_getActionArgs,
            displayName: "reload",
            events: [],
        });
        const evh_2754826507016131_clickPreventStop_5467 = new core_1.actions.EventHandlerImpl({
            action: act_2754826507016131_emit_5468,
            event: "click.prevent.stop",
            displayName: "reload",
        });
        const evh_2754826507016131_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2754826507016131_clickPreventStop_5467],
        });
        const act_7463771596263925_emit_5472 = new core_1.actions.EmitAction({
            actionArgs: this.act_7463771596263925_emit_5472_getActionArgs,
            events: [],
        });
        const evh_7463771596263925_success_5471 = new core_1.actions.EventHandlerImpl({
            action: act_7463771596263925_emit_5472,
            event: "success",
            displayName: "emit",
        });
        const act_7463771596263925_request_5470 = new core_1.actions.RequestAction({
            actionArgs: this.act_7463771596263925_request_5470_getActionArgs,
            displayName: "retryJob",
            events: [evh_7463771596263925_success_5471],
        });
        const evh_7463771596263925_clickPreventStop_5469 = new core_1.actions.EventHandlerImpl({
            action: act_7463771596263925_request_5470,
            event: "click.prevent.stop",
            displayName: "retryJob",
        });
        const evh_7463771596263925_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7463771596263925_clickPreventStop_5469],
        });
        const act_7763705103899431_emit_5476 = new core_1.actions.EmitAction({
            actionArgs: this.act_7763705103899431_emit_5476_getActionArgs,
            events: [],
        });
        const evh_7763705103899431_success_5475 = new core_1.actions.EventHandlerImpl({
            action: act_7763705103899431_emit_5476,
            event: "success",
            displayName: "emit",
        });
        const act_7763705103899431_request_5474 = new core_1.actions.RequestAction({
            actionArgs: this.act_7763705103899431_request_5474_getActionArgs,
            displayName: "cancelJob",
            events: [evh_7763705103899431_success_5475],
        });
        const evh_7763705103899431_clickPreventStop_5473 = new core_1.actions.EventHandlerImpl({
            action: act_7763705103899431_request_5474,
            event: "click.prevent.stop",
            displayName: "cancelJob",
        });
        const evh_7763705103899431_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7763705103899431_clickPreventStop_5473],
        });
        return {
            act_2754826507016131_emit_5468,
            evh_2754826507016131_clickPreventStop_5467,
            evh_2754826507016131_clickPreventStop,
            act_7463771596263925_emit_5472,
            evh_7463771596263925_success_5471,
            act_7463771596263925_request_5470,
            evh_7463771596263925_clickPreventStop_5469,
            evh_7463771596263925_clickPreventStop,
            act_7763705103899431_emit_5476,
            evh_7763705103899431_success_5475,
            act_7763705103899431_request_5474,
            evh_7763705103899431_clickPreventStop_5473,
            evh_7763705103899431_clickPreventStop,
            reload: act_2754826507016131_emit_5468,
            retryJob: act_7463771596263925_request_5470,
            cancelJob: act_7763705103899431_request_5474,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], JobHeader.prototype, "item", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('item'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], JobHeader.prototype, "onItem", null);
JobHeader = __decorate([
    (0, vue_property_decorator_1.Component)()
], JobHeader);
exports.default = JobHeader;
vue_property_decorator_1.Vue.component("JobHeader", JobHeader);
