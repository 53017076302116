<template>
<ItpBox
  alignItems="center"
  class="items-spacing w-100"
  v-if="loaded"
>
  <ItpBox
    direction="column"
  >
    <ItpText
      muted
      :text="resourceTitle"
      class="mb-0"
    >
    </ItpText>
    <ItpText
      tag="h5"
      :text="resourceKey"
      class="mb-0"
    >
    </ItpText>
  </ItpBox>
  <ItpBox
    direction="column"
    v-if="!entry.isNew"
  >
    <ItpText
      muted
      :text="'Invoice number'|pgettext('InvoiceRegisterEntryHeader')"
      class="mb-0"
    >
    </ItpText>
    <ItpText
      tag="h4"
      :text="entry.invoice_number"
      class="mb-0"
    >
    </ItpText>
  </ItpBox>
  <b-button-group
    class="ml-auto"
  >
    <ItpButton
      icon="fa-sync-alt"
      :tooltip="'Reload'|gettext"
      :spinning="actions.reload.isRunning"
      variant="light"
      class="ml-auto"
      v-if="!entry.isNew"
      @click.prevent.stop="evh_1548630417156826_clickPreventStop($event, {})"
    >
    </ItpButton>
    <ItpLink
      icon="bxs:file-pdf"
      :href="entry.preview_url"
      tooltip="Open PDF"
      target="_blank"
      use-auth
      button
      variant="light"
      show-spinner
      no-download-link
      :text="'Preview'|gettext"
      v-if="entry.preview_url"
    >
    </ItpLink>
    <b-dropdown
      right
      variant="light"
      class="border"
      v-if="!entry.isNew"
    >
      <ItpIcon
        fa="bars"
        class="mx-1"
        slot="button-content"
      >
      </ItpIcon>
      <BtnLockResource
        no-icon
        dropdown-item
        :resource="entry"
        :tooltip="'Lock entry'|pgettext('InvoiceRegister')"
        v-if="!entry.isNew && !entry.isReadOnly"
        @success="evh_6793520326132864_success($event, {})"
      >
      </BtnLockResource>
      <BtnDeleteResource
        no-icon
        dropdown-item
        :resource="entry"
        :tooltip="'Delete entry'|pgettext('InvoiceRegister')"
        v-if="!entry.isNew && !entry.isReadOnly"
        @success="evh_1269078620197197_success($event, {})"
      >
      </BtnDeleteResource>
    </b-dropdown>
  </b-button-group>
</ItpBox>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class InvoiceRegisterEntryHeader extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  entry!: object;

  @Watch('entry')
  onEntry(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("entry")
  }

  @Prop({
    required: true,
    type: Object,
  })
  register!: object;

  @Watch('register')
  onRegister(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("register")
  }

  messages!: any;
  ux!: any;
  dataMembers = ['messages', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        messages: {
          newTransaction: this.$fn.pgettext("InvoiceRegister", "New entry"),
        }
        ,
        ux: null,
      },
    }
  }

  get resourceTitle() {
    if (this.loaded) {

      return this.register.name
    }
    return null;
  }

  get resourceKey() {
    if (this.loaded) {

      return this.entry.isNew ? this.messages.newTransaction : this.entry.entry_number
    }
    return null;
  }

  async act_1548630417156826_emit_2556_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_1548630417156826_emit_2556, alias=reload
    return {
      event: "reload",
    }
  }

  async evh_1548630417156826_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1548630417156826_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_6793520326132864_emit_2558_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_6793520326132864_emit_2558, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_6793520326132864_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6793520326132864_success.executeFromDOM(this, event, scope);
  }

  async act_1269078620197197_emit_2560_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_1269078620197197_emit_2560, alias=undefined
    return {
      event: "deleted",
    }
  }

  async evh_1269078620197197_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1269078620197197_success.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_1548630417156826_emit_2556: actions.EmitAction;
    evh_1548630417156826_clickPreventStop_2555: actions.EventHandlerImpl;
    evh_1548630417156826_clickPreventStop: actions.EventHandlerGroup;
    act_6793520326132864_emit_2558: actions.EmitAction;
    evh_6793520326132864_success_2557: actions.EventHandlerImpl;
    evh_6793520326132864_success: actions.EventHandlerGroup;
    act_1269078620197197_emit_2560: actions.EmitAction;
    evh_1269078620197197_success_2559: actions.EventHandlerImpl;
    evh_1269078620197197_success: actions.EventHandlerGroup;
  }

  getActions() {
    const act_1548630417156826_emit_2556 = new actions.EmitAction(
      {
        actionArgs: this.act_1548630417156826_emit_2556_getActionArgs,
        displayName: "reload",
        events: [],
      }
    );
    const evh_1548630417156826_clickPreventStop_2555 = new actions.EventHandlerImpl(
      {
        action: act_1548630417156826_emit_2556,
        event: "click.prevent.stop",
        displayName: "reload",
      }
    );
    const evh_1548630417156826_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1548630417156826_clickPreventStop_2555],
      }
    );
    const act_6793520326132864_emit_2558 = new actions.EmitAction(
      {
        actionArgs: this.act_6793520326132864_emit_2558_getActionArgs,
        events: [],
      }
    );
    const evh_6793520326132864_success_2557 = new actions.EventHandlerImpl(
      {
        action: act_6793520326132864_emit_2558,
        event: "success",
        displayName: "emit",
      }
    );
    const evh_6793520326132864_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_6793520326132864_success_2557],
      }
    );
    const act_1269078620197197_emit_2560 = new actions.EmitAction(
      {
        actionArgs: this.act_1269078620197197_emit_2560_getActionArgs,
        events: [],
      }
    );
    const evh_1269078620197197_success_2559 = new actions.EventHandlerImpl(
      {
        action: act_1269078620197197_emit_2560,
        event: "success",
        displayName: "emit",
      }
    );
    const evh_1269078620197197_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_1269078620197197_success_2559],
      }
    );
    return {
      act_1548630417156826_emit_2556,
      evh_1548630417156826_clickPreventStop_2555,
      evh_1548630417156826_clickPreventStop,
      act_6793520326132864_emit_2558,
      evh_6793520326132864_success_2557,
      evh_6793520326132864_success,
      act_1269078620197197_emit_2560,
      evh_1269078620197197_success_2559,
      evh_1269078620197197_success,
      reload: act_1548630417156826_emit_2556,
    }
  }
}

Vue.component("InvoiceRegisterEntryHeader", InvoiceRegisterEntryHeader);

</script>