<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <DIV
    class="container p-0"
  >
    <ItpRow
      class="no-gutters"
    >
      <ItpCol
        w="6"
      >
        <ItpRow
          class="no-gutters mb-2"
          v-if="worksheet.status"
        >
          <ItpText
            :text="'Status'|pgettext('Worksheet')"
            muted
            class="col-3"
          >
          </ItpText>
          <UcWorksheetStatus
            strong
            :value="worksheet.status"
            class="col"
          >
          </UcWorksheetStatus>
        </ItpRow>
        <ItpRow
          class="no-gutters mb-2"
          v-if="worksheet.company"
        >
          <ItpText
            :text="'Company'|pgettext('Worksheet')"
            muted
            class="col-3"
          >
          </ItpText>
          <UcCompactClientDisplay
            :payload="worksheet.company"
            class="col"
          >
          </UcCompactClientDisplay>
        </ItpRow>
        <ItpRow
          class="no-gutters mb-2"
          v-if="worksheet.client"
        >
          <ItpText
            :text="'Client'|pgettext('Worksheet')"
            muted
            class="col-3"
          >
          </ItpText>
          <UcClientMiniCard
            no-icon
            :item="worksheet.client"
            class="col"
          >
          </UcClientMiniCard>
        </ItpRow>
        <ItpRow
          class="no-gutters mb-2"
          v-if="worksheet.contractor"
        >
          <ItpText
            :text="'Contractor'|pgettext('Worksheet')"
            muted
            class="col-3"
          >
          </ItpText>
          <UcClientMiniCard
            no-icon
            :item="worksheet.contractor"
            class="col"
          >
          </UcClientMiniCard>
        </ItpRow>
        <ItpRow
          class="no-gutters mb-2"
          v-if="worksheet.rental"
        >
          <ItpText
            :text="'Rental'|pgettext('Worksheet')"
            muted
            class="col-3"
          >
          </ItpText>
          <UcRentalMiniCard
            no-icon
            :item="worksheet.rental"
            class="col"
          >
          </UcRentalMiniCard>
        </ItpRow>
      </ItpCol>
      <ItpCol
        w="6"
      >
        <ItpRow
          class="no-gutters mb-2"
          v-if="worksheet.description"
        >
          <ItpText
            :text="'Description'|pgettext('Worksheet')"
            muted
            class="col-3"
          >
          </ItpText>
          <ItpText
            :text="worksheet.description"
            strong
            class="col-6"
          >
          </ItpText>
        </ItpRow>
        <ItpRow
          class="no-gutters mb-2"
          v-if="worksheet.worksheet_date"
        >
          <ItpText
            :text="'Date'|pgettext('Worksheet')"
            muted
            class="col-3"
          >
          </ItpText>
          <ItpText
            :text="worksheet.worksheet_date | date"
            strong
            class="col-6"
          >
          </ItpText>
        </ItpRow>
        <ItpRow
          class="no-gutters mb-2"
          v-if="worksheet.start_date || worksheet.end_date"
        >
          <ItpText
            :text="'Start/End'|pgettext('Worksheet')"
            muted
            class="col-3"
          >
          </ItpText>
          <DIV
            class="col-6"
          >
            <ItpText
              :text="worksheet.start_date|date"
              defaultText="n/a"
              strong
            >
            </ItpText>
            <span>
              &#8208;
            </span>
            <ItpText
              :text="worksheet.end_date|date"
              defaultText="n/a"
              strong
            >
            </ItpText>
          </DIV>
        </ItpRow>
        <ItpRow
          class="no-gutters mb-2"
          v-if="worksheet.gl_account_number"
        >
          <ItpText
            :text="'GL Account Number'|pgettext('Worksheet')"
            muted
            class="col-3"
          >
          </ItpText>
          <ItpText
            :text="worksheet.gl_account_number"
            wrap
            strong
            class="col-9"
          >
          </ItpText>
        </ItpRow>
        <ItpRow
          class="no-gutters mb-2"
          v-if="worksheet.notes"
        >
          <ItpText
            :text="'Notes'|pgettext('Worksheet')"
            muted
            class="col-3"
          >
          </ItpText>
          <ItpText
            :text="worksheet.notes"
            wrap
            class="col-9"
          >
          </ItpText>
        </ItpRow>
      </ItpCol>
    </ItpRow>
  </DIV>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcWorksheetData extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  worksheet!: object;

  @Watch('worksheet')
  onWorksheet(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("worksheet")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  get isReadonly() {
    if (this.loaded) {

      return false
    }
    return null;
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_5792: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_5791: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_5792 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_5791 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_5792,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_5791],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_5792,
      evh_7315092382398562_reload_5791,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("UcWorksheetData", UcWorksheetData);

</script>