"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let RentalServiceCostAllocator = class RentalServiceCostAllocator extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['item', 'labelAlign', 'editor', 'formName', 'formId', 'createTitle', 'resourceTitle', 'ux', 'b_7315092382398562_modalBindings', 'b_7789867021953041_dataSource'];
    }
    beforeCreate() {
    }
    onOid(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("oid");
    }
    onService(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("service");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.oid ?
                this.$fn.fetch('read_rental_service_cost_allocator', { parameters: { id: this.oid }, asResource: true }) :
                this.$fn.schemaDefaults('CreateRentalServiceCostAllocator', {
                    rental_id: this.service.rental.id,
                    rental_service_id: this.service.id,
                });
        });
    }
    $$load_editor() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.item.editor();
        });
    }
    $$load_formId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            item: null,
            labelAlign: "right",
            editor: null,
            formName: "rentalServiceCostAllocator",
            formId: null,
            createTitle: this.$fn.pgettext("RentalServiceCostAllocator", "New cost allocator"),
            resourceTitle: this.$fn.pgettext("RentalServiceCostAllocator/header", "Cost allocator"),
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "md",
            },
            b_7789867021953041_dataSource: {
                name: "suggest_meter",
                parameters: {
                    types_only: "cost_allocator",
                },
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: [!this.oid && this.createTitle,
                    this.item && this.item.meter && this.item.meter.serial_number,
                ],
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_6994_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_1040167445267876_closeComponent_7000_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    ok: true,
                },
            };
        });
    }
    act_1040167445267876_rest_6998_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                method: "save",
                container: this.editor.cost_allocators,
                resource: this.editor,
            };
        });
    }
    evh_1040167445267876_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
        });
    }
    evh_1419464017721962_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_6994 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_6994_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_6993 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_6994,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_6993],
        });
        const act_2248226175642056_closeComponent_6996 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2248226175642056_close_6995 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_6996,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_6995],
        });
        const act_1040167445267876_closeComponent_7000 = new core_1.actions.CloseComponentAction({
            actionArgs: this.act_1040167445267876_closeComponent_7000_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_saved_6999 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_closeComponent_7000,
            event: "saved",
            displayName: "closeComponent",
        });
        const act_1040167445267876_rest_6998 = new core_1.actions.RestAction({
            actionArgs: this.act_1040167445267876_rest_6998_getActionArgs,
            displayName: "create",
            events: [evh_1040167445267876_saved_6999],
        });
        const evh_1040167445267876_submit_6997 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_rest_6998,
            event: "submit",
            displayName: "create",
        });
        const evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_6997],
        });
        const act_1419464017721962_closeComponent_7002 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_1419464017721962_clickPreventStop_7001 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_closeComponent_7002,
            event: "click.prevent.stop",
            displayName: "closeComponent",
        });
        const evh_1419464017721962_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1419464017721962_clickPreventStop_7001],
        });
        return {
            act_7315092382398562_closeModal_6994,
            evh_7315092382398562_close_6993,
            evh_7315092382398562_close,
            act_2248226175642056_closeComponent_6996,
            evh_2248226175642056_close_6995,
            evh_2248226175642056_close,
            act_1040167445267876_closeComponent_7000,
            evh_1040167445267876_saved_6999,
            act_1040167445267876_rest_6998,
            evh_1040167445267876_submit_6997,
            evh_1040167445267876_submit,
            act_1419464017721962_closeComponent_7002,
            evh_1419464017721962_clickPreventStop_7001,
            evh_1419464017721962_clickPreventStop,
            create: act_1040167445267876_rest_6998,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], RentalServiceCostAllocator.prototype, "oid", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('oid'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], RentalServiceCostAllocator.prototype, "onOid", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], RentalServiceCostAllocator.prototype, "service", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('service'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], RentalServiceCostAllocator.prototype, "onService", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], RentalServiceCostAllocator.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], RentalServiceCostAllocator.prototype, "onSize", null);
RentalServiceCostAllocator = __decorate([
    (0, vue_property_decorator_1.Component)()
], RentalServiceCostAllocator);
exports.default = RentalServiceCostAllocator;
vue_property_decorator_1.Vue.component("RentalServiceCostAllocator", RentalServiceCostAllocator);
