var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c("ItpText", { attrs: { text: _vm.value.text, pre: "" } }),
      _vm._v(" "),
      _c("ItpButton", {
        attrs: {
          text: _vm._f("pgettext")("Edit text templates...", "MultilineNotes")
        },
        on: {
          click: function($event) {
            $event.preventDefault()
            $event.stopPropagation()
            return _vm.evh_5962862420439144_clickPreventStop($event, {})
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }