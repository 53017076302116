<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="ClientBrowser"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <VueSlickgrid
    name="clients"
    :headerTitle="'Clients'|pgettext('ClientBrowser')"
    :items="$fn.slickDataSource('list_client')"
    checkbox-row-selection
    export-to-csv
    :exportToCsvFilename="'clients'|pgettext('filename')"
    :columnDefaults="b_2248226175642056_columnDefaults"
    :columns="b_2248226175642056_columns"
    class="page--grid"
    ref="grid"
    @selectedRowsChanged="evh_2248226175642056_selectedRowsChanged($event, {})"
  >
    <DIV
      class="flex-grow-1 d-flex"
      slot="toolbar"
    >
      <ItpButton
        icon="fa-upload"
        :text="'Dataimport...'|pgettext('ClientBrowser')"
        size="sm"
        variant="light"
        class="mx-2"
        @click="evh_6466705138206980_click($event, {})"
      >
      </ItpButton>
      <ItpButton
        :icon="$config.client.createClientIcon"
        :text="'New client...'|pgettext('ClientBrowser')"
        size="sm"
        variant="light"
        class="mx-2"
        @click="evh_8320016629450276_click($event, {})"
      >
      </ItpButton>
      <PageHelp
        path="/claims/clients.html"
      >
      </PageHelp>
    </DIV>
  </VueSlickgrid>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "clients",
    path: "/clients",
    component: "ClientBrowser"
  }
)

@Component()
export default class ClientBrowser extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  selectedItems!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_2248226175642056_columnDefaults!: any;
  b_2248226175642056_columns!: any;
  dataMembers = ['selectedItems', 'ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        selectedItems: [
        ]
        ,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
        b_2248226175642056_columnDefaults: {
          sortable: true,
          filterable: true,
          resizable: true,
        }
        ,
        b_2248226175642056_columns: [{
          field: "code",
          name: this.$fn.pgettext("ClientsView", "Code"),
          formatter: {
            name: "routeLink",
            to: "client",
            params: {
              id: "id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "name",
          name: this.$fn.pgettext("ClientsView", "Name"),
          formatter: {
            name: "routeLink",
            to: "client",
            params: {
              id: "id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "owned",
          name: this.$fn.pgettext("ClientsView", "Owned"),
          textAlign: "center",
          formatter: {
            name: "checkbox",
          }
          ,
        }
          ,
        {
          field: "client_relation",
          name: this.$fn.pgettext("ClientsView", "Client relation"),
          formatter: [{
            name: "gettext",
            context: "ClientRelations",
            conversion: "StartCase",
          }
            ,
          ]
          ,
        }
          ,
        {
          field: "is_natural_person",
          name: this.$fn.pgettext("ClientsView", "Natural person"),
          textAlign: "center",
          formatter: {
            name: "checkbox",
          }
          ,
        }
          ,
        {
          field: "is_contractor",
          name: this.$fn.pgettext("ClientsView", "Contractor"),
          textAlign: "center",
          formatter: {
            name: "checkbox",
          }
          ,
        }
          ,
        {
          field: "external_key",
          name: this.$fn.pgettext("ClientsView", "External key"),
        }
          ,
        ]
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.gettext("Clients"),
        icon: this.$config.client.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_1666_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_1666, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_setData_1668_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2248226175642056_setData_1668, alias=undefined
    return {
      path: "selectedItems",
      value: this.$refs.grid.getSelectedItems(),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_2248226175642056_selectedRowsChanged(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope);
  }

  async act_6466705138206980_showModal_1670_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_6466705138206980_showModal_1670, alias=dataImport
    return {
      name: "ClientDataImportModal",
    }
  }

  async evh_6466705138206980_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6466705138206980_click.executeFromDOM(this, event, scope);
  }

  async act_8320016629450276_showModal_1672_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8320016629450276_showModal_1672, alias=undefined
    return {
      name: "CreateClient",
    }
  }

  async evh_8320016629450276_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_click.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_1666: actions.CloseModalAction;
    evh_7315092382398562_close_1665: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_setData_1668: actions.SetDataAction;
    evh_2248226175642056_selectedRowsChanged_1667: actions.EventHandlerImpl;
    evh_2248226175642056_selectedRowsChanged: actions.EventHandlerGroup;
    act_6466705138206980_showModal_1670: actions.ShowModalAction;
    evh_6466705138206980_click_1669: actions.EventHandlerImpl;
    evh_6466705138206980_click: actions.EventHandlerGroup;
    act_8320016629450276_showModal_1672: actions.ShowModalAction;
    evh_8320016629450276_click_1671: actions.EventHandlerImpl;
    evh_8320016629450276_click: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_1666 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_1666_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_1665 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_1666,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_1665],
      }
    );
    const act_2248226175642056_setData_1668 = new actions.SetDataAction(
      {
        actionArgs: this.act_2248226175642056_setData_1668_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_selectedRowsChanged_1667 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_setData_1668,
        event: "selectedRowsChanged",
        displayName: "setData",
      }
    );
    const evh_2248226175642056_selectedRowsChanged = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_selectedRowsChanged_1667],
      }
    );
    const act_6466705138206980_showModal_1670 = new actions.ShowModalAction(
      {
        actionArgs: this.act_6466705138206980_showModal_1670_getActionArgs,
        displayName: "dataImport",
        events: [],
      }
    );
    const evh_6466705138206980_click_1669 = new actions.EventHandlerImpl(
      {
        action: act_6466705138206980_showModal_1670,
        event: "click",
        displayName: "dataImport",
      }
    );
    const evh_6466705138206980_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_6466705138206980_click_1669],
      }
    );
    const act_8320016629450276_showModal_1672 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8320016629450276_showModal_1672_getActionArgs,
        events: [],
      }
    );
    const evh_8320016629450276_click_1671 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_showModal_1672,
        event: "click",
        displayName: "showModal",
      }
    );
    const evh_8320016629450276_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_click_1671],
      }
    );
    return {
      act_7315092382398562_closeModal_1666,
      evh_7315092382398562_close_1665,
      evh_7315092382398562_close,
      act_2248226175642056_setData_1668,
      evh_2248226175642056_selectedRowsChanged_1667,
      evh_2248226175642056_selectedRowsChanged,
      act_6466705138206980_showModal_1670,
      evh_6466705138206980_click_1669,
      evh_6466705138206980_click,
      act_8320016629450276_showModal_1672,
      evh_8320016629450276_click_1671,
      evh_8320016629450276_click,
      dataImport: act_6466705138206980_showModal_1670,
    }
  }
}

Vue.component("ClientBrowser", ClientBrowser);

</script>