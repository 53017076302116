<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpWidgetCard>
    <ItpBox
      alignItems="center"
      slot="header"
    >
      <strong>
        {{ 'General'|pgettext('Contract') }}
      </strong>
      <ItpButton
        :text="'Edit...'|gettext"
        variant="light"
        size="sm"
        class="ml-auto"
        v-if="!readonly"
        @click.prevent.stop="evh_8320016629450276_clickPreventStop($event, {})"
      >
      </ItpButton>
    </ItpBox>
    <BListGroup>
      <ItpLabeledListGroupItem
        :label="'Contract number'|pgettext('Contract')"
        :content="contract.contract_number"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Contract type'|pgettext('Contract')"
      >
        <UcContractTypeBadge
          :value="contract.contract_type"
        >
        </UcContractTypeBadge>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Status'|pgettext('Contract')"
      >
        <UcContractStatusCard
          :contract="contract"
          :reload="lastLoaded"
          @reload="evh_7349226261686150_reload($event, {})"
        >
        </UcContractStatusCard>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Description'|pgettext('Contract')"
        :content="contract.extras.description"
        v-if="contract.extras.description"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Folder number'|pgettext('Contract')"
        :content="contract.extras.folder_number"
        v-if="contract.extras.folder_number"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Reference number'|pgettext('Contract')"
        :content="contract.extras.reference_number"
        v-if="contract.extras.reference_number"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Lessor'|pgettext('Contract')"
        expandable
      >
        <ItpBox>
          <ItpLink
            :text="contract.company.name"
            :to="b_6379803495938895_to"
          >
          </ItpLink>
          <ItpButton
            :text="'Edit...'|gettext"
            variant="light"
            size="sm"
            class="ml-auto"
            v-if="!readonly"
            @click.prevent.stop="evh_5937240531526321_clickPreventStop($event, {})"
          >
          </ItpButton>
        </ItpBox>
        <template
          slot="expander"
        >
          <UcContractCompanyCard
            :item="contract.company"
          >
          </UcContractCompanyCard>
        </template>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Tenant'|pgettext('Contract')"
        expandable
      >
        <ItpBox>
          <ItpLink
            :text="contract.counterparty.name"
            :to="b_8906220998092919_to"
          >
          </ItpLink>
          <ItpButton
            :text="'Edit...'|gettext"
            variant="light"
            size="sm"
            class="ml-auto"
            v-if="!readonly"
            @click.prevent.stop="evh_7527485200259320_clickPreventStop($event, {})"
          >
          </ItpButton>
        </ItpBox>
        <template
          slot="expander"
        >
          <UcContractClientCard
            :item="contract.counterparty"
          >
          </UcContractClientCard>
        </template>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Rental'|pgettext('Contract')"
        expandable
        expanderCols="11"
      >
        <ItpBox>
          <ItpLink
            :text="contract.rental.name"
            :to="{
    name: 'app.rental',
    params: {
      id: contract.rental.id
    }
  }"
            v-if="contract.rental"
          >
          </ItpLink>
          <ItpButton
            :text="'Edit...'|gettext"
            variant="light"
            size="sm"
            class="ml-auto"
            v-if="!readonly"
            @click.prevent.stop="evh_7127218473441393_clickPreventStop($event, {})"
          >
          </ItpButton>
        </ItpBox>
        <template
          slot="expander"
        >
          <UcContractRentalCard
            :rental="contract.rental"
          >
          </UcContractRentalCard>
          <UcContractRentalServices
            :rental="contract.rental"
            :reload="lastLoaded"
            @reload="evh_4198689235226718_reload($event, {})"
          >
          </UcContractRentalServices>
        </template>
      </ItpLabeledListGroupItem>
    </BListGroup>
  </ItpWidgetCard>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcContractGeneralInfo extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  contract!: object;

  @Watch('contract')
  onContract(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("contract")
  }

  @Prop({
    type: Boolean,
  })
  readonly!: boolean;

  @Watch('readonly')
  onReadonly(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("readonly")
  }

  ux!: any;
  b_6379803495938895_to!: any;

  async $$load_b_6379803495938895_to() {
    return {
      name: "app.company",
      params: {
        id: this.contract.company.id,
      }
      ,
    }
  }

  b_8906220998092919_to!: any;

  async $$load_b_8906220998092919_to() {
    return {
      name: "app.client",
      params: {
        id: this.contract.counterparty.id,
      }
      ,
    }
  }

  dataMembers = ['ux', 'b_6379803495938895_to', 'b_8906220998092919_to'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
        b_6379803495938895_to: null,
        b_8906220998092919_to: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_8320016629450276_emit_6110_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_8320016629450276_emit_6110, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_8320016629450276_close_6109_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_8320016629450276_close_6109, alias=undefined
    return !!$event.data.modalResult.value
  }

  async act_8320016629450276_showModal_6108_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8320016629450276_showModal_6108, alias=undefined
    return {
      name: "ContractEditor",
      props: {
        target: "general",
        contract: _.cloneDeep(this.contract),
      }
      ,
    }
  }

  async evh_8320016629450276_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_7349226261686150_emit_6112_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_7349226261686150_emit_6112, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_7349226261686150_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7349226261686150_reload.executeFromDOM(this, event, scope);
  }

  async act_5937240531526321_emit_6116_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_5937240531526321_emit_6116, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_5937240531526321_close_6115_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_5937240531526321_close_6115, alias=undefined
    return $event.data.modalResult.ok
  }

  async act_5937240531526321_showModal_6114_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_5937240531526321_showModal_6114, alias=undefined
    return {
      name: "ContractEditor",
      props: {
        target: "company",
        contract: _.cloneDeep(this.contract),
      }
      ,
    }
  }

  async evh_5937240531526321_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5937240531526321_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_7527485200259320_emit_6120_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_7527485200259320_emit_6120, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_7527485200259320_close_6119_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_7527485200259320_close_6119, alias=undefined
    return $event.data.modalResult.ok
  }

  async act_7527485200259320_showModal_6118_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_7527485200259320_showModal_6118, alias=undefined
    return {
      name: "ContractEditor",
      props: {
        target: "counterparty",
        contract: _.cloneDeep(this.contract),
      }
      ,
    }
  }

  async evh_7527485200259320_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7527485200259320_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_7127218473441393_emit_6124_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_7127218473441393_emit_6124, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_7127218473441393_close_6123_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_7127218473441393_close_6123, alias=undefined
    return $event.data.modalResult.ok
  }

  async act_7127218473441393_showModal_6122_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_7127218473441393_showModal_6122, alias=undefined
    return {
      name: "ContractEditor",
      props: {
        target: "rental",
        contract: this.contract.rental
          ? _.cloneDeep(this.contract)
          : _.extend(_.cloneDeep(this.contract), {
            rental: {
              id: null,
              name: null,
              number: null,
              description: null,
              house_number: null,
              inactive: false,
              location_code: null,
              lot_number: null,

            }
          }),
      }
      ,
    }
  }

  async evh_7127218473441393_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7127218473441393_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async evh_4198689235226718_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4198689235226718_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_6106: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_6105: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_8320016629450276_emit_6110: actions.EmitAction;
    evh_8320016629450276_close_6109: actions.EventHandlerImpl;
    act_8320016629450276_showModal_6108: actions.ShowModalAction;
    evh_8320016629450276_clickPreventStop_6107: actions.EventHandlerImpl;
    evh_8320016629450276_clickPreventStop: actions.EventHandlerGroup;
    act_7349226261686150_emit_6112: actions.EmitAction;
    evh_7349226261686150_reload_6111: actions.EventHandlerImpl;
    evh_7349226261686150_reload: actions.EventHandlerGroup;
    act_5937240531526321_emit_6116: actions.EmitAction;
    evh_5937240531526321_close_6115: actions.EventHandlerImpl;
    act_5937240531526321_showModal_6114: actions.ShowModalAction;
    evh_5937240531526321_clickPreventStop_6113: actions.EventHandlerImpl;
    evh_5937240531526321_clickPreventStop: actions.EventHandlerGroup;
    act_7527485200259320_emit_6120: actions.EmitAction;
    evh_7527485200259320_close_6119: actions.EventHandlerImpl;
    act_7527485200259320_showModal_6118: actions.ShowModalAction;
    evh_7527485200259320_clickPreventStop_6117: actions.EventHandlerImpl;
    evh_7527485200259320_clickPreventStop: actions.EventHandlerGroup;
    act_7127218473441393_emit_6124: actions.EmitAction;
    evh_7127218473441393_close_6123: actions.EventHandlerImpl;
    act_7127218473441393_showModal_6122: actions.ShowModalAction;
    evh_7127218473441393_clickPreventStop_6121: actions.EventHandlerImpl;
    evh_7127218473441393_clickPreventStop: actions.EventHandlerGroup;
    act_4198689235226718_reloadComponentData_6126: actions.ReloadComponentDataAction;
    evh_4198689235226718_reload_6125: actions.EventHandlerImpl;
    evh_4198689235226718_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_6106 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_6105 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_6106,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_6105],
      }
    );
    const act_8320016629450276_emit_6110 = new actions.EmitAction(
      {
        actionArgs: this.act_8320016629450276_emit_6110_getActionArgs,
        events: [],
      }
    );
    const evh_8320016629450276_close_6109 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_emit_6110,
        event: "close",
        when: this.evh_8320016629450276_close_6109_getWhen,
        displayName: "emit",
      }
    );
    const act_8320016629450276_showModal_6108 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8320016629450276_showModal_6108_getActionArgs,
        events: [evh_8320016629450276_close_6109],
      }
    );
    const evh_8320016629450276_clickPreventStop_6107 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_showModal_6108,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_8320016629450276_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_clickPreventStop_6107],
      }
    );
    const act_7349226261686150_emit_6112 = new actions.EmitAction(
      {
        actionArgs: this.act_7349226261686150_emit_6112_getActionArgs,
        events: [],
      }
    );
    const evh_7349226261686150_reload_6111 = new actions.EventHandlerImpl(
      {
        action: act_7349226261686150_emit_6112,
        event: "reload",
        displayName: "emit",
      }
    );
    const evh_7349226261686150_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7349226261686150_reload_6111],
      }
    );
    const act_5937240531526321_emit_6116 = new actions.EmitAction(
      {
        actionArgs: this.act_5937240531526321_emit_6116_getActionArgs,
        events: [],
      }
    );
    const evh_5937240531526321_close_6115 = new actions.EventHandlerImpl(
      {
        action: act_5937240531526321_emit_6116,
        event: "close",
        when: this.evh_5937240531526321_close_6115_getWhen,
        displayName: "emit",
      }
    );
    const act_5937240531526321_showModal_6114 = new actions.ShowModalAction(
      {
        actionArgs: this.act_5937240531526321_showModal_6114_getActionArgs,
        events: [evh_5937240531526321_close_6115],
      }
    );
    const evh_5937240531526321_clickPreventStop_6113 = new actions.EventHandlerImpl(
      {
        action: act_5937240531526321_showModal_6114,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_5937240531526321_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_5937240531526321_clickPreventStop_6113],
      }
    );
    const act_7527485200259320_emit_6120 = new actions.EmitAction(
      {
        actionArgs: this.act_7527485200259320_emit_6120_getActionArgs,
        events: [],
      }
    );
    const evh_7527485200259320_close_6119 = new actions.EventHandlerImpl(
      {
        action: act_7527485200259320_emit_6120,
        event: "close",
        when: this.evh_7527485200259320_close_6119_getWhen,
        displayName: "emit",
      }
    );
    const act_7527485200259320_showModal_6118 = new actions.ShowModalAction(
      {
        actionArgs: this.act_7527485200259320_showModal_6118_getActionArgs,
        events: [evh_7527485200259320_close_6119],
      }
    );
    const evh_7527485200259320_clickPreventStop_6117 = new actions.EventHandlerImpl(
      {
        action: act_7527485200259320_showModal_6118,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_7527485200259320_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_7527485200259320_clickPreventStop_6117],
      }
    );
    const act_7127218473441393_emit_6124 = new actions.EmitAction(
      {
        actionArgs: this.act_7127218473441393_emit_6124_getActionArgs,
        events: [],
      }
    );
    const evh_7127218473441393_close_6123 = new actions.EventHandlerImpl(
      {
        action: act_7127218473441393_emit_6124,
        event: "close",
        when: this.evh_7127218473441393_close_6123_getWhen,
        displayName: "emit",
      }
    );
    const act_7127218473441393_showModal_6122 = new actions.ShowModalAction(
      {
        actionArgs: this.act_7127218473441393_showModal_6122_getActionArgs,
        events: [evh_7127218473441393_close_6123],
      }
    );
    const evh_7127218473441393_clickPreventStop_6121 = new actions.EventHandlerImpl(
      {
        action: act_7127218473441393_showModal_6122,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_7127218473441393_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_7127218473441393_clickPreventStop_6121],
      }
    );
    const act_4198689235226718_reloadComponentData_6126 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_4198689235226718_reload_6125 = new actions.EventHandlerImpl(
      {
        action: act_4198689235226718_reloadComponentData_6126,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_4198689235226718_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_4198689235226718_reload_6125],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_6106,
      evh_7315092382398562_reload_6105,
      evh_7315092382398562_reload,
      act_8320016629450276_emit_6110,
      evh_8320016629450276_close_6109,
      act_8320016629450276_showModal_6108,
      evh_8320016629450276_clickPreventStop_6107,
      evh_8320016629450276_clickPreventStop,
      act_7349226261686150_emit_6112,
      evh_7349226261686150_reload_6111,
      evh_7349226261686150_reload,
      act_5937240531526321_emit_6116,
      evh_5937240531526321_close_6115,
      act_5937240531526321_showModal_6114,
      evh_5937240531526321_clickPreventStop_6113,
      evh_5937240531526321_clickPreventStop,
      act_7527485200259320_emit_6120,
      evh_7527485200259320_close_6119,
      act_7527485200259320_showModal_6118,
      evh_7527485200259320_clickPreventStop_6117,
      evh_7527485200259320_clickPreventStop,
      act_7127218473441393_emit_6124,
      evh_7127218473441393_close_6123,
      act_7127218473441393_showModal_6122,
      evh_7127218473441393_clickPreventStop_6121,
      evh_7127218473441393_clickPreventStop,
      act_4198689235226718_reloadComponentData_6126,
      evh_4198689235226718_reload_6125,
      evh_4198689235226718_reload,
    }
  }
}

Vue.component("UcContractGeneralInfo", UcContractGeneralInfo);

</script>