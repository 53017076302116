<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="MeterBrowser"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <VueSlickgrid
    name="meters"
    :headerTitle="'Meters'|pgettext('MeterBrowser')"
    :items="$fn.slickDataSource('list_meter', {parameters: getQueryParameters})"
    checkbox-row-selection
    export-to-csv
    :exportToCsvFilename="'meters'|pgettext('ExportFilename')"
    :columnDefaults="b_2248226175642056_columnDefaults"
    :columns="b_2248226175642056_columns"
    class="page--grid"
    ref="grid"
    @selectedRowsChanged="evh_2248226175642056_selectedRowsChanged($event, {})"
  >
    <DIV
      class="flex-grow-1 d-flex"
      slot="toolbar"
    >
      <DIV
        class="mx-2"
      >
        <ItpFormCheckbox
          name="actives_only"
          :label="'Hide inactive meters'|pgettext('MeterBrowser')"
          inline
          v-model="dsParams.actives_only"
        >
        </ItpFormCheckbox>
      </DIV>
      <ItpButton
        icon="fa-plus"
        :text="'New meter'|pgettext('MeterBrowser')"
        size="sm"
        variant="primary"
        class="mx-2"
        @click="evh_8320016629450276_click($event, {})"
      >
      </ItpButton>
      <PageHelp
        path="/meters/meters.html"
      >
      </PageHelp>
    </DIV>
  </VueSlickgrid>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "meters",
    path: "/meters",
    component: "MeterBrowser"
  }
)

@Component()
export default class MeterBrowser extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
    this.$$cache_dsParams = new ComponentValueCache(this, "dsParams", "MeterBrowser.dsParams");
  }

  getQueryParameters(...args: any[]) {
    if (this.dsParams.actives_only) {
      return { skip_inactive: 1 };
    }
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  selectedItems!: any;
  dsParams!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_2248226175642056_columnDefaults!: any;
  b_2248226175642056_columns!: any;
  dataMembers = ['selectedItems', 'dsParams', 'ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        selectedItems: [
        ]
        ,
        dsParams: {
          actives_only: true,
        }
        ,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
        b_2248226175642056_columnDefaults: {
          sortable: true,
          filterable: true,
          resizable: true,
        }
        ,
        b_2248226175642056_columns: [{
          field: "serial_number",
          name: this.$fn.pgettext("MeterBrowser", "Serial Number"),
          type: "command",
          formatter: {
            name: "routeLink",
            to: "meter",
            params: {
              id: "id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "main_meter_serial",
          name: this.$fn.pgettext("MeterBrowser", "Main Meter"),
          formatter: {
            name: "routeLink",
            to: "meter",
            navigateOnIconClick: true,
            params: {
              id: "main_meter_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "inactive",
          name: this.$fn.gettext("Inactive"),
          textAlign: "center",
          formatter: {
            name: "checkbox",
          }
          ,
        }
          ,
        {
          field: "meter_type",
          name: this.$fn.pgettext("MeterBrowser", "Meter type"),
          formatter: {
            name: "gettext",
            context: "MeterTypes",
            conversion: "Sentence",
            filterInput: true,
          }
          ,
        }
          ,
        {
          field: "tag_id",
          name: this.$fn.pgettext("MeterBrowser", "Tag ID"),
        }
          ,
        {
          field: "deveui",
          name: this.$fn.pgettext("MeterBrowser", "DevEUI"),
        }
          ,
        {
          field: "unit",
          name: this.$fn.pgettext("MeterBrowser", "Unit"),
        }
          ,
        {
          field: "multiplier",
          name: this.$fn.pgettext("MeterBrowser", "Mt."),
          type: "number",
        }
          ,
        {
          field: "site_name",
          name: this.$fn.pgettext("MeterBrowser", "Site"),
          formatter: {
            name: "routeLink",
            to: "property",
            navigateOnIconClick: true,
            params: {
              id: "site_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "description",
          name: this.$fn.pgettext("MeterBrowser", "Description"),
        }
          ,
        {
          field: "model_name",
          name: this.$fn.pgettext("MeterBrowser", "Model"),
        }
          ,
        {
          field: "valid_from",
          name: this.$fn.pgettext("MeterBrowser", "Valid From"),
          type: "date",
        }
          ,
        {
          field: "valid_to",
          name: this.$fn.pgettext("MeterBrowser", "Valid To"),
          type: "date",
        }
          ,
        {
          field: "rentals",
          name: this.$fn.pgettext("MeterBrowser", "Rentals"),
          toolTip: this.$fn.pgettext("MeterBrowser", "Current rentals"),
          formatter: {
            name: "chain",
            mode: "mapReduce",
            separator: ", ",
            formatterContext: "item",
            formatters: [{
              name: "getvalue",
              opts: {
                field: "name",
                source: "value",
              }
              ,
            }
              ,
            {
              name: "routeLink",
              to: "rental",
              navigateOnIconClick: true,
              params: {
                id: "id",
              }
              ,
            }
              ,
            ]
            ,
          }
          ,
        }
          ,
        {
          field: "tenants",
          name: this.$fn.pgettext("MeterBrowser", "Tenants"),
          toolTip: this.$fn.pgettext("MeterBrowser", "Current tenants"),
          formatter: {
            name: "chain",
            mode: "mapReduce",
            separator: ", ",
            formatterContext: "item",
            formatters: [{
              name: "getvalue",
              opts: {
                field: "name",
                source: "value",
              }
              ,
            }
              ,
            {
              name: "routeLink",
              to: "client",
              navigateOnIconClick: true,
              params: {
                id: "id",
              }
              ,
            }
              ,
            ]
            ,
          }
          ,
        }
          ,
        {
          field: "latest_reading_date",
          name: this.$fn.pgettext("MeterBrowser", "Lr. date"),
          toolTip: this.$fn.pgettext("MeterBrowser", "Latest reading date"),
          type: "date",
        }
          ,
        {
          field: "latest_reading_value",
          name: this.$fn.pgettext("MeterBrowser", "Lr."),
          toolTip: this.$fn.pgettext("MeterBrowser", "Latest reading"),
          type: "number",
        }
          ,
        {
          field: "latest_invoice_reading_date",
          name: this.$fn.pgettext("MeterBrowser", "L inv. date"),
          toolTip: this.$fn.pgettext("MeterBrowser", "Latest invoiced reading date"),
          type: "date",
        }
          ,
        {
          field: "latest_invoice_reading_value",
          name: this.$fn.pgettext("MeterBrowser", "L inv. r."),
          toolTip: this.$fn.pgettext("MeterBrowser", "Latest invoiced reading"),
          type: "number",
        }
          ,
        ]
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("MeterBrowser", "Meters"),
        icon: this.$config.meter.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_2880_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_2880, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_setData_2882_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2248226175642056_setData_2882, alias=undefined
    return {
      path: "selectedItems",
      value: this.$refs.grid.getSelectedItems(),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_2248226175642056_selectedRowsChanged(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope);
  }

  async act_8320016629450276_showModal_2884_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8320016629450276_showModal_2884, alias=undefined
    return {
      name: "CreateMeter",
    }
  }

  async evh_8320016629450276_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_click.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_2880: actions.CloseModalAction;
    evh_7315092382398562_close_2879: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_setData_2882: actions.SetDataAction;
    evh_2248226175642056_selectedRowsChanged_2881: actions.EventHandlerImpl;
    evh_2248226175642056_selectedRowsChanged: actions.EventHandlerGroup;
    act_8320016629450276_showModal_2884: actions.ShowModalAction;
    evh_8320016629450276_click_2883: actions.EventHandlerImpl;
    evh_8320016629450276_click: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_2880 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_2880_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_2879 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_2880,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_2879],
      }
    );
    const act_2248226175642056_setData_2882 = new actions.SetDataAction(
      {
        actionArgs: this.act_2248226175642056_setData_2882_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_selectedRowsChanged_2881 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_setData_2882,
        event: "selectedRowsChanged",
        displayName: "setData",
      }
    );
    const evh_2248226175642056_selectedRowsChanged = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_selectedRowsChanged_2881],
      }
    );
    const act_8320016629450276_showModal_2884 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8320016629450276_showModal_2884_getActionArgs,
        events: [],
      }
    );
    const evh_8320016629450276_click_2883 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_showModal_2884,
        event: "click",
        displayName: "showModal",
      }
    );
    const evh_8320016629450276_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_click_2883],
      }
    );
    return {
      act_7315092382398562_closeModal_2880,
      evh_7315092382398562_close_2879,
      evh_7315092382398562_close,
      act_2248226175642056_setData_2882,
      evh_2248226175642056_selectedRowsChanged_2881,
      evh_2248226175642056_selectedRowsChanged,
      act_8320016629450276_showModal_2884,
      evh_8320016629450276_click_2883,
      evh_8320016629450276_click,
    }
  }
}

Vue.component("MeterBrowser", MeterBrowser);

</script>