import { Application } from '@integro/core';
import * as _ from 'lodash';
import * as sg from '@integro/slickg';
import { FormatterEx, SlickGrid } from 'slickgrid';

export function initialize(app: Application) {
  sg.registerFormatter('statusDot', statusDot);

  sg.VueSlickgrid.setDefaults(grid => {
    return {
      rowClass(data: object) {
        if (_.get(data, 'inactive')) {
          return app.config.bgClass.inactive;
        }
        // if (_.get(data, 'locked')) {
        //   return app.config.bgClass.locked;
        // }
      }
    };
  });
}

function statusDot() {
  const colors: Dictionary<string> = _.get(
    window,
    '$app.config.statuses.colors'
  ) as any;

  const statusDotFormatter: FormatterEx<any> = (
    row,
    cell,
    value,
    columnDef,
    dataContext,
    grid: SlickGrid
  ) => {
    const field = _.get(columnDef, 'field') || '';
    const fieldValue = field && _.get(dataContext, field);

    if (!fieldValue) {
      return value;
    }

    let color = _.get(colors, fieldValue);
    if (!color) {
      return value;
    }
    return `<span class="mr-2 ml-1 text-${color}">⬤</span>${value}`;
  };

  _.set(statusDotFormatter, 'exportRawData', true);

  return statusDotFormatter;
}
