<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="CreateTemplate"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      name="template"
      schema="CreateTemplate"
      id="jrgztyjdyk"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <ItpFormGroup
        name="name"
        :label="'Name'|pgettext('Templates')"
      >
        <ItpFormInput
          name="name"
          size="md"
          v-model="item.name"
          required
          autofocus
          :placeholder="'Name of template'|pgettext('Templates')"
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        name="name"
        :label="'Object type'|pgettext('Templates')"
      >
        <ItpFormSelect
          name="name"
          size="md"
          v-model="item.object_type"
          :options="objectTypes"
          required
        >
        </ItpFormSelect>
      </ItpFormGroup>
      <ItpFormGroup
        name="template_type"
        :label="'Template type'|pgettext('Templates')"
        :description="'Emails are generated from MJML templates, PDF documents are generated from HTML templates.'|pgettext('Templates')"
      >
        <ItpFormSelect
          name="template_type"
          size="md"
          v-model="item.template_type"
          required
          :options="b_7527331142178322_options"
        >
        </ItpFormSelect>
      </ItpFormGroup>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      form="jrgztyjdyk"
      :text="'Save'|pgettext('Templates')"
      type="submit"
      variant="primary"
      default
      :spinning="actions.submit.isRunning"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|pgettext('Templates')"
      variant="default"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class CreateTemplate extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: "sm",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  item!: any;

  async $$load_item() {
    return this.$fn.schemaDefaults('CreateTemplate')
  }

  objectTypes!: any;

  async $$load_objectTypes() {
    return this.$fn.getEnumValuesFromSchema('ObjectTypes')
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_7527331142178322_options!: any;
  dataMembers = ['item', 'objectTypes', 'ux', 'b_7315092382398562_modalBindings', 'b_7527331142178322_options'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        item: null,
        objectTypes: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
        b_7527331142178322_options: [{
          value: "mjml",
          text: "MJML",
        }
          ,
        {
          value: "html",
          text: "HTML",
        }
          ,
        ]
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("Templates", "Create Template"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_4190_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_4190, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_closeModal_4196_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1040167445267876_closeModal_4196, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        value: $event.data.response.data,
      }
      ,
    }
  }

  async evh_1040167445267876_success_4195_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_1040167445267876_success_4195, alias=undefined
    return !!this.modalName
  }

  async act_1040167445267876_request_4194_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_1040167445267876_request_4194, alias=submit
    return {
      operation: "create_template",
      data: { ...this.item, content: '<div></div>' },
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_4190: actions.CloseModalAction;
    evh_7315092382398562_close_4189: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_4192: actions.CloseComponentAction;
    evh_2248226175642056_close_4191: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1040167445267876_closeModal_4196: actions.CloseModalAction;
    evh_1040167445267876_success_4195: actions.EventHandlerImpl;
    act_1040167445267876_request_4194: actions.RequestAction;
    evh_1040167445267876_submit_4193: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_1419464017721962_closeModal_4198: actions.CloseModalAction;
    evh_1419464017721962_clickPreventStop_4197: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_4190 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_4190_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_4189 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_4190,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_4189],
      }
    );
    const act_2248226175642056_closeComponent_4192 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_4191 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_4192,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_4191],
      }
    );
    const act_1040167445267876_closeModal_4196 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1040167445267876_closeModal_4196_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_4195 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeModal_4196,
        event: "success",
        when: this.evh_1040167445267876_success_4195_getWhen,
        displayName: "closeModal",
      }
    );
    const act_1040167445267876_request_4194 = new actions.RequestAction(
      {
        actionArgs: this.act_1040167445267876_request_4194_getActionArgs,
        displayName: "submit",
        events: [evh_1040167445267876_success_4195],
      }
    );
    const evh_1040167445267876_submit_4193 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_request_4194,
        event: "submit",
        displayName: "submit",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_4193],
      }
    );
    const act_1419464017721962_closeModal_4198 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_4197 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeModal_4198,
        event: "click.prevent.stop",
        displayName: "closeModal",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_4197],
      }
    );
    return {
      act_7315092382398562_closeModal_4190,
      evh_7315092382398562_close_4189,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_4192,
      evh_2248226175642056_close_4191,
      evh_2248226175642056_close,
      act_1040167445267876_closeModal_4196,
      evh_1040167445267876_success_4195,
      act_1040167445267876_request_4194,
      evh_1040167445267876_submit_4193,
      evh_1040167445267876_submit,
      act_1419464017721962_closeModal_4198,
      evh_1419464017721962_clickPreventStop_4197,
      evh_1419464017721962_clickPreventStop,
      submit: act_1040167445267876_request_4194,
    }
  }
}

Vue.component("CreateTemplate", CreateTemplate);

</script>