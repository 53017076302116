<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <UcSuggestRentalOption
    :option="option"
    v-if="option.object === 'rental'"
  >
  </UcSuggestRentalOption>
  <UcSuggestContractOption
    :option="option"
    v-if="option.object === 'contract'"
  >
  </UcSuggestContractOption>
  <UcSuggestClientOption
    :option="option"
    v-if="option.object === 'client'"
  >
  </UcSuggestClientOption>
  <UcSuggestPropertyOption
    :option="option"
    v-if="option.object === 'property' || option.object === 'property_unit'"
  >
  </UcSuggestPropertyOption>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class InvoiceReferenceSearchOption extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  option!: object;

  @Watch('option')
  onOption(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("option")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_6658: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_6657: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_6658 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_6657 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_6658,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_6657],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_6658,
      evh_7315092382398562_reload_6657,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("InvoiceReferenceSearchOption", InvoiceReferenceSearchOption);

</script>