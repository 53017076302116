"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialize = void 0;
const _ = require("lodash");
const sg = require("@integro/slickg");
function initialize(app) {
    sg.registerFormatter('statusDot', statusDot);
    sg.VueSlickgrid.setDefaults(grid => {
        return {
            rowClass(data) {
                if (_.get(data, 'inactive')) {
                    return app.config.bgClass.inactive;
                }
            }
        };
    });
}
exports.initialize = initialize;
function statusDot() {
    const colors = _.get(window, '$app.config.statuses.colors');
    const statusDotFormatter = (row, cell, value, columnDef, dataContext, grid) => {
        const field = _.get(columnDef, 'field') || '';
        const fieldValue = field && _.get(dataContext, field);
        if (!fieldValue) {
            return value;
        }
        let color = _.get(colors, fieldValue);
        if (!color) {
            return value;
        }
        return `<span class="mr-2 ml-1 text-${color}">⬤</span>${value}`;
    };
    _.set(statusDotFormatter, 'exportRawData', true);
    return statusDotFormatter;
}
