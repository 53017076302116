"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let FieldsetContractClientData = class FieldsetContractClientData extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['client', 'ux', 'b_1040167445267876_dataSource'];
    }
    beforeCreate() {
    }
    onContract(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("contract");
    }
    onLabel_align(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("labelAlign");
    }
    onNo_legend(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("noLegend");
    }
    $$load_client() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.contract.counterparty;
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            client: null,
            ux: null,
            b_1040167445267876_dataSource: {
                name: "suggest_client",
            },
        });
    }
    evh_7315092382398562_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
        });
    }
    act_1040167445267876_setData_6054_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "contract.counterparty",
                value: $event.data.response.data,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_1040167445267876_request_6052_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "read_client",
                parameters: {
                    id: $event.data.value.id,
                },
                headers: {
                    accept: "application/vnd.itp.contractparty+json",
                },
            };
        });
    }
    evh_1040167445267876_optionSelected(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_optionSelected.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_reloadComponentData_6050 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7315092382398562_reload_6049 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_6050,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_6049],
        });
        const act_1040167445267876_setData_6054 = new core_1.actions.SetDataAction({
            actionArgs: this.act_1040167445267876_setData_6054_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_6053 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_setData_6054,
            event: "success",
            displayName: "setData",
        });
        const act_1040167445267876_request_6052 = new core_1.actions.RequestAction({
            actionArgs: this.act_1040167445267876_request_6052_getActionArgs,
            events: [evh_1040167445267876_success_6053],
        });
        const evh_1040167445267876_optionSelected_6051 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_request_6052,
            event: "optionSelected",
            displayName: "request",
        });
        const evh_1040167445267876_optionSelected = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_optionSelected_6051],
        });
        return {
            act_7315092382398562_reloadComponentData_6050,
            evh_7315092382398562_reload_6049,
            evh_7315092382398562_reload,
            act_1040167445267876_setData_6054,
            evh_1040167445267876_success_6053,
            act_1040167445267876_request_6052,
            evh_1040167445267876_optionSelected_6051,
            evh_1040167445267876_optionSelected,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], FieldsetContractClientData.prototype, "contract", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('contract'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], FieldsetContractClientData.prototype, "onContract", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "right",
    }),
    __metadata("design:type", String)
], FieldsetContractClientData.prototype, "labelAlign", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('labelAlign'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], FieldsetContractClientData.prototype, "onLabel_align", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
    }),
    __metadata("design:type", Boolean)
], FieldsetContractClientData.prototype, "noLegend", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('noLegend'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], FieldsetContractClientData.prototype, "onNo_legend", null);
FieldsetContractClientData = __decorate([
    (0, vue_property_decorator_1.Component)()
], FieldsetContractClientData);
exports.default = FieldsetContractClientData;
vue_property_decorator_1.Vue.component("FieldsetContractClientData", FieldsetContractClientData);
