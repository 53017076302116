"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tasks = exports.StatData = exports.Settings = exports.ExpenseTypes = exports.Groups = void 0;
const Groups_yaml_component_1 = require("./Groups.yaml?component");
exports.Groups = Groups_yaml_component_1.default;
const ExpenseTypes_yaml_component_1 = require("./ExpenseTypes.yaml?component");
exports.ExpenseTypes = ExpenseTypes_yaml_component_1.default;
const Settings_yaml_component_1 = require("./Settings.yaml?component");
exports.Settings = Settings_yaml_component_1.default;
const StatData_yaml_component_1 = require("./StatData.yaml?component");
exports.StatData = StatData_yaml_component_1.default;
const Tasks_yaml_component_1 = require("./Tasks.yaml?component");
exports.Tasks = Tasks_yaml_component_1.default;
