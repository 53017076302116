"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UcAddressList = exports.UcAddressTypeBadge = exports.UcAddressCard = exports.FieldsetAddress = exports.Address = void 0;
const Address_yaml_component_1 = require("./Address.yaml?component");
exports.Address = Address_yaml_component_1.default;
const FieldsetAddress_yaml_component_1 = require("./FieldsetAddress.yaml?component");
exports.FieldsetAddress = FieldsetAddress_yaml_component_1.default;
const UcAddressCard_yaml_component_1 = require("./UcAddressCard.yaml?component");
exports.UcAddressCard = UcAddressCard_yaml_component_1.default;
const UcAddressTypeBadge_yaml_component_1 = require("./UcAddressTypeBadge.yaml?component");
exports.UcAddressTypeBadge = UcAddressTypeBadge_yaml_component_1.default;
const UcAddressList_yaml_component_1 = require("./UcAddressList.yaml?component");
exports.UcAddressList = UcAddressList_yaml_component_1.default;
