var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        card: "",
        "no-primary-button": "",
        closeButtonText: _vm._f("gettext")("Close"),
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "Invoice",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.item
        ? _c(
            "UcHeader",
            {
              attrs: {
                slot: "header",
                card: !_vm.modal,
                modal: _vm.modal,
                color: _vm.$config.invoice.color
              },
              on: {
                close: function($event) {
                  return _vm.evh_2248226175642056_close($event, {})
                }
              },
              slot: "header"
            },
            [
              _c("UcInvoiceHeader", {
                class: _vm.b_8564662037462133_class,
                attrs: { invoice: _vm.item, formContext: _vm.formContext },
                on: {
                  created: function($event) {
                    return _vm.evh_8564662037462133_created($event, {})
                  },
                  deleted: function($event) {
                    return _vm.evh_8564662037462133_deleted($event, {})
                  },
                  reload: function($event) {
                    return _vm.evh_8564662037462133_reload($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              !_vm.isNew
                ? _c(
                    "ItpPanel",
                    {
                      staticClass: "mb-4",
                      attrs: {
                        collapsible: "",
                        caption: _vm._f("pgettext")("Invoice data", "Invoice"),
                        carousel: ""
                      }
                    },
                    [
                      _c("UcInvoiceData", {
                        attrs: { invoice: _vm.item, reload: _vm.lastLoaded },
                        on: {
                          reload: function($event) {
                            return _vm.evh_5534025912102772_reload($event, {})
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.currency && _vm.item
                ? _c(
                    "DIV",
                    { staticClass: "overflow-hidden w-100" },
                    [
                      _c("UcInvoiceLines", {
                        staticClass: "h-100 w-100",
                        attrs: {
                          invoice: _vm.item,
                          currency: _vm.currency,
                          defaultTaxCode: _vm.item.meta.default_tax_code,
                          defaultDiscount: _vm.item.meta.default_discount,
                          reload: _vm.lastLoaded,
                          formContext: _vm.formContext
                        },
                        on: {
                          reload: function($event) {
                            return _vm.evh_1468474219929533_reload($event, {})
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.item
        ? _c(
            "UcFooter",
            {
              attrs: {
                slot: "footer",
                card: !_vm.modal,
                modal: _vm.modal,
                color: _vm.$config.invoice.color
              },
              slot: "footer"
            },
            [
              _vm.currency
                ? _c("UcInvoiceFooter", {
                    attrs: {
                      invoice: _vm.item,
                      currency: _vm.currency,
                      exchangedCurrency: _vm.exchangedCurrency
                    },
                    on: {
                      reload: function($event) {
                        return _vm.evh_1548630417156826_reload($event, {})
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }