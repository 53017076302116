<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpBox
    direction="row"
  >
    <div
      class="mr-3"
    >
      <Icon
        icon="ant-design:percentage-outlined"
        :tooltip="headerText"
      >
      </Icon>
    </div>
    <ItpBox
      direction="column"
    >
      <ItpText
        :text="headerText"
        strong
        tag="h5"
      >
      </ItpText>
      <DIV
        class="pb-2"
      >
        <strong
          class="mr-2"
        >
          {{ notification.data.service_data.name }}
        </strong>
        <small
          class="mr-2"
        >
          {{ 'current price'|gettext }}
        </small>
        <strong
          class="mr-2"
        >
          {{ notification.data.source_object.amount | number }} {{ notification.data.source_object.currency}}
        </strong>
        <small
          class="mr-2"
        >
          {{ 'new price'|gettext }}
        </small>
        <strong>
          {{ notification.data.new_price | number }} {{ notification.data.source_object.currency}}
        </strong>
        <ItpButton
          :text="'Add new price...'|gettext"
          variant="light"
          size="sm"
          class="ml-3"
          @click="evh_7751968922212722_click($event, {})"
        >
        </ItpButton>
      </DIV>
      <ItpBox
        direction="row"
        class="mb-2"
      >
        <UcContractMiniCard
          :item="notification.data.contract_data"
          no-icon
        >
        </UcContractMiniCard>
        <span
          class="mx-3"
        >
          &middot;
        </span>
        <UcClientMiniCard
          :item="notification.data.contract_data.counterparty"
          no-icon
        >
        </UcClientMiniCard>
      </ItpBox>
      <ItpText
        small
        muted
      >
        {{ notification.created_at | datetime }}  {{ notification.created_by | username }}

      </ItpText>
    </ItpBox>
  </ItpBox>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcInflationIndexedPriceChangeNotificationCard extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  notification!: object;

  @Watch('notification')
  onNotification(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("notification")
  }

  headerText!: any;
  ux!: any;
  dataMembers = ['headerText', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        headerText: this.$fn.gettext("Inflation indexed price change"),
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_7751968922212722_showModal_2206_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_7751968922212722_showModal_2206, alias=undefined
    return {
      name: "InflationIndexedPriceChange",
      props: {
        id: this.notification.id,
      }
      ,
    }
  }

  async evh_7751968922212722_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7751968922212722_click.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_2204: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_2203: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_7751968922212722_reloadComponentData_2208: actions.ReloadComponentDataAction;
    evh_7751968922212722_close_2207: actions.EventHandlerImpl;
    act_7751968922212722_showModal_2206: actions.ShowModalAction;
    evh_7751968922212722_click_2205: actions.EventHandlerImpl;
    evh_7751968922212722_click: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_2204 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_2203 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_2204,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_2203],
      }
    );
    const act_7751968922212722_reloadComponentData_2208 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7751968922212722_close_2207 = new actions.EventHandlerImpl(
      {
        action: act_7751968922212722_reloadComponentData_2208,
        event: "close",
        displayName: "reloadComponentData",
      }
    );
    const act_7751968922212722_showModal_2206 = new actions.ShowModalAction(
      {
        actionArgs: this.act_7751968922212722_showModal_2206_getActionArgs,
        events: [evh_7751968922212722_close_2207],
      }
    );
    const evh_7751968922212722_click_2205 = new actions.EventHandlerImpl(
      {
        action: act_7751968922212722_showModal_2206,
        event: "click",
        displayName: "showModal",
      }
    );
    const evh_7751968922212722_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_7751968922212722_click_2205],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_2204,
      evh_7315092382398562_reload_2203,
      evh_7315092382398562_reload,
      act_7751968922212722_reloadComponentData_2208,
      evh_7751968922212722_close_2207,
      act_7751968922212722_showModal_2206,
      evh_7751968922212722_click_2205,
      evh_7751968922212722_click,
    }
  }
}

Vue.component("UcInflationIndexedPriceChangeNotificationCard", UcInflationIndexedPriceChangeNotificationCard);

</script>