<template>
<ItpModal
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="WAttachmentModal"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      :id="formId"
      name="attachment"
      ref="mainForm"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <ItpFormGroup
        name="file"
        :label="'File'|pgettext('Attachment')"
        labelFor="file"
        required
        v-if="!attachment"
      >
        <ItpFormFile
          :autofocus="!attachment"
          v-model="file"
          name="file"
          required
          :placeholder="'Choose a file or drop it here'|gettext"
          :drop-placeholder="'Drop file here'|gettext"
          lang="hu"
          :validation="b_7789867021953041_validation"
        >
        </ItpFormFile>
      </ItpFormGroup>
      <ItpFormGroup
        name="description"
        :label="'Description'|gettext"
      >
        <ItpFormTextarea
          name="description"
          v-model="item.description"
          :autofocus="!!attachment"
        >
        </ItpFormTextarea>
      </ItpFormGroup>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :text="'Save'|pgettext('Button')"
      :disabled="!forms.attachment || !forms.attachment.submittable"
      type="submit"
      :form="formId"
      variant="primary"
      icon="fa-save"
      :spinning="actions.save.isRunning"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpModal>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class WAttachmentModal extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "modal",
  };

  beforeCreate() {
  }

  @Prop({
    type: Object,
  })
  attachment!: object;

  @Watch('attachment')
  onAttachment(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("attachment")
  }

  @Prop({
    type: String,
  })
  object_id!: string;

  @Watch('object_id')
  onObject_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("object_id")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  item!: any;

  async $$load_item() {
    return this.attachment || { object_id: this.object_id, description: null }
  }

  uploadTitle!: any;
  editTitle!: any;
  file!: any;
  ux!: any;
  b_7789867021953041_validation!: any;
  dataMembers = ['formId', 'item', 'uploadTitle', 'editTitle', 'file', 'ux', 'b_7789867021953041_validation'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        formId: null,
        item: null,
        uploadTitle: this.$fn.pgettext("Attachments", "Upload file"),
        editTitle: this.$fn.pgettext("Attachments", "Edit description"),
        file: null,
        ux: null,
        b_7789867021953041_validation: ["required"
          ,
        ]
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.attachment ? this.editTitle : this.uploadTitle,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_1904_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_1904, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_closeModal_1910_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1040167445267876_closeModal_1910, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
      }
      ,
    }
  }

  async act_1040167445267876_request_1908_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_1040167445267876_request_1908, alias=save
    return {
      operation: this.attachment ? 'update_attachment' : 'create_attachment',
      data: this.attachment ? this.item : { ...this.item, file: this.file },
      contentType: this.attachment ? 'application/json' : 'multipart/form-data',
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async act_1419464017721962_closeModal_1912_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1419464017721962_closeModal_1912, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
      }
      ,
    }
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_1904: actions.CloseModalAction;
    evh_7315092382398562_close_1903: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeModal_1906: actions.CloseModalAction;
    evh_2248226175642056_close_1905: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1040167445267876_closeModal_1910: actions.CloseModalAction;
    evh_1040167445267876_success_1909: actions.EventHandlerImpl;
    act_1040167445267876_request_1908: actions.RequestAction;
    evh_1040167445267876_submit_1907: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_1419464017721962_closeModal_1912: actions.CloseModalAction;
    evh_1419464017721962_clickPreventStop_1911: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_1904 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_1904_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_1903 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_1904,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_1903],
      }
    );
    const act_2248226175642056_closeModal_1906 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_1905 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeModal_1906,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_1905],
      }
    );
    const act_1040167445267876_closeModal_1910 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1040167445267876_closeModal_1910_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_1909 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeModal_1910,
        event: "success",
        displayName: "closeModal",
      }
    );
    const act_1040167445267876_request_1908 = new actions.RequestAction(
      {
        actionArgs: this.act_1040167445267876_request_1908_getActionArgs,
        displayName: "save",
        events: [evh_1040167445267876_success_1909],
      }
    );
    const evh_1040167445267876_submit_1907 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_request_1908,
        event: "submit",
        displayName: "save",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_1907],
      }
    );
    const act_1419464017721962_closeModal_1912 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1419464017721962_closeModal_1912_getActionArgs,
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_1911 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeModal_1912,
        event: "click.prevent.stop",
        displayName: "closeModal",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_1911],
      }
    );
    return {
      act_7315092382398562_closeModal_1904,
      evh_7315092382398562_close_1903,
      evh_7315092382398562_close,
      act_2248226175642056_closeModal_1906,
      evh_2248226175642056_close_1905,
      evh_2248226175642056_close,
      act_1040167445267876_closeModal_1910,
      evh_1040167445267876_success_1909,
      act_1040167445267876_request_1908,
      evh_1040167445267876_submit_1907,
      evh_1040167445267876_submit,
      act_1419464017721962_closeModal_1912,
      evh_1419464017721962_clickPreventStop_1911,
      evh_1419464017721962_clickPreventStop,
      save: act_1040167445267876_request_1908,
    }
  }
}

Vue.component("WAttachmentModal", WAttachmentModal);

</script>