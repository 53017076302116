<template>
<VueSlickgrid
  name="clientInvoices"
  :headerTitle="'Client invoices'|pgettext('ClientInvoices')"
  :items="$fn.slickDataSource('browse_invoice_lines', {parameters: getQueryParameters})"
  checkbox-row-selection
  footer-totals
  export-to-csv
  :exportToCsvFilename="'client-invoices'|pgettext('filename')"
  :columnDefaults="b_7315092382398562_columnDefaults"
  :columns="b_7315092382398562_columns"
  ref="grid"
  @selectedRowsChanged="evh_7315092382398562_selectedRowsChanged($event, {})"
>
</VueSlickgrid>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcClientInvoices extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  getQueryParameters(...args: any[]) {
    return { client_id: this.clientId }
  }

  @Prop({
    required: true,
    type: String,
  })
  clientId!: string;

  @Watch('clientId')
  onClient_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("clientId")
  }

  ux!: any;
  b_7315092382398562_columnDefaults!: any;
  b_7315092382398562_columns!: any;
  dataMembers = ['ux', 'b_7315092382398562_columnDefaults', 'b_7315092382398562_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
        b_7315092382398562_columnDefaults: {
          sortable: true,
          filterable: true,
          resizable: true,
        }
        ,
        b_7315092382398562_columns: [{
          field: "invoice_kind",
          name: this.$fn.pgettext("Invoice", "Invoice kind"),
        }
          ,
        {
          field: "invoice_type",
          name: this.$fn.pgettext("Invoice", "Invoice type"),
        }
          ,
        {
          field: "invoice_number",
          name: this.$fn.pgettext("Invoice", "Invoice number"),
          type: "command",
          formatter: {
            name: "routeLink",
            to: "invoice",
            params: {
              id: "invoice_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "invoice_date",
          name: this.$fn.pgettext("Invoice", "Invoice date"),
          type: "date",
        }
          ,
        {
          field: "fulfillment_date",
          name: this.$fn.pgettext("Invoice", "Fulfillment date"),
          type: "date",
        }
          ,
        {
          field: "description",
          name: this.$fn.pgettext("InvoiceLine", "Description"),
        }
          ,
        {
          field: "quantity",
          name: this.$fn.pgettext("InvoiceLine", "Quantity"),
          type: "number",
          formatter: {
            name: "localestring",
            locale: "hu-HU",
          }
          ,
        }
          ,
        {
          field: "unit",
          name: this.$fn.pgettext("InvoiceLine", "Unit"),
        }
          ,
        {
          field: "unit_price",
          name: this.$fn.pgettext("InvoiceLine", "Price"),
          type: "number",
          formatter: {
            name: "localestring",
            locale: "hu-HU",
          }
          ,
        }
          ,
        {
          field: "discount",
          name: this.$fn.pgettext("InvoiceLine", "Discount"),
          type: "number",
          formatter: {
            name: "localestring",
            locale: "hu-HU",
          }
          ,
        }
          ,
        {
          field: "net_amount",
          name: this.$fn.pgettext("InvoiceLine", "Net"),
          type: "number",
          formatter: {
            name: "localestring",
            locale: "hu-HU",
          }
          ,
        }
          ,
        {
          field: "vat_amount",
          name: this.$fn.pgettext("InvoiceLine", "VAT"),
          type: "number",
          formatter: {
            name: "localestring",
            locale: "hu-HU",
          }
          ,
        }
          ,
        {
          field: "amount",
          name: this.$fn.pgettext("InvoiceLine", "Amount"),
          type: "number",
          formatter: {
            name: "localestring",
            locale: "hu-HU",
          }
          ,
        }
          ,
        {
          field: "tax_code",
          name: this.$fn.pgettext("InvoiceLine", "Tax Code"),
        }
          ,
        {
          field: "currency",
          name: this.$fn.pgettext("Invoice", "Currency"),
        }
          ,
        {
          field: "billing_period_start",
          name: this.$fn.pgettext("Invoice", "Period start"),
          type: "date",
        }
          ,
        {
          field: "billing_period_end",
          name: this.$fn.pgettext("Invoice", "Period end"),
          type: "date",
        }
          ,
        ]
        ,
      },
    }
  }

  async act_7315092382398562_setData_4620_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_7315092382398562_setData_4620, alias=undefined
    return {
      path: "selectedItems",
      value: this.$refs.grid.getSelectedItems(),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_7315092382398562_selectedRowsChanged(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_selectedRowsChanged.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_setData_4620: actions.SetDataAction;
    evh_7315092382398562_selectedRowsChanged_4619: actions.EventHandlerImpl;
    evh_7315092382398562_selectedRowsChanged: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_setData_4620 = new actions.SetDataAction(
      {
        actionArgs: this.act_7315092382398562_setData_4620_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_selectedRowsChanged_4619 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_setData_4620,
        event: "selectedRowsChanged",
        displayName: "setData",
      }
    );
    const evh_7315092382398562_selectedRowsChanged = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_selectedRowsChanged_4619],
      }
    );
    return {
      act_7315092382398562_setData_4620,
      evh_7315092382398562_selectedRowsChanged_4619,
      evh_7315092382398562_selectedRowsChanged,
    }
  }
}

Vue.component("UcClientInvoices", UcClientInvoices);

</script>