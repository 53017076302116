"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcMeterInvoices = class UcMeterInvoices extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['items', 'ux', 'b_6804155476809633_editable', 'b_6804155476809633_columns'];
    }
    beforeCreate() {
    }
    onMeter(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("meter");
    }
    $$load_items() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_meter_invoices', { parameters: { meter_id: this.meter.id } });
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            items: null,
            ux: {
                initialLoadSpinner: true,
            },
            b_6804155476809633_editable: false,
            b_6804155476809633_columns: [{
                    name: "invoice_number",
                    type: "template",
                    header: this.$fn.pgettext("MeterInvoices", "Invoice"),
                    width: "130px",
                    value: "<DIV>\n\
  <ItpLink :to=\"{name: 'app.invoice', params: { id: row.invoice_id }}\" >\n\
    {{ row.invoice_number }}\n\
  </ItpLink>\n\
  <ItpText muted>{{ row.invoice_date|date }}</ItpText>\n\
  <ItpLink :to=\"{name: 'app.client', params: { id: row.client_id }}\" >\n\
    {{ row.client_name }}\n\
  </ItpLink>\n\
</DIV>\n\
",
                },
                {
                    name: "contract_number",
                    type: "template",
                    header: this.$fn.pgettext("MeterInvoices", "Contract / Rental"),
                    width: "130px",
                    value: "<DIV>\n\
  <ItpLink :to=\"{name: 'app.rental', params: { id: row.contract_id }}\" >\n\
    {{ row.contract_number }}\n\
  </ItpLink>\n\
  <ItpLink :to=\"{name: 'app.rental', params: { id: row.rental_id }}\" >\n\
    {{ row.rental_name }}\n\
  </ItpLink>\n\
</DIV>\n\
",
                },
                {
                    name: "from_date",
                    header: this.$fn.pgettext("MeterInvoices", "From"),
                    type: "template",
                    value: "<DIV>\n\
  {{ row.from_amount|number }} {{ row.from_unit }}<br />\n\
  <ItpText muted>{{ row.from_date|date }}</ItpText>\n\
</DIV>\n\
",
                    width: "150px",
                },
                {
                    name: "to_date",
                    header: this.$fn.pgettext("MeterInvoices", "To"),
                    type: "template",
                    value: "<DIV>\n\
  {{ row.to_amount|number }} {{ row.to_unit }}<br />\n\
  <ItpText muted>{{ row.to_date|date }}</ItpText>\n\
</DIV>\n\
",
                    width: "150px",
                },
                {
                    name: "real_consumption_amount",
                    header: this.$fn.pgettext("MeterInvoices", "Consumption"),
                    type: "template",
                    value: "<DIV>\n\
  {{ row.real_consumption_amount|number }} {{ row.real_consumption_unit }}\n\
</DIV>\n\
",
                    width: "110px",
                },
                {
                    name: "billing_multiplier",
                    header: this.$fn.pgettext("MeterInvoices", "Mp."),
                    tooltip: this.$fn.pgettext("MeterInvoices", "Multiplier"),
                    type: "number",
                    width: "70px",
                },
                {
                    name: "quantity",
                    header: this.$fn.pgettext("MeterInvoices", "Quantity"),
                    type: "template",
                    value: "<span>{{ row.quantity|number }} {{ row.unit }}</span>",
                    width: "100px",
                },
                {
                    name: "unit_price",
                    header: this.$fn.pgettext("MeterInvoices", "Price"),
                    type: "number",
                    width: "70px",
                },
                {
                    name: "net_amount",
                    header: this.$fn.pgettext("MeterInvoices", "Net"),
                    type: "number",
                    width: "80px",
                },
                {
                    name: "amount",
                    header: this.$fn.pgettext("MeterInvoices", "Amount"),
                    type: "number",
                    width: "80px",
                },
                {
                    name: "currency",
                    header: this.$fn.pgettext("MeterInvoices", "Currency"),
                    type: "number",
                    width: "80px",
                },
            ],
        });
    }
    getActions() {
        return {};
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], UcMeterInvoices.prototype, "meter", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('meter'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcMeterInvoices.prototype, "onMeter", null);
UcMeterInvoices = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcMeterInvoices);
exports.default = UcMeterInvoices;
vue_property_decorator_1.Vue.component("UcMeterInvoices", UcMeterInvoices);
