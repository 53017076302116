"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
(0, core_1.addRoute)({
    name: "settings.groups",
    path: "groups",
    component: "Groups"
});
let Groups = class Groups extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['items', 'groupTypes', 'ux', 'b_7315092382398562_modalBindings', 'b_8564662037462133_columns'];
    }
    beforeCreate() {
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_items() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_group').then(p => p.data);
        });
    }
    $$load_b_8564662037462133_columns() {
        return __awaiter(this, void 0, void 0, function* () {
            return [{
                    name: "inactive",
                    header: this.$fn.gettext("Inactive"),
                    width: "30px",
                    textAlign: "center",
                    formatter: {
                        name: "checkbox",
                    },
                    editor: {
                        name: "checkbox",
                    },
                },
                {
                    name: "group_type",
                    type: "select",
                    header: this.$fn.gettext("Group Type"),
                    width: "160px",
                    required: true,
                    options: this.groupTypes,
                },
                {
                    name: "name",
                    header: this.$fn.gettext("Name"),
                    width: "140px",
                    required: true,
                },
                {
                    name: "description",
                    header: this.$fn.gettext("Description"),
                    width: "320px",
                },
            ];
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            items: null,
            groupTypes: [{
                    id: "meter",
                    text: this.$fn.pgettext("GroupType", "Meter"),
                },
                {
                    id: "client",
                    text: this.$fn.pgettext("GroupType", "Client"),
                },
            ],
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "sm",
            },
            b_8564662037462133_columns: null,
        });
    }
    get document() {
        if (this.loaded) {
            return;
        }
        return null;
    }
    act_7315092382398562_closeModal_4282_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    act_8564662037462133_script_4284_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            $event.data.waitFor(this.$fn.crud('group').upsert($event.data.row));
        });
    }
    act_8564662037462133_script_4284_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_8564662037462133_script_4284_getActionArgs_value($event),
            };
        });
    }
    evh_8564662037462133_save(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8564662037462133_save.executeFromDOM(this, event, scope);
        });
    }
    act_8564662037462133_script_4286_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            $event.data.waitFor(this.$fn.crud('group').delete($event.data.row));
        });
    }
    act_8564662037462133_script_4286_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_8564662037462133_script_4286_getActionArgs_value($event),
            };
        });
    }
    evh_8564662037462133_delete(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8564662037462133_delete.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_4282 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_4282_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_4281 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_4282,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_4281],
        });
        const act_8564662037462133_script_4284 = new core_1.actions.ScriptAction({
            actionArgs: this.act_8564662037462133_script_4284_getActionArgs,
            events: [],
        });
        const evh_8564662037462133_save_4283 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_script_4284,
            event: "save",
            displayName: "script",
        });
        const evh_8564662037462133_save = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8564662037462133_save_4283],
        });
        const act_8564662037462133_script_4286 = new core_1.actions.ScriptAction({
            actionArgs: this.act_8564662037462133_script_4286_getActionArgs,
            events: [],
        });
        const evh_8564662037462133_delete_4285 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_script_4286,
            event: "delete",
            displayName: "script",
        });
        const evh_8564662037462133_delete = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8564662037462133_delete_4285],
        });
        return {
            act_7315092382398562_closeModal_4282,
            evh_7315092382398562_close_4281,
            evh_7315092382398562_close,
            act_8564662037462133_script_4284,
            evh_8564662037462133_save_4283,
            evh_8564662037462133_save,
            act_8564662037462133_script_4286,
            evh_8564662037462133_delete_4285,
            evh_8564662037462133_delete,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], Groups.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Groups.prototype, "onSize", null);
Groups = __decorate([
    (0, vue_property_decorator_1.Component)()
], Groups);
exports.default = Groups;
vue_property_decorator_1.Vue.component("Groups", Groups);
