var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "InvoiceBrowser",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _c("UcHeader", {
        attrs: {
          slot: "header",
          card: !_vm.modal,
          modal: _vm.modal,
          color: _vm.$config.invoice.color,
          title: _vm.documentTitle
        },
        on: {
          close: function($event) {
            return _vm.evh_2248226175642056_close($event, {})
          }
        },
        slot: "header"
      }),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            {
              attrs: { card: !_vm.modal, modal: _vm.modal, "full-height": "" }
            },
            [
              _c(
                "ItpBox",
                {
                  staticClass: "h-100 w-100 overflow-hidden",
                  attrs: { direction: "column" }
                },
                [
                  _vm.loaded
                    ? _c(
                        "ItpForm",
                        {
                          attrs: { name: "invoiceBrowser", id: _vm.formId },
                          on: {
                            submit: function($event) {
                              return _vm.evh_5534025912102772_submit($event, {})
                            }
                          }
                        },
                        [
                          _c(
                            "DIV",
                            { staticClass: "row align-items-center mb-3" },
                            [
                              _c("ItpFormSelect2", {
                                staticClass: "col-4",
                                attrs: {
                                  name: "company",
                                  dataSource: _vm.b_5731695935601903_dataSource,
                                  valueField: "id",
                                  textField: "name",
                                  placeholder: _vm._f("pgettext")(
                                    "Company",
                                    "InvoiceBrowser"
                                  )
                                },
                                model: {
                                  value: _vm.filters.company_id,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filters, "company_id", $$v)
                                  },
                                  expression: "filters.company_id"
                                }
                              }),
                              _vm._v(" "),
                              _c("ItpFormSelect2", {
                                staticClass: "col-3",
                                attrs: {
                                  name: "kind",
                                  options: _vm.invoiceKinds,
                                  valueField: "id",
                                  textField: "text",
                                  clearable: "",
                                  placeholder: _vm._f("pgettext")(
                                    "Invoice kind",
                                    "InvoiceBrowser"
                                  )
                                },
                                model: {
                                  value: _vm.filters.kind,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filters, "kind", $$v)
                                  },
                                  expression: "filters.kind"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "DIV",
                            { staticClass: "row align-items-center mb-3" },
                            [
                              _c("ItpFormSelect2", {
                                staticClass: "col-4",
                                attrs: {
                                  name: "client",
                                  dataSource: _vm.b_3054425420812800_dataSource,
                                  valueField: "id",
                                  textField: "name",
                                  placeholder: _vm._f("pgettext")(
                                    "Select client",
                                    "InvoiceBrowser"
                                  ),
                                  clearable: "",
                                  optionTemplate:
                                    "<UcSuggestClientOption :option=option></UcSuggestClientOption>",
                                  "use-option-template-for-selected-option": ""
                                },
                                model: {
                                  value: _vm.filters.client_id,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filters, "client_id", $$v)
                                  },
                                  expression: "filters.client_id"
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "DIV",
                                { staticClass: "col-3 form-row" },
                                [
                                  _c("ItpFormDatetime", {
                                    staticClass: "col",
                                    attrs: {
                                      name: "date_from",
                                      placeholder: _vm._f("pgettext")(
                                        "Date From",
                                        "InvoiceBrowser"
                                      ),
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.filters.date_from,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters, "date_from", $$v)
                                      },
                                      expression: "filters.date_from"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("ItpFormDatetime", {
                                    staticClass: "col",
                                    attrs: {
                                      name: "date_to",
                                      placeholder: _vm._f("pgettext")(
                                        "Date To",
                                        "InvoiceBrowser"
                                      ),
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.filters.date_to,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters, "date_to", $$v)
                                      },
                                      expression: "filters.date_to"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "DIV",
                                {
                                  staticClass: "col form-row align-items-center"
                                },
                                [
                                  _c("ItpFormCheckbox", {
                                    attrs: {
                                      name: "unpaid",
                                      label: _vm._f("pgettext")(
                                        "Unpaid",
                                        "InvoiceBrowser"
                                      ),
                                      inline: ""
                                    },
                                    model: {
                                      value: _vm.filters.unpaid,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters, "unpaid", $$v)
                                      },
                                      expression: "filters.unpaid"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("ItpButton", {
                                    attrs: {
                                      type: "submit",
                                      icon: "fa-search",
                                      text: _vm._f("gettext")("Search"),
                                      size: "md",
                                      variant: "light",
                                      spinning: _vm.actions.search.isRunning,
                                      disabled:
                                        !_vm.forms.invoiceBrowser ||
                                        !_vm.forms.invoiceBrowser.flags.valid
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "DIV",
                    { staticClass: "h-100" },
                    [
                      _c("VueSlickgrid", {
                        staticClass: "h-100",
                        attrs: {
                          name: "invoiceBrowserInvoices",
                          items: _vm.invoices,
                          "checkbox-row-selection": "",
                          "no-header": "",
                          editable: _vm.b_2070266589801014_editable,
                          columnDefaults: _vm.b_2070266589801014_columnDefaults,
                          columns: _vm.b_2070266589801014_columns
                        },
                        on: {
                          selectedRowsChanged: function($event) {
                            return _vm.evh_2070266589801014_selectedRowsChanged(
                              $event,
                              {}
                            )
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "UcFooter",
        {
          attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
          slot: "footer"
        },
        [
          _c("ItpButton", {
            staticClass: "ml-auto",
            attrs: {
              icon: "fa-check-square",
              text: _vm._f("gettext")("Add Selection"),
              size: "md",
              variant: "primary",
              disabled: !_vm.selectedItems || !_vm.selectedItems.length
            },
            on: {
              click: function($event) {
                return _vm.evh_1548630417156826_click($event, {})
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }