<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="PaymentsView"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <ItpBox
    direction="column"
    class="h-100 w-100 overflow-hidden"
    v-if="loaded"
  >
    <DIV
      class="h-100 w-100 overflow-hidden position-relative"
    >
      <VueSlickgrid
        name="payments"
        :headerTitle="'Payments'|pgettext('PaymentsView')"
        :items="getItems"
        checkbox-row-selection
        :headerShowColumnSelectorButton="b_6466705138206980_headerShowColumnSelectorButton"
        :headerShowReloadButton="b_6466705138206980_headerShowReloadButton"
        footer-totals
        export-to-csv
        :exportToCsvFilename="'payments'|pgettext('ExportFilename')"
        :columnDefaults="b_6466705138206980_columnDefaults"
        :columns="b_6466705138206980_columns"
        class="page--grid"
        ref="grid"
        @selectedRowsChanged="evh_6466705138206980_selectedRowsChanged($event, {})"
        @command="evh_6466705138206980_command($event, {})"
      >
        <DIV
          class="flex-grow-1 d-flex"
          slot="toolbar"
        >
          <PaymentsViewFilters
            class="w-auto mx-3"
            @submit="evh_2285343489561581_submit($event, {})"
          >
          </PaymentsViewFilters>
        </DIV>
      </VueSlickgrid>
    </DIV>
  </ItpBox>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "payments",
    path: "/payments",
    component: "PaymentsView"
  }
)

@Component()
export default class PaymentsView extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  getItems(...args: any[]) {
    const parameters = this.params;
    return parameters && parameters.account && parameters.date_from
      ? this.$fn.fetch('list_payment', { parameters })
      : []
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  selectedItems!: any;
  params!: any;
  totals!: any;
  paymentMethods!: any;

  async $$load_paymentMethods() {
    return this.$fn.fetch('list_payment_method')
      .then(data => _.reduce(data, (m, v, k) => _.set(m, v.id, this.$fn.pgettext('PaymentMethod', v.name))))
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_6466705138206980_headerShowColumnSelectorButton!: any;
  b_6466705138206980_headerShowReloadButton!: any;
  b_6466705138206980_columnDefaults!: any;
  b_6466705138206980_columns!: any;

  async $$load_b_6466705138206980_columns() {
    return [{
      field: "payment_method",
      name: this.$fn.pgettext("PaymentBrowserView", "Method"),
      formatter: {
        name: "mapvalue",
        mapping: this.paymentMethods,
      }
      ,
    }
      ,
    {
      field: "payment_number",
      name: this.$fn.pgettext("PaymentBrowserView", "Number"),
      type: "command",
      formatter: [{
        name: "chain",
        formatters: [{
          name: "link",
          href: "#",
        }
          ,
        {
          name: "cellclass",
          value: (row) => ({
            'font-weight-bold': true,
            'bg-yellow-200': !row.locked
          }),
        }
          ,
        ]
        ,
      }
        ,
      ]
      ,
    }
      ,
    {
      field: "payment_date",
      name: this.$fn.pgettext("PaymentBrowserView", "Date"),
      type: "date",
    }
      ,
    {
      field: "payment_category",
      name: this.$fn.pgettext("PaymentBrowserView", "Title"),
    }
      ,
    {
      field: "description",
      name: this.$fn.pgettext("PaymentBrowserView", "Description"),
    }
      ,
    {
      field: "opening_balance",
      name: this.$fn.pgettext("PaymentBrowserView", "Opening balance"),
      type: "number",
      formatter: {
        name: "localestring",
        locale: "hu-HU",
      }
      ,
      noFooterTotals: true,
    }
      ,
    {
      field: "amount_in",
      name: this.$fn.pgettext("PaymentBrowserView", "In"),
      type: "number",
      formatter: {
        name: "localestring",
        locale: "hu-HU",
      }
      ,
    }
      ,
    {
      field: "amount_out",
      name: this.$fn.pgettext("PaymentBrowserView", "Out"),
      type: "number",
      formatter: {
        name: "localestring",
        locale: "hu-HU",
      }
      ,
    }
      ,
    {
      field: "closing_balance",
      name: this.$fn.pgettext("PaymentBrowserView", "Closing balance"),
      type: "number",
      formatter: {
        name: "localestring",
        locale: "hu-HU",
      }
      ,
      noFooterTotals: true,
    }
      ,
    {
      field: "currency",
      name: this.$fn.pgettext("PaymentBrowserView", "Currency"),
    }
      ,
    {
      field: "exchange_rate",
      name: this.$fn.pgettext("PaymentBrowserView", "Exchange Rate"),
      type: "number",
      formatter: {
        name: "localestring",
        locale: "hu-HU",
      }
      ,
      noFooterTotals: true,
    }
      ,
    {
      field: "reference_number",
      name: this.$fn.pgettext("PaymentBrowserView", "Ref Nr."),
    }
      ,
    {
      field: "file_number",
      name: this.$fn.pgettext("PaymentBrowserView", "File Nr."),
    }
      ,
    {
      field: "client_name",
      name: this.$fn.pgettext("PaymentBrowserView", "Client"),
      formatter: {
        name: "routeLink",
        to: "client",
        navigateOnIconClick: true,
        params: {
          id: "client_id",
        }
        ,
      }
      ,
    }
      ,
    {
      field: "invoices",
      name: this.$fn.pgettext("PaymentBrowserView", "Invoices"),
      formatter: [{
        name: "chain",
        separator: ", ",
        formatterContext: "value",
        formatters: [{
          name: "getvalue",
          opts: {
            field: "invoice_number",
            source: "value",
          }
          ,
        }
          ,
        {
          name: "routeLink",
          to: "invoice",
          navigateOnIconClick: true,
          params: {
            id: "id",
          }
          ,
        }
          ,
        ]
        ,
      }
        ,
      ]
      ,
    }
      ,
    ]
  }

  dataMembers = ['selectedItems', 'params', 'totals', 'paymentMethods', 'ux', 'b_7315092382398562_modalBindings', 'b_6466705138206980_headerShowColumnSelectorButton', 'b_6466705138206980_headerShowReloadButton', 'b_6466705138206980_columnDefaults', 'b_6466705138206980_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        selectedItems: [
        ]
        ,
        params: {
        }
        ,
        totals: [{
          id: 0,
          amount_in: 0,
          amount_out: 0,
        }
          ,
        ]
        ,
        paymentMethods: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
        b_6466705138206980_headerShowColumnSelectorButton: false,
        b_6466705138206980_headerShowReloadButton: false,
        b_6466705138206980_columnDefaults: {
          sortable: false,
          filterable: true,
          resizable: true,
        }
        ,
        b_6466705138206980_columns: null,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("PaymentsView", "Payments"),
        icon: this.$config.payment.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_5688_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_5688, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async act_6466705138206980_setData_5690_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_6466705138206980_setData_5690, alias=undefined
    return {
      path: "selectedItems",
      value: this.$refs.grid.getSelectedItems(),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_6466705138206980_selectedRowsChanged(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6466705138206980_selectedRowsChanged.executeFromDOM(this, event, scope);
  }

  async act_6466705138206980_showModal_5692_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_6466705138206980_showModal_5692, alias=undefined
    return {
      name: "Payment",
      props: {
        modal: true,
        oid: $event.data.data.id,
      }
      ,
    }
  }

  async evh_6466705138206980_command_5691_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_6466705138206980_command_5691, alias=undefined
    return $event.data.column.id === 'payment_number'
  }

  async evh_6466705138206980_command(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6466705138206980_command.executeFromDOM(this, event, scope);
  }

  async act_2285343489561581_setData_5694_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2285343489561581_setData_5694, alias=undefined
    return {
      path: "params",
      value: $event.data,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_2285343489561581_script_5696_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_2285343489561581_script_5696, alias=undefined
    this.$refs.grid.reload()
  }

  async act_2285343489561581_script_5696_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_2285343489561581_script_5696, alias=undefined
    return {
      value: () => this.act_2285343489561581_script_5696_getActionArgs_value($event),
    }
  }

  async evh_2285343489561581_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2285343489561581_submit.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_5688: actions.CloseModalAction;
    evh_7315092382398562_close_5687: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_6466705138206980_setData_5690: actions.SetDataAction;
    evh_6466705138206980_selectedRowsChanged_5689: actions.EventHandlerImpl;
    evh_6466705138206980_selectedRowsChanged: actions.EventHandlerGroup;
    act_6466705138206980_showModal_5692: actions.ShowModalAction;
    evh_6466705138206980_command_5691: actions.EventHandlerImpl;
    evh_6466705138206980_command: actions.EventHandlerGroup;
    act_2285343489561581_setData_5694: actions.SetDataAction;
    evh_2285343489561581_submit_5693: actions.EventHandlerImpl;
    act_2285343489561581_script_5696: actions.ScriptAction;
    evh_2285343489561581_submit_5695: actions.EventHandlerImpl;
    evh_2285343489561581_submit: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_5688 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_5688_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_5687 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_5688,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_5687],
      }
    );
    const act_6466705138206980_setData_5690 = new actions.SetDataAction(
      {
        actionArgs: this.act_6466705138206980_setData_5690_getActionArgs,
        events: [],
      }
    );
    const evh_6466705138206980_selectedRowsChanged_5689 = new actions.EventHandlerImpl(
      {
        action: act_6466705138206980_setData_5690,
        event: "selectedRowsChanged",
        displayName: "setData",
      }
    );
    const evh_6466705138206980_selectedRowsChanged = new actions.EventHandlerGroup(
      {
        handlers: [evh_6466705138206980_selectedRowsChanged_5689],
      }
    );
    const act_6466705138206980_showModal_5692 = new actions.ShowModalAction(
      {
        actionArgs: this.act_6466705138206980_showModal_5692_getActionArgs,
        events: [],
      }
    );
    const evh_6466705138206980_command_5691 = new actions.EventHandlerImpl(
      {
        action: act_6466705138206980_showModal_5692,
        event: "command",
        when: this.evh_6466705138206980_command_5691_getWhen,
        displayName: "showModal",
      }
    );
    const evh_6466705138206980_command = new actions.EventHandlerGroup(
      {
        handlers: [evh_6466705138206980_command_5691],
      }
    );
    const act_2285343489561581_setData_5694 = new actions.SetDataAction(
      {
        actionArgs: this.act_2285343489561581_setData_5694_getActionArgs,
        events: [],
      }
    );
    const evh_2285343489561581_submit_5693 = new actions.EventHandlerImpl(
      {
        action: act_2285343489561581_setData_5694,
        event: "submit",
        displayName: "setData",
      }
    );
    const act_2285343489561581_script_5696 = new actions.ScriptAction(
      {
        actionArgs: this.act_2285343489561581_script_5696_getActionArgs,
        events: [],
      }
    );
    const evh_2285343489561581_submit_5695 = new actions.EventHandlerImpl(
      {
        action: act_2285343489561581_script_5696,
        event: "submit",
        displayName: "script",
      }
    );
    const evh_2285343489561581_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_2285343489561581_submit_5693, evh_2285343489561581_submit_5695],
      }
    );
    return {
      act_7315092382398562_closeModal_5688,
      evh_7315092382398562_close_5687,
      evh_7315092382398562_close,
      act_6466705138206980_setData_5690,
      evh_6466705138206980_selectedRowsChanged_5689,
      evh_6466705138206980_selectedRowsChanged,
      act_6466705138206980_showModal_5692,
      evh_6466705138206980_command_5691,
      evh_6466705138206980_command,
      act_2285343489561581_setData_5694,
      evh_2285343489561581_submit_5693,
      act_2285343489561581_script_5696,
      evh_2285343489561581_submit_5695,
      evh_2285343489561581_submit,
    }
  }
}

Vue.component("PaymentsView", PaymentsView);

</script>