var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _vm.loaded && _vm.item
        ? _c(
            "ItpForm",
            {
              attrs: {
                name: "InvoiceGeneration",
                schema: "CreateInvoiceGenerationTask",
                readonly: _vm.item.locked,
                id: _vm.formId
              },
              on: {
                submit: function($event) {
                  return _vm.evh_2248226175642056_submit($event, {})
                }
              }
            },
            [
              _c(
                "ItpPanel",
                {
                  attrs: {
                    collapsible: "",
                    stateKey: "InvoiceGenerator.panels.filters"
                  }
                },
                [
                  _c("ItpText", {
                    attrs: {
                      slot: "caption",
                      strong: "",
                      muted: "",
                      block: "",
                      text: _vm._f("pgettext")("Filters", "InvoiceGenerator")
                    },
                    slot: "caption"
                  }),
                  _vm._v(" "),
                  _c(
                    "ItpBox",
                    {
                      staticClass: "mt-3 p-2 w-100",
                      attrs: { wrap: "", direction: "column" }
                    },
                    [
                      _c("ItpText", {
                        staticClass: "mb-2",
                        attrs: {
                          strong: "",
                          block: "",
                          text: _vm._f("pgettext")(
                            "Product Invoice Groups",
                            "InvoiceGenerator"
                          )
                        }
                      }),
                      _vm._v(" "),
                      _vm._l(_vm.productInvoiceGroups, function(item) {
                        return _c("ItpFormCheckbox", {
                          key: item.id,
                          attrs: { name: item.id, label: item.name },
                          model: {
                            value: _vm.invoiceGroupFilters[item.id],
                            callback: function($$v) {
                              _vm.$set(_vm.invoiceGroupFilters, item.id, $$v)
                            },
                            expression: "invoiceGroupFilters[item.id]"
                          }
                        })
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c(
                    "ItpBox",
                    {
                      staticClass: "mt-3 p-2 w-100",
                      attrs: { wrap: "", direction: "column" }
                    },
                    [
                      _c("ItpText", {
                        staticClass: "mb-2",
                        attrs: {
                          strong: "",
                          block: "",
                          text: _vm._f("pgettext")(
                            "Worksheets",
                            "InvoiceGenerator"
                          )
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpFormCheckbox", {
                        attrs: {
                          name: "include_worksheet",
                          label: _vm._f("pgettext")(
                            "Include works from worksheets",
                            "InvoiceGenerator"
                          )
                        },
                        model: {
                          value: _vm.includeWorksheets,
                          callback: function($$v) {
                            _vm.includeWorksheets = $$v
                          },
                          expression: "includeWorksheets"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c(
                    "ItpBox",
                    {
                      staticClass: "mt-3 p-2 w-100",
                      attrs: { wrap: "", direction: "column" }
                    },
                    [
                      _c("ItpText", {
                        staticClass: "mb-2",
                        attrs: {
                          strong: "",
                          block: "",
                          text: _vm._f("pgettext")(
                            "Other Filters",
                            "InvoiceGenerator"
                          )
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpSelect2", {
                        staticClass: "my-2",
                        attrs: {
                          name: "selectedFilterType",
                          options: _vm.filterTypes,
                          valueField: "target",
                          textField: "text",
                          clearable: _vm.b_1045524669200057_clearable,
                          "bind-object": ""
                        },
                        model: {
                          value: _vm.selectedFilterType,
                          callback: function($$v) {
                            _vm.selectedFilterType = $$v
                          },
                          expression: "selectedFilterType"
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpFormSelect2", {
                        staticClass: "mb-2",
                        attrs: {
                          name: "selectedFilterValue",
                          "bind-object": "",
                          dataSource:
                            _vm.selectedFilterType &&
                            _vm.selectedFilterType.dataSource,
                          optionTemplate:
                            _vm.selectedFilterType &&
                            _vm.selectedFilterType.optionTemplate,
                          useOptionTemplateForSelectedOption:
                            _vm.selectedFilterType &&
                            _vm.selectedFilterType
                              .useOptionTemplateForSelectedOption,
                          options:
                            _vm.selectedFilterType &&
                            _vm.selectedFilterType.options,
                          valueField:
                            (_vm.selectedFilterType &&
                              _vm.selectedFilterType.valueField) ||
                            "id",
                          textField:
                            (_vm.selectedFilterType &&
                              _vm.selectedFilterType.textField) ||
                            "name",
                          textFieldSeparator: "-",
                          disabled: !_vm.selectedFilterType
                        },
                        model: {
                          value: _vm.selectedFilterValue,
                          callback: function($$v) {
                            _vm.selectedFilterValue = $$v
                          },
                          expression: "selectedFilterValue"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "ItpRow",
                        { staticClass: "mb-2", attrs: { form: "" } },
                        [
                          _c(
                            "ItpCol",
                            [
                              _c("ItpFormSelect", {
                                attrs: {
                                  name: "selectedOperation",
                                  options: _vm.operations,
                                  valueField: "value",
                                  textField: "text"
                                },
                                model: {
                                  value: _vm.selectedOperation,
                                  callback: function($$v) {
                                    _vm.selectedOperation = $$v
                                  },
                                  expression: "selectedOperation"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "ItpCol",
                            [
                              _c(
                                "ItpButton",
                                {
                                  staticClass: "ml-auto",
                                  attrs: {
                                    title: _vm._f("pgettext")(
                                      "Add Filter",
                                      "InvoiceGenerator"
                                    ),
                                    disabled: !_vm.canAddFilter
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.evh_5891271350223090_click(
                                        $event,
                                        {}
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("Icon", {
                                    attrs: { icon: "mdi:add-bold" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "ItpBox",
                        {
                          staticClass: "mt-3 w-100",
                          attrs: { wrap: "", direction: "column" }
                        },
                        [
                          !_vm.filters.length
                            ? _c(
                                "DIV",
                                { staticClass: "text-center" },
                                [
                                  _c("ItpText", {
                                    attrs: {
                                      text: _vm._f("pgettext")(
                                        "No filters",
                                        "InvoiceGenerator"
                                      )
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(_vm.filters, function(filter) {
                            return _vm.filters.length
                              ? _c(
                                  "ItpBox",
                                  {
                                    key: filter.id + filter.op,
                                    staticClass: "border rounded",
                                    attrs: {
                                      spacing: "p-2",
                                      alignItems: "baseline"
                                    }
                                  },
                                  [
                                    _c("ItpIcon", {
                                      staticClass: "px-2",
                                      attrs: {
                                        fa:
                                          filter.op === "include"
                                            ? "plus-square"
                                            : "minus-square"
                                      }
                                    }),
                                    _vm._v(" "),
                                    !filter.value.object ||
                                    !_vm.filterOptionTemplates[
                                      filter.value.object
                                    ]
                                      ? _c("DIV", [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(filter.value.name) +
                                              "\n            "
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    filter.value.object &&
                                    _vm.filterOptionTemplates[
                                      filter.value.object
                                    ]
                                      ? _c(
                                          _vm.filterOptionTemplates[
                                            filter.value.object
                                          ],
                                          {
                                            tag: "component",
                                            attrs: { option: filter.value }
                                          }
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("b-button-close", {
                                      staticClass: "px-2",
                                      attrs: {
                                        title: _vm._f("pgettext")(
                                          "Remove",
                                          "InvoiceGenerator"
                                        )
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.evh_1641744087697442_click(
                                            $event,
                                            { filter: filter }
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("HR"),
              _vm._v(" "),
              _c(
                "ItpPanel",
                {
                  attrs: {
                    collapsible: "",
                    stateKey: "InvoiceGenerator.panels.parameters"
                  }
                },
                [
                  _c("ItpText", {
                    attrs: {
                      slot: "caption",
                      strong: "",
                      muted: "",
                      block: "",
                      text: _vm._f("pgettext")("Parameters", "InvoiceGenerator")
                    },
                    slot: "caption"
                  }),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "billing_period_start",
                        label: _vm._f("pgettext")(
                          "Billing Period Start",
                          "InvoiceGenerator"
                        ),
                        labelColsMd: "6",
                        contentColsMd: "6",
                        labelAlign: _vm.labelAlign,
                        required: ""
                      }
                    },
                    [
                      _c("ItpFormDatetime", {
                        attrs: {
                          name: "billing_period_start",
                          placeholder: _vm._f("pgettext")(
                            "Start of Period",
                            "InvoiceGenerator"
                          ),
                          required: ""
                        },
                        model: {
                          value: _vm.item.billing_period_start,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "billing_period_start", $$v)
                          },
                          expression: "item.billing_period_start"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "billing_period_end",
                        label: _vm._f("pgettext")(
                          "Billing Period End",
                          "InvoiceGenerator"
                        ),
                        labelColsMd: "6",
                        contentColsMd: "6",
                        labelAlign: _vm.labelAlign,
                        required: ""
                      }
                    },
                    [
                      _c("ItpFormDatetime", {
                        attrs: {
                          name: "billing_period_end",
                          placeholder: _vm._f("pgettext")(
                            "End of Period",
                            "InvoiceGenerator"
                          ),
                          required: ""
                        },
                        model: {
                          value: _vm.item.billing_period_end,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "billing_period_end", $$v)
                          },
                          expression: "item.billing_period_end"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "invoice_date",
                        label: _vm._f("pgettext")(
                          "Invoice Date",
                          "InvoiceGenerator"
                        ),
                        labelColsMd: "6",
                        contentColsMd: "6",
                        labelAlign: _vm.labelAlign,
                        required: ""
                      }
                    },
                    [
                      _c("ItpFormDatetime", {
                        attrs: { name: "invoice_date", required: "" },
                        model: {
                          value: _vm.item.invoice_date,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "invoice_date", $$v)
                          },
                          expression: "item.invoice_date"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "fulfillment_date",
                        label: _vm._f("pgettext")(
                          "Fulfillment Date",
                          "InvoiceGenerator"
                        ),
                        labelColsMd: "6",
                        contentColsMd: "6",
                        labelAlign: _vm.labelAlign,
                        required: ""
                      }
                    },
                    [
                      _c("ItpFormDatetime", {
                        attrs: { name: "fulfillment_date", required: "" },
                        model: {
                          value: _vm.item.fulfillment_date,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "fulfillment_date", $$v)
                          },
                          expression: "item.fulfillment_date"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "due_date",
                        label: _vm._f("pgettext")(
                          "Due Date",
                          "InvoiceGenerator"
                        ),
                        labelColsMd: "6",
                        contentColsMd: "6",
                        labelAlign: _vm.labelAlign,
                        required: ""
                      }
                    },
                    [
                      _c("ItpFormDatetime", {
                        attrs: {
                          name: "due_date",
                          required: "",
                          placeholder: _vm._f("pgettext")(
                            "Due Date",
                            "InvoiceGenerator"
                          )
                        },
                        model: {
                          value: _vm.item.due_date,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "due_date", $$v)
                          },
                          expression: "item.due_date"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "meter_readings_start_date",
                        label: _vm._f("pgettext")(
                          "Meter Readings From",
                          "InvoiceGenerator"
                        ),
                        labelColsMd: "6",
                        contentColsMd: "6",
                        labelAlign: _vm.labelAlign
                      }
                    },
                    [
                      _c("ItpFormDatetime", {
                        attrs: {
                          name: "meter_readings_start_date",
                          placeholder: _vm._f("pgettext")(
                            "Meter Readings From",
                            "InvoiceGenerator"
                          )
                        },
                        model: {
                          value: _vm.item.meter_readings_start_date,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "meter_readings_start_date", $$v)
                          },
                          expression: "item.meter_readings_start_date"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "invoice_tags",
                        label: _vm._f("pgettext")(
                          "Invoice Tags",
                          "InvoiceGenerator"
                        ),
                        labelColsMd: "6",
                        contentColsMd: "6",
                        labelAlign: _vm.labelAlign
                      }
                    },
                    [
                      _c("ItpSelect2", {
                        attrs: {
                          name: "invoice_tags",
                          taggable: "",
                          multiple: ""
                        },
                        model: {
                          value: _vm.item.invoice_tags,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "invoice_tags", $$v)
                          },
                          expression: "item.invoice_tags"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("HR"),
              _vm._v(" "),
              _c(
                "ItpPanel",
                {
                  attrs: {
                    collapsible: "",
                    stateKey: "InvoiceGenerator.panels.options"
                  }
                },
                [
                  _c("ItpText", {
                    attrs: {
                      slot: "caption",
                      strong: "",
                      muted: "",
                      block: "",
                      text: _vm._f("pgettext")("Options", "InvoiceGenerator")
                    },
                    slot: "caption"
                  }),
                  _vm._v(" "),
                  _c(
                    "fieldset",
                    [
                      _c(
                        "ItpFormGroup",
                        [
                          _c("ItpFormCheckbox", {
                            attrs: {
                              name: "include_zero_consumptions",
                              label: _vm._f("pgettext")(
                                "Include zero consumptions",
                                "InvoiceGenerator"
                              ),
                              required: ""
                            },
                            model: {
                              value: _vm.item.include_zero_consumptions,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.item,
                                  "include_zero_consumptions",
                                  $$v
                                )
                              },
                              expression: "item.include_zero_consumptions"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "fieldset",
                    [
                      _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            help: _vm._f("gettext")(
                              "Charge all consumptions, even minimal ones."
                            )
                          }
                        },
                        [
                          _c("ItpFormCheckbox", {
                            attrs: {
                              name: "ignore_meter_billing_thresholds",
                              label: _vm._f("pgettext")(
                                "Ignore meter billing thresholds.",
                                "InvoiceGenerator"
                              ),
                              required: ""
                            },
                            model: {
                              value: _vm.item.ignore_meter_billing_thresholds,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.item,
                                  "ignore_meter_billing_thresholds",
                                  $$v
                                )
                              },
                              expression: "item.ignore_meter_billing_thresholds"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("HR"),
              _vm._v(" "),
              _c("JobHandlerControl", {
                attrs: {
                  jobType: "invoice_generation",
                  "load-current": "",
                  payload: function() {
                    return _vm.getPayload()
                  },
                  text: _vm._f("pgettext")(
                    "Generate Invoices",
                    "InvoiceGenerator"
                  ),
                  icon: "mdi:cog",
                  disabled: !_vm.canSubmit
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }