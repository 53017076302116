"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DunningLetters = exports.DunningLetterJobs = exports.CreateDunningLetter = exports.DunningLetter = void 0;
const DunningLetter_yaml_component_1 = require("./DunningLetter.yaml?component");
exports.DunningLetter = DunningLetter_yaml_component_1.default;
const CreateDunningLetter_yaml_component_1 = require("./CreateDunningLetter.yaml?component");
exports.CreateDunningLetter = CreateDunningLetter_yaml_component_1.default;
const DunningLetterJobs_yaml_component_1 = require("./DunningLetterJobs.yaml?component");
exports.DunningLetterJobs = DunningLetterJobs_yaml_component_1.default;
const DunningLetters_yaml_component_1 = require("./DunningLetters.yaml?component");
exports.DunningLetters = DunningLetters_yaml_component_1.default;
