<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpRow>
    <ItpCol
      w="12"
    >
      <ItpFormGroup
        labelColsMd="2"
        contentColsMd="5"
        name="account"
        :label="'Account'|pgettext('Payment')"
        :labelAlign="labelAlign"
        required
      >
        <ItpFormSelect2
          name="account"
          v-model="payment.account"
          valueField="id"
          textField="name"
          bind-object
          :options="accounts"
          :readonly="isReadOnly"
          optionTemplate="<UcAccountSelectOption :payload=option></UcAccountSelectOption>"
          use-option-template-for-selected-option
          @optionSelected="evh_4615685607825208_optionSelected($event, {})"
        >
        </ItpFormSelect2>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="2"
        contentColsMd="10"
        name="payment_category"
        :label="'Title'|pgettext('Payment')"
        :labelAlign="labelAlign"
      >
        <ItpFormSelect2
          name="payment_category"
          v-model="payment.payment_category"
          valueField="name"
          textField="name"
          :options="categories"
          taggable
          clearable
          :readonly="isReadOnly"
        >
        </ItpFormSelect2>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="2"
        contentColsMd="10"
        name="client"
        :label="'Client'|pgettext('Payment')"
        :labelAlign="labelAlign"
      >
        <ItpFormSelect2
          name="client"
          v-model="payment.client"
          bind-object
          :dataSource="b_8697745105058001_dataSource"
          optionTemplate="<UcSuggestClientOption :option=option></UcSuggestClientOption>"
          use-option-template-for-selected-option
          textField="name"
          clearable
          :readonly="isReadOnly"
        >
        </ItpFormSelect2>
      </ItpFormGroup>
    </ItpCol>
  </ItpRow>
  <HR>
  </HR>
  <ItpRow>
    <ItpCol
      w="5"
      form
    >
      <ItpFormGroup
        labelColsMd="5"
        contentColsMd="7"
        name="payment_number"
        :label="'Number'|pgettext('Payment')"
        :labelAlign="labelAlign"
        required
        v-if="payment.account.account_type !== 'cash_book'"
      >
        <ItpFormInput
          autofocus
          name="payment_number"
          v-model="payment.payment_number"
          :readonly="isReadOnly"
          :placeholder="'Enter payment number'|pgettext('Payment/payment_number/placeholder')"
          required
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="5"
        contentColsMd="7"
        name="payment_date"
        :label="'Payment Date'|pgettext('Payment')"
        :labelAlign="labelAlign"
        required
      >
        <ItpFormDatetime
          name="payment_date"
          v-model="payment.payment_date"
          :readonly="isReadOnly"
          required
        >
        </ItpFormDatetime>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="5"
        contentColsMd="7"
        name="payment_method"
        :label="'Method'|pgettext('Payment')"
        :labelAlign="labelAlign"
        required
      >
        <ItpFormSelect
          name="payment_method"
          v-model="payment.payment_method"
          :options="paymentMethods"
          :readonly="isReadOnly"
          required
        >
        </ItpFormSelect>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="5"
        contentColsMd="7"
        name="payment_type"
        :label="'Payment type'|pgettext('Payment')"
        :labelAlign="labelAlign"
        required
      >
        <ItpFormSelect
          name="payment_type"
          v-model="payment.payment_type"
          :options="cashBookPayment ? cashPaymentTypes : paymentTypes"
          :readonly="isReadOnly"
          required
        >
        </ItpFormSelect>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="5"
        contentColsMd="7"
        name="amount"
        :label="'Amount'|pgettext('Payment')"
        :labelAlign="labelAlign"
        :appendText="payment.currency"
        required
      >
        <ItpFormInput
          name="amount"
          v-model="payment.amount"
          :readonly="isReadOnly"
          step="any"
          required
          min="0"
          mask="number"
          unmask="typed"
          scale="2"
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="5"
        contentColsMd="5"
        name="exchange_rate"
        :label="'Exchange Rate'|pgettext('Payment')"
        :labelAlign="labelAlign"
        required
      >
        <ItpFormInput
          type="number"
          name="exchange_rate"
          v-model="payment.exchange_rate"
          :readonly="isReadOnly"
          required
        >
        </ItpFormInput>
      </ItpFormGroup>
    </ItpCol>
    <ItpCol
      w="7"
    >
      <ItpFormGroup
        labelColsMd="4"
        contentColsMd="8"
        name="description"
        :label="'Description'|pgettext('Payment')"
        :labelAlign="labelAlign"
      >
        <ItpFormInput
          name="description"
          v-model="payment.description"
          :readonly="isReadOnly"
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="4"
        name="reference_number"
        :label="'Reference Number'|pgettext('Payment')"
        :labelAlign="labelAlign"
      >
        <ItpFormInput
          name="reference_number"
          v-model="payment.reference_number"
          :readonly="isReadOnly"
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="4"
        name="file_number"
        :label="'File Number'|pgettext('Payment')"
        :labelAlign="labelAlign"
      >
        <ItpFormInput
          name="file_number"
          v-model="payment.file_number"
          :readonly="isReadOnly"
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="4"
        contentColsMd="8"
        name="notes"
        :label="'Notes'|pgettext('Payment')"
        :labelAlign="labelAlign"
      >
        <ItpFormTextarea
          name="notes"
          v-model="payment.notes"
          :readonly="isReadOnly"
        >
        </ItpFormTextarea>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="4"
        contentColsMd="8"
        name="internal_notes"
        :label="'Internal Notes'|pgettext('Payment')"
        :labelAlign="labelAlign"
      >
        <ItpFormTextarea
          name="internal_notes"
          v-model="payment.internal_notes"
          :readonly="isReadOnly"
        >
        </ItpFormTextarea>
      </ItpFormGroup>
    </ItpCol>
  </ItpRow>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class FieldsetPaymentGeneral extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  payment!: object;

  @Watch('payment')
  onPayment(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("payment")
  }

  @Prop({
    type: String,
    default: "right",
  })
  labelAlign!: string;

  @Watch('labelAlign')
  onLabel_align(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("labelAlign")
  }

  paymentTypes!: any;
  cashPaymentTypes!: any;
  accounts!: any;

  async $$load_accounts() {
    return this.$fn.fetch('list_account')
      .then(p => _
        .chain(p.data)
        .filter({ account_type: this.payment.account.account_type })
        .orderBy(['inactive', 'account_type', 'name'], ['asc', 'desc', 'asc'])
        .value()
      )
  }

  categories!: any;

  async $$load_categories() {
    return this.$fn.fetch('get_tags', { parameters: { tag_type: 'payment' } }).then(data => _.map(data, p => p.name))
  }

  paymentMethods!: any;

  async $$load_paymentMethods() {
    return this.$fn.fetch('list_payment_method')
      .then(data => _
        .chain(data)
        .filter(p => this.payment.account.account_type !== 'cash_book' ? !p.cash : p.cash)
        .map(p => ({
          value: p.id,
          text: this.$fn.pgettext('PaymentMethod', p.name)
        }))
        .value()
      )
  }

  ux!: any;
  b_8697745105058001_dataSource!: any;
  dataMembers = ['paymentTypes', 'cashPaymentTypes', 'accounts', 'categories', 'paymentMethods', 'ux', 'b_8697745105058001_dataSource'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        paymentTypes: {
          credit: this.$fn.pgettext("PaymentType", "credit"),
          debit: this.$fn.pgettext("PaymentType", "debit"),
        }
        ,
        cashPaymentTypes: {
          deposit: this.$fn.pgettext("CashBookPaymentType", "deposit"),
          payment: this.$fn.pgettext("CashBookPaymentType", "payment"),
        }
        ,
        accounts: null,
        categories: null,
        paymentMethods: null,
        ux: null,
        b_8697745105058001_dataSource: {
          name: "suggest_client",
        }
        ,
      },
    }
  }

  get isReadOnly() {
    if (this.loaded) {

      return this.payment.isReadonly
    }
    return null;
  }

  get cashBookPayment() {
    if (this.loaded) {

      return this.payment.account && this.payment.account.account_type == 'cash_book'
    }
    return null;
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_4615685607825208_setData_5568_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_4615685607825208_setData_5568, alias=undefined
    return {
      path: "payment.currency",
      value: $event.data.option.currency,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_4615685607825208_optionSelected(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4615685607825208_optionSelected.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_5566: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_5565: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_4615685607825208_setData_5568: actions.SetDataAction;
    evh_4615685607825208_optionSelected_5567: actions.EventHandlerImpl;
    evh_4615685607825208_optionSelected: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_5566 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_5565 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_5566,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_5565],
      }
    );
    const act_4615685607825208_setData_5568 = new actions.SetDataAction(
      {
        actionArgs: this.act_4615685607825208_setData_5568_getActionArgs,
        events: [],
      }
    );
    const evh_4615685607825208_optionSelected_5567 = new actions.EventHandlerImpl(
      {
        action: act_4615685607825208_setData_5568,
        event: "optionSelected",
        displayName: "setData",
      }
    );
    const evh_4615685607825208_optionSelected = new actions.EventHandlerGroup(
      {
        handlers: [evh_4615685607825208_optionSelected_5567],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_5566,
      evh_7315092382398562_reload_5565,
      evh_7315092382398562_reload,
      act_4615685607825208_setData_5568,
      evh_4615685607825208_optionSelected_5567,
      evh_4615685607825208_optionSelected,
    }
  }
}

Vue.component("FieldsetPaymentGeneral", FieldsetPaymentGeneral);

</script>