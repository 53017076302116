<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="CreateWorksheetsFromWasteBinReadingsModal"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documenTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      name="CreateWorksheetsFromWasteBinReadingsModal"
      :id="formId"
      v-if="loaded"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <ItpFormGroup
        name="service_id"
        :label="'Service'|pgettext('CreateWorksheetsFromWasteBinReadingsModal')"
        :helpTooltip="'Create line items with the selected service.'|pgettext('CreateWorksheetsFromWasteBinReadingsModal')"
        :labelAlign="labelAlign"
        labelColsMd="5"
        contentColsMd="7"
      >
        <ItpFormSelect2
          name="service_id"
          v-model="item.service_id"
          :dataSource="b_7789867021953041_dataSource"
          valueField="id"
          textField="name"
          required
        >
        </ItpFormSelect2>
      </ItpFormGroup>
      <ItpFormGroup
        name="worksheet_description"
        :label="'Worksheet description'|pgettext('CreateWorksheetsFromWasteBinReadingsModal')"
        :helpTooltip="'The given value will be the description of the created worksheet. Otherwise the selected service name.'|pgettext('CreateWorksheetsFromWasteBinReadingsModal')"
        :labelAlign="labelAlign"
        labelColsMd="5"
        contentColsMd="7"
      >
        <ItpFormInput
          name="worksheet_description"
          v-model="item.worksheet_description"
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        name="contractor_id"
        :label="'Contractor'|pgettext('Worksheet')"
        :labelAlign="labelAlign"
        labelColsMd="5"
        contentColsMd="7"
      >
        <ItpFormSelect2
          name="contractor_id"
          v-model="item.contractor_id"
          :dataSource="b_7527331142178322_dataSource"
          valueField="id"
          textField="name"
          optionTemplate="<UcSuggestClientOption :option=option></UcSuggestClientOption>"
          use-option-template-for-selected-option
        >
        </ItpFormSelect2>
      </ItpFormGroup>
      <ItpFormGroup
        name="worker_id"
        :label="'Worker'|pgettext('Worksheet')"
        :labelAlign="labelAlign"
        labelColsMd="5"
        contentColsMd="7"
      >
        <ItpFormSelect2
          name="worker_id"
          v-model="item.worker_id"
          :dataSource="b_5074589820235598_dataSource"
          valueField="id"
          textField="name"
        >
        </ItpFormSelect2>
      </ItpFormGroup>
      <ItpFormGroup
        name="gl_account_number"
        :label="'GL Account Number'|pgettext('CreateWorksheetsFromWasteBinReadingsModal')"
        :labelAlign="labelAlign"
        labelColsMd="5"
        contentColsMd="7"
      >
        <ItpFormInput
          name="gl_account_number"
          v-model="item.gl_account_number"
        >
        </ItpFormInput>
      </ItpFormGroup>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :text="'Create worksheets'|pgettext('CreateWorksheetsFromWasteBinReadingsModal')"
      type="submit"
      :form="formId"
      variant="primary"
      icon="fa-save"
      :spinning="actions.submit.isRunning"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      :disabled="actions.submit.isRunning"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class CreateWorksheetsFromWasteBinReadingsModal extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Array,
  })
  readings!: array;

  @Watch('readings')
  onReadings(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("readings")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  labelAlign!: any;
  item!: any;

  async $$load_item() {
    return this.$fn.schemaDefaults('CreateWorksheetsFromReadings', { readings: this.readings })
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_7789867021953041_dataSource!: any;
  b_7527331142178322_dataSource!: any;
  b_5074589820235598_dataSource!: any;
  dataMembers = ['formId', 'labelAlign', 'item', 'ux', 'b_7315092382398562_modalBindings', 'b_7789867021953041_dataSource', 'b_7527331142178322_dataSource', 'b_5074589820235598_dataSource'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        formId: null,
        labelAlign: "right",
        item: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "md",
        }
        ,
        b_7789867021953041_dataSource: {
          name: "suggest_product",
          parameters: {
            part_types: "service",
          }
          ,
        }
        ,
        b_7527331142178322_dataSource: {
          name: "suggest_client",
          parameters: {
            contractors_only: true,
          }
          ,
        }
        ,
        b_5074589820235598_dataSource: {
          name: "suggest_client_employee",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("CreateWorksheetsFromWasteBinReadingsModal", "Create Worksheets"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_1458_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_1458, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_closeModal_1464_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1040167445267876_closeModal_1464, alias=undefined
    return {
      result: {
        close: true,
      }
      ,
    }
  }

  async act_1040167445267876_reloadSlickgrid_1466_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_1040167445267876_reloadSlickgrid_1466, alias=undefined
    return {
      grid: "wasteBinReadings",
    }
  }

  async act_1040167445267876_request_1462_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_1040167445267876_request_1462, alias=submit
    return {
      operation: "create_worksheets_from_waste_bin_readings",
      data: this.item,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_1458: actions.CloseModalAction;
    evh_7315092382398562_close_1457: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeModal_1460: actions.CloseModalAction;
    evh_2248226175642056_close_1459: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1040167445267876_closeModal_1464: actions.CloseModalAction;
    evh_1040167445267876_success_1463: actions.EventHandlerImpl;
    act_1040167445267876_reloadSlickgrid_1466: actions.ReloadSlickgridAction;
    evh_1040167445267876_success_1465: actions.EventHandlerImpl;
    act_1040167445267876_request_1462: actions.RequestAction;
    evh_1040167445267876_submit_1461: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_1419464017721962_closeModal_1468: actions.CloseModalAction;
    evh_1419464017721962_clickPreventStop_1467: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_1458 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_1458_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_1457 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_1458,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_1457],
      }
    );
    const act_2248226175642056_closeModal_1460 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_1459 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeModal_1460,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_1459],
      }
    );
    const act_1040167445267876_closeModal_1464 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1040167445267876_closeModal_1464_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_1463 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeModal_1464,
        event: "success",
        displayName: "closeModal",
      }
    );
    const act_1040167445267876_reloadSlickgrid_1466 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_1040167445267876_reloadSlickgrid_1466_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_1465 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_reloadSlickgrid_1466,
        event: "success",
        displayName: "reloadSlickgrid",
      }
    );
    const act_1040167445267876_request_1462 = new actions.RequestAction(
      {
        actionArgs: this.act_1040167445267876_request_1462_getActionArgs,
        displayName: "submit",
        events: [evh_1040167445267876_success_1463, evh_1040167445267876_success_1465],
      }
    );
    const evh_1040167445267876_submit_1461 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_request_1462,
        event: "submit",
        displayName: "submit",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_1461],
      }
    );
    const act_1419464017721962_closeModal_1468 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_1467 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeModal_1468,
        event: "click.prevent.stop",
        displayName: "closeModal",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_1467],
      }
    );
    return {
      act_7315092382398562_closeModal_1458,
      evh_7315092382398562_close_1457,
      evh_7315092382398562_close,
      act_2248226175642056_closeModal_1460,
      evh_2248226175642056_close_1459,
      evh_2248226175642056_close,
      act_1040167445267876_closeModal_1464,
      evh_1040167445267876_success_1463,
      act_1040167445267876_reloadSlickgrid_1466,
      evh_1040167445267876_success_1465,
      act_1040167445267876_request_1462,
      evh_1040167445267876_submit_1461,
      evh_1040167445267876_submit,
      act_1419464017721962_closeModal_1468,
      evh_1419464017721962_clickPreventStop_1467,
      evh_1419464017721962_clickPreventStop,
      submit: act_1040167445267876_request_1462,
    }
  }
}

Vue.component("CreateWorksheetsFromWasteBinReadingsModal", CreateWorksheetsFromWasteBinReadingsModal);

</script>