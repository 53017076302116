import CompleteInvoices from './CompleteInvoices.yaml?component';
import CompleteInvoicesAlt from './CompleteInvoicesAlt.yaml?component';
import DraftInvoicesDashboard from './DraftInvoicesDashboard.yaml?component';
import FieldsetInvoiceGeneration from './FieldsetInvoiceGeneration.yaml?component';
import InvoiceGeneration from './InvoiceGeneration.yaml?component';
import InvoiceGenerationSession from './InvoiceGenerationSession.yaml?component';
import PageDraftInvoices from './PageDraftInvoices.yaml?component';
import UcInvoiceGeneratorControls from './UcInvoiceGeneratorControls.yaml?component';

export {
  CompleteInvoices,
  CompleteInvoicesAlt,
  DraftInvoicesDashboard,
  FieldsetInvoiceGeneration,
  PageDraftInvoices,
  InvoiceGeneration,
  InvoiceGenerationSession,
  UcInvoiceGeneratorControls
};
