var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpBox",
    [
      _c(
        "ItpSidebar",
        {
          staticClass: "mr-2",
          attrs: { "default-width": "400", "scroll-y": "" }
        },
        [_c("UcInvoiceGeneratorControls", { ref: "panel" })],
        1
      ),
      _vm._v(" "),
      _c(
        "DIV",
        { staticClass: "h-100 w-100 overflow-hidden position-relative" },
        [
          _c(
            "VueSlickgrid",
            {
              ref: "grid",
              staticClass: "page--grid",
              attrs: {
                name: "draftInvoices",
                resizeTrigger: _vm.resizeTrigger,
                headerTitle: _vm._f("gettext")("Draft Invoices"),
                items: _vm.$fn.slickDataSource("list_draft_invoices"),
                "checkbox-row-selection": "",
                "footer-totals": "",
                "export-to-csv": "",
                exportToCsvFilename: _vm._f("pgettext")(
                  "draft-invoices",
                  "ExportFilename"
                ),
                columnDefaults: _vm.b_1040167445267876_columnDefaults,
                columns: _vm.b_1040167445267876_columns
              },
              on: {
                selectedRowsChanged: function($event) {
                  return _vm.evh_1040167445267876_selectedRowsChanged(
                    $event,
                    {}
                  )
                },
                command: function($event) {
                  return _vm.evh_1040167445267876_command($event, {})
                }
              }
            },
            [
              _c(
                "DIV",
                {
                  staticClass: "flex-grow-1 d-flex",
                  attrs: { slot: "toolbar" },
                  slot: "toolbar"
                },
                [
                  _c("ItpButton", {
                    staticClass: "mx-2",
                    attrs: {
                      icon: "fa-trash",
                      text: _vm._f("gettext")("Delete"),
                      size: "sm",
                      variant: "light",
                      disabled: !_vm.selectedItems || !_vm.selectedItems.length,
                      spinning: _vm.actions.deleteItems.isRunning
                    },
                    on: {
                      click: function($event) {
                        return _vm.evh_7789867021953041_click($event, {})
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("JobHandlerControl", {
                    attrs: {
                      jobType: "invoice_sync",
                      "load-current": "",
                      payload: function() {
                        return _vm.onCreateInvoicesPayload()
                      },
                      icon: "material-symbols:cloud-sync-outline-rounded",
                      text: _vm._f("pgettext")(
                        "Create Invoices",
                        "DraftInvoices|Submit"
                      ),
                      disabled: !_vm.selectedItems || !_vm.selectedItems.length
                    }
                  }),
                  _vm._v(" "),
                  _c("ItpLink", {
                    staticClass: "d-inline-block mx-2",
                    attrs: {
                      text: _vm._f("pgettext")(
                        "Download invoice lines",
                        "draftInvoices"
                      ),
                      href: _vm.$fn.urlFor("draft_invoice_lines_report"),
                      button: "",
                      variant: "light",
                      "use-auth": ""
                    }
                  }),
                  _vm._v(" "),
                  _c("PageHelp", {
                    attrs: { path: "/finance/draft-invoices.html" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }