<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <JobProgress
    :item="item"
  >
  </JobProgress>
  <ItpBox
    direction="row"
  >
    <ItpText
      muted
      :text="'Start time'|pgettext('Job')"
      class="mr-2"
      v-if="item.start_time"
    >
    </ItpText>
    <ItpText
      strong
      class="mr-4"
      v-if="item.start_time"
    >
      {{ item.start_time|datetime }}
    </ItpText>
    <ItpText
      muted
      :text="'End time'|pgettext('Job')"
      class="mr-2"
      v-if="item.end_time"
    >
    </ItpText>
    <ItpText
      strong
      class="mr-2"
      v-if="item.end_time"
    >
      {{ item.end_time|datetime }}
    </ItpText>
  </ItpBox>
  <DIV
    class="border mt-2"
  >
    <pre
      class="m-0 p-3"
    >{{ item.log }}</pre>
  </DIV>
  <JobFiles
    :item="item"
    v-if="item.files && item.files.length"
  >
  </JobFiles>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class JobRunView extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  item!: object;

  @Watch('item')
  onItem(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("item")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_5484: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_5483: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_5484 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_5483 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_5484,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_5483],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_5484,
      evh_7315092382398562_reload_5483,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("JobRunView", JobRunView);

</script>