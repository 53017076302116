<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpWidgetCard>
    <ItpBox
      alignItems="center"
      slot="header"
    >
      <strong>
        {{ 'General'|pgettext('FixedAsset') }}
      </strong>
      <ItpButton
        :text="'Edit...'|gettext"
        variant="light"
        size="sm"
        class="ml-auto"
        v-if="!readonly"
        @click.prevent.stop="evh_8320016629450276_clickPreventStop($event, {})"
      >
      </ItpButton>
    </ItpBox>
    <BListGroup>
      <ItpLabeledListGroupItem
        :label="'Asset Type'|pgettext('FixedAsset')"
      >
        <UcFixedAssetTypeBadge
          :value="fixedAsset.asset_type"
        >
        </UcFixedAssetTypeBadge>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Name'|pgettext('FixedAsset')"
        :content="fixedAsset.name"
        v-if="fixedAsset.name"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Description'|pgettext('FixedAsset')"
        :content="fixedAsset.description"
        v-if="fixedAsset.description"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Inventory Number'|pgettext('FixedAsset')"
        :content="fixedAsset.inventory_number"
        v-if="fixedAsset.inventory_number"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Rental'|pgettext('FixedAsset')"
      >
        <UcFixedAssetRentalCard
          :fixedAsset="fixedAsset"
          @reload="evh_8352907821006950_reload($event, {})"
        >
        </UcFixedAssetRentalCard>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'SKU'|pgettext('FixedAsset')"
        :content="fixedAsset.sku"
        v-if="fixedAsset.sku"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Type Number'|pgettext('FixedAsset')"
        :content="fixedAsset.type_no"
        v-if="fixedAsset.type_no"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Quantity'|pgettext('FixedAsset')"
        :content="fixedAsset.quantity|number"
        v-if="fixedAsset.quantity"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Cost'|pgettext('FixedAsset')"
        :content="fixedAsset.cost|number"
        v-if="fixedAsset.cost"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'General Ledger Code'|pgettext('FixedAsset')"
        :content="fixedAsset.gl_code"
        v-if="fixedAsset.gl_code"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Year of Production'|pgettext('FixedAsset')"
        :content="fixedAsset.year_of_production"
        v-if="fixedAsset.year_of_production"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Manufacturer'|pgettext('FixedAsset')"
        :content="fixedAsset.manufacturer"
        v-if="fixedAsset.manufacturer"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Serial Number'|pgettext('FixedAsset')"
        :content="fixedAsset.serial_number"
        v-if="fixedAsset.serial_number"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Category'|pgettext('FixedAsset')"
        :content="fixedAsset.category"
        v-if="fixedAsset.category"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Groups'|pgettext('FixedAsset')"
        :content="fixedAsset.groups"
        v-if="fixedAsset.groups"
      >
      </ItpLabeledListGroupItem>
    </BListGroup>
  </ItpWidgetCard>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcFixedAssetGeneral extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  fixedAsset!: object;

  @Watch('fixedAsset')
  onFixed_asset(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("fixedAsset")
  }

  @Prop({
    type: Boolean,
  })
  readonly!: boolean;

  @Watch('readonly')
  onReadonly(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("readonly")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_8320016629450276_emit_4798_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_8320016629450276_emit_4798, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_8320016629450276_close_4797_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_8320016629450276_close_4797, alias=undefined
    return !!$event.data.modalResult.value
  }

  async act_8320016629450276_showModal_4796_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8320016629450276_showModal_4796, alias=undefined
    return {
      name: "MFixedAssetEditor",
      props: {
        target: "general",
        fixedAsset: _.cloneDeep(this.fixedAsset),
      }
      ,
    }
  }

  async evh_8320016629450276_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_8352907821006950_emit_4800_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_8352907821006950_emit_4800, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_8352907821006950_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8352907821006950_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_4794: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_4793: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_8320016629450276_emit_4798: actions.EmitAction;
    evh_8320016629450276_close_4797: actions.EventHandlerImpl;
    act_8320016629450276_showModal_4796: actions.ShowModalAction;
    evh_8320016629450276_clickPreventStop_4795: actions.EventHandlerImpl;
    evh_8320016629450276_clickPreventStop: actions.EventHandlerGroup;
    act_8352907821006950_emit_4800: actions.EmitAction;
    evh_8352907821006950_reload_4799: actions.EventHandlerImpl;
    evh_8352907821006950_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_4794 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_4793 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_4794,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_4793],
      }
    );
    const act_8320016629450276_emit_4798 = new actions.EmitAction(
      {
        actionArgs: this.act_8320016629450276_emit_4798_getActionArgs,
        events: [],
      }
    );
    const evh_8320016629450276_close_4797 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_emit_4798,
        event: "close",
        when: this.evh_8320016629450276_close_4797_getWhen,
        displayName: "emit",
      }
    );
    const act_8320016629450276_showModal_4796 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8320016629450276_showModal_4796_getActionArgs,
        events: [evh_8320016629450276_close_4797],
      }
    );
    const evh_8320016629450276_clickPreventStop_4795 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_showModal_4796,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_8320016629450276_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_clickPreventStop_4795],
      }
    );
    const act_8352907821006950_emit_4800 = new actions.EmitAction(
      {
        actionArgs: this.act_8352907821006950_emit_4800_getActionArgs,
        events: [],
      }
    );
    const evh_8352907821006950_reload_4799 = new actions.EventHandlerImpl(
      {
        action: act_8352907821006950_emit_4800,
        event: "reload",
        displayName: "emit",
      }
    );
    const evh_8352907821006950_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_8352907821006950_reload_4799],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_4794,
      evh_7315092382398562_reload_4793,
      evh_7315092382398562_reload,
      act_8320016629450276_emit_4798,
      evh_8320016629450276_close_4797,
      act_8320016629450276_showModal_4796,
      evh_8320016629450276_clickPreventStop_4795,
      evh_8320016629450276_clickPreventStop,
      act_8352907821006950_emit_4800,
      evh_8352907821006950_reload_4799,
      evh_8352907821006950_reload,
    }
  }
}

Vue.component("UcFixedAssetGeneral", UcFixedAssetGeneral);

</script>