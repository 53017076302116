<template>
<ItpButton
  :dropdownItem="dropdownItem"
  :text="noText ? null : messages.text"
  :icon="noIcon ? null : 'fa-trash'"
  :variant="dropdownItem ? 'default' : variant"
  :spinning="actions.delete.isRunning"
  :disabled="disabled"
  :size="size"
  :tooltip="tooltip"
  @click.prevent.stop="evh_7315092382398562_clickPreventStop($event, {})"
>
</ItpButton>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class BtnDeleteResource extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  resource!: object;

  @Watch('resource')
  onResource(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("resource")
  }

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Watch('disabled')
  onDisabled(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("disabled")
  }

  @Prop({
    type: Boolean,
    default: false,
  })
  noIcon!: boolean;

  @Watch('noIcon')
  onNo_icon(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("noIcon")
  }

  @Prop({
    type: Boolean,
    default: false,
  })
  noText!: boolean;

  @Watch('noText')
  onNo_text(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("noText")
  }

  @Prop({
    type: String,
    default: "sm",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  @Prop({
    type: String,
  })
  tooltip!: string;

  @Watch('tooltip')
  onTooltip(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("tooltip")
  }

  @Prop({
    type: String,
  })
  dialogHeader!: string;

  @Watch('dialogHeader')
  onDialog_header(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("dialogHeader")
  }

  @Prop({
    type: Boolean,
    default: false,
  })
  dropdownItem!: boolean;

  @Watch('dropdownItem')
  onDropdown_item(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("dropdownItem")
  }

  @Prop({
    type: String,
    default: "light",
  })
  variant!: string;

  @Watch('variant')
  onVariant(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("variant")
  }

  messages!: any;
  ux!: any;
  dataMembers = ['messages', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        messages: {
          text: this.$fn.pgettext("BtnDeleteResource", "Delete"),
        }
        ,
        ux: null,
      },
    }
  }

  async act_7315092382398562_emit_1776_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_7315092382398562_emit_1776, alias=undefined
    return {
      event: "success",
    }
  }

  async act_7315092382398562_resource_1774_getActionArgs($event: actions.ActionEvent): Promise<actions.ResourceActionArgs> {
    // parameterResolver name=act_7315092382398562_resource_1774, alias=delete
    return {
      method: "delete",
      target: this.resource,
      data: { etag: this.resource.etag },
    }
  }

  async act_7315092382398562_resource_1774_getConfirm($event: actions.ActionEvent): Promise<actions.ResourceActionArgs> {
    // parameterResolver name=act_7315092382398562_resource_1774, alias=delete
    return {
      header: this.dialogHeader,
      message: this.$fn.gettext("Please confirm delete."),
      btnOk: this.$fn.gettext("Delete"),
      severity: "warning",
    }
  }

  async evh_7315092382398562_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_emit_1776: actions.EmitAction;
    evh_7315092382398562_success_1775: actions.EventHandlerImpl;
    act_7315092382398562_resource_1774: actions.ResourceAction;
    evh_7315092382398562_clickPreventStop_1773: actions.EventHandlerImpl;
    evh_7315092382398562_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_emit_1776 = new actions.EmitAction(
      {
        actionArgs: this.act_7315092382398562_emit_1776_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_success_1775 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_emit_1776,
        event: "success",
        displayName: "emit",
      }
    );
    const act_7315092382398562_resource_1774 = new actions.ResourceAction(
      {
        actionArgs: this.act_7315092382398562_resource_1774_getActionArgs,
        confirm: this.act_7315092382398562_resource_1774_getConfirm,
        displayName: "delete",
        events: [evh_7315092382398562_success_1775],
      }
    );
    const evh_7315092382398562_clickPreventStop_1773 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_resource_1774,
        event: "click.prevent.stop",
        displayName: "delete",
      }
    );
    const evh_7315092382398562_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_clickPreventStop_1773],
      }
    );
    return {
      act_7315092382398562_emit_1776,
      evh_7315092382398562_success_1775,
      act_7315092382398562_resource_1774,
      evh_7315092382398562_clickPreventStop_1773,
      evh_7315092382398562_clickPreventStop,
      delete: act_7315092382398562_resource_1774,
    }
  }
}

Vue.component("BtnDeleteResource", BtnDeleteResource);

</script>