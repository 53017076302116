<template>
<ItpBox
  alignItems="center"
  class="items-spacing w-100"
  v-if="loaded"
>
  <ItpBox
    direction="column"
  >
    <ItpText
      muted
      class="mb-0"
    >
      {{ resourceTitle }}
    </ItpText>
    <ItpText
      tag="h4"
      :text="resourceKey"
      class="mb-0"
    >
    </ItpText>
  </ItpBox>
  <UcInvoiceTypeBadge
    :value="invoice.invoice_type"
  >
  </UcInvoiceTypeBadge>
  <UcInvoiceStatusBadge
    :value="invoice.status"
  >
  </UcInvoiceStatusBadge>
  <DIV
    v-if="invoice.tags && invoice.tags.length"
  >
    <span
      class="badge badge-secondary mx-1 shadow-none"
      v-for="tag in invoice.tags"
    >
      {{ tag }}
    </span>
  </DIV>
  <DIV
    :title="'Closed'|gettext"
    v-if="invoice.isReadOnly"
  >
    <ItpIcon
      :icon="$config.lockIcon"
    >
    </ItpIcon>
  </DIV>
  <ItpBox
    direction="column"
    v-if="invoice.reference_invoice"
  >
    <ItpText
      muted
      class="mb-0"
    >
      {{ 'Reference invoice'|pgettext('Invoice') }}
    </ItpText>
    <UcInvoiceMiniCard
      :item="invoice.reference_invoice"
      no-icon
    >
    </UcInvoiceMiniCard>
  </ItpBox>
  <ItpBox
    direction="column"
    v-if="invoice.referencing_invoices && invoice.referencing_invoices.length"
  >
    <ItpText
      muted
      class="mb-0"
    >
      {{ 'Referencing invoices'|pgettext('Invoice') }}
    </ItpText>
    <ItpBox>
      <UcInvoiceMiniCard
        :key="obj.id"
        :item="obj"
        no-icon
        class="mr-2"
        v-for="obj in invoice.referencing_invoices"
      >
      </UcInvoiceMiniCard>
    </ItpBox>
  </ItpBox>
  <Icon
    :icon="'flagpack:' + invoice.language"
    :title="'Invoice Language'|pgettext('Invoice')"
    v-if="!invoice.isNew"
  >
  </Icon>
  <DIV
    class="btn-toolbar ml-auto"
  >
    <BtnSyncInvoice
      :invoice="invoice"
      class="ml-auto mr-2"
      v-if="!invoice.isNew"
      @success="evh_8680084583475136_success($event, {})"
    >
    </BtnSyncInvoice>
    <BtnSyncResource
      :resource="invoice"
      target="remote"
      confirmMessage="A számlát csak egyszer lehet felölteni a régi rendszerbe,
feltöltés után a számlát már nem lehet módosítani."
      class="ml-auto mr-2"
      v-if="!invoice.isNew"
      @success="evh_3282321863127091_success($event, {})"
    >
    </BtnSyncResource>
    <ItpButton
      :icon="$config.editIcon"
      :tooltip="'Edit'|gettext"
      variant="light"
      class="ml-auto mr-2"
      v-if="!invoice.isNew && !invoice.isReadOnly"
      @click.prevent.stop="evh_2386378572842701_clickPreventStop($event, {})"
    >
    </ItpButton>
    <ItpButton
      icon="fa-sync-alt"
      :tooltip="'Reload'|gettext"
      :spinning="actions.reload.isRunning"
      variant="light"
      class="ml-auto mr-2"
      v-if="!invoice.isNew"
      @click.prevent.stop="evh_6305342467955186_clickPreventStop($event, {})"
    >
    </ItpButton>
    <ItpButton
      variant="light"
      icon="icon-park-outline:printer-one"
      :href="invoice.print_url"
      :tooltip="'Open print document'|pgettext('Invoice')"
      target="_blank"
      use-auth
      show-spinner
      class="ml-auto mr-2"
      v-if="invoice.print_url"
    >
    </ItpButton>
    <ItpButton
      variant="light"
      icon="icon-park-outline:preview-open"
      :href="invoice.preview_url"
      :tooltip="'Open preview'|pgettext('Invoice')"
      target="_blank"
      use-auth
      show-spinner
      class="ml-auto mr-2"
      v-if="invoice.preview_url"
    >
    </ItpButton>
    <ItpButton
      variant="light"
      icon="icon-park-outline:log"
      :tooltip="'Show log messages'|pgettext('Invoice')"
      class="ml-auto mr-2"
      v-if="invoice.log_messages"
      @click.prevent.stop="evh_6493473528837603_clickPreventStop($event, {})"
    >
    </ItpButton>
    <b-dropdown
      right
      variant="light"
      no-caret
      class="border"
      v-if="!invoice.isNew"
    >
      <ItpIcon
        fa="bars"
        class="mx-1"
        slot="button-content"
      >
      </ItpIcon>
      <ItpButton
        dropdown-item
        :text="'Copy'|pgettext('BtnCopyResource')"
        :tooltip="'Create new invoice from this one'|pgettext('Invoice')"
        variant="default"
        @click="evh_5796712150329752_click($event, {})"
      >
      </ItpButton>
      <ItpButton
        dropdown-item
        :text="'Create storno'|pgettext('Invoice')"
        :tooltip="createStornoTooltip"
        variant="default"
        :disabled="!invoiceIsReversible"
        v-if="!invoice.isNew"
        @click="evh_4125470329773403_click($event, {})"
      >
      </ItpButton>
      <ItpButton
        dropdown-item
        variant="default"
        :text="'Create corrector'|pgettext('Invoice')"
        :tooltip="createCorrectorTooltip"
        :disabled="!invoiceIsModifiable"
        v-if="!invoice.isNew"
        @click="evh_3785918277944093_click($event, {})"
      >
      </ItpButton>
      <BtnLockResource
        no-icon
        dropdown-item
        :resource="invoice"
        :tooltip="'Lock invoice'|pgettext('Invoice')"
        v-if="!invoice.isNew && !invoice.isReadOnly"
        @success="evh_2191919750581405_success($event, {})"
      >
      </BtnLockResource>
      <BtnDeleteResource
        no-icon
        dropdown-item
        :resource="invoice"
        :tooltip="'Delete invoice'|pgettext('Invoice')"
        v-if="!invoice.isNew && !invoice.isReadOnly"
        @success="evh_3699289115122175_success($event, {})"
      >
      </BtnDeleteResource>
      <hr>
      </hr>
      <ItpButton
        dropdown-item
        :text="'History'|pgettext('BtnResourceHistory')"
        :tooltip="'Invoice history'|pgettext('Invoice')"
        variant="default"
        @click.prevent.stop="evh_1247093655110851_clickPreventStop($event, {})"
      >
      </ItpButton>
    </b-dropdown>
  </DIV>
</ItpBox>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcInvoiceHeader extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  invoice!: object;

  @Watch('invoice')
  onInvoice(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoice")
  }

  @Prop({
    type: String,
    default: function() {
      return this.$fn.randomElementId()
    },
  })
  formId!: string;

  @Watch('formId')
  onForm_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("formId")
  }

  @Prop({
    type: String,
  })
  invoiceNumber!: string;

  @Watch('invoiceNumber')
  onInvoice_number(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoiceNumber")
  }

  @Prop({
    required: true,
    type: Object,
  })
  formContext!: object;

  @Watch('formContext')
  onForm_context(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("formContext")
  }

  messages!: any;
  ux!: any;
  dataMembers = ['messages', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        messages: {
          newTransaction: this.$fn.pgettext("Invoice", "New Invoice"),
          invoiceKind: {
            incoming: this.$fn.pgettext("InvoiceKind", "Incoming Invoice"),
            outgoing: this.$fn.pgettext("InvoiceKind", "Outgoing Invoice"),
          }
          ,
          createCorrectorTooltip: this.$fn.pgettext("Invoice", "Create corrector invoice"),
          createStornoTooltip: this.$fn.pgettext("Invoice", "Create storno invoice"),
        }
        ,
        ux: null,
      },
    }
  }

  get resourceTitle() {
    if (this.loaded) {

      return this.messages.invoiceKind[this.invoice.invoice_kind]
    }
    return null;
  }

  get resourceKey() {
    if (this.loaded) {

      return this.invoiceNumber || (this.invoice.isNew ? this.messages.newTransaction : this.invoice.invoice_number)
    }
    return null;
  }

  get createCorrectorTooltip() {
    if (this.loaded) {

      return _.get(this.invoice, 'meta.modifiable.message') || this.messages.createCorrectorTooltip
    }
    return null;
  }

  get createStornoTooltip() {
    if (this.loaded) {

      return _.get(this.invoice, 'meta.reversible.message') || this.messages.createStornoTooltip
    }
    return null;
  }

  get invoiceIsReversible() {
    if (this.loaded) {

      return _.get(this.invoice, 'meta.reversible.result')
    }
    return null;
  }

  get invoiceIsModifiable() {
    if (this.loaded) {

      return _.get(this.invoice, 'meta.modifiable.result')
    }
    return null;
  }

  async act_8680084583475136_emit_5272_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_8680084583475136_emit_5272, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_8680084583475136_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8680084583475136_success.executeFromDOM(this, event, scope);
  }

  async act_3282321863127091_emit_5274_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_3282321863127091_emit_5274, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_3282321863127091_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3282321863127091_success.executeFromDOM(this, event, scope);
  }

  async act_2386378572842701_emit_5278_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_2386378572842701_emit_5278, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_2386378572842701_close_5277_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2386378572842701_close_5277, alias=undefined
    return $event.data.modalResult.ok
  }

  async act_2386378572842701_showModal_5276_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2386378572842701_showModal_5276, alias=undefined
    return {
      name: "InvoiceEditor",
      props: {
        invoice: _.cloneDeep(this.invoice),
      }
      ,
    }
  }

  async evh_2386378572842701_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2386378572842701_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_6305342467955186_emit_5280_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_6305342467955186_emit_5280, alias=reload
    return {
      event: "reload",
    }
  }

  async evh_6305342467955186_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6305342467955186_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_6493473528837603_showModal_5282_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_6493473528837603_showModal_5282, alias=undefined
    return {
      name: "InvoiceLogMessageViewer",
      props: {
        invoiceId: this.invoice.id,
      }
      ,
    }
  }

  async evh_6493473528837603_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6493473528837603_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_5796712150329752_emit_5286_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_5796712150329752_emit_5286, alias=undefined
    return {
      event: "created",
      value: $event.data.response.data,
    }
  }

  async act_5796712150329752_request_5284_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_5796712150329752_request_5284, alias=createInvoicecopy
    return {
      operation: "create_invoice",
      data: { invoice_id: this.invoice.id, invoice_type: 'copy' },
    }
  }

  async evh_5796712150329752_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5796712150329752_click.executeFromDOM(this, event, scope);
  }

  async act_4125470329773403_emit_5290_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_4125470329773403_emit_5290, alias=undefined
    return {
      event: "created",
      value: $event.data.response.data,
    }
  }

  async act_4125470329773403_request_5288_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_4125470329773403_request_5288, alias=createStornoInvoice
    return {
      operation: "create_invoice",
      data: { invoice_id: this.invoice.id, invoice_type: 'storno' },
    }
  }

  async evh_4125470329773403_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4125470329773403_click.executeFromDOM(this, event, scope);
  }

  async act_3785918277944093_emit_5294_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_3785918277944093_emit_5294, alias=undefined
    return {
      event: "created",
      value: $event.data.response.data,
    }
  }

  async act_3785918277944093_request_5292_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_3785918277944093_request_5292, alias=createCorrectorIvoice
    return {
      operation: "create_invoice",
      data: {
        invoice_id: this.invoice.id,
        invoice_type: 'correction',
        invoice_lines: _.map(this.formContext.selectedInvoiceLines, p => p.id).join(',')
      },
    }
  }

  async evh_3785918277944093_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3785918277944093_click.executeFromDOM(this, event, scope);
  }

  async act_2191919750581405_emit_5296_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_2191919750581405_emit_5296, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_2191919750581405_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2191919750581405_success.executeFromDOM(this, event, scope);
  }

  async act_3699289115122175_emit_5298_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_3699289115122175_emit_5298, alias=undefined
    return {
      event: "deleted",
    }
  }

  async evh_3699289115122175_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3699289115122175_success.executeFromDOM(this, event, scope);
  }

  async act_1247093655110851_showModal_5300_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_1247093655110851_showModal_5300, alias=undefined
    return {
      name: "entityEvents",
      props: {
        entityId: this.invoice.id,
      }
      ,
    }
  }

  async evh_1247093655110851_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1247093655110851_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_8680084583475136_emit_5272: actions.EmitAction;
    evh_8680084583475136_success_5271: actions.EventHandlerImpl;
    evh_8680084583475136_success: actions.EventHandlerGroup;
    act_3282321863127091_emit_5274: actions.EmitAction;
    evh_3282321863127091_success_5273: actions.EventHandlerImpl;
    evh_3282321863127091_success: actions.EventHandlerGroup;
    act_2386378572842701_emit_5278: actions.EmitAction;
    evh_2386378572842701_close_5277: actions.EventHandlerImpl;
    act_2386378572842701_showModal_5276: actions.ShowModalAction;
    evh_2386378572842701_clickPreventStop_5275: actions.EventHandlerImpl;
    evh_2386378572842701_clickPreventStop: actions.EventHandlerGroup;
    act_6305342467955186_emit_5280: actions.EmitAction;
    evh_6305342467955186_clickPreventStop_5279: actions.EventHandlerImpl;
    evh_6305342467955186_clickPreventStop: actions.EventHandlerGroup;
    act_6493473528837603_showModal_5282: actions.ShowModalAction;
    evh_6493473528837603_clickPreventStop_5281: actions.EventHandlerImpl;
    evh_6493473528837603_clickPreventStop: actions.EventHandlerGroup;
    act_5796712150329752_emit_5286: actions.EmitAction;
    evh_5796712150329752_success_5285: actions.EventHandlerImpl;
    act_5796712150329752_request_5284: actions.RequestAction;
    evh_5796712150329752_click_5283: actions.EventHandlerImpl;
    evh_5796712150329752_click: actions.EventHandlerGroup;
    act_4125470329773403_emit_5290: actions.EmitAction;
    evh_4125470329773403_success_5289: actions.EventHandlerImpl;
    act_4125470329773403_request_5288: actions.RequestAction;
    evh_4125470329773403_click_5287: actions.EventHandlerImpl;
    evh_4125470329773403_click: actions.EventHandlerGroup;
    act_3785918277944093_emit_5294: actions.EmitAction;
    evh_3785918277944093_success_5293: actions.EventHandlerImpl;
    act_3785918277944093_request_5292: actions.RequestAction;
    evh_3785918277944093_click_5291: actions.EventHandlerImpl;
    evh_3785918277944093_click: actions.EventHandlerGroup;
    act_2191919750581405_emit_5296: actions.EmitAction;
    evh_2191919750581405_success_5295: actions.EventHandlerImpl;
    evh_2191919750581405_success: actions.EventHandlerGroup;
    act_3699289115122175_emit_5298: actions.EmitAction;
    evh_3699289115122175_success_5297: actions.EventHandlerImpl;
    evh_3699289115122175_success: actions.EventHandlerGroup;
    act_1247093655110851_showModal_5300: actions.ShowModalAction;
    evh_1247093655110851_clickPreventStop_5299: actions.EventHandlerImpl;
    evh_1247093655110851_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_8680084583475136_emit_5272 = new actions.EmitAction(
      {
        actionArgs: this.act_8680084583475136_emit_5272_getActionArgs,
        events: [],
      }
    );
    const evh_8680084583475136_success_5271 = new actions.EventHandlerImpl(
      {
        action: act_8680084583475136_emit_5272,
        event: "success",
        displayName: "emit",
      }
    );
    const evh_8680084583475136_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_8680084583475136_success_5271],
      }
    );
    const act_3282321863127091_emit_5274 = new actions.EmitAction(
      {
        actionArgs: this.act_3282321863127091_emit_5274_getActionArgs,
        events: [],
      }
    );
    const evh_3282321863127091_success_5273 = new actions.EventHandlerImpl(
      {
        action: act_3282321863127091_emit_5274,
        event: "success",
        displayName: "emit",
      }
    );
    const evh_3282321863127091_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_3282321863127091_success_5273],
      }
    );
    const act_2386378572842701_emit_5278 = new actions.EmitAction(
      {
        actionArgs: this.act_2386378572842701_emit_5278_getActionArgs,
        events: [],
      }
    );
    const evh_2386378572842701_close_5277 = new actions.EventHandlerImpl(
      {
        action: act_2386378572842701_emit_5278,
        event: "close",
        when: this.evh_2386378572842701_close_5277_getWhen,
        displayName: "emit",
      }
    );
    const act_2386378572842701_showModal_5276 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2386378572842701_showModal_5276_getActionArgs,
        events: [evh_2386378572842701_close_5277],
      }
    );
    const evh_2386378572842701_clickPreventStop_5275 = new actions.EventHandlerImpl(
      {
        action: act_2386378572842701_showModal_5276,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_2386378572842701_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_2386378572842701_clickPreventStop_5275],
      }
    );
    const act_6305342467955186_emit_5280 = new actions.EmitAction(
      {
        actionArgs: this.act_6305342467955186_emit_5280_getActionArgs,
        displayName: "reload",
        events: [],
      }
    );
    const evh_6305342467955186_clickPreventStop_5279 = new actions.EventHandlerImpl(
      {
        action: act_6305342467955186_emit_5280,
        event: "click.prevent.stop",
        displayName: "reload",
      }
    );
    const evh_6305342467955186_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_6305342467955186_clickPreventStop_5279],
      }
    );
    const act_6493473528837603_showModal_5282 = new actions.ShowModalAction(
      {
        actionArgs: this.act_6493473528837603_showModal_5282_getActionArgs,
        events: [],
      }
    );
    const evh_6493473528837603_clickPreventStop_5281 = new actions.EventHandlerImpl(
      {
        action: act_6493473528837603_showModal_5282,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_6493473528837603_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_6493473528837603_clickPreventStop_5281],
      }
    );
    const act_5796712150329752_emit_5286 = new actions.EmitAction(
      {
        actionArgs: this.act_5796712150329752_emit_5286_getActionArgs,
        events: [],
      }
    );
    const evh_5796712150329752_success_5285 = new actions.EventHandlerImpl(
      {
        action: act_5796712150329752_emit_5286,
        event: "success",
        displayName: "emit",
      }
    );
    const act_5796712150329752_request_5284 = new actions.RequestAction(
      {
        actionArgs: this.act_5796712150329752_request_5284_getActionArgs,
        displayName: "createInvoicecopy",
        events: [evh_5796712150329752_success_5285],
      }
    );
    const evh_5796712150329752_click_5283 = new actions.EventHandlerImpl(
      {
        action: act_5796712150329752_request_5284,
        event: "click",
        displayName: "createInvoicecopy",
      }
    );
    const evh_5796712150329752_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_5796712150329752_click_5283],
      }
    );
    const act_4125470329773403_emit_5290 = new actions.EmitAction(
      {
        actionArgs: this.act_4125470329773403_emit_5290_getActionArgs,
        events: [],
      }
    );
    const evh_4125470329773403_success_5289 = new actions.EventHandlerImpl(
      {
        action: act_4125470329773403_emit_5290,
        event: "success",
        displayName: "emit",
      }
    );
    const act_4125470329773403_request_5288 = new actions.RequestAction(
      {
        actionArgs: this.act_4125470329773403_request_5288_getActionArgs,
        displayName: "createStornoInvoice",
        events: [evh_4125470329773403_success_5289],
      }
    );
    const evh_4125470329773403_click_5287 = new actions.EventHandlerImpl(
      {
        action: act_4125470329773403_request_5288,
        event: "click",
        displayName: "createStornoInvoice",
      }
    );
    const evh_4125470329773403_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_4125470329773403_click_5287],
      }
    );
    const act_3785918277944093_emit_5294 = new actions.EmitAction(
      {
        actionArgs: this.act_3785918277944093_emit_5294_getActionArgs,
        events: [],
      }
    );
    const evh_3785918277944093_success_5293 = new actions.EventHandlerImpl(
      {
        action: act_3785918277944093_emit_5294,
        event: "success",
        displayName: "emit",
      }
    );
    const act_3785918277944093_request_5292 = new actions.RequestAction(
      {
        actionArgs: this.act_3785918277944093_request_5292_getActionArgs,
        displayName: "createCorrectorIvoice",
        events: [evh_3785918277944093_success_5293],
      }
    );
    const evh_3785918277944093_click_5291 = new actions.EventHandlerImpl(
      {
        action: act_3785918277944093_request_5292,
        event: "click",
        displayName: "createCorrectorIvoice",
      }
    );
    const evh_3785918277944093_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_3785918277944093_click_5291],
      }
    );
    const act_2191919750581405_emit_5296 = new actions.EmitAction(
      {
        actionArgs: this.act_2191919750581405_emit_5296_getActionArgs,
        events: [],
      }
    );
    const evh_2191919750581405_success_5295 = new actions.EventHandlerImpl(
      {
        action: act_2191919750581405_emit_5296,
        event: "success",
        displayName: "emit",
      }
    );
    const evh_2191919750581405_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_2191919750581405_success_5295],
      }
    );
    const act_3699289115122175_emit_5298 = new actions.EmitAction(
      {
        actionArgs: this.act_3699289115122175_emit_5298_getActionArgs,
        events: [],
      }
    );
    const evh_3699289115122175_success_5297 = new actions.EventHandlerImpl(
      {
        action: act_3699289115122175_emit_5298,
        event: "success",
        displayName: "emit",
      }
    );
    const evh_3699289115122175_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_3699289115122175_success_5297],
      }
    );
    const act_1247093655110851_showModal_5300 = new actions.ShowModalAction(
      {
        actionArgs: this.act_1247093655110851_showModal_5300_getActionArgs,
        events: [],
      }
    );
    const evh_1247093655110851_clickPreventStop_5299 = new actions.EventHandlerImpl(
      {
        action: act_1247093655110851_showModal_5300,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_1247093655110851_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1247093655110851_clickPreventStop_5299],
      }
    );
    return {
      act_8680084583475136_emit_5272,
      evh_8680084583475136_success_5271,
      evh_8680084583475136_success,
      act_3282321863127091_emit_5274,
      evh_3282321863127091_success_5273,
      evh_3282321863127091_success,
      act_2386378572842701_emit_5278,
      evh_2386378572842701_close_5277,
      act_2386378572842701_showModal_5276,
      evh_2386378572842701_clickPreventStop_5275,
      evh_2386378572842701_clickPreventStop,
      act_6305342467955186_emit_5280,
      evh_6305342467955186_clickPreventStop_5279,
      evh_6305342467955186_clickPreventStop,
      act_6493473528837603_showModal_5282,
      evh_6493473528837603_clickPreventStop_5281,
      evh_6493473528837603_clickPreventStop,
      act_5796712150329752_emit_5286,
      evh_5796712150329752_success_5285,
      act_5796712150329752_request_5284,
      evh_5796712150329752_click_5283,
      evh_5796712150329752_click,
      act_4125470329773403_emit_5290,
      evh_4125470329773403_success_5289,
      act_4125470329773403_request_5288,
      evh_4125470329773403_click_5287,
      evh_4125470329773403_click,
      act_3785918277944093_emit_5294,
      evh_3785918277944093_success_5293,
      act_3785918277944093_request_5292,
      evh_3785918277944093_click_5291,
      evh_3785918277944093_click,
      act_2191919750581405_emit_5296,
      evh_2191919750581405_success_5295,
      evh_2191919750581405_success,
      act_3699289115122175_emit_5298,
      evh_3699289115122175_success_5297,
      evh_3699289115122175_success,
      act_1247093655110851_showModal_5300,
      evh_1247093655110851_clickPreventStop_5299,
      evh_1247093655110851_clickPreventStop,
      reload: act_6305342467955186_emit_5280,
      createInvoicecopy: act_5796712150329752_request_5284,
      createStornoInvoice: act_4125470329773403_request_5288,
      createCorrectorIvoice: act_3785918277944093_request_5292,
    }
  }
}

Vue.component("UcInvoiceHeader", UcInvoiceHeader);

</script>